<template>
  <div>
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}">
      {{getSelectedDevice.name}} {{$t('TablesOne.TablesOne')}}
    </div>
    <div class="container-fix mt-2" v-if="hideAccount">
      <v-row class="d-flex align-content-center justify-center" v-if="hideAccount">
        <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
          <cube @controlButtonClick="controlButtonClick"/>
        </v-col>
        <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 offset-xl-1 offset-lg-1">
          <div class="home">
            <div>
              <th-table-one>
              </th-table-one>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import smoothHeight from 'vue-smooth-height'
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue"
import Cube from "./Cube/Cube.vue"
import tableOne from "./Table/ThTable1.vue"
export default Vue.extend({
  mixins:[smoothHeight],
  name: "Tables",
  components: {
    cube: Cube,
    ThTableOne: tableOne,
  },
  data: () => ({
    hideAccount: true
  }),
  computed: {
    ...mapGetters(['getSelectedDevice', 'isMobileDisplay'])
  },
  methods: {
    ...mapActions(['getDevices']),
    controlButtonClick(){
      if(this.getSelectedDevice && this.getSelectedDevice.connection){
        this.$router.push('control').catch(() => {})
      }
    }
  },
  async created(){
    await this.getDevices()
  }
});
</script>
<style lang="sass">
</style>
