<template>
  <div class="container-fix">
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}">
      {{$t('EventLog.EventLog')}}
    </div>
    <v-row no-gutters :class="`${isMobileDisplay?'mt-6':'mt-16'}`">
      <v-col class="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 offset-xl-1" justify="center" align="center">
        <div v-if="getLoadDevices">
          <v-select
            :loading="userDevicesLoading"
            :disabled="userDevicesLoading"
            :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input mt-3'}`"
            outlined
            dark
            v-model="items"
            :items="getLoadDevices"
            @change="getUsersDevice"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :label="$t('EventLog.Object')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          ></v-select>
          <div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              origin="top center"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dark
                  v-model="datesRange"
                  :label="$t('EventLog.SelectDate')"
                  class="menu-picker"
                  :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input mt-3'}`"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                no-title
                scrollable
                range
                :locale="$t('Calendar.locale')"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{$t('EventLog.Cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  :disabled="datesRange.length < 1"
                  @click="$refs.menu.save(datesRange), selectDateRange(datesRange)"
                >
                  {{$t('EventLog.Ok')}}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
      </v-col>
      <v-col class="mt-2 d-flex justify-space-around justify-center col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
        <div class="fix-height wd-100 scroll " v-if="eventLogs.length > 0">
          <v-data-table
            disable-pagination
            dark
            hide-default-footer
            :headers="headersTable"
            :items="eventLogs"
            align="center"
            class="elevation-1"
          >
            <template v-slot:item.message="{ item }">
              <span v-html="item.message"></span>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
export default Vue.extend({
  name: "Account",
  components: {
  },
  data: () => ({
    menu: false,
    isAgreeLoading: false,
    items:[],
    itemsFilters:[
      {
        name: 'По дате, по возрастанию',
        id: 1
      },
      {
        name: 'По дате, по убыванию',
        id: 2
      },
      {
        name: 'Постановка',
        id: 3
      },
      {
        name: 'Снятие',
        id: 4
      },
      {
        name: 'Сработка',
        id: 5
      },
      {
        name: 'Невзятие',
        id: 5
      },
    ],
    userDevicesLoading: true,
    filtersLoading: false,
    activeId: null,
    datesRange: [],
    eventLogs:[],
    headersTable: [
      {
        align: 'center',
        text: 'date',
        sortable: true,
        value: 'date',
      },
      {
        align: 'center',
        text: 'message',
        sortable: false,
        value: 'message',
      }
    ]
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay'])
  },
  async created(){
    try{
      await this.getDevices()
      console.log(this.getLoadDevices)
      this.userDevicesLoading = false

      const device = this.getSelectedDevice
      if(device !== null && device !== undefined && device.id){
        await this.getUsersDevice(device.id)
        this.items = this.activeId
      }
      this.headersTable[0].text = this.$t('EventLog.Date')
      this.headersTable[1].text = this.$t('EventLog.Message')
    }
    catch(e){
      this.userDevicesLoading = false
    }
  },
  methods: {
    ...mapActions(['getDevices', 'getUsersByDevice', 'getDeviceEventLog']),
    ...mapMutations(['selectDeviceById']),
    async getUsersDevice(deviceId){
      this.selectDeviceById(deviceId)
      this.activeId = deviceId
      try{
        let result = await this.getUsersByDevice(deviceId)
        console.log(result)
      }
      catch(e){
        console.log(e)
      }
    },
    async selectDateRange(range){
      this.datesRange = range
      console.log(range)
      if(range.length > 0 && this.activeId != null && this.activeId > 0){
        this.eventLogs = []
        try{
          const result = await this.getDeviceEventLog({
            deviceId: this.activeId,
            timeStart: `${range[0]}T00:00:00.411Z`,
            timeEnd: `${range.length == 1 ? range[0] : range[1]}T23:59:59.411Z`
          })
          result.eventLogResponse.reverse().forEach(d => {
            const data = {
              date: d.date,
              message: d.rawMessage
            }/*
            if(d.eventLogType == 0){
              data.message = `${d.message} поставлен под охрану (${d.address})`
            }
            else if(d.eventLogType == 1){
              data.message = `${d.message} снят с охраны (${d.address})`
            }
            else if(d.eventLogType == 5){
              data.message = `${d.message} сработала ОПС (${d.address})`
            }
            else if(d.eventLogType == 4){
              data.message = `${d.message} невзятие ОПС (${d.address})`
            }
            else if(d.eventLogType == 6){
              data.message = `Произошло событие ${d.message} (${d.address})`
            }*/
            this.eventLogs.push(data)
          })
          console.log(result)
        }
        catch(e){
          console.log(e)
        }
      }
    },
  }
});
</script>
<style scoped>
.v-list-item__content{
  color: rgba(255, 255, 255, 0.87) !important;
}
.max-width-fix > .v-input > .v-input__control > .v-input__slot {
  max-width: 90% !important;
}
.v-select-list {
  max-width: 100% !important;
}
.fix-height{
  height: 500px !important;
  display: inline-block;
  width: 100%;
}
.scroll{
  overflow-y: scroll;
}
.wd-100{
  width: 90% !important;
  height: 500px;
}
.v-data-table__wrapper > table > .v-data-table-header > tr > th {
  border: thin solid rgba(255, 255, 255, 0.12) !important;
  background-color: black;
  color: white !important;
  font-weight: 400;
  font-size: 0.9em !important;
}
.v-data-table__wrapper > table > tbody > tr >td {
  border: thin solid rgba(255, 255, 255, 0.12) !important;
}
.theme--dark.v-data-table{
  background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar {
  width: 0;
}
</style>