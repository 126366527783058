import axios from 'axios'

const StreamAPI = axios.create ({
  baseURL: `${process.env.VUE_APP_ui_video_url}`,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`
  }
})

export default StreamAPI