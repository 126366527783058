<template>
  <div>
    <v-row>
      <v-col cols="12">
        <canvas id="chart1" v-show="chartOneLoad"></canvas>
        <canvas id="chart2" v-show="!chartOneLoad"></canvas>
      </v-col>
      <v-col cols="12">
        <v-row class="d-flex justify-space-around justify-center">
          <v-col cols="3">
            <div>
              <v-select
                :loading="selectController.loading"
                :disabled="selectController.disabled"
                outlined
                dark
                v-model="selectController.selected"
                :items="selectController.items"
                :hint="`${selectController.items.data}, ${selectController.items.id}`"
                @change="selectControllerNameChange(selectController.selected)"
                item-text="data"
                item-value="id"
                item-color="#000"
                item-disabled="connection"
                label="OBJECTS"
                :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
              >
              </v-select>
            </div>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dark
                  v-model="datesRange"
                  label="SELECT DATE"
                  class="menu-picker"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                :disabled="selectController.items.length == 0"
                no-title
                scrollable
                range
                :locale="$t('Calendar.locale')"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false, clearChart()"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  :disabled="selectController.items.length == 0"
                  @click="$refs.menu.save(datesRange), selectDateRange(datesRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <div class="mt-1">
              <v-btn
                @click.stop="$emit('openBigMap')"
                icon
                fab
                class="mx-2"
                color="white"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import storageService from '../../services/storageService'
import Chart from 'chart.js'
import {  mapActions, mapGetters, mapMutations } from 'vuex'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import ChartZoom from 'chartjs-plugin-zoom'
import ChartSreaming from 'chartjs-plugin-streaming'
import zoomChartData from '../Chart/zoomChartData.js'
import chartData from '../Chart/chartData.js'
import { date } from 'joi'
const config = process.env.VUE_APP_ui_api_url

export default {
  data: () => ({
    stopGetData:false,
    datesRange:[],
    menu:false,
    selectController:{
      disabled: false,
      loading: false,
      selected: null,
      items: [],
      allItems: []
    },
    chartOneLoad: true,
    zoomChartData: zoomChartData,
    chartData: chartData,
    charts:[],
    connection: null,
    tempSensors: [],
    serialNumber: null
  }),
  computed: {
    ...mapGetters(['filterState', 'getUser', 'getAccessToken', 'getLoadDevices', 'getChartOneLastValue', 'getSelectedDeviceSerialNumber', 'getSelectedDevice'])
  },
  methods: {
    ...mapActions(['loadStateTypes', 'loadStateSensorsByTypes', 'getDevices', 'GetSensorsData']),
    ...mapMutations(['updateChartOne', 'selectDeviceById', 'selectDevice']),
    clearChart(){
      this.chartOneLoad = true
      if(this.charts[0]) this.charts[0].destroy();
      if(this.charts[1]) this.charts[1].destroy();
      this.$root.$emit('clearChart')
      const ctx1 = document.getElementById('chart1').getContext('2d')
      if(this.charts.length == 0)
      this.charts.push(new Chart(ctx1, this.zoomChartData))
      else
      this.charts[0] = new Chart(ctx1, this.zoomChartData)
    },
    async selectDateRange(dataRange){
      this.chartOneLoad = false
      console.log(dataRange)
      this.connection.stop()
      this.tempSensors = []
      this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
      if(this.charts[0]) this.charts[0].destroy();
      //this.zoomChartData.options.scales.xAxes[0] = this.zoomChartData.options.scales.xAxes[0]
      this.updateChartOne(this.tempSensors)
      try {
        this.stopGetData = true
        this.chartData.data.labels = []
        this.chartData.data.datasets = []
        const ctx2 = document.getElementById('chart2').getContext('2d')
        if(this.charts.length == 1)
        this.charts.push(new Chart(ctx2, this.chartData))
        else
        this.charts[1] = new Chart(ctx2, this.chartData)
        console.log(this.selectController.items)
        this.chartData.rawChart = this.charts[1]
        console.log(this.chartData.rawChart)
        await this.selectController.items.forEach(async (item, ind) => {
          const dataset = {
            label: item.raw.Name + item.raw.Descriptor + item.raw.PanelControlName,
            data: [],
            borderColor: '#76A5F5',
            borderWidth: 1,
            backgroundColor: 'rgba(118,165,245, 0.07)',
          }
          let type = 0
          console.log(item.raw.Descriptor)
          if(item.raw.Descriptor == '.T.') type = 0
          else if(item.raw.Descriptor == '.V.') type = 1
          else if(item.raw.Descriptor == '.P.') type = 2
          const info = {
            dateStart: this.datesRange[0] + 'T00:00:00.411Z',
            dateEnd: this.datesRange[1] + 'T00:00:00.411Z',
            deviceId: item.raw.DeviceId,
            panelControlId: item.raw.PanelControlId,
            type: type
          }
          const res = await this.GetSensorsData(info)
          console.log(res.sensorDatas)
          res.sensorDatas.forEach((data, indf) => {
            if(ind == 0){
              this.chartData.data.labels.push(data.date.substr(11, 5))
            }
            dataset.data.push(data.state)
          })
          this.chartData.data.datasets.push(dataset)
          this.charts[this.charts.length-1].update()
          console.log(this.chartData)
        })



      } catch(e){
        console.log(e)
      }
    },
    connectedToHub(){
      this.connection = new HubConnectionBuilder()
      .withUrl(`${config}/state`, {
        accessTokenFactory: () => this.getAccessToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Error)
      .build()

      this.connection.start()
      .then(() => {
        console.log('state Connected')
      })
      .catch((err) => {
        return console.error('err ' + err)
      })

      this.connection.on("ReceiveSubscribeStatus", (message) => {
        if(this.stopGetData) return;
        const element = JSON.parse(message)
        if(element){
          if(element.State.length > 12){
            const dataSens = element.State.slice(1, 6)
            const temp = element.State.slice(9)

            let tempOut = temp[0] == '0' ? '' : '-'
            tempOut += temp[1] + temp[2] + '.' + temp[3]
            
            if(element.State.includes('.T.') && dataSens != '01.01'){
              if(this.tempSensors.filter(sensor => sensor.key == dataSens + element.Id).length == 0){
                this.tempSensors.push({key: dataSens+element.Id, value: tempOut})
              }else{
                this.tempSensors.filter(sensor => sensor.key == dataSens + element.Id)[0].value = tempOut
              }
              this.updateChartOne(this.tempSensors)
            }
            else if(element.State.includes('.P.') && dataSens != '01.01'){
              let pressOut = temp[0] + temp[1] + temp[2]
              if(this.tempSensors.filter(sensor => sensor.key == dataSens + element.Id).length == 0){
                this.tempSensors.push({key: dataSens + element.Id, value: pressOut})
              }else{
                this.tempSensors.filter(sensor => sensor.key == dataSens + element.Id)[0].value = pressOut
              }
              this.updateChartOne(this.tempSensors)
            }
            else if(element.State.includes('.V.') && dataSens != '01.01'){
              let humOut = temp[1] + temp[2] + '.' + temp[3]
              if(this.tempSensors.filter(sensor => sensor.key == dataSens + element.Id).length == 0){
                this.tempSensors.push({key: dataSens+element.Id, value: humOut})
              }else{
                this.tempSensors.filter(sensor => sensor.key == dataSens + element.Id)[0].value = humOut
              }
              this.updateChartOne(this.tempSensors)
            }
          }
          //this.updateChartOne(this.tempSensors)
        }
      })
      this.connection.on("ReceiveMessage", (message) => {})
      this.connection.on("ReceiveStatus", (message) => {})
      this.connection.onclose(e => {})
    },
    selectControllerNameChange(data){
      this.$root.$emit('OpenBigMapGlobalAndFocusByDeviceId', data)
    },
    async mountData(){
      return
      this.connectedToHub()
      const ctx1 = document.getElementById('chart1').getContext('2d')
      if(this.charts.length == 0)
      this.charts.push(new Chart(ctx1, this.zoomChartData))
      else
      this.charts[0] = new Chart(ctx1, this.zoomChartData)
      this.$root.$on('updateChartFull', () => {
        console.log('updateChartFull')
        this.connection.stop()
        this.connectedToHub()
        this.tempSensors = []
        this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
        this.updateChartOne(this.tempSensors)
      })
      this.$root.$on('subscribeSensor', async (data) => {
        this.selectController.items.push({id: data.DeviceId, data: data.Name, raw: data})
        this.selectController.selected = data.DeviceId
        console.log(data)
        this.mountData()
        if(this.chartOneLoad){
          this.connection.invoke("SubscribeGettingValuesDeviceById", data)
          .then(res => {
            console.log(res)
          })
          .catch(e => {
            console.log(e)
          })
        }else{
          const item = this.selectController.items[this.selectController.items.length-1]
          const dataset = {
            label: item.raw.Name + item.raw.Descriptor + item.raw.PanelControlName,
            data: [],
            borderColor: '#76A5F5',
            borderWidth: 1,
            backgroundColor: 'rgba(118,165,245, 0.07)',
          }
          let type = 0
          console.log(item.raw.Descriptor)
          if(item.raw.Descriptor == '.T.') type = 0
          else if(item.raw.Descriptor == '.V.') type = 1
          else if(item.raw.Descriptor == '.P.') type = 2
          const info = {
            dateStart: this.datesRange[0] + 'T00:00:00.411Z',
            dateEnd: this.datesRange[1] + 'T00:00:00.411Z',
            deviceId: item.raw.DeviceId,
            panelControlId: item.raw.PanelControlId,
            type: type
          }
          const res = await this.GetSensorsData(info)
          console.log(res.sensorDatas)
          res.sensorDatas.forEach((data, indf) => {
            //if(ind == 0)
            //this.chartData.data.labels.push(data.date.substr(11, 8))
            dataset.data.push(data.state)
          })
          this.chartData.data.datasets.push(dataset)
          this.charts[this.charts.length-1].update()
        }
      })
      this.$root.$on('updateChart', (data) => {
        console.log(data)
        const buff = []
        data.forEach(object => {
          object.object.openSensorTypes.forEach(sensor => {
            const key = sensor.PanelElementId[0]+sensor.PanelElementId[1]+'.'+sensor.PanelElementId[2]+sensor.PanelElementId[3]
            const findData = this.tempSensors.filter(sensor => sensor.key == key)
            if(findData.length > 0){
              buff.push(findData[0])
            }
          })
        })
        console.log(this.tempSensors)
        this.tempSensors = buff
      })
      if((this.getAccessToken || storageService.getToken()) && (this.getSelectedDeviceSerialNumber || storageService.getSelectedDevice())){
        this.serialNumber = this.getSelectedDeviceSerialNumber? this.getSelectedDeviceSerialNumber : storageService.getSelectedDevice()
        console.log(this.serialNumber)
        storageService.setSelectedDevice(this.serialNumber)
        storageService.setSelectedDeviceId(this.getSelectedDevice.id)
      }
    }
  },
  async mounted(){
    this.mountData()
  },
  beforeDestroy(){
    this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
    this.chartData.options.scales.xAxes[0].realtime.onRestart()
    if(this.charts[0]) this.charts[0].destroy();
    this.tempSensors = []
    this.updateChartOne(this.tempSensors)
    this.connection.stop()
  }
};
</script>
<style>
.dv-btn-micro{
  font-size: 12pt !important;
  width: 160px !important;
  max-width: 250px !important;
  height: 30pt !important;
  font-weight: 400 !important;
  color: white !important;
  border: 1px solid #fff;
  background-color: rgba(39, 39, 39, 0) !important;
  margin-top: -40px;
}
.dv-btn-micro:hover{
  background-color: rgba(0, 0, 0, 1) !important;
}
.mt-fix{
  margin-top: -40px;
}
.v-picker__actions{
  background-color: rgba(0, 0, 0, 1) !important;
}
.menu-picker > .v-input__control > .v-input__slot>.v-text-field__slot > input{
  /*margin-left: 20px !important;*/
  height: 100% !important;
}
</style>