<template>
  <Plotly
    :data="data"
    :layout="layout"
    :display-mode-bar="false"
    :scrollZoom="true"
  ></Plotly>
</template>
<script>
import { bool } from 'joi'
import { Plotly } from 'vue-plotly'
 
export default {
  components: {
    Plotly
  },
  props:{
    plotlyData: Array,
    isMini: Boolean
  },
  data: () => ({
    data:[],
    layout:{
      showlegend: true,
      legend: {
        x: 0,
        y: 1.2,
        orientation: "h"
      },
      uirevision: true,
      hovermode:'closest',
      title: "My graph",
      plot_bgcolor: 'rgba(0,0,0,0)',
      paper_bgcolor: 'rgba(0,0,0,0)',
      autoscale: false,
      autosize: false,
      width: '100%',
      height: 363,
      margin: {
        l: 0,
        r: 0,
        pad: 4
      },
      x0: 0,
      y0: 12.0,
      x1: 1,
      y1: 12.0,
      font: {
        color: 'white',
        size: 12,
      },
      xaxis: {
        title: 'GDP per Capita',
        showgrid: false,
        zeroline: false,
        range: ['2021-11-21', '2021-11-22'],
        rangemode:'tozero',
        autorange:true,
        linecolor: 'rgba(0,0,0,0)',
        linewidth: 10,
        tickformat: '%H:%M',
        nticks: 12,
        tickmode: 'auto',
        tickfont: {
          size: 12
        }
      },
      yaxis: {
        title: 'Percent',
        showgrid: false,
        showline: false,
        range: [15, 35],
        autorange:true,
        automargin: true,
        tickformat: d3.format(".2f"),
        nticks: 12,
        tickmode: 'auto',
        tickfont: {
          size: 12
        }
      }
    },
    options: {
      scrollZoom: true,
      staticPlot: true
    }
  }),
  created(){
    console.log('createdPlotly')
    this.layout.height = this.isMini ? 363 : '100%'
    this.layout.xaxis.title = ''
    this.layout.yaxis.title = ''
    this.layout.title = ''
    this.$root.$on('clearSingleUpdate', () => {
      this.data = []
    })
    this.$root.$on('chartSingleUpdate', (data) => {
      console.log('chartSingleUpdate')
      let dt = new Date(data.rawData[0].date)
      let dq = new Date(data.rawData[0].date)
      let maxState = 0
      data.rawData.forEach(element => {
        if(parseFloat(element.state) > maxState){
          maxState = parseFloat(element.state)
        }
      });
      dt.setDate(dt.getDate() + 1);
      dq.setDate(dt.getDate() + 1);
      if(dt>dq){
        const dtn = new Date(dt)
        dt = new Date(dq)
        dq = new Date(dtn)
      }
      this.layout.xaxis.range = [dt.toJSON().substr(0,10), dq.toJSON().substr(0,10)]
      this.layout.yaxis.range = [0, maxState+maxState*0.25]

      this.data.push({
        x: [],
        y: [],
        mode: 'lines+markers',
        line: {
          width: 3,
          shape: 'spline',
          color: 'rgba(0, 0, 0, 0)',
        },
        /* */
        name: data.name,
        fill: 'tozeroy',
        fillcolor: 'rgba(17, 157, 255, 0.1)',
        marker: {
          color: 'rgba(17, 157, 255, 0.7)',
          size: 3
        },
      })
      data.rawData.forEach(da => {
        let dt = new Date(da.date)
        let ds = new Date()
        const dts = this.data[this.data.length-1]
        dts.x.push(dt)
        dts.y.push(ds > dt? da.state:'') 
        dts.hovertemplate = `<b>${this.$t('Charts.Tooltip.Object')}</b>: ${data.hover.name}<br><b>${this.$t('Charts.Tooltip.Sensor')}</b>: ${data.hover.controlName}<br><b>${this.$t('Charts.Tooltip.Date')}</b>: ${da.date.substr(0,10)}<br><b>${this.$t('Charts.Time')}</b>: %{x}<br><b>${this.$t('Charts.Tooltip.State')}</b>: %{y}` + data.descriptor + '<extra></extra>',
        dts.hoverlabel = {
          bgcolor: 'rgb(0,0,0)',
          opacity: 0,
          bordercolor:  'rgba(0,0,0,0)',
          namelength: "",
          font:{
            color: 'white'
          } 
        }
      })
    })
  },
  beforeDestroy(){
    this.$root.$off('clearSingleUpdate')
    this.$root.$off('chartSingleUpdate')
  }
}
</script>

<style scoped>

</style>