const swipe = require('jquery-touchswipe')
const $ = require('../../Libs/jquery-3.4.1.min.js')
var _autoRotateTime = 10; //через сколько сек начнется автокручение

var cubeZoom = 1;
var cubeStepPos = 0;
let tm
let y = 0;
let x = 0;
var rotX = 0,
    rotY = 0;
var lastMouseX = 0,
    lastMouseY = 0;

    var downTarget, upTarget;


var clicked = false;
var IsMouse = true;
var rotateTimeout = null;


module.exports = {
  start(){
    cubeScale();
    setRandomColor2();
    LinkNotDrag();
  
    $(window).on("mousedown touchstart",function(e) {
      downTarget = e.target;
       //$("#debugText").text(downTarget.className);
      if($(e.target).closest("#shape").length){
        SpinStop();
      }
    });
  
    $(window).on("mouseup touchend",function(e) {
      upTarget = e.target;
    
  
      if(((upTarget.className == "cubeContainer" && 
      downTarget.className == "cubeContainer") || 
      (upTarget.id == "container" && 
      downTarget.id == "container")
      ) && 
      !$(e.target).closest("#shape").length){
        //SpinStart();
      }
    });
  
    $('.spinstart').click(function () {
      SpinStart();
    });
  
    $('.spinstop').click(function () { 
      SpinStop();
    });
  
    $(".zoomIn").click(function () { zoomIn(); });
    $(".zoomOut").click(function () { zoomOut(); });
  
    $('.btn-bm').click(function () {
      showFace($(this).attr("data-action"));
    });
  
    $('.btnTop').click(function () { runTimer(); up(); });
    $('.btnLeft').click(function () { runTimer(); left(); });
    $('.btnBottom').click(function () { runTimer(); down(); });
    $('.btnRight').click(function () { runTimer(); right(); });
  
    document.addEventListener('gesturestart', function(e) {
      e.preventDefault();
    });
  
    document.addEventListener('gesturechange', function(e) {
      e.preventDefault();
      if (e.scale < 1.0) {
        zoomOut("slow");
      } else if (e.scale > 1.0) {
        zoomIn("slow");
      }
    });
  
    document.addEventListener('gestureend', function(e) {
      e.preventDefault();
    });
  
  
    document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) { event.preventDefault(); }
    }, false);
  
    $(document).on("mousedown", function (ev) {
      IsMouse = true;
      lastMouseX = ev.clientX;
      lastMouseY = ev.clientY;
      clicked = $("#shape:hover").length > 0;
  
      if (clicked) {
        $(document).on("mousemove", mouseMoved);
      }
  
    });
    $(document).on("mouseup", function () {
      clicked = false;
      ////console.log(clicked);
      $(document).off("mousemove", mouseMoved);
    });
  
    $("#shape").on("touchstart", function (ev) {
      IsMouse = false;
     // handle_pinch_zoom(ev);
      lastMouseX = ev.touches[0].clientX;
      lastMouseY = ev.touches[0].clientY;
      $(document).on("touchmove", mouseMoved);
  
    });
    $(document).on("touchend", function () {
  
      $(document).off("touchmove", mouseMoved);
    });
    try{
      $(document).swipe({
        pinchStatus:function(event, phase, direction, distance , duration , fingerCount, pinchZoom, fingerData)
        {
          if(distance < 50 && fingerCount == 2){
            if(pinchZoom > 1.03){
              zoomIn("slow");
            } else if(pinchZoom < 1){
              zoomOut("slow");
            }
          }
        },
        fingers:2,  
        pinchThreshold:40,
        preventDefaultEvents: false
      }); 
    } catch(e){
      ////console.log(e)
    }

    if($('#shape')[0])
    addOnWheel($('#shape')[0], function (e) {
      var delta = e.deltaY || e.detail || e.wheelDelta;
      if (delta > 0) {
        zoomOut("fast");
      }
      else {
        zoomIn("fast");
      };
      e.preventDefault();
    });
    autoRotate();
    scaleFunc();
  }
}

function showFace(current) {
  //console.log('showFace')
  runTimer();

  y = 0;
  x = 0;

  cubeZoom = 0;
  cubeStepPos = 0;

  if ($("#shape").hasClass("spin")) {
    SetCurrentCubePosition();
    setTimeout(() => {
      $('#shape').addClass(current);
	  $('#shape').addClass("stop");
      $("#shape").removeClass("notAllTransition");
    }, 40);
  } else {

    $("#shape").removeClass().addClass(current);
	$('#shape').addClass("stop");
    $("#shape").removeClass("notAllTransition");
  }

}

function SetCurrentCubePosition(){
  //console.log('SetCurrentCubePosition')
  var matrix = window.getComputedStyle($("#shape")[0], null).getPropertyValue("transform");
  $("#shape").addClass("notAllTransition");
  $("#shape")[0].style.transform = matrix;
  $("#shape").removeClass("spin stop");
}

function setRandomColor() { //цвета задаются для всех подблоков сразу
  $("#shape>div>div").each(function (i) {
    //$(this).css("background-color", getRandomColor()); //случайный цвет

    //$(this).css("background-color", getRandomShades("blue")); //получаем оттенки синего
    //$(this).css("background-color", getRandomShades("green")); //получаем оттенки зеленого
    //$(this).css("background-color", getRandomShades("red"));  //получаем оттенки красного


    $(this).css("background-color", rainbowColor(i));
  });
}


function setRandomColor2() { //цвета задаются для подблоков отдельно каждой грани
  //console.log('setRandomColor2')
  $("#shape>div").each(function () {
    /*   $(this).find(">div").each(function (i) {
       
        $(this).css("background-color", rainbowColor(i));
      }); */

    for (var i = 0; i < 5; i++) {


      for (var j = 0; j < 5; j++) {

        $(this).find(">div").eq(j + (i * 5)).css("background-color", rainbowColor(i + j));
      }

    }
  });
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getRandomShades(color) {
  var max = 255;
  var min = 100;
  var shade = Math.floor(Math.random() * (max - min + 1)) + min;
  switch (color) {
    case "green": return "rgb(0," + shade + ",0)";
    case "red": return "rgb(" + shade + ",0,0)";
    case "blue": return "rgb(0,0," + shade + ")";
    default: return "rgb(0,0," + shade + ")";
  }
}

function rainbowColor(i) {
  //console.log('rainbowColor')
  return "hsl(" + (360 * i / 10) + ",80%,50%)";
}

function getCurrentCubeStyle(){
  var style = $("#shape").attr("style");
  style=style!=null? style.replace("transform:","") : "";
  return style;
}

/* function zoomIn(_speed) {
 
  if(cubeZoom>1200){
    return false;
  }

  if ($("#shape").attr("class")!="" && $("#shape").attr("class")!="Transition") {
    SetCurrentCubePosition();
  }
  $("#shape").addClass("Transition");

  var style = getCurrentCubeStyle();

  cubeZoom += _speed='fast' ? 80 : 20;
  cubeStepPos -= _speed='fast' ? 20 : 5;

  var transition = " translateZ("+cubeZoom+"px) translateY(" + cubeStepPos + "px) ";

  $("#shape").attr("style", "transform:" + transition.trim() + style);

  //$(".cubeContainer").css("transform", "scale(" + cubeZoom + ") translateY(" + cubeStepPos + "px)");
}

function zoomOut(_speed) {
 
  if ($("#shape").attr("class")!="" && $("#shape").attr("class")!="Transition") {
    SetCurrentCubePosition();
  }
  $("#shape").addClass("Transition");
   
  var style = getCurrentCubeStyle();

  cubeZoom -= _speed='fast' ? 80 : 20;
  cubeStepPos += _speed='fast' ? 20 : 5;

  var transition = " translateZ("+cubeZoom+"px) translateY(" + cubeStepPos + "px) ";

  $("#shape").attr("style", "transform:" + transition.trim() + style);

 // $(".cubeContainer").css("transform", "scale(" + cubeZoom + ") translateY(" + cubeStepPos + "px)");
} */

function zoomIn(_speed) {
  return false;
  cubeZoom += _speed == "slow"? 0.05 : 0.1;
  //cubeStepPos -= 10;
  $(".cubeContainer").css("transform", "translateY(" + cubeStepPos + "px) scale(" + cubeZoom + ")");
}

function zoomOut(_speed) {
  return false;
  if (cubeZoom <= 0.2) {
    return false;
  }
  cubeZoom -= _speed == "slow"? 0.05 : 0.1;
//  cubeStepPos += 10;
  $(".cubeContainer").css("transform", "translateY(" + cubeStepPos + "px) scale(" + cubeZoom + ")");
}


function rotate() {
  //console.log('rotate')
  if ($("#shape").hasClass("spin")) {
    SetCurrentCubePosition();
    setTimeout(() => {
      $("#shape")[0].style.transform = `rotateX(${x}deg) rotateY(${y}deg)`;
    }, 40);
  } else {

    $("#shape").removeClass();
    $("#shape")[0].style.transform = `rotateX(${x}deg) rotateY(${y}deg)`;
  }

};


function up() {
  //console.log('up')
  x += 45;
  rotate();
  return x;
};
function down() {
  //console.log('down')
  x -= 45;
  rotate();
  return x;
};
function left() {
  //console.log('left')
  y -= 45;
  rotate();
  return y;
};
function right() {
  //console.log('right')
  y += 45;
  rotate();
  return y;
};



function mouseMoved(ev) {
  //console.log('mouseMoved')

  runTimer();

  if(!IsMouse && ev.touches.length>1){
    return false;
  }

  if (!IsMouse) {
    ev.pageX = ev.touches[0].pageX;
    ev.pageY = ev.touches[0].pageY;
  }

  var deltaX = ev.pageX - lastMouseX;
  var deltaY = ev.pageY - lastMouseY;

  lastMouseX = ev.pageX;
  lastMouseY = ev.pageY;

  var matrix = window.getComputedStyle($("#shape")[0], null).getPropertyValue("transform");
  if(matrix!="none" && matrix != "matrix(1, 0, 0, 1, 0, 0)"){
  //var l = new WebKitCSSMatrix(matrix);
   //#region Получение текущей позиции вращения
   var myTransform = matrix.split(' '); 
   var myX = myTransform[6].split(",")[0]; 
   var angleX = Math.round(Math.asin(myX) * (180/Math.PI)); 
   var myY = myTransform[8].split(",")[0]; 
   var angleY = Math.round(Math.asin(myY) * (180/Math.PI)); 

  var values = matrix.split('(')[1].split(')')[0].split(','),
  pi = Math.PI,
  sinB = parseFloat(values[8]),
  b = Math.round(Math.asin(sinB) * 180 / pi),
  cosB = Math.cos(b * pi / 180),
  matrixVal10 = parseFloat(values[9]),
  a = Math.round(Math.asin((-matrixVal10 / cosB).toFixed(2)) * 180 / pi),
  matrixVal1 = parseFloat(values[0]),
  c = Math.round(Math.acos((matrixVal1 / cosB).toFixed(2)) * 180 / pi);

  var rotateX = 0,
      rotateY = 0,
      rotateZ = 0;

      rotateX = a;
      rotateY = b;
      rotateZ = c;
      
  /*     //console.log("x:"+rotateX);
      //console.log("y:"+rotateY);
      //console.log("z:"+rotateZ); */


  if($("#shape").hasClass("spin") || $("#shape").is("[class*='show']")){
    ////console.log(angleX + " " + rotateZ);


    rotY = rotateZ>170 ? rotateZ - angleY :angleY;
    rotX = rotateZ>170 ? rotateZ - angleX :angleX;

    if(angleX == 0 && rotateZ>170 ){
      rotX = angleX;
      rotY = rotateZ;
    }
    
/*     if(isNaN(rotateZ)){
      rotateZ = 0;
    }
    if(!isNaN(rotateX) && !isNaN(rotateX) && !isNaN(rotateZ)){
   

      rotateX = rotateX - rotateZ;  //rotateZ - Math.abs(rotateX);
    
    if(rotateZ>0){
      rotateY= Math.abs(rotateZ) - rotateY;//rotateZ - Math.abs(rotateY);
    }
     
      rotY = rotateY;
      rotX = rotateX;
    } */
  
  } 
  } else if (matrix == "matrix(1, 0, 0, 1, 0, 0)"){
    rotY = 0;
    rotX = 0;
  }
  //#endregion Получение текущей позиции вращения

  rotY -= deltaX * -0.2;
  rotX += deltaY * -0.2;

  if ($("#shape").hasClass("spin")) {
    SetCurrentCubePosition();
    setTimeout(() => {

      $("#shape").css(
        {
          "transform": "rotateX( " + rotX + "deg) rotateY(" + rotY + "deg)"
        }
      );
      
    }, 40);
  } else {

    $("#shape").removeClass();
 // //console.log(Math.abs(deltaX)+","+Math.abs(deltaY))
    if(!$("#shape").is(".Transition") &&(Math.abs(deltaX)>15 || Math.abs(deltaY)>15)){
      $("#shape").removeClass("notTransition");
      $("#shape").addClass("Transition");
    } else{
        $("#shape").removeClass("Transition");
        $("#shape").addClass("notTransition");
    }
    

     $("#shape").css(
      {

        "transform": "rotateX( " + rotX + "deg) rotateY(" + rotY + "deg)"
      }
    ); 
  }
}

function uploadArchive(_this) {
  var $input = _this;
  var fd = new FormData;

  fd.append('archive', $($input).prop('files')[0]);

  $.post({
    url: '/Content/Upload', //путь загрузки архива
    data: fd,
    processData: false,
    contentType: false
  }).done(function (data) {
    $("#shape>div>div").css({
      "background": "url('img/mountain.jpg')",
      "background-size": "cover"
    });
  })
    .fail(function (data) {
      $("#shape>div>div").css({
        "background": "url('img/mountain.jpg')",
        "background-size": "cover"
      });
    });
}
let moved = false;
function LinkNotDrag() {
  //console.log('LinkNotDrag')
  $("#shape a").on('click', function (evt) {
    evt.preventDefault();
    return false;
  });


  $("#shape a").on('mousedown', function (evt) {
    moved = false;
    evt.preventDefault();
  });

  $("#shape a").on('mousemove', function (evt) {
    moved = true;
    evt.preventDefault();
  });

  $("#shape a").on('mouseup', function (evt) {

    if (moved) {
      //console.log('moved');
  } else {
    //console.log('not moved');
    location.href = $(this).attr("href");
  }
  });

  $('#shape a').each(function () {
    $(this).attr("draggable","false");
  });
  $('#shape a').on("drag", function () {
    return false;
  });
  $('#shape a').on("focus", function () {
    return false;
  });
  $('#shape a').on("dragstart", function () {
    return false;
  });
  document.oncontextmenu = function () {
    return false;
  };
}

function addOnWheel(elem, handler) {
  //console.log('addOnWheel')
  if (elem.addEventListener) {
    if ('onwheel' in document) {
      // IE9+, FF17+
      elem.addEventListener("wheel", handler);
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      elem.addEventListener("mousewheel", handler);
    } else {
      // 3.5 <= Firefox < 17, более старое событие DOMMouseScroll пропустим
      elem.addEventListener("MozMousePixelScroll", handler);
    }
  } else { // IE8-
    elem.attachEvent("onmousewheel", handler);
  }
}


function SpinStart(){
  /*$('#shape').removeClass('show-front');
  $('#shape').removeClass('show-right');
  $('#shape').removeClass('show-back');
  $('#shape').removeClass('show-top');
  $('#shape').removeClass('show-bottom');
  $('#shape').removeClass('show-left');
  $('#shape').removeClass('stop');
  $('#shape').css("transform", "rotateX(15deg) rotateY(52.8deg)")
  $("#shape>div>div").css("transform", "none").removeClass("hovered");
  $('#shape').addClass("spin");
  return
  //console.log('SpinStart')*/
 
  $("#shape>div>div").css("transform", "none").removeClass("hovered");
  $('#shape').removeClass("stop");
  if($('#shape').hasClass("show-front")){
    $('#shape').removeClass("show-front");
    $('#shape').addClass("spin");
  }

  y = 0;
  x = 0;
  if (!$('#shape').hasClass("spin") && !$('#shape').hasClass("show-front")) {
    $('#shape').removeClass();
    $('#shape').addClass('fast-show-front');
    $('#shape').addClass('stop');

    $('#shape').css("transform", "none");

    setTimeout(() => {
      $('#shape').addClass('spin').removeClass('stop').removeClass('fast-show-front')
                 
    }, 1060);

  } else {
    $('#shape').removeClass("stop");
    if($('#shape').hasClass("show-front")){
      $('#shape').removeClass("show-front");
      $('#shape').addClass("spin");
    }
   
  }
};

function SpinStop(){
  //console.log('SpinStop')
  runTimer(); 
  var matrix = window.getComputedStyle($("#shape")[0], null).getPropertyValue("transform");
  $('#shape').addClass('notAllTransition');
  $("#shape")[0].style.transform = matrix;
  
  $('#shape').addClass('stop');
}

function autoRotate() {
  //console.log('autoRotate')
    runTimer();
    $("#shape").hover(function () {
        clearInterval(rotateTimeout);
    }, function(){
      runTimer();
    });
}

function runTimer(){
  //console.log('runTimer')
  clearInterval(rotateTimeout);
  rotateTimer(_autoRotateTime);
}

function rotateTimer(_Seconds) {
  //console.log('rotateTimer')
    if(isIphone()){ // отключить для айфонов автостарт
      return false;
    }
    var first_Seconds = _Seconds;
    rotateTimeout = setInterval(function () { 
        if (_Seconds > 0) {
            if ($("#shape:hover").length === 0 || detectMob()) {
                _Seconds--;
            } else {
                _Seconds = first_Seconds;
            }
         //  console .log(_Seconds);
          //$("#debugText").text(_Seconds);

        } else {
            clearInterval(rotateTimeout); 
            ////console.log(_Seconds);
           
            if(!$("#shape").hasClass("spin") || $("#shape").hasClass("stop")){
              SpinStart();
            }
           
        }
    }, 1000);
}

function scaleFunc(){
  //console.log('scaleFunc')
  var scaleTimer;
  var showFaceTimer;
  $("#shape>div>div").hover(function(){
    runTimer();
    _this = this;
    scaleTimer = setTimeout(function(){

      var rowNum = Math.ceil($(_this).index() / 3);
      var colNum = Math.floor($(_this).index() % 3);
      colNum = colNum == 0 ? colNum+3 : colNum;
      $(_this).css("transform", "translate("+(200 - (colNum * 100))+"%," + (200 - (rowNum * 100)) + "%) scale(3.1)")
              .addClass("hovered");

     //  $(_this).parent().find(">div").not(_this).addClass("hiddenFace");
     //  $("#shape>div").not($(_this).parent()).addClass("hiddenFace");

      showFaceTimer = setTimeout(function(){
        var face = $(_this).closest("[data-face]");
        showFace("show-" + $(face).attr("data-face"));
      }, 100);
      
    }, 600);

  }, function(){
    clearTimeout(scaleTimer);
    clearTimeout(showFaceTimer);
  //  $(_this).parent().find(">div").not(_this).removeClass("hiddenFace");
 //   $("#shape>div").not($(_this).parent()).removeClass("hiddenFace");
    $(this).css("transform", "none").removeClass("hovered");
  });
}

function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some(function (toMatchItem) {
      return navigator.userAgent.match(toMatchItem);
  });
}

function isOldApple() {
  const toMatch = [
      /iPhone OS 12/i,
  ];

  return toMatch.some(function (toMatchItem) {
    return navigator.userAgent.match(toMatchItem);
});
}

function isIphone() {
  const toMatch = [
      /iPhone/i,
  ];

  return toMatch.some(function (toMatchItem) {
    return navigator.userAgent.match(toMatchItem);
});
}

function cubeScale(){
  //console.log('cubeScale')
  if($("#main").width() < 320){
    $(".cubeContainer").css("transform", "scale(0.5)");
  } else
  if($("#main").width() < 992){
    $(".cubeContainer").css("transform", "scale(0.6)");
  }  
}