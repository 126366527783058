<template>
  <div class="fix-color">
    <phone-mask-input
      :placeholder="$t('ObjectCreation.EnterPhoneNumber')"
      v-model="phone"
      autoDetectCountry
      @onValidate="onValidate"
      @input="onInput"
      wrapperClass="wrraper-example"
      flagClass="flag-example"
      :inputClass="`${isMobileDisplay?'input-example-tablet':'input-example'}`"
    />
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import PhoneMaskInput from  "./PhoneMaskInput/PhoneMaskInput.vue"
export default {
  components:{
    PhoneMaskInput
  },
  data: () => ({
    phone: null
  }),
  computed: {
    ...mapGetters(['isMobileDisplay'])
  },
  methods:{
    submit(){
    },
    start(){
      console.log('log')
    },
    onValidate(data){
      console.log(data)
      this.$emit('phone', data)
    },
    onInput(data){
    }
  }
}
</script>
<style>
.wrraper-example{
  text-align: center;
}
.input-example{
  color: white;
  border: 1px solid white; 
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  width: 100%;
  height: 66.5px;
  padding: 0 12px;
  margin-bottom: 8px;
}
.input-example-tablet{
  color: white;
  border: 1px solid white; 
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  width: 100%;
  height: 35pt;
  padding: 0 12px;
  margin-bottom: 8px;
}
.flag-example{
  display: none;
}
</style>