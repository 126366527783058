<template>
  <div>
    <v-row class="d-flex justify-center pl-2 pr-2 mt-3">
      <v-col class="col-xl-8 col-lg-8 col-md-10 col-sm-12" cols="12">
        <v-card dark class="mt-5">
          <v-card-text class="pa-1">
            <v-row no-gutters>
              <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-6" cols="12">
                <v-row class="caption" no-gutters>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.MasterUserName')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getSelectedDevice.ownerName}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.ObjectTitle')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getSelectedDevice.name}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.PlaceAddress')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getSelectedDevice.locationName}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center" v-if="getSelectedDevice.pointLastData && getSelectedDevice.pointLastData.Latitude">{{$t('Account.ObjectInfo.GNSS')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center" v-if="getSelectedDevice.pointLastData && getSelectedDevice.pointLastData.Latitude">
                    {{$t('Account.ObjectInfo.Lat')}}: {{getSelectedDevice.pointLastData.Latitude}} {{$t('Account.ObjectInfo.Lon')}}: {{getSelectedDevice.pointLastData.Longitude}} {{$t('Account.ObjectInfo.Alt')}}: {{getSelectedDevice.pointLastData.Altitude}}
                  </v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center" v-if="getSelectedDevice.pointLastData && getSelectedDevice.pointLastData.PhoneNumber">{{$t('Account.ObjectInfo.DevicePhoneNumber')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center" v-if="getSelectedDevice.pointLastData && getSelectedDevice.pointLastData.PhoneNumber">{{getSelectedDevice.pointLastData.PhoneNumber}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.DeviceType')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getSelectedDevice.typeName}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{getSelectedDevice.serialNumber.length == 8 ? `${$t('Account.ObjectInfo.SerialNumber')}:` : 'POINT IMEI:'}}</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getSelectedDevice.serialNumber}}</v-col>
                </v-row>
              </v-col>
              <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-6" cols="12">
                <v-row class="caption" no-gutters>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.DateOfCommissioning')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getFormatDate(new Date(getSelectedDevice.dateManufacture))}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.DateOfLastConnection')}}:</v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">{{getFormatDate(new Date(getSelectedDevice.dateConnection))}}</v-col>
                  <v-col class="fix-padding col-4 caption"   align-self="center">{{$t('Account.ObjectInfo.Connection')}}: </v-col>
                  <v-col class="fix-padding col-8 caption"   align-self="center">
                    {{getSelectedDevice.connection ? $t('Account.ObjectInfo.Connected') : `${$t('Account.ObjectInfo.Disconnected')} (${getFormatDate(new Date(getSelectedDevice.dateDisconnection))})`}}
                    <span v-if="getSelectedDevice.connection  && getSelectedDevice.pointLastData && getSelectedDevice.pointLastData.SignalPower"> {{$t('Account.ObjectInfo.SignalPower')}}: {{getSelectedDevice.pointLastData.SignalPower}}</span>
                    <span v-if="getSelectedDevice.headStatus && getSelectedDevice.headStatus.code">
                      ({{getSelectedDevice.headStatus.description}})
                    </span>
                  </v-col>
                </v-row>
                <v-row class="caption mt-3 d-flex justify-space-around" no-gutters>
                  <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5" cols="12">
                    <v-select
                      class="dv-input-tablet caption mb-5 mr-xl-2 mr-lg-2 mr-md-2 mr-sm-2"
                      :loading="dataUpdatedPeriod.loading"
                      outlined
                      dark
                      v-model="dataUpdatedPeriod.selected"
                      :items="dataUpdatedPeriod.items"
                      @change="selectDataUpdatedPeriod(dataUpdatedPeriod.selected)"
                      item-color="#000"
                      item-text="id"
                      item-value="id"
                      :hint="`${items.id} ${$t('ControlPanel.Minutes')}`"
                      :label="$t('ControlPanel.DataUpdatedPeriod')"
                      :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{ item.id }} {{$t('ControlPanel.Minutes')}}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span class="white--text">{{ item.id }} {{$t('ControlPanel.Minutes')}}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5" cols="12">
                    <v-btn
                      class="caption dv-chart-btn-mini-tablet"
                      dark
                      :loading="loadingSaveData"
                      :disabled="dataUpdatedPeriod.selected == null || (getSelectedDevice.pointPollingTimeOut && dataUpdatedPeriod.selected == getSelectedDevice.pointPollingTimeOut)"
                      @click="saveDataUpdatedPeriod(dataUpdatedPeriod.selected)"
                    >
                      {{$t('ControlPanel.Save')}}
                    </v-btn>
                  </v-col>
                  
                  <v-col v-if="isMobile" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2" cols="12">
                    <v-row no-gutters class="d-flex justify-start mt-2">
                      <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5 mr-xl-4 mr-lg-4 mr-md-4 mr-sm-4" cols="12">
                        <v-btn
                          class="caption dv-chart-btn-mini-tablet mt-2"
                          dark
                          @click="pushData"
                        >
                          {{$t('ControlPanel.Push')}}
                        </v-btn>
                      </v-col>
                      <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5" cols="12">
                        <div
                          class="d-flex align-content-center flex-wrap justify-center dv-btn caption dv-chart-btn-mini-tablet mt-2"
                          dark
                          disabled
                          :class="`${signalGet?'white-button':'black-button'}`"
                        >
                          {{$t('ControlPanel.Signal')}}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="text-body-2 white--text text-center pt-2" v-if="metrics.length > 0">{{$t('ControlPanel.ActualIndicationsFor')}}: {{getFormatDate(new Date(actualIndicationsForDate))}}</div>
        <v-card dark class="mt-2">
          <v-card-text class="pa-1">
            <v-row no-gutters>
              <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pr-xl-0 pr-lg-0 pr-md-0 pr-sm-0 pb-0" cols="12">
                <v-row class="text-body-2" no-gutters>
                  <v-col
                    v-for="(metric, key) in metrics.filter((value, key) => key <= 15)"
                    class="fix-padding caption"
                    :class="`${key%2==0?'col-xl-8 col-lg-8 col-md-8 col-sm-8':'col-xl-4 col-lg-4 col-md-4 col-sm-4'}` + ` ${key == 0||key == 1?'fix-color':''}`"
                    :cols="`${key%2==0?'7':'5'}`"
                    align-self="center"
                    :key="key"
                  >
                    {{metric}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-0 pt-0" cols="12">
                <v-row class="text-body-2" no-gutters>
                  <v-col
                    v-for="(metric, key) in metrics.filter((value, key) => key > 15)"
                    class="fix-padding caption"
                    align-self="center"
                    :class="`${key%2==0?'col-xl-8 col-lg-8 col-md-8 col-sm-8':'col-xl-4 col-lg-4 col-md-4 col-sm-4'}`"
                    :cols="`${key%2==0?'7':'5'}`"
                    :key="key"
                  >
                    {{metric}}
                  </v-col>
                </v-row>
              </v-col>  
            </v-row>
          </v-card-text>
        </v-card>
        <v-row no-gutters>
          <v-col v-if="!isMobile" class="col-xl-6 col-lg-6 col-md-6 col-sm-6" cols="12">
            <v-row no-gutters class="d-flex justify-start mt-2">
              <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5 mr-xl-4 mr-lg-4 mr-md-4 mr-sm-4" cols="12">
                <v-btn
                  class="caption dv-chart-btn-mini-tablet mt-2"
                  dark
                  @click="pushData"
                >
                  {{$t('ControlPanel.Push')}}
                </v-btn>
              </v-col>
              <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5" cols="12">
                <div
                  class="d-flex align-content-center flex-wrap justify-center dv-btn caption dv-chart-btn-mini-tablet mt-2"
                  dark
                  disabled
                  :class="`${signalGet?'white-button':'black-button'}`"
                >
                  {{$t('ControlPanel.Signal')}}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else class="col-xl-6 col-lg-6 col-md-6 col-sm-6" cols="12">
          </v-col>
          <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-6" cols="12">
            <v-row no-gutters class="d-flex justify-end mt-2">
              <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5 mr-xl-4 mr-lg-4 mr-md-4 mr-sm-4" cols="12">
                <v-btn
                  class="caption dv-chart-btn-mini-tablet mt-2"
                  dark
                  :loading="loadingRefreshData"
                  @click="loadData"
                >
                  {{$t('ControlPanel.RefreshData')}}
                </v-btn>
              </v-col>
              <v-col class="col-xl-5 col-lg-5 col-md-5 col-sm-5" cols="12">
                <v-btn
                  class="caption dv-chart-btn-mini-tablet mt-2"
                  dark
                  :disabled="metrics.length == 0"
                  :loading="sendDataToEmailLoading"
                  @click="sendDataToEmail"
                >
                  {{$t('ControlPanel.SendToEmail')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import dateFormat, { masks } from "dateformat"
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Vue from "vue"
const config = process.env.VUE_APP_ui_api_url
export default Vue.extend({
  name: "Mercury236ControlPanel",
  props: {
  },
  components: {
  },
  data(){
    return{
      signalGet: false,
      commands: [':CMD{DOUT:1;}'],
      metrics: [],
      actualIndicationsForDate: null,
      items:[],
      dataUpdatedPeriod: {
        loading: false,
        items: [{id:1}, {id:5}, {id:10}, {id:15}, {id:30}, {id:60}],
        selected: null,
      },
      connectionControl: null,
      connection: null,
      metricsParse: null,
      loadingRefreshData: true,
      loadingSaveData: false,
      sendDataToEmailLoading: false,
      mercurySensors: {
        1200: "WaterConsumption"
      }
    }
  },
  watch: {
    '$i18n.locale': async function(newVal, oldVal) {
      this.refreshData(this.metricsParse)
    }
  },
  computed: {
    ...mapGetters(['configsData', 'getSelectedDeviceSerialNumber', 'getAccessToken', 'getUser', 'getSelectedDevice', 'isMobile']),
    windowWidth(){
      return this.$vuetify.breakpoint.width
    },
    windowHeight(){
      return this.$vuetify.breakpoint.height
    }
  },
  methods:{
    ...mapMutations(['selectDeviceById']),
    ...mapActions(['getLastMetrics', 'changePointPollingTimeout', 'sendEmailPointActualValues']),
    sendMessage(message){
      if(this.connectionControl.state == 'Connected'){
        try {
          this.connectionControl
          .invoke("Command", {
            HeadSerialNumber: this.getSelectedDeviceSerialNumber,
            Command: message
          })
        } catch (e) {
          this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          console.log(e)
        }
      }
    },
    getFormatDate(date){
      return dateFormat(date, "dd.mm.yyyy HH:MM:ss")
    },
    selectDataUpdatedPeriod(data){
      console.log(data)
    },
    pushData(){
      this.signalGet = true
      this.commands.forEach(command => {
        this.sendMessage(command)
      })
      setTimeout(() => {
        this.signalGet = false
      }, 2000)
    },
    receiveMessage(message){
      console.log(message)
    },
    async tryConnectToHub(){
      try {
        //SendData Start//
        this.connectionControl = new HubConnectionBuilder()
        .withUrl(`${config}/device`, {
          accessTokenFactory: () => this.getAccessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .configureLogging(LogLevel.Error)
        .build();

        this.connectionControl.start()
        .then(() => {console.log('Connected')})
        .catch((err) => {
          this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
        })

        this.connectionControl.on("Command", (message) => {console.log(message)})
        this.connectionControl.onclose(e => {})
        //SendData End//

        //GetData Start//
        this.connection = new HubConnectionBuilder()
        .withUrl(`${config}/state`, {
          accessTokenFactory: () => this.getAccessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .configureLogging(LogLevel.Error)
        .build();

        this.connection.start()
        .then(() => {
          this.connection.invoke("StartGettingValuesDevice", {
            UserId: this.getUser.id,
            SerialNumber: this.getSelectedDeviceSerialNumber
          })
        })
        .catch((err) => {
          this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
        })
        
        this.connection.on("ReceiveStatus", (message) => {})
        this.connection.on("ReceiveMessage", (message) => {
          this.receiveMessage(message)
        })
        //GetData End//
      }
      catch(e){
        this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
        console.log(e)
      }
    },
    async saveDataUpdatedPeriod(data){
      this.loadingSaveData = true
      console.log(data)
      try{
        await this.changePointPollingTimeout({
          deviceId: this.getSelectedDevice.id,
          pollingTimeout: data
        })
        .then(res=> {
          console.log(res)
          this.loadingSaveData = false
          this.$root.$emit('globalError', this.$t('SnackBar.Success'))
        })
      }
      catch(e){
        this.loadingSaveData = false
        this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
        console.log(e)
      }
    },
    async loadData(){
      try{
        this.loadingRefreshData = true
        await this.getLastMetrics(this.getSelectedDeviceSerialNumber)
        .then(metrics => {
          this.metricsParse = JSON.parse(metrics.lastData)
          console.log(this.metricsParse)
          this.refreshData(this.metricsParse)
        })
      }
      catch(e){
        this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
        console.log(e)
      }
    },
    refreshData(metricsParse){
      try{
        this.loadingRefreshData = true
        this.actualIndicationsForDate = metricsParse[0].Date
        if(metricsParse.length > 0){
          this.metrics = []
          metricsParse.forEach(metric => {
            if(Object.keys(this.mercurySensors).includes(`${metric.Type}`)){
              this.metrics.push(this.$t(`SensorNames.${this.mercurySensors[`${metric.Type}`]}`)+':')
              this.metrics.push(`${parseFloat(metric.State.replace(',', '.')).toFixed(3)} ` + this.$t(`Sensors.${this.mercurySensors[`${metric.Type}`]}`))
            }
          })
        }
        this.loadingRefreshData = false
      }catch(e){
        this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
        console.log(e)
      }
    },
    async sendDataToEmail(){
      try{
        const data = {
          lastData: this.metricsParse,
          deviceId: this.getSelectedDevice.id
        }
        await this.sendEmailPointActualValues(data)
        .then(res => {
          console.log(res)
          this.$root.$emit('globalError', this.$t('SnackBar.Success'))
        })
        .catch(e => {
          this.$root.$emit('globalError', this.$t('SnackBar.ServerErrors.k2006'))
          console.log(e)
        })
      }
      catch(e){
        this.$root.$emit('globalError', this.$t('SnackBar.ServerErrors.k2006'))
        console.log(e)
      }
    }
  },
  async created(){
    await this.loadData()
    if(this.getSelectedDevice.pointPollingTimeOut){
      this.dataUpdatedPeriod.selected = this.getSelectedDevice.pointPollingTimeOut
    }
    await this.tryConnectToHub()
  },
  beforeDestroy(){
    if(this.connectionControl) this.connectionControl.stop()
  }
})
</script>
<style scoped>
.fix-color{
  color:#fff !important;
}
.black-button{
  color: #fff !important;
  background-color: rgba(39, 39, 39, 0) !important;
}
.white-button{
  background-color: rgba(240, 240, 240, 0.8) !important;
  color: #000 !important;

}
</style>