<template>
  <div>
    <v-row>
      <v-col cols="12" class="mt-5 d-flex justify-space-around justify-center" >
        <div class="fix-height wd-100 scroll" v-if="deviceTables">
          <v-data-table
            v-if="tableActive == 1"
            disable-pagination
            dark
            hide-default-footer
            :headers="headersQ1"
            :items="deviceTables.tableQ1"
            align="center"
            class="elevation-1"
          ></v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import storageService from '../../services/storageService'
const config = process.env.VUE_APP_ui_api_url
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import {  mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  data() {
    return {
      serialNumber: null,
      connection: null,
      tableActive: 1,
      headersQ1: [
        { 
          align: 'center',
          text: 'CONTROLLER',
          sortable: false,
          value: 'Type',
        },
        { 
          align: 'center',
          text: 'ADDRESS',
          sortable: false,
          value: 'Address',
        },
        {
          align: 'center',
          text: 'PORT',
          sortable: false,
          value: 'Port',
        },
        { 
          align: 'center',
          text: 'CONDITION',
          sortable: false,
          value: 'FatalError',
        }
      ],
      headersQ2: [
        { 
          align: 'center',
          text: 'CONTROLLER',
          sortable: false,
          value: 'Type',
        },
        { 
          align: 'center',
          text: 'ADDRESS',
          sortable: false,
          value: 'Address',
        },
        {
          align: 'center',
          text: 'PORT',
          sortable: false,
          value: 'Port',
        }
      ],
      headersQ3: [
        { 
          align: 'center',
          text: 'CONTROLLER',
          sortable: false,
          value: 'Type',
        },
        { 
          align: 'center',
          text: 'ADDRESS',
          sortable: false,
          value: 'Address',
        },
        { 
          align: 'center',
          text: 'PortMaskInputs',
          sortable: false,
          value: 'PortMaskInputs',
        },
        { 
          align: 'center',
          text: 'PortMaskOutputs',
          sortable: false,
          value: 'PortMaskOutputs',
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['getSelectedDevice', 'getLoadDevices', 'getAccessToken', 'getChartOneLastValue', 'getSelectedDeviceSerialNumber', 'deviceTables', 'getUser'])
  },
  methods: {
    ...mapMutations(['updateChartOne', 'updateQ3Table', 'removeTables', 'selectDevice']),
    ...mapActions(['getDeviceTables'])
  },
  async mounted(){
    try{
      if(this.getSelectedDeviceSerialNumber == null){

        const dvc = this.getLoadDevices.filter(dev => dev.deviceOwnerId == this.getUser.id)
        if(dvc.length > 0){
          this.selectDevice(dvc[0].serialNumber)
        }else{
          this.selectDevice(dvc[0].serialNumber)
        }
        console.log('this.getSelectedDeviceSerialNumber')
      }
      if(this.getSelectedDeviceSerialNumber){
        this.serialNumber = this.getSelectedDeviceSerialNumber
        await this.getDeviceTables(this.getSelectedDeviceSerialNumber)
        console.log(this.deviceTables)
      }
    }catch(e){
      console.log(e)
    }
    if(this.serialNumber == null) return
    this.connection = new HubConnectionBuilder()
    .withUrl(`${config}/state`, {
      accessTokenFactory: () => this.getAccessToken,
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
    .configureLogging(LogLevel.Error)
    .build();
    this.connection.start()
    .then(() => {
      console.log('state Connected')
      this.connection.invoke("StartGettingValuesDevice", {
        UserId: this.getUser.id,
        SerialNumber: this.serialNumber
      })
    })
    .catch((err) => {
      return console.error('err ' + err)
    })

    this.connection.on("ReceiveMessage", (message) => {
    })
    this.connection.on("ReceiveStatus", (message) => {
      if(message){
        this.updateQ3Table(JSON.parse(message))
      }
    })
    this.connection.onclose(e => {})
  },
  async created(){
    if((this.getAccessToken || storageService.getToken()) && (this.getSelectedDeviceSerialNumber || storageService.getSelectedDevice())){
      this.serialNumber = this.getSelectedDeviceSerialNumber? this.getSelectedDeviceSerialNumber : storageService.getSelectedDevice()
      console.log(this.serialNumber)
      storageService.setSelectedDevice(this.serialNumber)
      storageService.setSelectedDeviceId(this.getSelectedDevice.id)
    }
    this.headersQ1[0].text = this.$t('TablesOne.Controller')
    this.headersQ1[1].text = this.$t('TablesOne.Address')
    this.headersQ1[2].text = this.$t('TablesOne.Port')
    this.headersQ1[3].text = this.$t('TablesOne.Condition')
  },
  beforeDestroy(){
    this.removeTables()
    this.connection.stop()
  }
};
</script>
<style scoped>
  tr:hover:not(.v-table__expanded__content) {
    background-color: rgba(255,255,255, 0.15) !important;
  }
  .wd-100{
    width: 90% !important;
    height: 500px;
  }
  .scroll{
    overflow-y: scroll;
  }
  .fix-height{
    height: 500px !important;
    display: inline-block;
    width: 100%;
  }
  .v-data-table__wrapper > table > .v-data-table-header > tr > th {
    border: thin solid rgba(255, 255, 255, 0.12) !important;
    background-color: black;
    color: white !important;
    font-weight: 400;
    font-size: 0.9em !important;
  }
  .v-data-table__wrapper > table > tbody > tr >td {
    border: thin solid rgba(255, 255, 255, 0.12) !important;
  }
  .theme--dark.v-data-table{
    background-color: rgba(255, 255, 255, 0);
  }
  ::-webkit-scrollbar {
    width: 0;
  }
</style>