//import axios from 'axios'
import axiosService from "../../services/axiosService";
import storageService from "../../services/storageService";
const axios = axiosService.getInstance()

export default {
  actions: {
    async getPointCommands(context:any, deviceId:any){
      return await axios.get(`/api/Head/GetPointCommands/${deviceId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async createPointCommand(context:any, data:any){
      return await axios.post(`/api/Head/CreatePointCommand`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async updatePointCommand(context:any, data:any){
      return await axios.post(`/api/Head/UpdatePointCommand`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async deletePointCommand(context:any, data:any){
      return await axios.delete(`/api/Head/DeletePointCommand/${data.deviceId}/${data.commandId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getAdcSensorTypes(context:any){
      return await axios.post(`/api/Head/GetAdcSensorTypes`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('adcDescriptors', response.data.devices)
          return response.data.descriptors
        }else{
          context.commit('adcDescriptors', [])
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getUserDescriptors(context:any, data:any){
      return await axios.post(`/api/Head/GetUserDescriptors/${data.userId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async sendEmailPointActualValues(context:any, data:any) {
      return await axios.post(`/api/Head/SendEmailPointActualValues`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async changePointPollingTimeout(context:any, data:any) {
      return await axios.post(`/api/Head/ChangePointPollingTimeout/${data.deviceId}/${data.pollingTimeout}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('pollingTimeout', data)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getLastMetrics(context:any, data:any) {
      return await axios.get(`/api/Head/Metrics/${data}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getDeviceEventLog(context:any, data:any) {
      console.log(data)
      return await axios.post(`/api/Head/GetDeviceEventLog/`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getSenorsBySerialNumber(context:any, deviceId:any) {
      return await axios.get(`/api/Head/GetSenors/${deviceId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async updateOpenSenors(context:any, data:any) {
      return await axios.post(`/api/Head/UpdateOpenSenors/`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getDeviceTables(context:any, serialNumber:string) {
      return await axios.get(`/api/Head/GetDeviceTables/${serialNumber}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          const data = JSON.parse(response.data.tables)
          context.commit('devicesTablesSave', data)
          return {tables: data}
          //
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getOwnerDevices(context:any) {
      return await axios.get(`/api/Head/GetOwnerDevices`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('loadDevices', response.data.devices)
          return response.data
        }else{
          context.commit('loadDevices', [])
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getDevices(context:any) {
      return await axios.get(`/api/Head/GetDevices`)
        .then((response:any) => {
          if(response.data.result.code == 0){
            context.commit('loadDevices', response.data.devices)
            return response.data
          }else{
            throw response.data.result
          }
        })
        .catch((err:any) => {
          console.log(err)
        })
    },
    async createDevice(context:any, data:any) {
      return await axios.post(`/api/Head/CreateDevice`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async userDeviceRegitration(context:any, data:any) {
      return await axios.post(`/api/Account/CheckUserDeviceExist`, data)
      .then((response:any) => {
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async userDeviceRegitrationCode(context:any, data:any) {
      return await axios.post(`/api/Account/UserDeviceRegistration`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('saveUser', response.data.user)
          axiosService.updateAccessToken(response.data.token)
          context.commit('saveAccessToken', response.data.token)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async userDeviceDelete(context:any, data:any) {
      return await axios.post(`/api/Head/DeleteDevice/${data.deviceId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async userDeviceDeleteCheck(context:any, data:any) {
      return await axios.post(`/api/Head/DeleteDeviceCheck/${data.deviceId}/${data.code}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('loadDevices', response.data.devices)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getCoordsDevices(context:any) {
      return await axios.get(`/api/Account/DevicesLocation`)
      .then((response:any) => {
        console.log(response)
        if(response.data.result.code == 0){
          context.commit('loadDevicesCoords', response.data.devices)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async devicesLoadOnlineStatus(context:any) {
      return await axios.get(`/api/Account/DevicesLoadOnlineStatus`)
      .then((response:any) => {
        let arrResultConnections = Object.keys(response.data.connections)
        if(response.data.result.code == 0){
          context.commit('devicesLoadOnlineStatus', {keys: arrResultConnections, data: response.data.connections})
          return arrResultConnections
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    }
  },
  state: {
    tables: [],
    devices: [],
    descriptors: [],
    coords: null,
    selectedDeviceSerialnumber: null,
    selectedDevice: null
  },
  mutations: {
    setDeviceConnectionState(state:any, data:any)
    {
      const findDevice = state.devices.filter((device:any) => device.id == data.Id)
      if(findDevice.length > 0){
        findDevice[0].headStatus.description = data.Comment
        if(data.Comment == 'Connected'){
          findDevice[0].connection = true
          findDevice[0].headStatus.code = 0
        }else{
          findDevice[0].connection = false
          findDevice[0].headStatus.code = 1
        }
      }
    },
    adcDescriptors(state:any, data:any)
    {
      state.descriptors=data
    },
    pollingTimeout(state:any, data:any){
      const findDevice = state.devices.filter((device:any) => device.id == data.deviceId)
      if(findDevice.length > 0){
        findDevice[0].pointPollingTimeOut = data.pollingTimeout
      }
    },
    removeTables(state:any){
      state.tables = []
    },
    updateQ3Table(state:any, data:any){
      console.log('1')
      let index = 0
      state.tables.forEach((table:any, i:any) => {
        if(table.serialNumber == state.selectedDeviceSerialnumber){
          index = i
        }
      })
      console.log('2')
      if(state.tables.length == 0){
        return
      }
      if(index == 0){
        index = state.tables.length-1
      }
      let buff:any = []
      state.tables[index].tableQ3.forEach((table:any) => {
        if(table.Type != null){
          buff.push(table)
        }
      })
      
      console.log('3')
      state.tables[index].tableQ3 = buff
      console.log(state.tables[index].tableQ3)
      state.tables[index].tableQ3.forEach((table:any) => {
        let tDataNew:any = []
        Object.keys(data).forEach((tbl:any, index:any) => {
          if(tbl == table.Address){
            tDataNew.push(Object.values(data)[index])
          }
        })
        //console.log(tDataNew)
        if(tDataNew.length > 0){
          let dataStr = ''
          if(tDataNew[0].Temperature) dataStr += ` T: ${tDataNew[0].Temperature}C;`
          if(tDataNew[0].Pressure && tDataNew[0].Pressure != "000") dataStr += ` P: ${tDataNew[0].Pressure};`
          if(tDataNew[0].Humidity && tDataNew[0].Humidity != "00.0") dataStr += ` H: ${tDataNew[0].Humidity};`
          
          if(tDataNew[0].OStatus) {
            let b = tDataNew[0].OStatus
            let res:any =[]
            b.forEach((r:any) => {
              if(r == 2){
                res.push(0)
              }else{
                res.push(r)
              }
            })
            dataStr += JSON.stringify(res)
            console.log('OStatus ' + dataStr)
          }
          if(tDataNew[0].AoStatus){
            dataStr += JSON.stringify(tDataNew[0].AoStatus)
            console.log('AoStatus ' + dataStr)
          }
          table.PortMaskOutputs = dataStr

          
          let dataIStr = ''
          if(tDataNew[0].Status){
            dataIStr += JSON.stringify(tDataNew[0].Status)
            console.log('Status ' + dataIStr)
          }
          if(tDataNew[0].AiStatus){
            dataIStr += JSON.stringify(tDataNew[0].AiStatus)
            console.log('AiStatus ' + dataIStr)
          }
          table.PortMaskInputs = dataIStr
        }
      })
    },
    devicesTablesSave(state:any, tables:any){
      if(!tables.TableQ1 || !tables.TableQ2 || !tables.TableQ2) return
      let index = 0
      state.tables.forEach((table:any, i:any) => {
        if(table.serialNumber == state.selectedDeviceSerialnumber){
          index = i
        }
      })
      state.tables.push({
        serialNumber: state.selectedDeviceSerialnumber,
        tableQ1: tables.TableQ1,
        tableQ2: tables.TableQ2,
        tableQ3: tables.TableQ3
      })
      if(index == 0){
        index = state.tables.length-1
      }
      //console.log(index)
      //console.log(state.tables[index])
      state.tables[index].tableQ3.forEach((deviceQ3:any) => {
        deviceQ3.PortMaskOutputs = '-'
        deviceQ3.PortMaskInputs = '-'
      })
      state.tables[index].tableQ1.forEach((deviceQ1:any) => {
        const findedDevice = tables.TableQ2.filter((deviceQ2:any) => {
          return deviceQ2.Address == deviceQ1.Address
        })
        if(findedDevice.length > 0){
          deviceQ1.Type = findedDevice[0].Type
        }
      })
    },
    devicesLoadOnlineStatus(state:any, connections:any){
      state.connections = connections.keys
      if(!state.devices) return
      state.devices.forEach((device:any) => {
        if(connections.keys.filter((conn:any) => conn == device.serialNumber).length > 0){
          device.connection = true
          device.headStatus = connections.data[device.serialNumber]
        }
        else
        {
          device.connection = false
        }
      })
    },
    loadDevicesCoords(state:any, coords:any){
      state.coords = coords
    },
    selectDeviceById(state:any, id:any){
      const findDeviceById =  state.devices.filter((device:any) => device.id == id)
      if(findDeviceById.length > 0){
        state.selectedDevice = findDeviceById[0]
        state.selectedDeviceSerialnumber = findDeviceById[0].serialNumber
        if(state.selectedDevice && state.selectedDevice.id && state.selectedDevice.serialNumber){
          storageService.setSelectedDeviceId(state.selectedDevice.id)
          storageService.setSelectedDevice(state.selectedDevice.serialNumber)
        }
      }else{
        state.selectedDevice = null
        state.selectedDeviceSerialnumber = null
      }
    },
    selectDeviceStatus(state:any, data:any){
      console.log('selectDeviceStatus')
      console.log(data)
      if(data.id == undefined || data.status == undefined) {
        return
      }
      try{
        const device = state.devices.findIndex((device:any) => device.id == data.id)
        console.log(device)
        if(device != -1){
          state.devices[device].connection = data.status
        }
        /*if(state.selectedDevice && state.selectedDevice.id == data.id){
          state.selectedDevice.connection = data.status
        }*/
        console.log(state.devices[device])
      }catch(e){
        console.log(e)
      }
    },
    selectDevice(state:any, serialNumber:any){
      state.selectedDeviceSerialnumber = serialNumber
      state.selectedDevice = state.devices.filter((device:any) => device.serialNumber == serialNumber)[0]
      console.log(serialNumber)
      console.log(state.devices)
      if(state.selectedDevice && state.selectedDevice.id && state.selectedDevice.serialNumber){
        storageService.setSelectedDeviceId(state.selectedDevice.id)
        storageService.setSelectedDevice(state.selectedDevice.serialNumber)
      }
    },
    clearSelectedDevice(state: any){
      state.selectedDeviceSerialnumber= null
      state.selectedDevice= null
    },
    selectDeviceSN(state:any, serialNumber:any){
      state.selectedDeviceSerialnumber = serialNumber
    },
    loadDevices(state:any, devices:any){
      state.devices = devices
    },
    setPointLastData(state:any, data:any){
      let findDevice = state.devices.filter((device:any) => device.id == data.Id)
      if(findDevice.length > 0 && findDevice[0].pointLastData && findDevice[0].pointLastData.Altitude){
        findDevice[0].pointLastData.Altitude = data.Altitude
        findDevice[0].pointLastData.Latitude = data.Latitude
        findDevice[0].pointLastData.Longitude = data.Longitude
        findDevice[0].pointLastData.SignalPower = data.SignalPower
      }
    }
  },
  getters:{
    adcDescriptors(state:any)
    {
      const boop:any=[]
      if(state.descriptors==null)
      {
        return boop
      }
      return state.descriptors
    },
    getDeviceById:(state:any) => (deviceId:any) => {
      const indexDevice = state.devices.findIndex((device:any) => device.id == deviceId)
      if(indexDevice == -1){
        return null
      }
      return state.devices[indexDevice]
    },
    deviceTables(state:any){
      return state.tables.filter((table:any) => {
        return table.serialNumber == state.selectedDeviceSerialnumber
      })[0]
    },
    getLoadDevices(state:any) {
      const dat:any = []
      if(state.devices == null){
        return dat
      }
      state.devices.forEach((device:any) => {
        if(device.pointLastData && device.pointLastData.length > 5){
          dat.push(device)
          dat[dat.length-1].pointLastData = JSON.parse(device.pointLastData)
        }else{
          dat.push(device)
        }
      })
      return dat
    },
    getSelectedDevice(state:any) {
      console.log(state.selectedDevice)
      console.log(state.devices)
      if(state.selectedDevice == null && state.devices != null){
        state.devices.forEach((device:any) => {
          if(device.serialNumber == state.selectedDeviceSerialnumber){
            //console.log(state)
            state.selectedDeviceSerialnumber = state.selectedDeviceSerialnumber
            state.selectedDevice = state.devices.filter((device:any) => device.serialNumber == state.selectedDeviceSerialnumber)[0]
            if(state.selectedDevice && state.selectedDevice.id && state.selectedDevice.serialNumber){
              storageService.setSelectedDeviceId(state.selectedDevice.id)
              storageService.setSelectedDevice(state.selectedDevice.serialNumber)
            }

            //state.mutations.selectDevice(device.serialNumber)
          }
        })
      }
      console.log(state.selectedDevice)
      return state.selectedDevice
		},
    getSelectedDeviceSerialNumber(state:any) {
      return state.selectedDeviceSerialnumber
		}
  }
}
