<template>
  <div>
    <v-row class="pl-2 pr-2 mt-3">
      <v-col cols="12" class="d-flex justify-center ">
        <v-card dark class="mt-5 point-control-panel-size-card">
          <v-card-text class="pa-1">
            <v-row no-gutters class="d-flex justify-center">
              <v-col cols="10" class="d-flex justify-center mt-2">
                <v-btn dark class="mt-2 dv-btn" @click="pushData(commands)">
                  {{$t('ControlPanel.Push')}}
                </v-btn>
              </v-col>
              <v-col cols="10">
                <div
                  class="d-flex align-content-center flex-wrap justify-center dv-btn mt-3"
                  dark
                  disabled
                  :class="`${signalGet?'white-button':'black-button'}`"
                >
                  <span>{{$t('ControlPanel.Signal')}}</span>
                </div>
              </v-col>
              <v-col cols="10">
                <v-btn dark class="mt-3 dv-btn" @click="createCommandModal = true">
                  CREATE COMMAND
                </v-btn>
              </v-col>
              <v-col cols="10" class="" v-for="(item, index) in pointCommands" :key="index">
                <v-menu
                  v-model="item.menu"
                  offset-y
                  origin="top center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      dark class="mt-3 dv-btn" 
                      v-bind="props"
                      @click.left.prevent="pushData([item.command])"
                      @click.right.prevent="item.menu = true"
                      >
                      {{item.commandName}}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn dark class="dv-btn" @click="editCommandModalF(item)">
                          Edit
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn dark class="dv-btn mt-2" @click="deleteCommandModalF(item)">
                          Delete
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-dialog
      v-model="deleteCommandModal"
      max-width="320"
    >
      <v-card class="dv-dialog">
        <v-card-title class="text-h5 d-flex justify-center">
          {{$t('ObjectDeletion.AreYouSure')}}
        </v-card-title>
        <v-card-actions class="d-flex justify-center text-center">
          <v-row>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="deleteCommandModal = false"
              >
                {{$t('ObjectDeletion.Disagree')}}
              </v-btn>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="deleteCommand(selectedItem)"
              >
                {{$t('ObjectDeletion.Agree')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editCommandModal"
      max-width="320"
    >
      <v-card class="dv-dialog">
        <v-card-title class="text-h5 d-flex justify-center">
          EDIT COMMAND
        </v-card-title>
        <v-card-actions class="d-flex justify-center text-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="CommandName"
                outlined
                label="Command Name"
                required
                :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="Command"
                outlined
                label="Command Text"
                required
                :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              ></v-text-field>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="editCommandModal = false"
              >
                CLOSE
              </v-btn>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="editCommand(selectedItem)"
              >
                SAVE
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createCommandModal"
      max-width="320"
    >
      <v-card class="dv-dialog">
        <v-card-title class="text-h5 d-flex justify-center">
          CREATE COMMAND
        </v-card-title>
        <v-card-actions class="d-flex justify-center text-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="CommandName"
                outlined
                label="Command Name"
                required
                :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="Command"
                outlined
                label="Command Text"
                required
                :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              ></v-text-field>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="createCommandModal = false"
              >
                CLOSE
              </v-btn>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="createCommand"
              >
                SAVE
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
  import dateFormat, { masks } from "dateformat"
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import Vue from "vue"
  const config = process.env.VUE_APP_ui_api_url
  export default Vue.extend({
    name: "PointControlPanel",
    props: {
    },
    components: {
    },
    data(){
      return{
        CommandName: '',
        Command: '',
        selectedItem: null,
        editCommandModal: false,
        deleteCommandModal: false,
        createCommandModal: false,
        menu: false,
        pointCommands: [],
        signalGet: false,
        commands: [':CMD{DOUT:1;}'],
        metrics: [],
        actualIndicationsForDate: null,
        connectionControl: null,
        connection: null,
        metricsParse: null,
        loadingRefreshData: true,
        loadingSaveData: false,
        sendDataToEmailLoading: false,
      }
    },
    watch: {
      '$i18n.locale': async function(newVal, oldVal) {
        //this.refreshData(this.metricsParse)
      }
    },
    computed: {
      ...mapGetters(['isMobileDisplay','configsData', 'getSelectedDeviceSerialNumber', 'getAccessToken', 'getUser', 'getSelectedDevice', 'isMobile']),
      windowWidth(){
        return this.$vuetify.breakpoint.width
      },
      windowHeight(){
        return this.$vuetify.breakpoint.height
      }
    },
    methods:{
      ...mapMutations(['selectDeviceById']),
      ...mapActions(['getLastMetrics', 'changePointPollingTimeout', 'sendEmailPointActualValues',
        'getPointCommands', 'createPointCommand', 'updatePointCommand', 'deletePointCommand']),
      async createCommand(){
        try {
          await this.createPointCommand({
            DeviceId: this.getSelectedDevice.id,
            CommandName: this.CommandName,
            Command: this.Command
          })
          .then(async res =>  {
            console.log(res)
            await this.loadPointCommands()
            this.createCommandModal = false
          })
          .catch(e =>  {
            console.log(e)
          })
        } catch (e) {
          console.log(e)
        }
      },
      editCommandModalF(item){
        this.editCommandModal = true
        this.selectedItem = item
        this.CommandName = item.commandName
        this.Command = item.command
      },
      async editCommand(item){
        try {
          await this.updatePointCommand({
            DeviceId: item.deviceId,
            CommandId: item.id,
            CommandName: this.CommandName,
            Command: this.Command
          })
          .then(async res =>  {
            console.log(res)
            await this.loadPointCommands()
            this.editCommandModal = false
          })
          .catch(e =>  {
            console.log(e)
          })
        } catch (e) {
          console.log(e)
        }
      },
      deleteCommandModalF(item){
        this.deleteCommandModal = true
        this.selectedItem = item
      },
      async deleteCommand(item){
        try {
          await this.deletePointCommand({
            deviceId: item.deviceId,
            commandId: item.id
          })
          .then(async res =>  {
            console.log(res)
            await this.loadPointCommands()
            this.deleteCommandModal = false
          })
          .catch(e =>  {
            console.log(e)
          })
        } catch (e) {
          console.log(e)
        }
      },
      sendMessage(message){
        if(this.connectionControl.state == 'Connected'){
          try {
            this.connectionControl
            .invoke("Command", {
              HeadSerialNumber: this.getSelectedDeviceSerialNumber,
              Command: message
            })
          } catch (e) {
            this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
            console.log(e)
          }
        }
      },
      pushData(commands){
        commands.forEach(command => {
          this.sendMessage(command)
        })
      },
      receiveMessage(message){
        console.log(message)
        if(message == 'M2 command performed'){
          this.signalGet = true
          setTimeout(() => {
            this.signalGet = false
          }, 2000)
        }
      },
      async tryConnectToHub(){
        try {
          this.connectionControl = new HubConnectionBuilder()
          .withUrl(`${config}/device`, {
            accessTokenFactory: () => this.getAccessToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build();
  
          this.connectionControl.start()
          .then(() => {console.log('Connected')})
          .catch((err) => {
            this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          })
  
          this.connectionControl.on("Command", (message) => {console.log(message)})
          this.connectionControl.onclose(e => {})
          //SendData End//
  
          //GetData Start//
          this.connection = new HubConnectionBuilder()
          .withUrl(`${config}/state`, {
            accessTokenFactory: () => this.getAccessToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build();
  
          this.connection.start()
          .then(() => {
            this.connection.invoke("StartGettingValuesDevice", {
              UserId: this.getUser.id,
              SerialNumber: this.getSelectedDeviceSerialNumber
            })
          })
          .catch((err) => {
            this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          })
          
          this.connection.on("ReceiveStatus", (message) => {})
          this.connection.on("ReceiveMessage", (message) => {
            this.receiveMessage(message)
          })
          //GetData End//
        }
        catch(e){
          this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          console.log(e)
        }
      },
      async loadPointCommands(){
        try {
          this.pointCommands = []
          await this.getPointCommands(this.getSelectedDevice.id)
          .then(res =>  {
            res.pointCommans.forEach(cmd => {
              this.pointCommands.push({
                ...cmd,
                menu: false
              })
            })
            console.log(this.pointCommands)
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async created(){
      await this.tryConnectToHub()
      await this.loadPointCommands()
    },
    beforeDestroy(){
      if(this.connectionControl) this.connectionControl.stop()
    }
})
</script>
<style scoped>
.point-control-panel-size-card{
  width: 300px !important;
  height: 570px !important;
}
.point-control-panel-button-card{
  width: 100% !important;
  height: 66px !important;
}
.fix-color{
  color:#fff !important;
}
.black-button{
  color: #fff !important;
  background-color: rgba(39, 39, 39, 0) !important;
}
.white-button{
  background-color: rgba(240, 240, 240, 0.8) !important;
  color: #000 !important;

}
</style>