import Vuetify from '../../plugins/vuetify'
export default {
    actions: {
    },
    state: {
    },
    mutations: {
    },
    getters:{
      isMobileDisplay(state:any){
        return Vuetify.framework.breakpoint.md || Vuetify.framework.breakpoint.sm || Vuetify.framework.breakpoint.xs
      },
      isMobile(state:any){
        return Vuetify.framework.breakpoint.xs
      },
    }
  }
  