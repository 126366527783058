<template>
  <div>
    <v-row no-gutters>
      <v-col v-show="getUser!=null" cols="12" class="d-flex justify-space-between mt-3 mb-3">
        <v-btn
          :class="`${isMobileDisplay? 'dv-btn-mini-tablet caption':'dv-btn-mini'} btn-bm`"
          dark data-action="show-front">
          {{$t('Account.CubeButtons.Account')}}
        </v-btn>
        <v-btn :class="`${isMobileDisplay? 'dv-btn-mini-tablet caption':'dv-btn-mini'} btn-bm`" dark data-action="show-right">
          {{$t('Account.CubeButtons.Info')}}
        </v-btn>
        <v-btn :class="`${isMobileDisplay? 'dv-btn-mini-tablet caption':'dv-btn-mini'} btn-bm`" dark data-action="show-back">
          {{$t('Account.CubeButtons.Control')}}
        </v-btn>
      </v-col>
      <v-col v-if="getUser == null" cols="12" class="d-flex justify-space-between mt-10">
        <div class="mt-13"></div>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-around justify-center" :class="`${isMobileDisplay? 'mt-6 mb-6':'mt-14 mb-15'}`">
        <div class="row " id="rowCube" style="height: 230px; text-align:center;">
          <div class="cubeContainer" :style="`transform: scale(${isMobileDisplay? '0.65':'0.7'});`">
            <div id="shape" class="spin">
              <div v-for="(cube, index) in cubeData" :key="index" :class="cube.class" :data-face="cube.face">
                <span :class="cube.spanClass">{{cube.spanText}}</span>
                <div
                  v-for="(item, index) in cube.items"
                  :key="index"
                  :class="`sprite ${item.class} ${$t('Cube')}`"
                  @click="clickElement(item)" class="cursor-pointer"
                  :disabled="item.href == ''"
                >
                  <div ><!--{{item.text}}--></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between mt-6">
        <v-btn :class="`${isMobileDisplay? 'dv-btn-mini-tablet caption':'dv-btn-mini'} btn-bm`" dark data-action="show-left">
          {{$t('Account.CubeButtons.Air')}}
        </v-btn>
        <v-btn :class="`${isMobileDisplay? 'dv-btn-mini-tablet caption':'dv-btn-mini'} btn-bm`" dark data-action="show-top">
          {{$t('Account.CubeButtons.Water')}}
        </v-btn>
        <v-btn :class="`${isMobileDisplay? 'dv-btn-mini-tablet caption':'dv-btn-mini'} btn-bm`" dark data-action="show-bottom">
          {{$t('Account.CubeButtons.Earth')}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SensorTypeEnum from '../../Enums/SensorType'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import cube from './cube.js'
import Vue from "vue";
export default Vue.extend({
  name: "Cube",
  data: () => ({
    isShowButtons: false,
    cubeData: [
      {
        class: 'ft',
        face: 'front',
        spanClass: 'face-legend',
        spanText: '1',
        items: [
          { class:'s-1-1', href:'OpenSensors', text:''},
          { class:'s-1-2', href:'Account', text:''},
          { class:'s-1-3', href:'', text:''},
          { class:'s-1-4', href:'ObjectCreation', text:''},
          { class:'s-1-5', href:'', text:''},
          { class:'s-1-6', href:'ObjectDeletion', text:''},
          { class:'s-1-7', href:'', text:''},
          { class:'s-1-8', href:'AddUsersToAccount', text:''},
          { class:'s-1-9', href:'', text:''}
        ]
      },
      {
        class: 'rt',
        face: 'right',
        spanClass: 'face-legend',
        spanText: '2',
        items: [
          { class:'s-2-1', href:'', text:''},
          { class:'s-2-2', href:'Eventlog', text:''},
          { class:'s-2-3', href:'', text:''},
          { class:'s-2-4', href:'TablesOne', text:''},
          { class:'s-2-5', href:'TablesTwo', text:''},
          { class:'s-2-6', href:'TablesThree', text:''},
          { class:'s-2-7', href:'Charts', text: SensorTypeEnum[1]},
          { class:'s-2-8', href:'', text:''},
          { class:'s-2-9', href:'', text:''}
        ]
      },
      {
        class: 'bk',
        face: 'back',
        spanClass: 'face-legend',
        spanText: '3',
        items: [
          { class:'s-3-1', href:'ReconnectDevice', text:''},
          { class:'s-3-2', href:'ControlPanel', text:''},
          { class:'s-3-3', href:'', text:''},
          { class:'s-3-4', href:'FirmwareDownloading', text:''},
          { class:'s-3-5', href:'ConfigurationDownloading', text:''},
          { class:'s-3-6', href:'VideoStreams', text:''},
          { class:'s-3-7', href:'', text:''},
          { class:'s-3-8', href:'', text:''},
          { class:'s-3-9', href:'', text:''}
        ]
      },
      {
        class: 'lt',
        face: 'left',
        spanClass: 'face-legend',
        spanText: '4',
        items: [
          { class:'s-4-1', href:'Charts', text: SensorTypeEnum[1]},
          { class:'s-4-2', href:'Charts', text: SensorTypeEnum[2]},
          { class:'s-4-3', href:'Charts', text: SensorTypeEnum[3]},
          { class:'s-4-4', href:'', text:''},
          { class:'s-4-5', href:'', text:''},
          { class:'s-4-6', href:'', text:''},
          { class:'s-4-7', href:'', text:''},
          { class:'s-4-8', href:'', text:''},
          { class:'s-4-9', href:'', text:''}
        ]
      },
      {
        class: 'tp',
        face: 'top',
        spanClass: 'face-legend',
        spanText: '5',
        items: [
          { class:'s-5-1', href:'', text:''},
          { class:'s-5-2', href:'', text:''},
          { class:'s-5-3', href:'', text:''},
          { class:'s-5-4', href:'', text:''},
          { class:'s-5-5', href:'', text:''},
          { class:'s-5-6', href:'', text:''},
          { class:'s-5-7', href:'', text:''},
          { class:'s-5-8', href:'', text:''},
          { class:'s-5-9', href:'', text:''}
        ]
      },
      {
        class: 'bm',
        face: 'bottom',
        spanClass: 'face-legend',
        spanText: '6',
        items: [
          { class:'s-6-1', href:'', text:''},
          { class:'s-6-2', href:'', text:''},
          { class:'s-6-3', href:'', text:''},
          { class:'s-6-4', href:'', text:''},
          { class:'s-6-5', href:'', text:''},
          { class:'s-6-6', href:'', text:''},
          { class:'s-6-7', href:'', text:''},
          { class:'s-6-8', href:'', text:''},
          { class:'s-6-9', href:'', text:''}
        ]
      },
    ],
    cubeFix:[
      { class: 'ft-1', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'ft-2', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'ft-3', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'ft-4', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'rt-1', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'rt-2', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'rt-3', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'rt-4', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'bm-1', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'bm-2', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'bm-3', items: ['', '', '', '', '', '', '', '', ''] },
      { class: 'bm-4', items: ['', '', '', '', '', '', '', '', ''] }
    ]
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay'])
  },
  mounted(){
    setTimeout(() => {
      cube.start()
    }, 350)
  },
  methods:{
    ...mapMutations(['setFilterState']),
    clickElement(item){
      if ((this.getUser == null || this.$route.path.includes('OpenCharts'))){
        if ((item.class.includes('s-1') || item.class.includes('s-2') || item.class.includes('s-3')) && this.getUser == null){
          return
        }
        else if (item.class.includes('s-4') || item.class.includes('s-5') || item.class.includes('s-6')){
          if (item.class.includes('s-4-1')){
            this.$router.push(`/OpenCharts?q=1`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-2')){
            this.$router.push(`/OpenCharts?q=2`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-3')){
            this.$router.push(`/OpenCharts?q=3`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-4')){
            this.$router.push(`/OpenCharts?q=4`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-5')){
            this.$router.push(`/OpenCharts?q=5`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-6')){
            this.$router.push(`/OpenCharts?q=6`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-7')){
            this.$router.push(`/OpenCharts?q=7`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-8')){
            this.$router.push(`/OpenCharts?q=8`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-4-9')){
            this.$router.push(`/OpenCharts?q=9`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-1')){
            this.$router.push(`/OpenCharts?q=10`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-2')){
            this.$router.push(`/OpenCharts?q=11`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-3')){
            this.$router.push(`/OpenCharts?q=12`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-4')){
            this.$router.push(`/OpenCharts?q=13`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-5')){
            this.$router.push(`/OpenCharts?q=14`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-6')){
            this.$router.push(`/OpenCharts?q=15`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-7')){
            this.$router.push(`/OpenCharts?q=16`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-8')){
            this.$router.push(`/OpenCharts?q=17`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-5-9')){
            this.$router.push(`/OpenCharts?q=18`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-1')){
            this.$router.push(`/OpenCharts?q=19`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-2')){
            this.$router.push(`/OpenCharts?q=20`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-3')){
            this.$router.push(`/OpenCharts?q=21`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-4')){
            this.$router.push(`/OpenCharts?q=22`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-5')){
            this.$router.push(`/OpenCharts?q=23`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-6')){
            this.$router.push(`/OpenCharts?q=24`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-7')){
            this.$router.push(`/OpenCharts?q=25`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-8')){
            this.$router.push(`/OpenCharts?q=26`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }
          else if (item.class.includes('s-6-9')){
            this.$router.push(`/OpenCharts?q=27`)
            setTimeout(() => {
              window.location.reload()
            }, 150)
            return
          }

        }
      }
      if(item.class.includes('s-3-2')){
        this.$router.push(`/ControlPanel?o=${this.getSelectedDevice.id}`)
        return
      }
      console.log(item)
      console.log(this.getSelectedDevice)
      //if((/*item.href == 'Charts' || */item.href == 'ControlPanel') && this.getSelectedDevice != null && !this.getSelectedDevice.connection){
      /*  console.log('return')
        return
      }*/
      console.log(this.$router)
      if(item.text.length > 0){
        this.setFilterState(item.text)
        if(this.$route.path == '/Charts') this.$root.$emit('updateChartFull', item.text)
      }
      this.$router.push(item.href).catch(() => {})
    },
    cubeOver(){
      this.isShowButtons = true
      console.log('over')
    },
    cubeLeave(){
      this.isShowButtons = false
      console.log('leave')
    },
    accountButtonClick(){
      console.log('accountButtonClick')
    },
    dataButtonClick(){
      console.log('dataButtonClick')
    },
    controlButtonClick(){
      this.$emit('controlButtonClick')
      console.log('controlButtonClick')
    },
    airButtonClick(){
      console.log('airButtonClick')
    },
    waterButtonClick(){
      console.log('waterButtonClick')
    },
    earthButtonClick(){
      console.log('earthButtonClick')
    }
  }
});
</script>

<style scoped>
  @import './cube_.css';
  .dv-btn-mini{
    color: white;
    width: 30% !important;
    min-width: 30px !important;
    max-width: 150px !important;
  }
  .dv-btn-mini-tablet{
    color: white;
    width: 30% !important;
    min-width: 30px !important;
    max-width: 150px !important;
  }
  .cursor-pointer{
    cursor: pointer;
  }
</style>