import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()

export default {
  actions: {
    async deleteConfig(context:any, configId:any) {
      return await axios.delete(`/api/Account/DeleteConfig/${configId}`)
      .then((response:any) => {
        return response.data
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getConfigsAsync(context:any, serialNumber:string) {
      return await axios.get(`/api/Account/GetConfigs/${serialNumber}`)
      .then((response:any) => { 
        context.commit('loadRawConfigs', response.data.deviceConfigs)
        return response.data.deviceConfigs
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getDeviceConfig(context:any, serialNumber:string) {
      return await axios.get(`/api/Head/Config/${serialNumber}`)
      .then((response:any) => { 
        console.log(response)
        context.commit('loadConfigs', {config: JSON.parse(response.data.config), serialNumber})
        return {mobileConfig: JSON.parse(response.data.mobileConfig), config: JSON.parse(response.data.config), direction: response.data.direction, configName: response.data.configName, id: response.data.id }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async bindDeviceWithConfig(context:any, data:any) {
      return await axios.post(`/api/Account/BindDeviceWithConfig/${data.deviceId}/${data.configId}/${data.needLoadToDevice}/${data.needLoadMusic}`)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
  },
  state: {
    configs: [],
    rawConfigs: []
  },
  mutations: {
    loadConfigs(state:any, data:any){
      state.configs.push(data) 
    },
    loadRawConfigs(state:any, data:any){
      state.rawConfigs = data 
    },
    clearConfigs(state:any){
      state.rawConfigs = []
      state.configs = []
    }
  },
  getters:{
		configsData(state:any, serialNumber:string) {
      return state.configs.filter((cnf:any) => cnf.serialNumber == serialNumber)[0]
		},
    getLoadFCTypes(state:any) {
      return state.rawConfigs
		}
  }
}