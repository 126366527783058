<template>
  <div class="container">
    <div class="login-text" align="center">
      ADMIN CONTROLLERS CREATION
    </div>
    <v-row class="mt-16" align="center">
      <v-col 
        justify="center"
        align="center">
        <v-btn
          dark
          class="dv-btn mt-5" 
        >
          CONTROLLER TYPE
        </v-btn>
        <v-btn
          dark
          class="dv-btn mt-3" 
          v-for="controller in deviceTypes" :key="controller.id"
        >
          {{controller.name}}
        </v-btn>
      </v-col>
      <v-col>
      </v-col>
      <v-col class="text-center">
        <v-text-field
          v-model="Name"
          outlined
          label="Name"
          required
          class="mt-3" 
        ></v-text-field>
        <v-text-field
          v-model="TypeCode"
          type="number"
          outlined
          label="Type Code"
          required
          class="mt-3" 
        ></v-text-field>
        <v-select
          v-model="is_device"
          :items="is_deviceState"
          class="mt-3" 
          outlined
          clearable
          dark
          label="Controller?"
        ></v-select>
        <v-btn
          dark
          class="dv-btn mt-3" 
          @click="createType()"
        >
          CREATE
        </v-btn>
      </v-col>
      <!---->
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue"

export default Vue.extend({
  name: "ControllerCreate",
  components: {
  },
  data: () => ({
    is_device: true,
    is_deviceState: [
      true,
      false
    ],
    TypeCode: 0,
    Name: null
  }),
  computed: {
    ...mapGetters(['deviceTypes'])
  },
  methods: {
    ...mapActions(['deviceTypeCreate', 'getDeviceTypes']),
    async createType(){
      let data = {
        Name: this.Name,
        Type: this.TypeCode,
        IsHead: this.is_device
      }
      try{
        await this.deviceTypeCreate(data)
        this.Name = null
        this.TypeCode = null
        this.is_device = true
      }catch(e){
        console.log(e)
      }
    }
  },
  async created(){
    await this.getDeviceTypes()
  }
});
</script>
