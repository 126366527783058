<template>
  <div>
    <v-row>
      <v-col class="col-12" v-show="isDynamicChart">
        <canvas id="chart1"></canvas>
      </v-col>
      <v-col class="fix-pad col-12" v-show="!isDynamicChart">
        <v-card class="fix-card-border">
          <v-overlay
            :absolute="true"
            :value="overlay"
            :opacity="0"
          >
            <transition name="fade">
              <v-img v-show="overlayImage" src="../../assets/enemy.gif" class="up-size"></v-img>
            </transition>
          </v-overlay>
          <plotlyTest
            :plotlyData="plotlyData"
            :isMini="isMobileDisplay"
            class="mb-1"
          ></plotlyTest>
        </v-card>
      </v-col>
      <v-col class="col-12">
        <v-row class="d-flex justify-space-around justify-center">
          <v-col class="col-xl-3 col-lg-3 col-md-3 chart-pad fix-chart-control" cols="12">
            <v-select
              :loading="selectController.loading"
              :disabled="selectController.disabled"
              outlined
              dark
              v-model="selectController.selected"
              :items="getLoadDevices"
              @change="selectControllerNameChange(selectController.selected)"
              item-text="data"
              item-value="id"
              item-color="#000"
              :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              :label="$t('Charts.Object')"
              :hint="items.id"
              :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
            >
              <template v-slot:selection="{ item }">
                <div class="v-select__selection v-select__selection--comma">{{ item.name }}</div>
              </template>
              <template v-slot:item="{ item }">
                <span class="white--text">{{ item.name }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="col-xl-3 col-lg-3 col-md-3 chart-pad fix-chart-control" cols="12">
            <v-select
              :loading="selectSensorType.loading"
              :disabled="selectSensorType.disabled"
              outlined
              dark
              v-model="selectSensorType.selected"
              :items="selectSensorType.items"
              @change="selectSensorTypeChange(selectSensorType.selected)"
              :hint="selectSensorType.items.Type"
              item-text="data"
              item-value="Type"
              item-color="#000"
              :label="$t('Charts.SensorType')"
              :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
            >
              <template v-slot:selection="{ item }">
                <div class="v-select__selection v-select__selection--comma">{{ `${$tc(`SensorNames.${item.Name}`, getSelectedDevice.subDeviceType == 10 ? 2:1, {n: getHLStates(item.Name)})}` }}</div>
              </template>
              <template v-slot:item="{ item }">
                <span class="white--text">{{ `${$tc(`SensorNames.${item.Name}`, getSelectedDevice.subDeviceType == 10 ? 2:1, {n: getHLStates(item.Name)})}`}}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="col-xl-3 col-lg-3 col-md-3 chart-pad fix-chart-control" cols="12">
            <v-dialog
              v-if="windowWidth < 600 || isMobileDisplay"
              ref="menu"
              v-model="menu"
              :disabled="selectSensorType.disabled"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dark
                  v-model="datesRange"
                  :label="$t('Charts.SelectDate')"
                  class="menu-picker"
                  :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                no-title
                scrollable
                :locale="$t('Calendar.locale')"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{$t('Charts.Cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datesRange), selectDateRange(datesRange)"
                >
                  {{$t('Charts.Ok')}}
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-menu
              v-else
              ref="menu"
              v-model="menu"
              :disabled="selectSensorType.disabled"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :class="`${isMobileDisplay?'dv-input-tablet':'dv-input'}`"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dark
                  v-model="datesRange"
                  :label="$t('Charts.SelectDate')"
                  class="menu-picker"
                  :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                no-title
                scrollable
                :locale="$t('Calendar.locale')"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{$t('Charts.Cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datesRange), selectDateRange(datesRange)"
                >
                  {{$t('Charts.Ok')}}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="col-xl-1 col-lg-1 col-md-1 chart-pad fix-chart-control" cols="12">
            <v-btn
              :disabled="selectSensorType.disabled"
              v-if="selectedTypeChart == 0"
              @click.stop="stopChartOne"
              class="dv-chart-btn-mini"
              :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}"
              dark
            >
              <v-icon>
                {{chartOneStopped?'mdi-play':'mdi-stop'}}
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="selectSensorType.disabled"
              v-else
              @click.stop="clearChart"
              class="dv-chart-btn-mini"
              :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}"
              dark
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn> 
          </v-col>
          <v-col class="col-xl-1 col-lg-1 col-md-1 chart-pad fix-chart-control" cols="12">
            <v-btn
              :disabled="selectSensorType.disabled"
              @click.stop="$emit('openBigMap')"
              class="dv-chart-btn-mini "
              :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}"
              dark
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubDeviceType from '../../Enums/SubDeviceType'
import SensorTypeEnum from '../../Enums/SensorType'
import storageService from '../../services/storageService'
import Chart from 'chart.js'
import {  mapActions, mapGetters, mapMutations } from 'vuex'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import ChartZoom from 'chartjs-plugin-zoom'
import ChartSreaming from 'chartjs-plugin-streaming'
import zoomChartData from './zoomChartData.js'
import plotlyTest from './plotlyTest.vue'
import chartData from './chartData.js'

export default {
  components: {
    plotlyTest: plotlyTest
  },
  data: () => ({
    items: [],
    overlayImage:false,
    overlay: false,
    plotlyData:[],
    isDynamicChart: true,
    datas: [],
    selectedTypeChart: 0,
    Chart: null,
    datesRange: null,
    selectSensorType:{
      disabled: false,
      loading: false,
      selected: SensorTypeEnum[1],
      items: [],
      allItems: []
    },
    selectController:{
      disabled: false,
      loading: false,
      selected: null,
      items: [],
      allItems: []
    },
    dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    zoomChartData: zoomChartData,
    chartData: chartData,
    charts: null,
    tempSensors: [],
    isPause: false,
    chartOneStopped: false
  }),
  computed: {
    ...mapGetters(['getHLDescriptor', 'getHLStates','getIsSubscribeChartById', 'getSubscribeCharts', 'getChartInfoById',
     'getChartInfo', 'filterStateCode', 'filterState','getUser', 'getDeviceById', 
    'getAccessToken', 'getSelectedDevice', 'getLoadDevices', 'getChartOneLastValue', 
    'getSelectedDeviceSerialNumber', 'isMobileDisplay', 'getStateConnection','getListToSee']),
    windowWidth(){
      return this.$vuetify.breakpoint.width
    }
  },
  methods: {
    ...mapActions(['getHLNames','StartStateConnection', 'loadStateTypes', 'loadStateSensorsByTypes', 'getDevices', 'GetSensorsData']),
    ...mapMutations(['setHLStates','clearListToSee', 'subscribeChart', 'unsubscribeCharts', 'setFilterState', 'updateChartOne', 'selectDeviceById', 'selectDevice']),
    async updateChart(){
      if(this.getSelectedDevice != null && this.getChartInfo.length > 0){
        this.selectController.selected = this.getSelectedDevice
        this.selectControllerNameChange(this.getSelectedDevice.id)
        const chartInfo = this.getChartInfoById(this.getSelectedDevice.id)
        this.tempSensors = []
        if(chartInfo == null) {
          this.setFilterState(SensorTypeEnum[5000])
          return
        }
        if(this.isDynamicChart){
          this.unSubscribeOnSensors()
        }
        if(chartInfo.sensorTypes.filter(stateType => stateType.Type == this.filterStateCode).length > 0){
          this.selectSensorType.selected = this.filterStateCode

          await chartInfo.sensorTypes.forEach(async chartData => {
            if(chartData.Type == this.filterStateCode){
              if(this.isDynamicChart){
                this.subscribeOnSensor(chartData)
              }else{
              }
            }
          })
        }else{
          this.setFilterState(SensorTypeEnum[chartInfo.sensorTypes[0].Type])
          this.selectSensorType.selected = chartInfo.sensorTypes[0]
          await chartInfo.sensorTypes.forEach(async chartData => {
            if(chartData.Type == this.filterStateCode){
              if(this.isDynamicChart){
                this.subscribeOnSensor(chartData)
              }else{
              }
            }
          })
        }

        if(this.isDynamicChart){
          this.selectSensorTypeChange(this.selectSensorType.selected)
        }else{
          await this.getStaticChart('updateChart 2')
        }
      }
    },
    async selectControllerNameChange(data){
      console.log(data)
      this.tempSensors = []
      this.selectDeviceById(data)
      //const dev = this.getDeviceById
      //this.selectDevice(dev.serialNumber)
      this.selectSensorType.items = []
      this.unSubscribeOnSensors()
      
      const chartInfo = this.getChartInfoById(this.getSelectedDevice.id)
      console.log(chartInfo)
      if(chartInfo){
        chartInfo.sensorTypes.forEach(sensorType => {
          if(this.selectSensorType.items.filter(item => item.Type == sensorType.Type).length == 0){
            this.selectSensorType.items.push({
              Type: sensorType.Type,
              Name: SensorTypeEnum[sensorType.Type]
            })
          }
          sensorType.Type == this.filterStateCode
        })
        this.selectSensorType.items.sort((a, b) => a.Type - b.Type)
      } 

      if(chartInfo == null) {
        this.setFilterState(SensorTypeEnum[5000])
        return
      }
      if(chartInfo.sensorTypes.filter(stateType => stateType.Type == this.filterStateCode).length > 0){
        this.selectSensorType.selected = this.filterStateCode
        let isAdded = false
        await chartInfo.sensorTypes.forEach(async chartData => {
          if(chartData.Type == this.filterStateCode && !isAdded){
            this.subscribeOnSensor(chartData)
            isAdded = true
          }
        })
        if(!this.isDynamicChart){
          await this.getStaticChart('selectControllerNameChange 1')
        }
      }else{
        console.log(chartInfo)
        this.setFilterState(SensorTypeEnum[chartInfo.sensorTypes[0].Type])
        this.selectSensorType.selected = chartInfo.sensorTypes[0]
        let isAdded = false
        await chartInfo.sensorTypes.forEach(async chartData => {
          if(chartData.Type == this.filterStateCode && !isAdded){
            this.subscribeOnSensor(chartData)
            isAdded = true
          }
        })
        if(!this.isDynamicChart){
          await this.getStaticChart('selectControllerNameChange 1')
        }
      }
      await this.loadHLData()
      this.$root.$emit('updateYaMapFilter')
    },
    async selectSensorTypeChange(id){
      try{
        this.setFilterState(SensorTypeEnum[id])
        const chartInfo = this.getChartInfoById(this.getSelectedDevice.id)
        this.tempSensors = []
        this.unSubscribeOnSensors()
        let isAdded = false
        chartInfo.sensorTypes.forEach(chartData => {
          if(chartData.Type == this.filterStateCode && !isAdded){
            this.subscribeOnSensor(chartData)
            isAdded = true
          }
        })
        if(!this.isDynamicChart || this.selectedTypeChart == 2)
        {
          await this.getStaticChart('selectSensorTypeChange 1')
        }
        this.$root.$emit('updateYaMapFilter')
      }catch(e){
        this.selectController.disabled = false
      }
    },
    resetDynamicChart(){
      this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
      this.chartData.data.labels = []
      this.chartData.data.datasets = []
      if(this.Chart) this.Chart.destroy();
      this.tempSensors = []
      this.updateChartOne(this.tempSensors)
      this.$root.$emit('clearSingleUpdate')
      this.chartData.options.zoom.enabled = true
      this.chartData.datesRange = this.datesRange
      this.chartData.rawChart = this.Chart
    },
    async loadSingleChart(chartData){
      const info = {
        dateStart: this.datesRange + 'T00:00:00.411Z',
        dateEnd: this.datesRange + 'T00:00:00.411Z',
        deviceId: chartData.deviceId,
        panelControlId: chartData.PanelElementId,
        type: this.filterStateCode
      }
      await this.GetSensorsData(info)
      .then(dt => {
        this.hideOverlay()
        const dat = dt.sensorDatas.map(ind => {
          return {
            date: ind.date,
            state: ind.state.replace(',', '.')
          }
        })
        this.$root.$emit('chartSingleUpdate', {
          rawData: dat,
          name: `${this.filterState} ${this.getDeviceById(chartData.deviceId).name}`, //getHLDescriptor
          descriptor: this.$tc(`Sensors.${this.filterState}`, this.getSelectedDevice.subDeviceType == 10 ? 2:1, {n: this.getHLDescriptor(this.filterState)}),
          hover: {
            name: this.getDeviceById(chartData.deviceId).name,
            controlName: this.$tc(`SensorNames.${this.filterState}`, this.getSelectedDevice.subDeviceType == 10 ? 2:1, {n: this.getHLStates(this.filterState)})
          }
        })
      })
      .catch(e => {
        //this.$root.$emit('globalError', this.$t('SnackBar.DeviceSensorsNotFound'))
        this.hideOverlay()
      })
    },
    async getStaticChart(father = 'loh', deviceId = 0){
      this.$root.$emit('clearSingleUpdate')
      this.overlay = true
      this.overlayImage = true
      if(this.getSelectedDevice.deviceType == 4){
        const info = {
          dateStart: this.datesRange + 'T00:00:00.411Z',
          dateEnd: this.datesRange + 'T00:00:00.411Z',
          deviceId: this.getSelectedDevice.id,
          panelControlId: `0${this.filterStateCode>1099?'':'0'}${this.filterStateCode>1009?'':'0'}${this.filterStateCode-1000}`,
          type: this.filterStateCode
        }
        await this.GetSensorsData(info)
        .then(dt => {
          this.hideOverlay()
          const dat = dt.sensorDatas.map(ind => {
            return {
              date: ind.date,
              state: ind.state.replace(',', '.')
            }
          })
          this.$root.$emit('chartSingleUpdate', {
            rawData: dat,
            name: `${this.filterState} ${this.getSelectedDevice?.name}`,
            descriptor: this.$tc(`Sensors.${this.filterState}`, this.getSelectedDevice.subDeviceType == 10 ? 2:1, {n: this.getHLDescriptor(this.filterState)}),
            hover: {
              name: this.getSelectedDevice?.name,
              controlName: this.$tc(`SensorNames.${this.filterState}`, this.getSelectedDevice.subDeviceType == 10 ? 2:1, {n: this.getHLStates(this.filterState)})
            }
          })
        })
        .catch(e => {
          console.log(e)
          this.$root.$emit('globalError', this.$t('SnackBar.DeviceSensorsNotFound'))
          this.hideOverlay()
        })
      
      }
      else
      {
        console.log('getSubscribeCharts 1')
        console.log(this.getSubscribeCharts)
        //return
        //const chartInfo = this.getChartInfoById(this.getSelectedDevice.id)
        //if(!this.isDynamicChart){
          //await this.getSubscribeCharts.forEach(async chartData => {
            //console.log('getSubscribeCharts')
            //consolt.log(this.getSubscribeCharts)
          await this.getSubscribeCharts.forEach(async chartData => {
            await this.loadSingleChart(chartData)
          })
          /*await chartInfo.sensorTypes.forEach(async chartData => {
            if(chartData.Type == this.filterStateCode && panelControlId.filter(dt => dt == chartData.PanelElementId).length == 0){
              //panelControlId.push(chartData.PanelElementId)
              const info = {
                dateStart: this.datesRange + 'T00:00:00.411Z',
                dateEnd: this.datesRange + 'T00:00:00.411Z',
                deviceId: this.getSelectedDevice.id,
                panelControlId: chartData.PanelElementId,
                type: this.filterStateCode
              }
              await this.GetSensorsData(info)
              .then(dt => {
                this.hideOverlay()
                const dat = dt.sensorDatas.map(ind => {
                  return {
                    date: ind.date,
                    state: ind.state.replace(',', '.')
                  }
                })
                this.$root.$emit('chartSingleUpdate', {
                  rawData: dat,
                  name: `${this.filterState} ${this.getSelectedDevice?.name}`,
                  descriptor: this.$t(`Sensors.${this.filterState}`),
                  hover: {
                    name: this.getSelectedDevice?.name,
                    controlName: this.filterState
                  }
                })
              })
              .catch(e => {
                this.$root.$emit('globalError', this.$t('SnackBar.DeviceSensorsNotFound'))
                this.hideOverlay()
              })
            }
          })*/
        //}
      }
      console.log(father)
      //console.log(panelControlId)
    },
    async selectDateRange(dataRange, sender = 'LOH'){
      try {
        console.log(`selectDateRange ${dataRange} ${sender}`)
        if(this.isDynamicChart){
          //this.unSubscribeOnSensors()
          this.resetDynamicChart()
        }
        this.isDynamicChart = false

        this.plotlyData = []
        this.selectedTypeChart = 1
        await this.getStaticChart()
      } catch(e){
        //console.log(e)
      }
      this.selectController.disabled = false
    },
    hideOverlay(){
      this.overlayImage = false
      setTimeout(() => {
        this.overlay = false
      }, 500)
    },
    stopChartOne(){
      if(this.chartOneStopped){
        this.chartOneStopped = !this.chartOneStopped
        this.zoomChartData.options.plugins.streaming.pause = false;
      }else{
        this.chartOneStopped = !this.chartOneStopped
        this.zoomChartData.options.plugins.streaming.pause = true;
      }
    },
    resetZoom(){
      this.charts.resetZoom()
    },
    mountChart(){
      const ctx1 = document.getElementById('chart1').getContext('2d')
      if(this.Chart) this.Chart.destroy();
      this.zoomChartData.langaugeData = this.$t('Charts.Tooltip')
      this.zoomChartData.options.title.text = this.$t('Charts.ChartParameter')
      this.Chart = new Chart(ctx1, this.zoomChartData)
      this.Chart.canvas.parentNode.style.height = this.isMobileDisplay? '372px' : '460px';
      this.Chart.resetZoom()
      this.Chart.update()
    },
    connectToHub(){
      this.$root.$on('StateConnectionReceiveSubscribeStatus', (message) => {
        try{

          const element = JSON.parse(message)
        console.log(element)
        if(element && element.State.length > 12){
                console.log('#22')
          const dataSens = element.State.slice(1, 6)
          if(!this.getIsSubscribeChartById(dataSens.replace('.', ''))){
            console.log('#23')
            const metric = JSON.parse(element.State)
            console.log(metric)
            if(metric.State.length == 16){
              
                console.log('#24')
              const enumType = SensorTypeEnum[metric.Type]
              let parsedState1 = 0
              if(enumType == SensorTypeEnum[28]|| enumType == SensorTypeEnum[30] || enumType == SensorTypeEnum[31] || enumType == SensorTypeEnum[36]){ //ACP
                console.log('#26')
                parsedState1 = `${parseFloat(metric.State.slice(13, 15))/10.0}`
              }else{
                console.log('#27')
                parsedState1 = `${parseInt(metric.State.slice(13, 15))}`
              }
              if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
                console.log('#28')
                console.log(this.tempSensors)
                console.log(`${element.Id}`)
                this.tempSensors.push({
                  label: element.Label,
                  sensorType: metric.Type,
                  key: element.Id,
                  value: parsedState1,
                  objectName: element.Name,
                  objectSensor: this.$tc(`SensorNames.${SensorTypeEnum[metric.Type]}`, this.getSelectedDevice.subDeviceType == 10 ? 2:1, {n: this.getHLStates(SensorTypeEnum[metric.Type])}),
                  descript: this.$t(`Sensors.${SensorTypeEnum[metric.Type]}`)
                })
              }else{
                
                console.log('#29')
                this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = parsedState1
              }
                console.log(this.tempSensors)
            }else{
                console.log('#0')
              if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
                console.log('#1')
                const dv = this.getDeviceById(metric.DeviceId)
                this.tempSensors.push({
                  label: element.Label,
                  sensorType: metric.Type,
                  key: element.Id,
                  value: metric.State,
                  objectName: element.Name,
                  objectSensor: this.$tc(`SensorNames.${SensorTypeEnum[metric.Type]}`, this.getSelectedDevice.subDeviceType == 10 ? 2:1, {n: this.getHLStates(SensorTypeEnum[metric.Type])}),
                  descript: this.$t(`Sensors.${SensorTypeEnum[metric.Type]}`)
                })
              }else{
                this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = metric.State
              }
            }
            //console.log(this.tempSensors)
            const goodTempSensors = []
            this.tempSensors.forEach(s => {
              this.getSubscribeCharts.forEach(se => {
              if(s.key && s.key.includes(`${se.PanelElementName}${se.PanelElementId}`)){
                goodTempSensors.push(s)
              }
              })
            })
            this.tempSensors = goodTempSensors
            this.updateChartOne(this.tempSensors)
            return
          }
          
                console.log('#25')
          const temp = element.State.slice(9)

          let tempOut = temp[0] == '0' ? '' : '-'
          tempOut += temp[1] + temp[2] + '.' + temp[3]
          
          if(element.State.includes('.T.') && dataSens != '01.01'){
            if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
              if(this.filterStateCode == 1)
              this.tempSensors.push({
                label: element.Label,
                sensorType: 1,
                key: element.Id,
                value: tempOut,
                objectName: element.Name,
                objectSensor: element.Sensor,
                descript: '°C'
              })
            }else{
              this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = tempOut
            }
            this.updateChartOne(this.tempSensors)
          }
          else if(element.State.includes('.P.') && dataSens != '01.01'){
            let pressOut = temp[0] + temp[1] + temp[2]
            if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
              if(this.filterStateCode == 3)
              this.tempSensors.push({
                label: element.Label,
                sensorType: 3,
                key: element.Id,
                value: pressOut,
                objectName: element.Name,
                objectSensor: element.Sensor,
                descript: 'mm Hg'
              })
            }else{
              this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = pressOut
            }
            this.updateChartOne(this.tempSensors)
          }
          else if(element.State.includes('.V.') && dataSens != '01.01'){
            let humOut = temp[1] + temp[2] + '.' + temp[3]
            if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
              if(this.filterStateCode == 2)
              this.tempSensors.push({
                label: element.Label,
                sensorType: 2,
                key: element.Id,
                value: humOut,
                objectName: element.Name,
                objectSensor: element.Sensor,
                descript: '%'
              })
            }else{
              this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = humOut
            }
            this.updateChartOne(this.tempSensors)
          }
        }
        this.updateChartOne(this.tempSensors)
        this.selectSensorType.disabled = false
        this.selectController.disabled = false
        }catch(e){

        }
      })
    },
    async loadHLData(){
      console.log('TryloadHLData')
      console.log(this.getSelectedDevice)
      if(this.getSelectedDevice.subDeviceType == 10){
        const HLNames = {}
        await this.getHLNames(this.getSelectedDevice.id)
        .then(result => {
          this.setHLStates({
            Input1: {
              name: result.inp1Name,
              measure: result.inp1Measure
            },
            Input2: {
              name: result.inp2Name,
              measure: result.inp2Measure
            },
            Input3: {
              name: result.inp3Name,
              measure: result.inp3Measure
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
      }
    },
    unSubscribeOnSensors(){
      try{
        this.unsubscribeCharts()
        this.tempSensors = []
        if(this.getStateConnection && this.getStateConnection.state == "Connected"){
          this.getStateConnection.invoke("UnSubscribeGettingValuesDeviceById")
          .then(res => console.log('unSubscribeOnSensors'))
          .catch(e => console.log(e))
        }
        if(this.Chart)this.Chart.update()
        this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
        this.updateChartOne([])
      }catch(e){
        console.log(e)
      }
    },
    subscribeOnSensor(chartInfo){
      this.subscribeChart({...chartInfo, isShowOnChart: true, deviceId: this.getSelectedDevice.id})
      let dataSubscription = {
        UserId: this.getUser.id,
        DeviceId: this.getSelectedDevice.id,
        PanelControlId: chartInfo.PanelElementId
      }
      console.log(dataSubscription)
      if(this.getStateConnection && this.getStateConnection.state == "Connected"){
        console.log(dataSubscription)
        if(this.getSelectedDevice.deviceType == 4){
          return
        }
        this.getStateConnection.invoke("SubscribeGettingValuesDeviceById", dataSubscription)
        .then(res => console.log('subscribeOnSensor ' + chartInfo.PanelElementId + ' ' + this.getSelectedDevice.id))
        .catch(e => console.log(e))
      }
    },
    async clearChart(){
      this.plotlyData = []
      if(this.chartOneStopped){
        this.chartOneStopped = !this.chartOneStopped
        this.zoomChartData.options.plugins.streaming.pause = false;
      }
      if(this.selectedTypeChart == 0) return
      this.selectSensorType.selected = SensorTypeEnum[1]
      this.selectController.items = []
      this.selectedTypeChart = 0
      this.isDynamicChart = true
      if(this.Chart){ this.Chart.destroy() }
      this.mountChart()
      await this.updateChart();
    }
  },
  created(){
    this.connectToHub()
  },
  async mounted(){
    await this.getDevices()
    this.mountChart()
    await setTimeout(async () => {
      if(this.getSelectedDevice != null && this.getChartInfo.length > 0){
        this.selectController.selected = this.getSelectedDevice
        this.selectControllerNameChange(this.getSelectedDevice.id)
        const chartInfo = this.getChartInfoById(this.getSelectedDevice.id)
        if(chartInfo == null) {
          return
        }
        if(chartInfo.sensorTypes.filter(stateType => stateType.Type == this.filterStateCode).length > 0){
          this.selectSensorType.selected = this.filterStateCode
        }else{
          this.setFilterState(chartInfo.sensorTypes[0])
          this.selectSensorType.selected = chartInfo.sensorTypes[0]
        }
      }
      Chart.defaults.global.defaultFontColor = 'white'
      await this.updateChart()
      this.$root.$on('updateChartFull', (data) => {
        this.selectSensorTypeChange(SensorTypeEnum[data])
        this.selectSensorType.selected = this.filterStateCode
      })
      await this.$root.$on('addSensorToSubscribe', async (data) => {
        if(!this.isDynamicChart){
          await this.loadSingleChart(data)
        }
      })
      this.$root.$on('unSubscribeOnSensorsCh', async () => {
        this.unSubscribeOnSensors()
      })
      await this.loadHLData()
    }, 1000)
  },
  beforeDestroy(){
    this.unSubscribeOnSensors()
    this.resetDynamicChart()
    this.$root.$off('StateConnectionReceiveSubscribeStatus')
    this.$root.$off('updateChartFull')
    this.$root.$off('addSensorToSubscribe')
    this.$root.$off('unSubscribeOnSensorsCh')
  }
};
</script>
<style>
.up-size{
  width: 200px !important;
}
.fade-enter-active, .fade-leave-active {
  /*transition: opacity .5s;*/
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fix-pad .fix-card-border{
  border: none !important; 
}
.fix-pad{
  padding: 3px;
}
.fix-h{
  min-height: 474px;
}
.dv-btn-micro{
  font-size: 12pt !important;
  width: 160px !important;
  max-width: 250px !important;
  height: 30pt !important;
  font-weight: 400 !important;
  color: white !important;
  border: 1px solid #fff;
  background-color: rgba(39, 39, 39, 0) !important;
  margin-top: -40px;
}
.dv-btn-micro:hover{
  background-color: rgba(0, 0, 0, 1) !important;
}
.mt-fix{
  margin-top: -40px;
}
.v-picker__actions{
  background-color: rgba(0, 0, 0, 1) !important;
}
.chart-pad{
  padding:12px 2.8%;
}
.fix-chart-control{
  margin: 0;
  padding:12px 0;
}
.mini-controls {
  padding: 0 3%;
  width: 94% !important;
}
.fix-ml{
  margin-left: 1px;
}
#overlay-boy{
  background-image: url('../../assets/enemy.gif');
  background-position: center;
  background-size: 200px 165px;
}
</style>