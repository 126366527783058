<template>
  <div class="container">
    <div class="login-text" align="center" v-if="token == null" :class="{'title':isMobileDisplay}">
      {{$t('Login.Login')}}
    </div>
    <v-row v-if="token == null" justify="center" class="container-fix"><!-- -->
      <v-col
        align="center"
        class="mt-16 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2">
        <phoneInput @phone="updatePhone" v-on:keyup.enter="submit()" class="v-size--defaul"/>
        <v-text-field
          v-model="code"
          @change="codeUpdate"
          outlined
          ref="smsFocus"
          label="SMS"
          required
          v-show="step > 1"
          class="mb-2"
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-5':'dv-input'}`"
          v-on:keyup.enter="submit()"
        ></v-text-field>
        <v-btn
          dark
          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
          @click="submit()"
          :loading="isLoading"
        >
          {{$t('Login.Enter')}}
        </v-btn>
        <v-btn
          dark
          class="mt-2"
          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
          v-if="isUserNotFound"
          @click="objectCreationGo()"
        >
          {{$t('Login.ObjectCreation')}}
        </v-btn>
      </v-col>
      <!---->
    </v-row>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import PhoneInput from '../components/PhoneInput.vue'
import storageService from '../services/storageService'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue";

export default Vue.extend({
  name: "Login",
  components: {
    phoneInput:PhoneInput
  },
  data: () => ({
    token: 'check',
    phone: '',
    code: '',
    step: 1,
    isLoading: false,
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    },
    isUserNotFound: false
  }),
  computed: {
    ...mapGetters(['getUser', 'isMobileDisplay'])
  },
  methods: {
    ...mapActions(['loginUserByPhone', 'loginUserByPhoneAndCode']),
    ...mapMutations(['saveUser', 'saveAccessToken', 'selectDeviceSN', 'saveRefreshToken']),
    async codeUpdate(cd){
      console.log(cd)
      if(cd.length==6){
        await this.submit()
      }
    },
    updatePhone(phone){
      if(phone.isValidByLibPhoneNumberJs){
        this.phone = phone.number
        this.submit()
      }
    },
    objectCreationGo(){
      this.saveUser({phone: this.phone})
      this.$router.push('objectcreation').catch(() => {})
    },
    async submit(){
      this.isLoading = true
      if(this.step == 1)
      {
        try {
          let result = await this.loginUserByPhone({phone: this.phone})
          this.step = 2
          this.code = result.result.description
          this.isLoading = false
          this.snackbar.text = this.$t('SnackBar.SmsNotifySend')
          this.snackbar.active = true
          this.$nextTick(() => {
            this.$refs.smsFocus.focus()
          })
        }catch(e){
          console.warn(e.code)
          if(!e.description){
            this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
          }else{
            this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
          }
          this.isLoading = false
          this.snackbar.active = true
          if(e.code == 2000 || e.code == 3006) {
            this.isUserNotFound = true
          }
          this.isLoading = false
        }
      }
      else
      {
        try {
          let result = await this.loginUserByPhoneAndCode({phone: this.phone, code: this.code})
          console.log(result)

          this.isLoading = false
          this.$router.push('Account').catch(() => {})
          this.snackbar.text = this.$t('SnackBar.SuccessLogin')
          this.snackbar.active = true
        }catch(e){
          console.warn(e)
          if(!e.description){
            this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
          }else{
            this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
          }
          this.snackbar.active = true
          this.isLoading = false
        }
      }
    }
  },
  beforeMount(){
  },
  created(){
    this.token = storageService.getToken()
    this.saveAccessToken(this.token)
    this.saveRefreshToken(storageService.getRefreshToken())
    if(storageService.getSelectedDevice())
    this.selectDeviceSN(storageService.getSelectedDevice())
    if(this.token != null){
      this.$router.push('Account').catch(() => {})
    }
  }
});
</script>
<style>
.input-example{
  width: 100%;
}
.margin-big-top{
  margin-top: 25%;
}
.login-form {
  width: 210pt !important;
}
.dv-input .v-input__control .v-input__slot{
  margin-bottom: 0 !important;
  max-width: 210pt !important;
  min-height: 50pt !important;
  max-height: 50pt !important;
}
.dv-input-tablet .v-input__control .v-input__slot {
  min-height: 35pt !important;
  max-height: 35pt !important;
}
.v-input__slot{
  min-height: 35pt !important;
}
.v-input__slot fieldset{
  border-color: #fff !important;
}
.v-text-field__slot .v-label{
  margin-top: 6px !important;
}
.v-menu__content{
  margin-top: 10px;
}
.v-text-field__slot input{
  color: #fff !important;
}
.v-text-field__slot input:-internal-autofill-selected{
  background-color: none !important;
}
.theme--light.v-label{
  color: #fff !important;
}
.v-list-item__title{
  font-size: 1.1rem !important;
}
.v-list-item__subtitle{
  font-size: 0.550rem !important;
}
.v-text-field__details{
  font-size: 12pt !important;
  display: none !important;
}
.login-text{
  font-size: 22pt;
  color:#fff;
}
.division-form-link {
  width: 120pt;
  border: 1px solid #fff;
  border-radius: 4px;
  text-align: center;
  padding-top: 18pt;
  padding-bottom: 18pt;
}
input {outline: none;}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    -webkit-text-fill-color: #fff !important; /* цвет текста */
    -webkit-box-shadow: inset 0 0 0 50px #000 !important; /* Цвет фона */
    -webkit-text-fill-color: #fff !important; /* цвет текста */
    color: #fff !important; /* цвет текста */
    background-color: rgba(0, 0, 0, 1) !important;
}
@media screen and (max-width: 750px){
  .division-form-link {
    width: 100% !important;
  }
  .v-input__control .v-input__slot{
    max-width: 100% !important;
  }
}
@media screen and (max-width: 580px){
}
</style>
