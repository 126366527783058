import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axiosService from "./services/axiosService";
import 'vue-height-tween-transition/index.css'
import VueI18n from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'
const messages = require('../langauges')
Vue.config.devtools = true
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: process.env.VUE_APP_ui_langauge,
  messages
})
//const HubService = require('./services/HubService')
console.log(process.env)

Vue.component('height-tween', require('vue-height-tween-transition'))
Vue.config.productionTip = false;
Vue.prototype.$sensors = []

Vue.use(FlagIcon);
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
