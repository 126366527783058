<template>
  <div class="map">
    <yandex-map
      id="ya-map-open"
      ref="map"
      :settings="settings"
      :coords="coordsCenter"
      :zoom="yaMapZoom"
      class="ya-map"
      value="map"
      :class="{'ya-map-open': bigMap}"
      @click="resizeToBigMap">
      <ymap-marker
        v-for="(marker, index) in coords" :key="index"
        :markerId="index"
        :coords="marker.coords"
        :icon="marker.icon"
        @click="markerClick(marker)"
        :balloon-template="marker.balloonContent"
        @mouseenter="mouseEnter(marker)"
        @mouseleave="mouseLeave(marker)"
      ></ymap-marker>
    </yandex-map>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
const config = process.env.VUE_APP_ui_api_url
const markers = {
  markerOwnerOnlineClose: require('../../assets/icons/markerOwnerOnlineClose.png'), //мой вкл закрыт
  markerOwnerOfflineClose: require('../../assets/icons/markerOwnerOfflineClose.png'), //мой выкл закрыт

  markerOwnerOnlineOpen: require('../../assets/icons/markerOwnerOnlineOpen.png'), //мой вкл открыт
  markerOwnerOfflineOpen: require('../../assets/icons/markerOwnerOfflineOpen.png'), //мой выкл открыт

  markerOnlineClose: require('../../assets/icons/markerOnlineClose.png'), //не мой вкл закрыт
  markerOfflineClose: require('../../assets/icons/markerOfflineClose.png'), //не мой выкл закрыт

  markerOnlineOpen: require('../../assets/icons/markerOnlineOpen.png'), //не мой вкл открыт
  markerOfflineOpen: require('../../assets/icons/markerOfflineOpen.png'), //не мой выкл открыт
}

export default {
  htmlFather: null,
  scrollElement: null,
  components: { yandexMap, ymapMarker },
  props:['sortData'],
  data: () => ({
    yaMapZoom: 16,
    coordsCenter:[47.231331, 39.748304],
    sensorTypes: [],
    coords:[],
    settings: {
      apiKey: '5166066c-78ca-4794-bf22-6784fdaad572',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    },
    hideAccount: true,
    devicesCoords: null,
    connection: null,
    connectionSensors: null,
    bigMap:false,
    fetchSensorData:{
      DeviceId: null,
      PanelElementsId: []
    },
    baloonCloseButton: null,
    tempSensors: [],
    selectedSensors: [],
    buttonsClass: [],
    buttonsElements:[],
    sensors: []
  }),
  computed: {
    ...mapGetters(['getMode', 'getAccessToken','getUser', 'getLoadDevices', 'getSelectedDevice', 'filterState', 'filterStateCode'])
  },
  methods:{
    ...mapActions(['getDevices', 'getCoordsDevices']),
    ...mapMutations(['setStatesRaw', 'selectDevice', 'selectDeviceStatus', 'buildMarkersVuex', 'updateMarkerVuex']),
    mouseEnter(data){
      if(data.online){
        console.log(data)
        this.markerClick(data)
        this.$refs.map.myMap.balloon.open(data.coords, data.balloonContent);
        //data.balloon.open();
      }
    },
    mouseLeave(data){
      /*console.log(data)
      data.balloon.close();**/
    },
    focusByDeviceId(DeviceId){
      const findDevice = this.coords.filter(device => device.id == DeviceId)
      if(findDevice.length > 0){
        //console.log(findDevice[0])
        this.coordsCenter = findDevice[0].coords
        //this.resizeToBigMap()
        this.coordsCenter = findDevice[0].coords
      }
    },
    async markerClick(data){
      //console.log('markerClick')
      if(this.connectionSensors == null){
        await this.ConnectedToStateHub()
      }
      if(this.connectionSensors.connectionState != 'Connected'){
        setTimeout(async () => {
          await this.markerClick(data)
        }, 500)
        return
      }
      //console.log(data)
      if(this.fetchSensorData.DeviceId != null){
        this.connectionSensors.invoke("EndGettingValuesDeviceById", {
          UserId: this.getUser == null ? 0 : this.getUser.id,
          DeviceId: this.fetchSensorData.DeviceId
        })
      }
      this.fetchSensorData.DeviceId = data.id
      this.connectionSensors.invoke("StartGettingValuesDeviceById", {
        UserId: this.getUser == null ? 0 : this.getUser.id,
        DeviceId: this.fetchSensorData.DeviceId
      })
      setTimeout(() => {
        this.fetchSensorData.PanelElementsId = []
        this.buttonsElements =[]
        this.buttonsClass.forEach(cls => {
          //console.log(cls)
          console.log(cls.raw)
          const el = document.getElementsByClassName(cls.raw)[0]
          if(el){
            this.buttonsElements.push(el)
            let dt = ''
            if(cls.raw[4] == 1){
              dt = '.T.'
            }
            else if(cls.raw[4] == 2){
              dt = '.V.'
            }
            else if(cls.raw[4] == 3){
              dt = '.P.'
            }
            //console.log(data)
            this.sensors.forEach(sens => {
              //console.log(sens)
              if(sens.Name == data.objectName && sens.DeviceId == data.id && sens.PanelControlId == cls.raw.slice(0, 4)){
                el.disabled="true"
                el.classList.add('disabled')
                //console.log('HEHE')
              }
            })
            el.onclick = () => {
              let dataSubscription = {
                UserId: this.getUser == null ? 0 : this.getUser.id,
                DeviceId: data.id,
                PanelControlId: cls.raw.slice(0, 4),
                Descriptor: dt,
                PanelControlName: cls.PanelElementName,
                Name: data.objectName
              }
              //console.log(dataSubscription)
              el.disabled="true"
              el.classList.add('disabled')
              this.$root.$emit('subscribeSensor', dataSubscription)
              this.$root.$emit('closeBigMapGlobal')
            }
          }
        })
        if(false){
          data.object.sensorTypes.forEach(sensor => {
            let elem = document.getElementById(`${sensor.PanelElementId}${sensor.Type}`)
            this.fetchSensorData.PanelElementsId.push({elem: elem, id:`${sensor.PanelElementId}${sensor.Type}`})
          })
        }else{
          data.object.openSensorTypes.forEach(sensor => {
            let elem = document.getElementById(`${sensor.PanelElementId}${sensor.Type}`)
            this.fetchSensorData.PanelElementsId.push({elem: elem, id:`${sensor.PanelElementId}${sensor.Type}`})
          })
        }
      }, 500)
    },
    resizeToBigMap(){
      this.$refs?.map?.myMap?.balloon?.close()
      console.log('resizeToBigMap ' + this.bigMap)
      if(!this.bigMap){
        this.bigMap = !this.bigMap
        this.htmlFather.className = "scroll-off"
        console.log(this.scrollElement)
        this.$root.$emit('closeAccountGlobal')
        try{
          this.$refs["map"].$el.scrollIntoView({ behavior: 'smooth' });
        }
        catch(e){
          console.log(e)
        }

        /*this.$nextTick(() => {
          this.$vuetify.goTo("map", {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic'
          })
        });*/
      }

    },
    async resizeToMiniMap(){
      if(this.bigMap){
        this.htmlFather.className = ""
        this.bigMap = !this.bigMap
        this.htmlFather.scrollIntoView({ behavior: 'smooth' });
        console.log(this.htmlFather)
      }
      //this.hideAccount = true
    },
    getMarker(isOwner, isOnline, isOpen){
      //console.log(`getMarker: ${isOwner} ${isOnline} ${isOpen}`)
      if(false){
        if(isOnline){
          if(isOpen){
            return markers.markerOwnerOnlineOpen
          }else{
            return markers.markerOwnerOnlineClose
          }
        }else{
          if(isOpen){
            return markers.markerOwnerOfflineOpen
          }else{
            return markers.markerOwnerOfflineClose
          }
        }
      }else{
        if(isOnline){
          if(isOpen){
            return markers.markerOnlineOpen
          }else{
            return markers.markerOnlineClose
          }
        }else{
          if(isOpen){
            return markers.markerOfflineOpen
          }else{
            return markers.markerOfflineClose
          }
        }
      }
    },
    async ConnectedToMapHub(){
      this.connection = this.getMode ?
        new HubConnectionBuilder()
        .withUrl(`${config}/open_map`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .configureLogging(LogLevel.Error)
        .build()
        :
        new HubConnectionBuilder()
        .withUrl(`${config}/open_map`, {
          //accessTokenFactory: () => this.getAccessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .configureLogging(LogLevel.Error)
        .build()
      this.connection.start()
      .then(() => {
        //console.log('Map Connected')
      })
      .catch((err) => {
        return console.error('err ' + err)
      })
      this.connection.on("OnConnected", (message) => {
        if(message){
          this.buildMarkers(JSON.parse(message).AllDeviceMapData)
        }
     })
      this.connection.on("MapDeviceUpdate", (message) => {
        /*console.log(message)
        if(message){
          this.updateMarker(JSON.parse(message))
        }*/
     })
    },
    async ConnectedToStateHub(){
      this.connectionSensors = this.getMode ?
        new HubConnectionBuilder()
        .withUrl(`${config}/open_state`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .configureLogging(LogLevel.Error)
        .build()
        :
        new HubConnectionBuilder()
        .withUrl(`${config}/open_state`, {
          //accessTokenFactory: () => this.getAccessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .configureLogging(LogLevel.Error)
        .build();
      this.connectionSensors.start()
      .then(() => {
        //console.log('State Connected')
      })
      .catch((err) => {
        return console.error('err ' + err)
      })
      this.connectionSensors.on("ReceiveStatus", (message) => {})
      this.connectionSensors.on("ReceiveMessage", (message) => {
        if(message){
          //console.log(message)
          let msg = message.split(';')
          msg.forEach(element => {
            if(element.length > 12){
              let elementId = element[1] + element[2] + element[4] + element[5]
              let typeSensor = null
              if(element.includes('.T.')){
                typeSensor = 1
              } else if(element.includes('.P.')){
                typeSensor = 3
              } else if(element.includes('.V.')){
                typeSensor = 2
              }
              const findEl = this.fetchSensorData.PanelElementsId.filter(sensor => sensor.id == `${elementId}${typeSensor}`)
              if(findEl.length == 0 || !findEl[0].elem) return
              let oldText = findEl[0].elem.innerText.split("):")[0]
              let parsedState = ""
              const dataSens = element.slice(1, 6)
              const temp = element.slice(9)
              let tempOut = temp[0] == '0' ? '' : '-'
              tempOut += temp[1] + temp[2] + '.' + temp[3]
              if(element.includes('.T.') && dataSens != '01.01'){
                parsedState = tempOut + "°C"
              }
              else if(element.includes('.P.') && dataSens != '01.01'){
                parsedState = temp[0] + temp[1] + temp[2] + ' мм рт. ст.'
                
              }
              else if(element.includes('.V.') && dataSens != '01.01'){
                parsedState = temp[1] + temp[2] + '.' + temp[3] + "%"
              }
              findEl[0].elem.innerText = oldText + "):" + parsedState
              return
            }
          })
        }
     })
    },
    buildSensor(data, isOwner){
      let acc =""
      //console.log(data)
      data.forEach(sensor => {
        const btn = `<button class="black-btn ${sensor.PanelElementId}${sensor.Type}">Add</button></div>`
        //console.log(this.filterStateCode)
        if(this.$route.path == '/Account' || sensor.Type == this.filterStateCode)
        acc += `<div class="sensor"><span id="${sensor.PanelElementId}${sensor.Type}"
        class="${isOwner ? (sensor.Available != 2 ? '' : 'bg-gray') : ''}">
        ${this.sensorTypes[sensor.Type]} (${sensor.PanelElementName}): </span>
        ${this.$route.path == '/Account' ? '' : btn}`
        if(this.buttonsClass.filter(b => b.raw == `${sensor.PanelElementId}${sensor.Type}`).length == 0){
          this.buttonsClass.push({raw: `${sensor.PanelElementId}${sensor.Type}`, PanelElementName: sensor.PanelElementName})
        }
      })
      //console.log(this.buttonsClass)
      return acc
    },
    clearChart(){
      this.buttonsElements.forEach(el => {
        el.removeAttribute("disabled");
        el.classList.remove('disabled')
      })
    },
    async buildMarkers(data){
      console.log('buildMarkers')
      console.log(data)
      console.log(this.filterStateCode)
      this.setStatesRaw(data)
      //console.log(data)
      this.coords = []
      data.forEach((object, index) => {
        const isConnected = object.headConnectedStatusCode == 0? true : false
        let deviceFind = null
        console.log('this.getLoadDevices')
        console.log(this.getLoadDevices)
        const isOwner = false/*this.getLoadDevices?.filter(device => {
          if(device.id == object.Id){
            deviceFind = device
          }
          return device.id == object.Id
        }).length > 0*/
        console.log('isOwner ' + isOwner)
        if(this.$route.path == '/Account' || object.openSensorTypes.filter(s => s.Available == 2 && s.Type == this.filterStateCode).length > 0 || isOwner){
          //console.log(this.$route.path == '/Account')
          this.coords.push({
            coords:[object.Latitude, object.Longitude],
            id: object.Id,
            online: isConnected,
            object: object,
            objectName: isOwner? deviceFind.name : `Object${object.Id}`,
            isOwner: isOwner,
            icon:{
              layout: 'default#imageWithContent',
              imageHref: this.getMarker(isOwner, isConnected, object.openSensorTypes != null && object.openSensorTypes.length > 0),
              imageSize: [70, 70],
              imageOffset: [-32, -45]
            },
            balloonContent: isConnected ? isOwner? `
              <p><h1 class="fix-height">${deviceFind.name}${object.sensorTypes.length == 0 ? '' : ` All ${this.filterState==null || this.$route.path == '/Account'?'':this.filterState + ' '}sensors:     `}</h1></p>
              ${this.buildSensor(object.sensorTypes, isOwner)}
            `: object.openSensorTypes.length == 0 ? null :`
              <p><h1 class="fix-height">${object.openSensorTypes.length == 0 ? '' : `Object${object.Id} Open ${this.filterState==null || this.$route.path == '/Account'?'':this.filterState + ' '}sensors:     `}</h1></p>
              ${this.buildSensor(object.openSensorTypes, isOwner)}
            ` : null,
          })
          console.log(this.coords)
        }
      })
      //console.log(this.coords)
    },
    async updateMarker(data){
      console.log('updateMarker')
      console.log(data)
      console.log(this.filterStateCode)
      this.setStatesRaw(data)
      let dataFind = {
        coords:[]
      }
      if(!this.coords.filter(coord => coord.id == data.Id).length == 0){
        dataFind = this.coords.filter(coord => coord.id == data.Id)[0]
      }
      
      const isConnected = data.headConnectedStatusCode == 0? true : false
      let deviceFind = null
      this.selectDeviceStatus({
        id: data.Id,
        status: isConnected
      })
      const isOwner = false/*this.getLoadDevices.filter(device => {
        if(device.id == data.Id){
          deviceFind = device
        }
        return device.id == data.Id
      }).length > 0*/
      dataFind.coords=[data.Latitude, data.Longitude]
      dataFind.id= data.Id
      dataFind.object = data
      dataFind.isOwner = isOwner
      dataFind.online= isConnected
      dataFind.icon={
        layout: 'default#imageWithContent',
        imageHref: this.getMarker(isOwner, isConnected, data.openSensorTypes != null && data.openSensorTypes.length > 0),
        imageSize: [70, 70],
        imageOffset: [-32, -45]
      }
      if(isOwner){
        //console.log(data.sensorTypes)
        dataFind.balloonContent = isConnected ? `
          <p><h1 class="fix-height">${deviceFind.name}${data.sensorTypes.length == 0 ? '' : ` All ${this.filterState==null || this.$route.path == '/Account'?'':this.filterState + ' '}sensors:     `}</h1></p>
          ${this.buildSensor(data.sensorTypes, isOwner)}
        ` : null
      }else{
        //console.log(data.openSensorTypes)
        dataFind.balloonContent = data.openSensorTypes.length == 0 ? null : isConnected ? `
          <p><h1 class="fix-height">${data.openSensorTypes.length == 0 ? '' : `Object${data.Id} Open ${this.filterState==null || this.$route.path == '/Account'?'':this.filterState + ' '}sensors:     `}</h1></p>
          ${this.buildSensor(data.openSensorTypes, isOwner)}
        ` : null
      }
      if(this.coords.filter(coord => coord.id == data.Id).length == 0){
        this.coords.push(dataFind)
      }
      //console.log('removed 1')
      if(!(this.$route.path == 'Account' || data.openSensorTypes.filter(s => s.Available == 2 && s.Type == this.filterStateCode).length > 0 || isOwner)){
        //console.log('removed 2')
        this.coords = this.coords.filter(c => c.object != data)
      }
      if(!isOwner){
        this.$root.$emit('updateChart', this.coords)
      }
    }
  },
  async created(){
    this.scrollElement = document.getElementsByClassName("map")[0]
    this.htmlFather = document.getElementsByTagName("html")[0]
    this.$root.$on('updateSensors', async (data) => {
      this.sensors = data
    })
    
    this.sensorTypes = [
      '',
      this.$t('SensorNames.Temperature'),
      this.$t('SensorNames.Humidity'),
      this.$t('SensorNames.Pressure')
    ]
    try{
      await this.getDevices()
    }catch (e){
      console.log(e)
    }
    this.$root.$on('updateChartFull', () => {
      //console.log('updateChartFull')
      this.connection.stop()
      this.ConnectedToMapHub()
    })
    this.$root.$on('clearChart', this.clearChart)
    this.$root.$on('closeBigMapGlobal', this.resizeToMiniMap)
    this.$root.$on('openBigMapGlobal', this.resizeToBigMap)
    this.$root.$on('OpenBigMapGlobalAndFocusByDeviceId', this.focusByDeviceId)
    await this.ConnectedToMapHub()
    //await this.ConnectedToStateHub()
  },
  mounted(){
    /*console.warn('mounted')
      this.$emit('mountMap')
    setTimeout(() => {
      this.resizeToBigMap()
    },15000)*/
    this.$root.$emit('closeAccountGlobal')
    setTimeout(() => {
      this.$root.$emit('openBigMapGlobal')
    },750)
  },
  destroyed(){
    if(this.connection != null) this.connection.stop()
    if(this.connectionSensors != null) this.connectionSensors.stop()
  }
}
</script>

<style lang="sass">
.disabled
  background-color: #666565 !important
.scroll-off
  overflow: hidden!important

.fix-height
  width: 270px !important

.black-btn
  background-color: black
  color: white
  border-radius: 3px
  padding: 3px
  float: right

.ya-map
  opacity: 0.4

.ya-map
  height: 250px
.ya-map-open
  height: 96%
  position: absolute
  overflow-y: hidden
  top: 4%
  left: 0
  opacity: 1

.black-btn:hover
  opacity: 0.8

.sensor
  margin-bottom: 10px

.map
  margin-top: 100px
  width: 100%

</style>