<template>
  <div style="margin-top:2%;">
    <div v-if="getSelectedDevice && (getSelectedDevice.deviceType == 4|| getSelectedDevice.deviceType == 1)">
      <Mercury236ControlPanel v-if="getSelectedDevice.subDeviceType == 3"></Mercury236ControlPanel>
      <Mercury206ControlPanel v-else-if="getSelectedDevice.subDeviceType == 4"></Mercury206ControlPanel>
      <ProteusControlPanel v-else-if="getSelectedDevice.subDeviceType == 8"></ProteusControlPanel>
      <ModuleAControlPanel v-else-if="getSelectedDevice.subDeviceType == 9"></ModuleAControlPanel>
      <HeadLightControlPanel v-else-if="getSelectedDevice.subDeviceType == 10"></HeadLightControlPanel>
      <PointControlPanel v-else-if="getSelectedDevice.subDeviceType == 2"></PointControlPanel>
    </div>
    <div v-else>
      <div class="mt-10" v-if="getSelectedDevice && getSelectedDevice.serialNumber.length > 13 || windowWidth > 800">
        <div id="lowerLine"></div>
        <div class="user-objects pb-6"></div>
        <div class="MainContainerBar">
          <k-progress v-show="isShowProgressBar" :percent="progressBarData" active :show-text="false" :line-height="1" bg-color="rgba(0,0,0,1)" color="#fff" active-color="#000"></k-progress>
          <div v-show="!isShowProgressBar" class="k-pr"></div>
        </div>
        <div class="row" id="MainContainer" :class="{'m-two':getSelectedDevice&&getSelectedDevice.deviceType == 4}"></div>
      </div>
      <div v-else class="container-fix mt-6" :style="{height: windowHeight*0.8 + 'px'}">
        <div class="say-device text-center pb-1" v-if="getSelectedDevice">{{getSelectedDevice.name}}</div>
          <k-progress v-show="isShowProgressBar" :percent="progressBarData" active :show-text="false" :line-height="1" bg-color="rgba(0,0,0,1)" color="#fff" active-color="#000"></k-progress>
          <div v-show="!isShowProgressBar" class="k-pr"></div>
        <div class="mobile-panel scroll">
          <div v-if="mobileConfig" class=" pl-3 pr-3 pb-3">
            <div v-for="(item, index) in mobileConfig" :key="index">
              <div
                :class="{'border-wi': indexPageSelected == index}"
                :ref="`${item.name_page}${index}`"
                class="dv-btn mt-3 v-btn border-wii" dark @click="selectPage(item, index, `${item.name_page}${index}`)"> {{item.name_page}}</div>
              <div v-if="indexPageSelected == index">
                <div v-for="(itemSub, index2) in item.subPages" :key="index2">
                  <div
                    :ref="itemSub.name_page" 
                    :class="{'border-wi-mini': selectedSubPageId == itemSub.number_page}"
                    class="dv-btn mt-3 v-btn" dark @click="selectSubPage(itemSub)"
                  >
                    {{itemSub.name_page}}
                  </div>

                  
                  <div class="mb-5" v-if="subPage != null && subPage == itemSub">
                    <div v-if="subPage.buttons">
                      <div v-for="(itemElement) in subPage.buttons" :key="itemElement.ID">
                        <v-btn
                          :class="{'fixed-button-mobile': opsData.filter(met => met.id == itemElement.ID).length > 0 ? opsData.filter(met => met.id == itemElement.ID)[0].value : false}"
                          :id="itemElement.ID" class="dv-btn mt-3 sub-element-button v-btn" dark @click="buttonMobilePanelClick(itemElement)">{{itemElement.name}}</v-btn>
                      </div>
                    </div>
                    <div v-if="subPage.meteo">
                      <div v-for="(itemElement) in subPage.meteo" :key="itemElement.ID">
                        <div class="dv-btn mt-3 sub-element-button meteo-button v-btn" dark>
                          {{itemElement.name}}: {{meteoData.filter(met => met.id == itemElement.ID)[0].value}}
                        </div>
                      </div>
                    </div>
                    <div v-if="subPage.progressbars">
                      <div v-for="(itemElement) in subPage.progressbars" :key="itemElement.ID">
                        <v-slider
                          dark
                          :label="itemElement.name"
                          v-model="progressData.filter(met => met.id == itemElement.ID)[0].value"
                          @change="progressBarChange(progressData.filter(met => met.id == itemElement.ID)[0])"
                          max="99"
                          min="0"
                        ></v-slider>
                      </div>
                    </div>
                    <div v-if="subPage.videos">
                      <div v-for="(itemElement, index) in subPage.videos" :key="itemElement.ID">
                        <div :class="`mobile-video video-container${index}`" :style="{height: windowWidth*0.65 + 'px'}">
                        </div>
                      </div>
                    </div>
                  </div>


                  <div v-if="selectedSubPageId == itemSub.number_page">
                    <div v-for="(itemElement, index3) in itemSub.elements" :key="index3">
                      <v-btn class="dv-btn mt-3 sub-element-button v-btn" dark v-if="itemElement.action == '1' && itemElement.type == 'button'">
                        {{itemElement.name}}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-for="(itemElement) in item.buttons" :key="itemElement.ID">
                  <v-btn 
                    :id="itemElement.ID" 
                    :class="{'fixed-button-mobile': opsData.filter(met => met.id == itemElement.ID).length > 0 ? opsData.filter(met => met.id == itemElement.ID)[0].value : false}"
                    class="dv-btn mt-3 sub-element-button v-btn" dark
                    @touchstart="buttonMobilePanelMouseDown(itemElement)"
                    @touchend="buttonMobilePanelMouseUp(itemElement)"
                    @click="buttonMobilePanelClick(itemElement)"
                  >
                    {{itemElement.name}}
                  </v-btn>
                </div>
                <div v-for="(itemElement) in item.meteo" :key="itemElement.ID">
                  <div class="dv-btn mt-3 sub-element-button meteo-button v-btn" dark>
                    {{itemElement.name}}: {{meteoData.filter(met => met.id == itemElement.ID)[0].value}}
                  </div>
                </div>
                <div v-if="item.progressbars">
                  <div v-for="(itemElement) in item.progressbars" :key="itemElement.ID">
                    <v-slider
                      dark
                      :label="itemElement.name"
                      v-model="progressData.filter(met => met.id == itemElement.ID)[0].value"
                      @change="progressBarChange(progressData.filter(met => met.id == itemElement.ID)[0])"
                      max="99"
                      min="0"
                    ></v-slider>
                  </div>
                </div>
                <div v-if="item.videos">
                  <div v-for="(itemElement, index) in item.videos" :key="itemElement.ID">
                    <div :class="`mobile-video video-container${index}`" :style="{height: windowWidth*0.65 + 'px'}">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

let streamService = null
const {StreamService} = require('./stream/StreamService').default
const $ = require('../../Libs/jquery-3.4.1.min.js')
import KProgress from 'k-progress';
import JSMpeg from '../../Libs/jsmpeg.min.js'
import testJson from './test.json'
import storageService from '../../services/storageService'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import controlPanel from './controlpanel.js'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import Vue from "vue";
import Mercury236ControlPanel from './Panels/Mercury236ControlPanel.vue'
import Mercury206ControlPanel from './Panels/Mercury206ControlPanel.vue'
import ProteusControlPanel from './Panels/ProteusControlPanel.vue'
import ModuleAControlPanel from './Panels/ModuleAControlPanel.vue'
import HeadLightControlPanel from './Panels/HeadLightControlPanel.vue'
import PointControlPanel from './Panels/PointControlPanel.vue'

const config = process.env.VUE_APP_ui_api_url

export default Vue.extend({
  name: "ControlPanel",
  props: {
    query: { type: String }
  },
  components: {
    KProgress,
    Mercury236ControlPanel,
    Mercury206ControlPanel,
    ProteusControlPanel,
    ModuleAControlPanel,
    HeadLightControlPanel,
    PointControlPanel
  },
  data(){
    return{
      isShowProgressBar: false,
      progressBarData: 0,
      isMobile: false,
      subPage: null,
      video:null,
      indexPageSelected: -1,
      isBig: true,
      canvasses: [],
      jsmpegPlayer: [],
      videoData: [],
      meteoData: [],
      opsData: [],
      progressData: null,
      passwordConfig: null,
      mobileConfigJson: null,
      selectedSubPageId: null,
      selectedPageId: null,
      htmlFather: null,
      parentObject:null,
      subParentObject:null,
      items:null,
      connection: null,
      stateConnection: null,
      connectionControl: null,
      serialNumber: "",//"022B47EF"//"023E6B5E",
      FKJson: null,
      isLoading: false,
      mobileConfig: [],
      videoJsMpegStreams: []
    }
  },
  computed: {
    ...mapGetters(['configsData', 'getSelectedDeviceSerialNumber', 'getAccessToken', 'getUser', 'getSelectedDevice', 'isMobileDisplay']),
    windowWidth(){
      return this.$vuetify.breakpoint.width
    },
    windowHeight(){
      return this.$vuetify.breakpoint.height
    }
  },
  methods:{
    ...mapMutations(['selectDeviceById']),
    ...mapActions(['getDeviceConfig', 'getDevicesAsync', 'getAllCamerasByDeviceId', 'getDevices']),
    buttonMobilePanelMouseDown(item){
      if(item.type != 'unfix'){
        return
      }
      //console.log(item)
      this.sendMessage(`:${item.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00`)
    },
    buttonMobilePanelMouseUp(item){
      if(item.type != 'unfix'){
        return
      }
      //console.log(item)
      setTimeout(() => {
        this.sendMessage(`:${item.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00`)
      }, 100)
    },
    updateProgressBar(data = 0){
      if(data == -1){
        this.progressBarData = 100
        setTimeout(() => {
          this.isShowProgressBar = false
          this.progressBarData = 0
        }, 500)
        return
      }
      if(data == -2){
        this.isShowProgressBar = false
        this.progressBarData = 0
        return
      }
      if(data == -3){
        this.isShowProgressBar = true
        this.progressBarData = 15
        return
      }
        this.isShowProgressBar = true
      console.log(`updateProgressBar ${this.progressBarData}`)
      if(this.progressBarData + 15 <= 100){
        this.progressBarData += 15
      }else {
        this.progressBarData = 100
        setTimeout(() => {
          this.isShowProgressBar = false
          this.progressBarData = 0
        }, 500)
      }
    },
    async updateVideoJsMpegStreams(streams){
      
    },
    test(){

    },
    async openFullStream(streamUrls, pageNumber){
      console.log(`video-container${pageNumber}` == 'video-container0')
      const videoCont = document.getElementsByClassName(`video-container${pageNumber}`)[0]
      console.log(videoCont)
      await streamUrls.forEach(async (streamUrl, index) => {
        console.log(streamUrl)
        await setTimeout(async () => {
          if(streamUrl.length > 10){
            const containerJQ = $(`.video-container${pageNumber}`)
            console.log(containerJQ)
            const w = containerJQ.width()
            const h = containerJQ.height()
          
            await fetch(`${process.env.VUE_APP_ui_video_url}/start`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },
              body: JSON.stringify({ streamUrl }),
            })
            .then((response) => response.json())
            .then((data) => {
              if (this.jsmpegPlayer.length-1 > index && this.jsmpegPlayer[index]) {
                this.jsmpegPlayer[index].stop()
                this.jsmpegPlayer[index].destroy()
              }
              const canvas = document.createElement('canvas')
              canvas.id = `jsmpeg-player${index}`
              canvas.style.width = `${w}px`
              canvas.style.height = `${h}px`
              canvas.style.float = 'left'
              if(index == 0){
                canvas.style.position = 'relative'
              }
              else if(index == 1){
                canvas.style.position = 'absolute'
                canvas.style.visibility = 'hidden'
              }
              else if(index == 2){
                canvas.style.position = 'absolute'
                canvas.style.visibility = 'hidden'
              }
              else if(index == 3){
                canvas.style.position = 'absolute'
                canvas.style.visibility = 'hidden'
              }
              setTimeout(() => {
                $(`#jsmpeg-player${index}`).click((da) => {

                  console.log(index)
                  this.isBig = !this.isBig
                  if(this.isBig){
                    console.log('isbig')
                    this.canvasses.forEach((cnvs, ind) => {
                      
                      if(index == ind){
                        cnvs.cnv.style.visibility = 'visible'
                        cnvs.cnv.style.width = `${w}px`
                        cnvs.cnv.style.height = `${h}px`
                      }else{
                        cnvs.cnv.style.visibility = 'hidden'
                        cnvs.cnv.style.width = `${0}px`
                        cnvs.cnv.style.height = `${0}px`
                      }
                    })
                  }
                  else
                  {
                    console.log('not isbig')
                    this.canvasses.forEach((cnvs, ind) => {
                      cnvs.cnv.style.visibility = 'visible'
                      cnvs.cnv.style.width = `${w/2}px`
                      cnvs.cnv.style.height = `${h/2}px`
                      if(ind == 0){
                        cnvs.cnv.style.position = 'relative'
                      }
                      else if(ind == 1){
                        cnvs.cnv.style.position = 'relative'
                      }
                      else if(ind == 2){
                        cnvs.cnv.style.position = 'relative'
                      }
                      else if(ind == 3){
                        cnvs.cnv.style.position = 'relative'
                      }
                    })
                  }
                });
              }, 100)
              videoCont.appendChild(canvas)
              if(this.jsmpegPlayer.length-1 > index && this.jsmpegPlayer[index]){
                this.jsmpegPlayer[index].stop()
                this.jsmpegPlayer[index] = new JSMpeg.Player(data.url, {
                  canvas,
                  autoplay: true,
                })
              }else{
                this.jsmpegPlayer.push(new JSMpeg.Player(data.url, {
                  canvas,
                  autoplay: true,
                }))
              }
              if(this.canvasses[index]){
                this.canvasses[index] = {
                  cnv: canvas,
                  isBig: false
                }
              }else{
                this.canvasses.push({
                  cnv: canvas,
                  isBig: false
                })
              }
            })
          }
        }, 200*index)
      })
    },
    progressBarChange(data){
      const value = `${data.value}`.length == 1 ? `0${data.value}` : `${data.value}`
      this.sendMessage(`:${data.id}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.${value}.00`)
    },
    buttonMobilePanelClick(data/*, state*/){
      console.log(data)
      console.log(this.opsData)
      if(data.type == 'unfix'){
        return
      }
      const findIndex = this.opsData.findIndex(but => but.id == data.ID)
      if(findIndex >= 0){
        if(!this.opsData[findIndex].value){
          console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00;`)
          this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00;`)
        }else{
          console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
          this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
        }
        this.opsData[findIndex].value = !this.opsData[findIndex].value
      }else{
        console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00;`)
        console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
        this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00;`)
        this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
      }
      /*if(state){
        console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00;`)
        this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.01.00;`)
      }else{
        console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
        this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
      }*/
      //console.log(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
      //this.sendMessage(`:${data.ID}.${this.passwordConfig.substring(0, 2)}*${this.passwordConfig.substring(2)}.02.00;`)
    },
    selectSubPage(data){
      console.log(data)
      //this.$refs[data.name_page][0]?.$el?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' });
      if(this.subPage == data){
        this.subPage = null
      }else{
        this.subPage = data
      }
      /*if(this.selectedSubPageId == data.number_page){
        this.selectedSubPageId = null
        return
      }
      this.selectedSubPageId = data.number_page
      console.log(data)*/
    },
    async selectPage(data, index, ref){
      console.log(data)
      //this.$refs[ref][0]?.$el?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' });
      if(this.indexPageSelected == index){
        this.indexPageSelected = -1
        this.subPage = null
        return
      }
      this.indexPageSelected = index
      if(data.videos && data.videos.length > 0){
        this.isShowProgressBar = true
        this.progressBarData = 10
        await data.videos.forEach(async (pr, index) => {
          const findVideo = this.video.cameras.filter(vid => vid.panelElementId.substr(0, 5) == pr.ID)
          if(findVideo.length > 0){
            this.videoData.push({
              id: pr.ID,
              value: JSON.parse(findVideo[0].rtcp)
            })
            setTimeout(async () => {
              //await this.openFullStream(JSON.parse(findVideo[0].rtcp), index)
              const canvasFathers = document.getElementsByClassName(`video-container${index}`)[0]
              if(streamService){
                  streamService.destroy()
              }
              streamService = new StreamService(JSON.parse(findVideo[0].rtcp), canvasFathers, index,false, this.updateProgressBar )
              
              this.progressBarData = 15
            }, 1000)
          }
          /*this.progressData.push({
            id: pr.ID,
            value: ''
          })*/
        })
      }else{
              this.progressBarData = 0
              this.isShowProgressBar = false
      }
      return
      this.selectedSubPageId = null
      if(this.selectedPageId == data.number_page){
        this.selectedPageId = null
        return
      }
      this.selectedPageId = data.number_page
      console.log(data)
    },
    clickSubParentMobileConfig(data){
      this.subParentObject = data
    },
    clickParentMobileConfig(data){
      console.log(data)
      this.parentObject = data
      this.subParentObject = null
    },
    generateMobilePanelConfig(config){
      this.htmlFather.className = "scroll-off"
      this.mobileConfig = []
      config.pages.forEach((page, index) => {
        if(page.name_page.startsWith('      ')){
          this.mobileConfig[this.mobileConfig.length-1].subPages.push(page)
        }else{
          this.mobileConfig.push(page)
          this.mobileConfig[this.mobileConfig.length-1].subPages = []
        }
      })
      this.passwordConfig = config.password
      console.log(config.password)
      console.log(this.mobileConfig)
    },
    sendMessage(message){
      if(this.connectionControl.state == 'Connected'){
        try {
          //console.log('Command ' + message)
          this.connectionControl
          .invoke("Command", {
            HeadSerialNumber: this.serialNumber,
            Command: message
          })
        } catch (e) {
          //console.log(e);
          controlPanel.onerror(e)
        }
      }
      else{
        //console.log(this.connectionControl.state)
      }
    },
    panelReady(){
      console.log('panelReady !!!')
    },
    async isCreated(){
      try {
        this.connectionControl = new HubConnectionBuilder()
          .withUrl(`${config}/device`, {
            accessTokenFactory: () => this.getAccessToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build();

        this.connectionControl.start()
        .then(() => {
          console.log('Connected')
        })
        .catch((err) => {
          return console.error('err ' + err)
        })

        this.connection = new HubConnectionBuilder()
          .withUrl(`${config}/state`, {
            accessTokenFactory: () => this.getAccessToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build();

        this.connection.start()
        .then(() => {
          console.log('Connected')
          this.connection.invoke("StartGettingValuesDevice", {
            UserId: this.getUser.id,
            SerialNumber: this.serialNumber
          })
          if(this.getSelectedDevice.deviceType == 2)
          {
            this.sendMessage(':FF.FF.00.00.01.00;')
          }
        })
        .catch((err) => {
          return console.error('err ' + err)
        })
        this.connection.on("ReceiveStatus", (message) => {
        console.log(message)
        })
        this.connection.on("ReceiveMessage", (message) => {
        console.log(message)
          if(message){
            if(message.includes("M2")){
               controlPanel.m2message(message)
            }
            let msg = message.split(';')
            msg.forEach(element => {
              if(element.length> 10){
                if(!(element.startsWith('[{') || element.endsWith('}]'))){
                  
                  controlPanel.onUpdateStatusNew(element+';')
                  controlPanel.onmessage(element+';')
                }
                this.mobileConfig.forEach((cjs, index1) => {
                  if(cjs.subPages && cjs.subPages.length > 0){
                    //console.log(cjs.subPages)
                    cjs.subPages.forEach(subpage => {
                      if(subpage.buttons && subpage.buttons.length > 0){
                        //console.log('button.ID')
                        
                          subpage.buttons.forEach(button => {
                            //console.log(button)
                            if(element.includes(`C${button.ID.substr(1)}`) || element.includes(button.ID)){
                              const find1 = this.opsData.filter(but => but.id == button.ID && element.endsWith("01"))
                              const find2 = this.opsData.filter(but => but.id == button.ID && element.endsWith("02"))
                              if(find1.length > 0){
                                //console.log(element)
                                find1[0].value = true
                              }
                              if(find2.length > 0){
                                find2[0].value = false
                              }
                            }
                        })
                      }
                    })
                  }
                  if(cjs.buttons && cjs.buttons.length > 0){
                    //console.log(cjs.buttons)
                    cjs.buttons.forEach(button => {
                      if(element.includes(`C${button.ID.substr(1)}`) || element.includes(button.ID)){
                        const find1 = this.opsData.filter(but => but.id == button.ID && element.endsWith("01"))
                        const find2 = this.opsData.filter(but => but.id == button.ID && element.endsWith("02"))
                        if(find1.length > 0){
                          //console.log(element)
                          find1[0].value = true
                        }
                        if(find2.length > 0){
                          find2[0].value = false
                        }
                      }
                      /*this.opsData.push({
                        id: button.ID,
                        value: 'fixed-button-mobile'
                      })*/
                    })
                  }
                  if(cjs.meteo && cjs.meteo.length > 0){
                    cjs.meteo.forEach((meteo, index2) => {
                      if(element.includes(meteo.ID)){
                        const find = this.meteoData.filter(met => met.id == meteo.ID && element.includes(met.descriptor))
                        if(find.length > 0){
                          if(element[7] == 'T'){
                            try {
                              find[0].value = parseFloat(element.substr(10, 3)) / 10;
                              if (element.substr(9, 1) == '1') find[0].value = -find[0].value;
                              find[0].value += ' °C'
                            }
                            catch { }
                          }
                          else if(element[7] == 'P'){
                            try {
                              find[0].value = parseInt(element.substr(9, 3)) + ' mm Hg'
                            }
                            catch {}
                          }
                          else if(element[7] == 'V'){
                            try {
                              let _vl = element.substr(10, 1) + element.substr(11, 2);
                              find[0].value = parseFloat(_vl) / 10 + ' %';
                            }
                            catch {}
                          } else{
                            find[0].value = `${parseFloat(element.substring(13)) / 10}`
                          }
                        }
                      }
                    })
                  }//:03.02.00.00.00

                  if(cjs.progressbars && cjs.progressbars.length > 0){
                    cjs.progressbars.forEach(pr => {
                      if(element.includes(pr.ID)){
                        const find = this.progressData.filter(met => met.id == pr.ID )
                        if(find.length > 0){
                          find[0].value = parseInt(element.substr(13, 2))
                        }
                      }
                    })
                  }
                })
              }
            });
          }
        });
        this.connection.onclose(e => {
        });

        this.connectionControl.on("Command", (message) => {
          console.log(message)
        });

        this.connectionControl.onclose(e => {
        });

        await this.startGetStates()
      } catch (e) {
        //console.log(e);
      }
    },
    async startGetStates(){
      try{
        if(this.serialNumber == null) return
        this.stateConnection = new HubConnectionBuilder()
          .withUrl(`${config}/state`, {
            accessTokenFactory: () => this.getAccessToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build();
        this.stateConnection.start()
          .then(() => {
            console.log('state Connected')
            this.stateConnection.invoke("StartGettingValuesDevice", {
              UserId: this.getUser.id,
              SerialNumber: this.serialNumber
            })
          })
          .catch((err) => {
            return console.error('err ' + err)
          })

        this.stateConnection.on("ReceiveMessage", (message) => {})
        this.stateConnection.on("ReceiveStatus", (message) => {
          if(message){
            controlPanel.onUpdateStatus(JSON.parse(message))
          }
        })
        this.stateConnection.onclose(e => {})
      }
      catch (e) {
        console.log(e)
      }
    }
  },
  mounted(){
  },
  async created(){
    await this.getDevices()
    if(this.query && this.query > 0){
      storageService.setSelectedDeviceId(this.query)
      this.selectDeviceById(this.query)
    }
    if(this.getSelectedDevice == null){
      this.$root.$emit('globalError', this.$t('SnackBar.ServerErrors.k3003'))
      this.$router.push('Account').catch(() => {})
      return
    }else{
      storageService.setSelectedDevice(this.getSelectedDeviceSerialNumber)
    }
    console.warn(this.getSelectedDevice)
    if((this.getSelectedDevice.deviceType == 4 || this.getSelectedDevice.deviceType == 1) && this.getSelectedDevice.subDeviceType != 2){
      return
    }
    console.log('2423')
    this.isMobile = this.windowWidth < 800 && this.getSelectedDevice && this.getSelectedDevice.deviceType != 4 && this.getSelectedDevice.serialNumber.length < 15
    console.log(this.isMobile)
    this.htmlFather = document.getElementsByTagName("html")[0]
    
    if((this.getAccessToken || storageService.getToken()) && (this.getSelectedDeviceSerialNumber || storageService.getSelectedDevice())){
      this.serialNumber = this.getSelectedDeviceSerialNumber? this.getSelectedDeviceSerialNumber : storageService.getSelectedDevice()
      let result = await this.getDeviceConfig(this.serialNumber)
      console.log(result)
      storageService.setSelectedDevice(this.serialNumber)
      storageService.setSelectedDeviceId(this.getSelectedDevice.id)
      this.FKJson = result.config
      this.meteoData = []
      this.opsData = []
      this.progressData = []
      this.videoData = []
      this.video = await this.getAllCamerasByDeviceId(this.getSelectedDevice.id)
      if(this.isMobile){
        await result.mobileConfig.pages.forEach(async (sd) => {
          if(sd.buttons && sd.buttons.length > 0){
            sd.buttons.forEach(button => {
              if(button.type == 'fix'){
                this.opsData.push({
                  id: button.ID,
                  value: false
                })
              }
            })
          }
          if(sd.meteo && sd.meteo.length > 0){
            sd.meteo.forEach(meteo => {
              console.log(meteo)
              let descriptor = ''
              if(meteo.type == 'T') descriptor = '.T.'
              else if(meteo.type == 'p') descriptor = '.V.'
              else if(meteo.type == 'P') descriptor = '.P.'
              this.meteoData.push({
                id: meteo.ID,
                descriptor: descriptor,
                value: ''
              })
            })
          }
          if(sd.progressbars && sd.progressbars.length > 0){
            sd.progressbars.forEach(pr => {
              this.progressData.push({
                id: pr.ID,
                value: ''
              })
            })
          }
          if(sd.videos && sd.videos.length > 0){
            await sd.videos.forEach(async (pr, index) => {
              const findVideo = this.video.cameras.filter(vid => vid.panelElementId.substr(0, 5) == pr.ID)
              if(findVideo.length > 0){
                this.videoData.push({
                  id: pr.ID,
                  value: JSON.parse(findVideo[0].rtcp)
                })
                this.progressBarData = 15
              }
              /*this.progressData.push({
                id: pr.ID,
                value: ''
              })*/
            })
          }else{
              this.progressBarData = 0
              this.isShowProgressBar = false
          }
        })
      }
      console.log(this.opsData)
      this.mobileConfigJson = result.mobileConfig
      if(this.FKJson != null){
        await this.isCreated()
        console.log(this.getSelectedDevice)
        if((this.windowWidth > 800 || this.getSelectedDevice.serialNumber.length > 13) && this.getSelectedDevice.subDeviceType != 2){
          console.warn(this.getSelectedDevice.serialNumber)
          await controlPanel.start(this.FKJson, this.sendMessage, this.panelReady, `${config}/Image/ops/`, `${config}/Image/${result.direction}/`, result.configName, this.video.cameras ? this.video.cameras: [], this.updateProgressBar, this.$t('ControlPanel'), this.isMobileDisplay, this.getSelectedDevice.serialNumber.length == 15, this.getSelectedDevice.deviceType)
          console.log(`${config}/Image/${result.direction}/`)
        }else{
          this.generateMobilePanelConfig(this.mobileConfigJson)
        }
        
        return
      }
    }
  },
  beforeDestroy(){
    if(this.htmlFather)
    this.htmlFather.className = ""
    controlPanel.close()
    if(this.connection) this.connection.stop()
    if(this.connectionControl) this.connectionControl.stop()
    if(this.stateConnection) this.stateConnection.stop()
    this.jsmpegPlayer.forEach(video => {
      video.stop()
    })
  }
});
</script>
<style scoped>
.MainContainerBar{
  margin: 0 auto !important;
}
.border-wii{
  border-width: 3px;
}
body{
  overflow: visible;
}
.sub-elements{
  background-color: brown;
}
.mobile-video canvas {
  line-height: 0.1 !important;
}
.mobile-video{
  margin-top: 15px;
}
.meteo-button{
  border: none;
}
.border-wi{
  border-width: 3.5px;
}
.border-wi-mini{
  border-width: 3px;
}
.v-select-list{
  min-width: 100%;
}
.v-menu__content{
  min-width: 100% !important;
}
.mobile-panel{
  border: 1px solid white;
  height: 90%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  border-radius: 5px;
}
.fix-size-canvas{
  width: 50% !important;
  height: 50% !important;
}
.fixed-button-mobile{
  background-color: #727272 !important;
}
  .scroll{
    overflow-y: scroll;
  }
  @import './Site.css';
  .btn-fix{
    color: white !important;
    margin-bottom: 50px;
    margin-top: -40px;
  }
  #MainContainer{
    margin: 0 auto;
  }
  .mg-center{
    text-align: center;
  }
</style>