<template>
  <div>
    <div :style="`height:100px;`" @click="emitGlobalClickEvent">
      <div v-show="showAccount" class="login-text" :class="{'title': isMobileDisplay}" align="center">
        {{$t('Account.Account')}}
      </div>
      <div class="mt-2 container-fix" v-show="showAccount">
        <v-row class="d-flex align-content-center justify-center">
          <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <cube @controlButtonClick="controlButtonClick"/>
          </v-col>
          <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 offset-xl-1 offset-lg-1">
            <v-row no-gutters class="justify-start">
              <v-col class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <v-btn
                  class="mt-3"
                  :class="{'caption dv-btn-tablet': isMobileDisplay, 'dv-btn': !isMobileDisplay}" 
                  dark
                  @click="getUserDevices()"
                  :loading="userDevicesLoading"
                >
                  {{$t('Account.Objects')}}
                </v-btn>
              </v-col>
            </v-row>
            <transition name="fade">
              <div
                v-if="isDrop"
              >
                <v-row no-gutters v-for="device in getLoadDevices" :key="device.id">
                  <v-col class="mt-3 col-xl-5 col-lg-5 col-md-6 col-sm-12">
                    <div
                      dark
                      class="text-center v-btn"
                      :class="{'caption dv-btn-tablet': isMobileDisplay, 'dv-btn': !isMobileDisplay}" 
                      @click="loadDeviceData(device)"
                    >
                      <span v-if="device.name == null">
                        {{device.serialNumber}}
                      </span>
                      <span v-else>
                        {{device.name}}
                      </span>
                    </div>
                  </v-col>
                  <v-col class="col-12 mt-3" v-if="activeId == device.id">
                    <v-card dark >
                      <v-card-text>
                        <v-row class="text-body-1">
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.MasterUserName')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.ownerName}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.ObjectTitle')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.name}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.PlaceAddress')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.locationName}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.Latitude">{{$t('Account.ObjectInfo.GNSS')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.Latitude">
                            {{$t('Account.ObjectInfo.Lat')}}: {{device.pointLastData.Latitude}} {{$t('Account.ObjectInfo.Lon')}}: {{device.pointLastData.Longitude}} {{$t('Account.ObjectInfo.Alt')}}: {{device.pointLastData.Altitude}}
                          </v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.PhoneNumber">{{$t('Account.ObjectInfo.DevicePhoneNumber')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.PhoneNumber">{{device.pointLastData.PhoneNumber}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.DeviceType')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.typeName}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.controller">{{$t('Account.ObjectInfo.TypeOfController')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.controller">{{device.controller.name}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.DateOfCommissioning')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{getFormatDate(new Date(device.dateManufacture))}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.DateOfLastConnection')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{getFormatDate(new Date(device.dateConnection))}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.utc">{{$t('Account.ObjectInfo.DateOfUtc')}}:</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.utc">{{getUtcDate(device.utc)}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.serialNumber.length == 8 ? `${$t('Account.ObjectInfo.SerialNumber')}:` : 'POINT IMEI:'}}</v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.serialNumber}}</v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('Account.ObjectInfo.Connection')}}: </v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center">
                            {{device.connection ? $t('Account.ObjectInfo.Connected') : `${$t('Account.ObjectInfo.Disconnected')} (${getFormatDate(new Date(device.dateDisconnection))})`}}
                            <span v-if="device.connection  && device.pointLastData && device.pointLastData.SignalPower"> {{$t('Account.ObjectInfo.SignalPower')}}: {{device.pointLastData.SignalPower}}</span>
                            <span v-if="device.headStatus && (device.headStatus.code == 0 || device.headStatus.code == 1)">
                              {{device.headStatus.description}}
                            </span>
                          </v-col>
                          <v-col class="fix-padding col-4" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="deviceInstantData.findIndex(d => d.Id == device.id) >= 0">
                            <v-badge
                              dot
                              :color="getInstantDataById.Color"
                            >INSTANT DATA: </v-badge>
                          </v-col>
                          <v-col class="fix-padding col-8" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="deviceInstantData.findIndex(d => d.Id == device.id) >= 0">{{getInstantDataById.Message.substr(0, 40)}}</v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </transition>
          </v-col>
        </v-row>
      </div>
      <YaMap></YaMap>
      <Copyright></Copyright>
      <v-snackbar
        v-model="snackbar.active"
        :timeout="snackbar.timeout"
      >
        <div class="text-center">
          {{ snackbar.text }}
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import dateFormat, { masks } from "dateformat";
import smoothHeight from 'vue-smooth-height'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
import Cube from "./Cube/Cube.vue"
import YaMap from "./Maps/YaMap.vue"
import Copyright from "./Copyright.vue"
export default Vue.extend({
  mixins:[smoothHeight],
  name: "Account",
  components: {
    Copyright,
    cube: Cube,
    YaMap: YaMap
  },
  data: () => ({
    deviceInstantData:[],
    connection: null,
    caller: null,
    showAccount: true,
    open: true,
    bigMap: false,
    userDevicesLoading: false,
    isDrop: false,
    activeId: 0,
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    },
    devicesCoords: null
  }),
  computed: {
    ...mapGetters(['getAccessToken','getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay']),
    getInstantDataById(){
      return this.deviceInstantData.filter(d => d.Id === this.getSelectedDevice.id)[0]
    }
  },
  methods: {
    ...mapActions(['getDevices', 'getCoordsDevices', 'devicesLoadOnlineStatus','getM2DoutButton']),
    ...mapMutations(['selectDevice']),
    getDate(){
      this.deviceInstantData.forEach(item => {
        if(item.DateTime + 10000 < Date.now()){
          item.Color = 'red'
        }
      })
    },
    getFormatDate(date){
      return dateFormat(date, "dd.mm.yyyy HH:MM:ss")
    },
    getUtcDate(gmt){
      return dateFormat(new Date().setHours(new Date().getHours() + gmt), "UTC:dd.mm.yyyy HH:MM:ss")
    },
    emitGlobalClickEvent(){
      //this.$root.$emit('closeBigMapGlobal')
    },
    controlButtonClick(){
      if(this.getSelectedDevice && this.getSelectedDevice.connection){
        this.$router.push('control').catch(() => {})
      }
    },
    async loadDeviceData(device){
      if(this.activeId != device.id){
        this.activeId = device.id
        this.selectDevice(device.serialNumber)
        try{
          //await this.getFullInfoDevice(this.activeId)
        }catch(e){
          console.log(e)
          if(!e.description){
            this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
          }else{
            this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
          }
          this.snackbar.active = true
        }
      }else{
        this.activeId = 0
        this.selectDevice(null)
      }
    },
    async getUserDevices(){
      await this.loadOnlineStatus()
      this.userDevicesLoading = true
      this.isDrop = !this.isDrop
      this.activeId = 0
      this.selectDevice(null)
      //if(!this.getLoadDevices){
        try{
          let result = await this.getDevices()
        }
        catch(e){
          console.log(e)
          if(!e.description){
            this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
          }else{
            this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
          }
          this.snackbar.active = true
        }
      //}
      this.userDevicesLoading = false
    },
    async loadOnlineStatus(){
      let devicesConnectedStatus = await this.devicesLoadOnlineStatus()
    },
  },
  /*async created(){
  },*/
  async mounted(){
    this.$root.$on('InstantDataUpdateDeviceUpdate', (message) => {
      //this.updateMarker(message)
      let index = this.deviceInstantData.findIndex(d => d.Id == message.instantDataDto.Id)
      if(index >= 0){
        this.deviceInstantData[index].Message = message.instantDataDto.Message
        this.deviceInstantData[index].DateTime = new Date(message.instantDataDto.DateTime).getTime()
        this.deviceInstantData[index].Color = 'green'
      }else{
        this.deviceInstantData.push({
          Color: 'green',
          Message: message.instantDataDto.Message, 
          DateTime: new Date(message.instantDataDto.DateTime).getTime(),
          Id: message.instantDataDto.Id
        })
      }
    })
    this.$root.$on('closeAccountGlobal', () => {
      this.showAccount = false
    })
    this.$root.$on('closeBigMapGlobal', () => {
      this.showAccount = true
    })
    await this.getDevices()
    if(this.getSelectedDevice){
      this.isDrop = true
      this.activeId = this.getSelectedDevice.id
    }
    this.$smoothElement({
      el: this.$refs.container,
    })
    console.log("ConnectToAllHubs")
    this.$root.$emit("ConnectToAllHubs")
    
    setInterval(() => {
      this.getDate()
    }, 1000)
  },
  beforeDestroy(){
    //clearInterval(this.caller)
  }
});
</script>
<style scoped lang="sass">
.login-text
  padding-top: 3vh
.v-card 
  background-color: rgba(0,0,0,0) !important
  border: 1px solid white !important
.fix-padding
  padding: 2px
.fade-enter-active, .fade-leave-active
  transition: opacity .10s
.fade-enter, .fade-leave-to
  opacity: 0
</style>
