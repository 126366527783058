import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()
import axiosBase from 'axios'
import states from '../States/index'
export default {
  actions: {
    async GetOpenSensorsData(context:any, data:any) {
      return await axiosBase.post(`${process.env.VUE_APP_ui_api_url}/api/Head/GetSensorsData/`, data)
        .then((response:any) => {
          console.log(response)
          if(response.data.result.code == 0){
            return response.data
          }else{
            throw response.data.result
          }
        })
        .catch((err:any) => {
          console.log(err)
          throw err
        })
    },
    async GetSensorsData(context:any, data:any) {
      return await axios.post(`/api/Head/GetSensorsData/`, data)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
  },
  state: {
    chartOneLastValue: [],
    markers: null,
    chartInfo: [],
    subscribeCharts: [],
    listToSee: []
  },
  mutations: {
    clearListToSee(state:any, data:any){
      state.subscribeCharts = []
    },
    unsubscribeCharts(state:any){
      state.subscribeCharts = []
    },
    subscribeChart(state:any, data:any){
      console.log('subscribeChart')
      console.log(data)
      const tryGetIndexChart = state.subscribeCharts.findIndex((chart:any) => chart.Address == data.Address && chart.Type == data.Type && data.deviceId == chart.deviceId)
      if(tryGetIndexChart >= 0){
        return
      }
      state.subscribeCharts.push(data)
      state.listToSee.push(data)
    },
    setDeviceStateInfo(state:any, data:any){
      const chartInfoIndex = state.chartInfo.findIndex((chart:any) => chart.id == data.id)
      if(chartInfoIndex > 0) {
        state.chartInfo[chartInfoIndex].sensorTypes = data.sensorTypes
        state.chartInfo[chartInfoIndex].openSensorTypes = data.openSensorTypes
      }
    },
    setDeviceStatesInfo(state:any, data:any){
      state.chartInfo = []
      data.forEach((stateData: any) => {
        state.chartInfo.push({
          sensorTypes: stateData.sensorTypes,
          openSensorTypes: stateData.openSensorTypes,
          id: stateData.Id
        })
      })
    },
    updateChartOne(state:any, data:any){
      states.state.filterState
      state.chartOneLastValue = data
    },
    buildMarkersVuex(state:any, data:any){
      state.markers = data
    },
    updateMarkerVuex(state:any, data:any){
      state.markers.forEach((marker:any) => {
        if(marker.id == data.id){
          marker = data
        }
      })
    }
  },
  getters:{
    getIsSubscribeChartById: (state:any) => (data:any) => {
      return state.subscribeCharts.findIndex((chart:any) => chart.PanelElementId == data) >= 0
    },
    getListToSee(state:any){
      return state.listToSee
    },
    getSubscribeCharts(state:any){
      return state.subscribeCharts
    },
		getChartOneLastValue(state:any) {
      return state.chartOneLastValue
		},
    getChartInfo(state:any){
      return state.chartInfo
    },
    getChartInfoById: (state:any) => (data:any) => {
      const chartInfoIndex = state.chartInfo.findIndex((chart:any) => chart.id == data)
      if(chartInfoIndex >= 0) {
        return state.chartInfo[chartInfoIndex]
      }
      return null
    }
  }
}