<template>
  <div>
    <div ref="container">
      <div>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Architecto consequatur dignissimos quidem porro repudiandae repellat earum voluptatem, ea atque cum. Alias deleniti excepturi, quisquam labore maiores ex magnam non architecto dignissimos? Repellat facere quasi vitae voluptatibus voluptates provident recusandae, odit impedit expedita iusto fugit incidunt qui sed voluptas enim! Iste sit eligendi distinctio deserunt expedita pariatur esse beatae saepe, praesentium deleniti nisi, aliquid quibusdam reprehenderit delectus ea voluptatum sint nam asperiores reiciendis dolorum minima. Eveniet doloribus repellendus neque facilis dignissimos hic, alias suscipit corporis veniam laborum dolore, dolorum sequi molestias! Commodi explicabo eum porro recusandae consequuntur asperiores doloremque corporis quod?
      </div>
    </div>
  </div>
</template>

<script>
import smoothHeight from 'vue-smooth-height';

export default {
  mixins:[smoothHeight],
  mounted(){
    this.$smoothElement({
      el: this.$refs.container,
    })
  },
}
</script>