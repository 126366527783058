<template>
  <div>
    <div @click="emitGlobalClickEvent">
      <div class="login-text" align="center" v-show="showAccount" :class="{'title':isMobileDisplay}">
        {{$t('Charts.Chart')}} {{filterState}}
      </div>
      <v-expand-transition>
        <div class="container-fix mt-2" v-show="showAccount">
          <v-row class="d-flex align-content-center justify-center" v-show="showAccount">
            <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <cube @controlButtonClick="controlButtonClick"/>
            </v-col>
            <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 offset-xl-1 offset-lg-1">
              <div class="home">
                <Ch @openBigMap="chClick" @openBigMapStart="openBigMapStart">
                </Ch>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <YaMap :key="updateComponent" @mountMap="isMountMap = true"></YaMap>
      <Copyright></Copyright>
      <v-snackbar
        v-model="snackbar.active"
        :timeout="snackbar.timeout"
      >
        <div class="text-center">
          {{ snackbar.text }}
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import smoothHeight from 'vue-smooth-height'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
import Cube from "../Cube/Cube.vue"
import Ch from "./ch.vue"
import YaMap from "./YaMap.vue"
import Copyright from "../Copyright.vue"
export default Vue.extend({
  mixins:[smoothHeight],
  name: "Charts",
  components: {
    Copyright:Copyright,
    cube: Cube,
    Ch: Ch,
    YaMap: YaMap
  },
  props: {
    query: { type: String }
  },
  data: () => ({
    isMountMap: false,
    inter: null,
    showAccount: false,
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    },
    updateComponent: 0
  }),
  computed: {
    ...mapGetters(['filterState', 'getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay']),
  },
  methods: {
    ...mapActions(['getDevices', 'getCoordsDevices', 'devicesLoadOnlineStatus']),
    ...mapMutations(['selectDevice', 'switchSimpleMode', 'setSimpleFilter']),
    emitGlobalClickEvent(){
      //this.$root.$emit('closeBigMapGlobal')
    },
    switchSensorTypeEventAction(){

    },
    clickAddDeviceMap(data){
      console.log(data)
    },
    openBigMapStart(){
      /*this.inter = setInterval(() => {
        console.error('inter')
        if(this.isMountMap){
          this.$root.$emit('openBigMapGlobal')
          clearInterval(this.inter)
        }
      },250)*/
    },
    chClick(){
      this.$root.$emit('openBigMapGlobal')
    }, 
    controlButtonClick(){
      if(this.getSelectedDevice && this.getSelectedDevice.connection){
        this.$router.push('control').catch(() => {})
      }
    },
  },
  async created(){
    console.log(this.query)
    this.switchSimpleMode(true)
    console.log(this.query)
    this.setSimpleFilter(parseInt(this.query))

    this.$root.$on('closeAccountGlobal', () => {
      this.showAccount = false
    })
    this.$root.$on('updateYaMapFilter', () => {
      this.updateComponent ++
    })
    this.$root.$on('closeBigMapGlobal', () => {
      this.showAccount = true
      window.scrollTo(0,0)
    })
    this.$root.$on('switchSensorTypeEventAction', (typeCode) => {
      console.log(typeCode)
    })
    
    
    return
    await this.getDevices()
  },
  mounted(){
  },
  destroyed(){
  }
});
</script>
<styles scoped lang="sass">
.login-text
  padding-top: 3vh
.v-card 
  background-color: rgba(0,0,0,0) !important
  border: 1px solid white !important
.fix-padding
  padding: 2px
</styles>