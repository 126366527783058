import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()

export default {
  actions: {
    async updateCamera(context:any, data:any) {
      return await axios.post(`/api/Camera/Update`, data)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async createCamera(context:any, data:any) {
      return await axios.post(`/api/Camera/Create`, data)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getAllCamerasByDeviceId(context:any, data:any) {
      return await axios.get(`/api/Camera/getAllCamerasByDeviceId/${data}`)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          context.commit('updateCameras', response.data.cameras)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getAllCameras(context:any, data:any) {
      return await axios.get(`/api/Camera/GetAll/`)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          context.commit('updateCameras', response.data.cameras)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getCameraByPanelId(context:any, id:any) {
      return await axios.get(`/api/Camera/GetByPanelId/${id}`)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async deleteCameraById(context:any, id:any) {
      return await axios.delete(`/api/Camera/Delete/${id}`)
      .then((response:any) => { 
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
  },
  state: {
    cameras:[]
  },
  mutations: {
    updateCameras(state:any, data:any){
      state.cameras = data
    }
  },
  getters:{
		cameras(state:any) {
      return state.cameras
		}
  }
}