<template>
  <div class="copyright d-flex flex-column">
    <div class="awdh" v-show="showCopyright">
      <div class="brand-name cursor-pointer mt-5">
        DIVISION
      </div>
      <div class="mt-8 white-color">
        <v-row  justify="center" align="center">
          <v-col class="col-xl-2 col-lg-3 col-md-3 col-sm-4" cols="8">
            <v-row
              align="center"
              justify="space-around"
            >
              <v-btn dark v-for="(social, index) in config.SOCIAL" :key="index" :href="social.LINK" icon target="_blank">
               <v-icon>
                  {{social.ICON}}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="mt-8 white-color">
        © 2022 {{$t('Footer.Copyright')}}
      </div>
    </div>
  </div>
</template>

<script>
const config = require('../../config.js')
import Vue from "vue"
export default Vue.extend({
  name: "Copyright",
  data: () => ({
    config: config,
    showCopyright: true
  }),
  mounted() {
    this.$root.$on('closeAccountGlobal', () => {
      this.showCopyright = false
    })
    this.$root.$on('closeBigMapGlobal', () => {
      this.showCopyright = true
    })
    this.$root.$on('showHiddenChart', () => {
      this.showCopyright = true
    })
  },
  beforeDestroy(){
    this.$root.$off('closeAccountGlobal')
    this.$root.$off('closeBigMapGlobal')
    this.$root.$off('showHiddenChart')
  }
});
</script>

<style lang="sass">
.white-color
  color: #fff
.copyright
  padding: 50px 30px

.brand-name
  display: inline-block
  width: 123px
  font-size: 28px
  font-weight: 400
  line-height: 1.5
  font-family: "Montserrat", Sans-serif, serif
  color: #ffffff
  border-top: 1px solid #ffffff
  border-bottom: 1px solid #ffffff
.awdh
  text-align: center
</style>