const { config } = require('chai');
const webgl = require('webgl-utils')
const $ = require('../../Libs/jquery-3.4.1.min.js')
//const JSMpeg = require('../../Libs/jsmpeg.min.js');
//import StreamCintroller from './streamController'
let streamService = null
const {StreamService} = require('./stream/StreamService').default
let startedPanel = false
let imageOpsUrl;
let imageOpsBGUrl;
let sendMessage;
let panelReady;
let configName;
let deviceType;
let serialNumber = null;
let jsmpegPlayer = [];
let canvasses = []
let equivalents_in = []
let equivalents_out = []
let isBig = true
let cameras;
let controlPanelLangaugeData = null
let updateProgressBar = null
let miniPanel = false
let isMtwo = false
module.exports = {
  close(){
    if(streamService){
        streamService.destroy()
    }
  },
  start(FKJSON, sendMessageF, panelReadyF, url, bgUrl, _configName, cm, progressBar, langaugeData, mini, ismtwo, deviceEnum) {
    startedPanel = true
    isMtwo = ismtwo
    miniPanel = mini
    updateProgressBar = progressBar
    controlPanelLangaugeData = langaugeData
    console.log(ismtwo)
    updateProgressBar(-2)
      console.log('control panel start')
      cameras = cm
      console.log(cameras)
      console.log(serialNumber)
      imageOpsUrl = url
      imageOpsBGUrl = bgUrl
      console.log(imageOpsBGUrl)
      configName = _configName
    sendMessage = sendMessageF
    panelReady = panelReadyF
    deviceType=deviceEnum
    processObjects();
     if(FKJSON)
      {
        serialNumber = FKJSON.serial_number
        if(serialNumber && serialNumber.length >= 15)
        {
            let cont = document.getElementById("MainContainer")
            cont.style.width = '300px'
            cont.classList.add('mtwo')
            console.log(cont)
            let contBar = document.getElementById("MainContainerBar")
            if(contBar)
            {
                contBar.style.width = '300px'
                contBar.classList.add('mtwo')
                console.log(cont)
            }
            
        }
      }
    if(FKJSON.pages){
        getFileConfig(FKJSON)
    }else{
        getFileConfig(JSON.parse(FKJSON))
    }
    
    //initMaps();
    //getObjects();

    /*setInterval(function () {
        let today = new Date();
        let hh = today.getHours().toString().padStart(2, '0');
        let mm = today.getMinutes().toString().padStart(2, '0');
        let ss = today.getSeconds().toString().padStart(2, '0');

        document.getElementById('StatusTime').textContent = hh + ':' + mm + ':' + ss;
    }, 1000);*/
    const statusLine = document.getElementById('StatusHum');
    console.log(statusLine)
  },
  controlPanelOfOmegaIsOpen(){
    console.log("[open] Соединение установлено");
    SendServer("FF.FF.00.00.01.00");
    showStateConnect('on');
  },
  onUpdateStatus(data){
    UpdateEquivalentInputs(data)
  },
  onUpdateStatusNew(data){
    UpdateEquivalentInputsNew(data)
  },
  onmessage(event){
    UpdateStatePanel(event);
  },
  m2message(event)
  {
    M2UpdatePanel(event);
  },
  onclose(event)
  {
    if (event.wasClean) {
      console.log(`[close] Соединение закрыто чисто, код = ${event.code} причина = ${event.reason}`);
    }
    else {
      console.log('[close] Соединение прервано');
    }
    if(document.getElementById('StatusState'))
    document.getElementById('StatusState').textContent = 'offline';
    // запустить соединение вновь
    //panelReady();
  },
  onerror(error){
    console.log(`[error] ${error.message}`);
    showStateConnect('off');
    // запустить соединение вновь
    panelReady();
  }
}
function MTwoComponents()
{
    let button = document.getElementById("01.03.00.00")
        button.innerText='PUSH'
    let indicator = document.getElementById("01.04")
        indicator.classList.add("indicator-off")
        indicator.innerText='SIGNAL'//off
}

let currentData = new Array();

let color_white = "#fff";
let color_color = "#0071e3";
let currentPage = '';

let receivedData = '';

let socket = null;
let password = '0000';

let objects = [];

function processObjects() {
    objects = [];
    $('#addObject').addClass('d-none');
}

function setPlaceMark(data) {
    /*if (mapReady == true) {
        // Создание метки 
        let myPlacemark = new ymaps.Placemark([data.latitude, data.longitude], {
            hintContent: 'Мой Division'
        }, {
            iconImageHref: '/images/red_placemark.png', // картинка иконки
            iconImageSize: [40, 40], // размеры картинки
            iconImageOffset: [0, 0] // смещение картинки
        });

        myMap.geoObjects.add(myPlacemark);
    }
    else {
        console.log('выполнить через 1 сек');
        setTimeout(setPlaceMark, 1000, data);
    }*/
}

/*
function objectClicked(sender) {

    for (let q in objects) {
        if (sender.value == objects[q].controllerId) {
            object = objects[q];

            // вывести соответствующую панель управления
            getFileConfig();
        }
    }
}*/

$(window).resize(function () {
    if(!startedPanel){
        return
    }
    if(currentData)
    if (currentData.length == 0)
        getFileConfig();
    else {

        if ($(window).width() <= 350) {
            console.log("Вы используете мобильное устройство 2");
            GoToMainMenu();
        }
        else {
            console.log("Вы используете ПК 2");
            StartControlPanel();
        }
    }
});


function showStateConnect(state) {

    let _constatus = document.getElementById('connectionStatus');
    // document.getElementById('StatusState').textContent = 'online';
    if (state == 'on') {
      //_constatus.src = '@/images/online.png';
    }
    else {
      //_constatus.src = '/images/offline.png';
    }
}
function M2UpdatePanel(data) 
{
    if (data.length > 0)
    {
        let button = document.getElementById('01.03.00.00')
        button.innerText="PUSH"
        const indicator = document.getElementById('01.04')
        indicator.classList.remove("indicator-off")
        indicator.innerText='OUT SIGNAL'//on
        indicator.classList.add("indicator");
        setTimeout(()=>
        {
            indicator.innerText='SIGNAL'//off
            indicator.classList.remove("indicator");
            indicator.classList.add("indicator-off")
        },2000) 
        indicator.classList.remove("indicator-off")
        console.log("Performed!")
        
    }
}

function UpdateEquivalentInputsNew(data){
    equivalents_out.forEach(equivalent => {
      const splinted = equivalent.split(".")
      if(splinted.length === 4){
        const address = parseInt(splinted[2], 16)
        const inputNumber = parseInt(splinted[3], 16)
        const splintedData = data.split(".")
        if(splintedData[0].substr(1) == splinted[0] && splintedData[1] == splinted[1]){
          const element = document.getElementById(equivalent)
          if(splintedData[4] == '01;'){
            element.setAttribute("data-state", "01");
            element.className = "status "
          }
          else if(splintedData[4] == '02;'){
            element.setAttribute("data-state", "02");
            element.className = "status inactivae"
          }
        }
      }
    })
}
function UpdateEquivalentInputs(data){
  equivalents_out.forEach(equivalent => {
    const splinted = equivalent.split(".")
    if(splinted.length === 4){
      const address = parseInt(splinted[2], 16)
      const inputNumber = parseInt(splinted[3], 16)
      if(address == 0 && inputNumber == 0){
        return
      }
      let findSubDevice = Object.values(data).filter(subDevice => subDevice.Rs485Address === address)
      if(findSubDevice.length > 0 && findSubDevice[0].OStatus.length > inputNumber){
        const element = document.getElementById(equivalent)
        if(findSubDevice[0].OStatus[inputNumber] === 1 || findSubDevice[0].OStatus[inputNumber] === 2){
          element.setAttribute("data-state", "01");
          element.className = "status inactivae"
        }else if(findSubDevice[0].OStatus[inputNumber] === 0){
          element.setAttribute("data-state", "02");
          element.className = "status"
        }
      }
    }
  })
}

function UpdateStatePanel(data) {
    if (data.length > 0) {
        if (receivedData.length > 0) {
            data = receivedData + data;
            receivedData = '';
        }
        for (let i in data) {
            if (data.substr(i, 1) == ':') {

                let packet = ''; // 00.00.00.00.00.xx

                if (i + 15 < data.length)
                    packet = data.substr(i + 1, 14);
                else {
                    receivedData = data.substr(i);
                    break;
                }

                //console.log(packet);
                let number = packet.substr(0, 11);

                // состояние входа (шлейфа)
                if (number.substr(0, 2) == '1w') {

                    // if (!currentData) continue;
                    // 1w.FF.03.03.01
                    // Input: 151.03 - запись в БД Property
                    // FF- адрес, 03 - тип, 03 - номер входа, 01 - состояние
                    // 01- замкнут, 02 - разомкнут

                    let address = '0';
                    try {
                        address = parseInt(number.substr(3, 2), 16).toString().padStart(3, '0');
                    }
                    catch { continue; }

                    let input = "0";
                    try {
                        input = (parseInt(number.substr(9, 2), 16) + 1).toString().padStart(2, '0');
                    }
                    catch { continue; }

                    let strEnter = address + "." + input;

                    if (address == '000' && number.substr(6, 2) == '32') // тип ОПС
                        strEnter = address + "*" + input;

                    let state = packet.substr(13, 1);
                    if (state == '2') state = '0';

                    for (let page in currentData.pagesOPS)
                        for (let al in currentData.pagesOPS[page].alarmloop) {

                            if (currentData.pagesOPS[page].alarmloop[al].enter == strEnter) {
                                currentData.pagesOPS[page].alarmloop[al].current = state;

                                UpdateCanvas(currentPage);

                                page = currentData.pages.length;
                                break;
                            }
                        }

                    continue;
                }


                if (number.substr(6, 1) == 'T' || number.substr(6, 1) == 'P' || number.substr(6, 1) == 'V')
                    number = number.substr(0, 5);

                if (number == '01.01') {
                    if (packet.substr(6, 1) == 'T') {
                        try {
                            value = parseFloat(packet.substr(9, 3)) / 10;
                            //console.log(value)        
                        }
                        catch { continue; }
                        if (packet.substr(8, 1) == '1') value = -value;

                        if(document.getElementById('StatusTemp'))
                        document.getElementById('StatusTemp').textContent = 'T = ' + value + ` ${controlPanelLangaugeData.SensorUnits.Temperature}`;
                        continue;
                    }
                    if (packet.substr(6, 1) == 'P') {
                        try {
                            value = parseInt(packet.substr(8, 3));
                        }
                        catch { continue; }

                        if(document.getElementById('StatusPres'))
                        document.getElementById('StatusPres').textContent = 'P = ' + value + ` ${controlPanelLangaugeData.SensorUnits.Pressure}`;
                        continue;
                    }
                    if (packet.substr(6, 1) == 'V') {
                        try {
                            value = parseInt(packet.substr(8, 3));
                        }
                        catch { continue; }

                        if(document.getElementById('StatusHum'))
                        document.getElementById('StatusHum').textContent = 'H = ' + value + ` ${controlPanelLangaugeData.SensorUnits.Humidity}`;
                        continue;
                    }

                    continue;
                }

                let someElement = document.getElementById(number);
                if (!someElement) {
                    number = number.substr(0, 5);
                    someElement = document.getElementById(number);
                }

                if (someElement) {
                    switch (someElement.dataset.type) {

                        case 'button':

                            if (someElement.dataset.property == 'fix') {

                                if (packet.substr(12, 2) == '01') {

                                    someElement.dataset.state = '01';
                                    let current = someElement.className;

                                    if (current.includes('mobile')) {

                                        if (current.includes('pressedMobile')) continue;
                                        someElement.className += " pressedMobile";

                                    }
                                    else {

                                        if (current.includes('pressed')) continue;
                                        someElement.className += " pressed";
                                    }
                                    continue;
                                }

                                if (packet.substr(12, 2) == '02') {

                                    someElement.dataset.state = '02';
                                    let current = someElement.className;

                                    if (current.includes('mobile')) {

                                        if (current.includes('pressedMobile'))
                                            someElement.className = someElement.className.replace(" pressedMobile", "");;

                                    }
                                    else {
                                        if (current.includes('pressed'))
                                            someElement.className = someElement.className.replace(" pressed", "");
                                    }
                                    continue;
                                }
                            }

                            break;

                        case 'razdel':
                            if (packet.substr(12, 2) == '01') {

                                someElement.dataset.state = '01';
                                let current = someElement.className;

                                if (current.includes('pressedOPS')) continue;
                                someElement.className += " pressedOPS";

                                UpdateCanvas(currentPage);

                                continue;
                            }

                            if (packet.substr(12, 2) == '02') {

                                someElement.dataset.state = '02';
                                let current = someElement.className;

                                if (current.includes('pressedOPS'))
                                    someElement.className = someElement.className.replace(" pressedOPS", "");

                                UpdateCanvas(currentPage);

                                continue;
                            }

                            break;

                        case 'slider':
                            let val = 0;
                            try {
                                //console.log(packet)
                                val = parseInt(packet.substr(12, 2));
                            }
                            catch { continue; }

                            someElement.value = val;

                            break;
                        case 'input':
                            let value = 0;
                            try {
                                value = parseInt(packet.substr(12, 2));
                            }
                            catch { continue; }

                            someElement.value = value;

                            break;

                        case 'status':
                            /*console.log('status')
                            console.log(someElement.dataset.property)
                            console.log(someElement.dataset.property == 'equivalent')*/
                            if (someElement.dataset.property == 'system') {
                                if (packet.substr(12, 2) == '01') {

                                    someElement.dataset.state = '01';
                                    let current = someElement.className;

                                    if (current.includes('mobile')) {

                                        if (current.includes('pressedMobile')) continue;
                                        someElement.className += " pressedMobile";
                                    }
                                    else {

                                        if (current.includes('pressed')) continue;
                                        someElement.className += " pressed";
                                    }

                                    continue;
                                }

                                if (packet.substr(12, 2) == '02') {

                                    someElement.dataset.state = '02';
                                    let current = someElement.className;

                                    if (current.includes('mobile')) {

                                        if (current.includes('pressedMobile'))
                                            someElement.className = someElement.className.replace(" pressedMobile", "");;
                                    }
                                    else {
                                        if (current.includes('pressed'))
                                            someElement.className = someElement.className.replace(" pressed", "");
                                    }
                                }
                            }
                            else if (someElement.dataset.property == 'equivalent') {
                              if (packet.substr(12, 2) == '01') {
                                someElement.dataset.state = '01';
                                someElement.className = "status"
                              }
                              if (packet.substr(12, 2) == '02') {
                                someElement.dataset.state = '02';
                                someElement.className = "status inactivae"
                              }
                            }
                            break;

                        case 'param':

                            value = 0;
                            let typeSensor = packet.substr(6, 1);
                            if (someElement.dataset.property == 'T' && typeSensor == 'T') {

                                // 55.45.T.0248.0
                                try {
                                    value = parseFloat(packet.substr(9, 3)) / 10;
                                }
                                catch { continue; }

                                if (packet.substr(8, 1) == '1') value = -value;

                                someElement.textContent = someElement.dataset.name + ' = ' + value;

                                continue;
                            }

                            if (someElement.dataset.property == 'P' && typeSensor == 'P') {

                                // 55.44.P.749
                                try {
                                    value = parseInt(packet.substr(8, 3));
                                }
                                catch { continue; }

                                someElement.textContent = someElement.dataset.name + ' = ' + value;

                                continue;
                            }

                            if (someElement.dataset.property == 'p' && typeSensor == 'V') {

                                // 55.46.V.026
                                try {
                                    value = parseInt(packet.substr(8, 3));
                                }
                                catch { continue; }

                                someElement.textContent = someElement.dataset.name + ' = ' + value;

                                continue;
                            }

                            if (someElement.dataset.property == 'U') {

                                // NN.NN.00.0X.XX
                                //console.log(packet);
                                try {
                                    let _vl = packet.substr(10, 1) + packet.substr(12, 2);
                                    value = parseFloat(_vl) / 10;
                                }
                                catch { continue; }

                                someElement.textContent = someElement.dataset.name + ' = ' + value;

                                continue;
                            }

                            break;


                        case 'meteoT':

                            if (packet.substr(6, 1) != 'T') break;
                            value = 0;
                            // 55.45.T.0248.0
                            try {
                                value = parseFloat(packet.substr(9, 3)) / 10;
                            }
                            catch { continue; }

                            if (packet.substr(8, 1) == '1') value = -value;

                            someElement.textContent = value;
                            break;

                        case 'meteoP':

                            if (packet.substr(6, 1) != 'P') break;
                            value = 0;
                            // 55.44.P.749
                            try {
                                value = parseInt(packet.substr(8, 3));
                            }
                            catch { continue; }

                            someElement.textContent = value;

                            break;

                        case 'meteop':
                            if (packet.substr(6, 1) != 'V') break;
                            value = 0;
                            // 55.46.V.026
                            try {
                                value = parseInt(packet.substr(8, 3));
                            }
                            catch { continue; }

                            someElement.textContent = value;
                            break;

                        case 'meteoU':

                            value = 0;
                            // NN.NN.00.0X.XX
                            try {
                                let _vl = packet.substr(10, 1) + packet.substr(12, 2);
                                value = parseFloat(_vl) / 10;
                            }
                            catch { continue; }

                            someElement.textContent = value;


                            break;

                        default:
                            break;
                    }
                }
            }
        }
    }
}

function findPage(page) {
  console.log('#findPage')
    for (let i in currentData.pages) {
        if (currentData.pages[i].number_page == page) {
            return currentData.pages[i];
        }
    }
    return null;
}

function NavigationMobile(obj) {

    $("#MainContainer").empty();

    let _child = $('<div>', {
        class: 'row'
    });

    _child.append($('<div>', {
        class: 'col',
        text: 'back',
        align: 'left',
        id : 'back',
        onclick: 'GoToMainMenu()'
    }));

    let _child2 = $('<div>', {
        class: 'row'
    });




    if (obj.toString() == 'ST.OS.00.00') {

        _child2.append($('<div>', {
            class: 'col text-center font-weight-bold',
            text: 'Системы Безопасности'
        }));

        let _mainColumn = $('<div>', {
            class: 'col',
            id: 'mobileHead',
            append: [_child, _child2]
        });

        for (let i in currentData.razdel) {

            let _obj = $('<div>', {
                class: 'row align-items-center',
                id: 'mobileRow'
            });

            let _event = 'SendCommand(' + "'" + currentData.razdel[i].id + "'" + ')';

            let _child = document.createElement('button');
            _child.setAttribute("id", currentData.razdel[i].id);
            _child.setAttribute("class", "mobile");
            _child.setAttribute("onclick", _event); // TODO: назначить программно
            _child.setAttribute("data-type", 'button');
            _child.setAttribute("data-property", 'fix');
            _child.setAttribute("data-state", "02");
            _child.textContent = "ON";

            _obj.append($('<div>', {
                class: 'col-2',
                align: 'center',
                append: _child
            }));

            _obj.append($('<div>', {
                class: 'col-7',
                text: currentData.razdel[i].name
            }));

            _mainColumn.append(_obj);
        }

        $('#MainContainer').append(_mainColumn);
        return;
    }

    let curPage = findPage(obj.toString());
    if (curPage.length == 0) return;

    _child2.append($('<div>', {
        class: 'col headPage',
        text: curPage.name_page.trim()
    }));

    let _mainColumn = $('<div>', {
        class: 'col',
        id: 'mobileHead',
        append: [_child, _child2]
    });

    // найти все индикаторы на текущей страницы. Их следуте вставить в 3й столбец
    let _meteo = new Array();
    for (let j in curPage.elements) {

        if (curPage.elements[j].type == "param") {
            _meteo.push(curPage.elements[j]);
        }
    }

    // разместить кнопки
    for (let j in curPage.elements) {
        if (curPage.elements[j].type == "button" && curPage.elements[j].action == "1") {

            let _obj = $('<div>', {
                class: 'row align-items-center',
                id: 'mobileRow'
            });

            let _event = 'SendCommand(' + "'" + curPage.elements[j].id + "'" + ')';

            let _child = document.createElement('button');
            _child.setAttribute("id", curPage.elements[j].id);
            _child.setAttribute("class", "mobile");
            _child.setAttribute("onclick", _event); // TODO: назначить программно
            _child.setAttribute("data-type", curPage.elements[j].type);
            _child.setAttribute("data-property", curPage.elements[j].property);
            _child.setAttribute("data-state", "02");
            _child.textContent = "ON";


            _obj.append($('<div>', {
                class: 'col-2',
                align: 'center',
                append: _child
            }));

            _obj.append($('<div>', {
                class: 'col-7',
                text: curPage.elements[j].name
            }));

            if (_meteo.length > 0) {

                _obj.append($('<div>', {
                    class: 'col-1',
                    align: 'center',
                    text: _meteo[0].property
                }));

                _obj.append($('<div>', {
                    class: 'col-2',
                    align: 'center',
                    text: '',
                    id: _meteo[0].id.substr(0, 5),
                    'data-type': 'meteo' + _meteo[0].property
                }));

                _meteo.splice(0, 1);
            }

            _mainColumn.append(_obj);
        }
    }

    // разместить прогрессбары
    for (let j in curPage.elements) {

        if (curPage.elements[j].type == "slider") {

            let _obj = $('<div>', {
                class: 'row align-items-center',
                id: 'mobileRow'
            });

            _obj.append($('<div>', {
                class: 'col-4',
                text: curPage.elements[j].name
            }));

            let _event = 'ChangedValue(' + "'" + curPage.elements[j].id + "'" + ')';

            let _dad = document.createElement('div');
            _dad.setAttribute("class", 'slidecontainer');

            let _child = document.createElement('input');
            _child.setAttribute("id", curPage.elements[j].id);
            _child.setAttribute("class", 'slider');
            _child.setAttribute("type", 'range');
            _child.setAttribute("onchange", _event);
            _child.setAttribute("min", '0');
            _child.setAttribute("max", '100');
            _child.setAttribute("value", '0');
            _child.setAttribute("data-type", 'slider');


            _dad.append(_child);

            _obj.append($('<div>', {
                class: 'col-5 align-self-center',
                append: _dad
            }));

            if (_meteo.length > 0) {

                _obj.append($('<div>', {
                    class: 'col-1',
                    align: 'center',
                    text: _meteo[0].property
                }));

                _obj.append($('<div>', {
                    class: 'col-2',
                    align: 'center',
                    text: '',
                    id: _meteo[0].id.substr(0, 5),
                    'data-type': 'meteo' + _meteo[0].property
                }));

                _meteo.splice(0, 1);
            }

            _mainColumn.append(_obj);
        }
    }

    // разместить статусы эквивалентные состоянию входа
    for (let j in curPage.elements) {

        if (curPage.elements[j].type == "status") {

            let _obj = $('<div>', {
                class: 'row align-items-center',
                id: 'mobileRow'
            });


            let _child = document.createElement('button');
            _child.setAttribute("id", curPage.elements[j].id);
            _child.setAttribute("class", "mobile");
            _child.setAttribute("data-type", curPage.elements[j].type);
            _child.setAttribute("data-property", "unfix");
            _child.setAttribute("data-state", "02");
            _child.textContent = "ON";
            if(curPage.elements[j].action == "100"){
                _child.setAttribute("disabled", "");
            }

            _obj.append($('<div>', {
                class: 'col-2',
                align: 'center',
                append: _child
            }));

            _obj.append($('<div>', {
                class: 'col-7',
                text: curPage.elements[j].name
            }));

            if (_meteo.length > 0) {

                _obj.append($('<div>', {
                    class: 'col-1',
                    align: 'center',
                    text: _meteo[0].property
                }));

                _obj.append($('<div>', {
                    class: 'col-2',
                    align: 'center',
                    text: '',
                    id: _meteo[0].id.substr(0, 5),
                    'data-type': 'meteo' + _meteo[0].property
                }));

                _meteo.splice(0, 1);
            }

            _mainColumn.append(_obj);
        }
    }

    // разместить индикаторы погодных данных, если элементов оказалось меньше
    if (_meteo.length > 0) {

        for (let i in _meteo) {

            _obj.append($('<div>', {
                class: 'col-9',
            }));

            _obj.append($('<div>', {
                class: 'col-1',
                align: 'center',
                text: _meteo[i].property
            }));

            _obj.append($('<div>', {
                class: 'col-2',
                align: 'center',
                text: '',
                id: _meteo[0].id.substr(0, 5),
                'data-type': 'meteo' + _meteo[0].property
            }));

        }
    }

    $('#MainContainer').append(_mainColumn);

    let _back = document.getElementById("back");
    _back.focus({ preventScroll: false });
    _back.scrollIntoView();
}

function SendServer(obj) {
  //console.log('#SendServer', obj.toString())
    //if (socket && socket.readyState == 1) {

        let command = ':' + obj.toString().substr(0, 6) + password.substr(0, 2) + '*' + password.substr(2, 2) + obj.toString().substr(11, 6);
        //let command = obj.toString().substr(0, 7) + password.substr(0, 2) + '*' + password.substr(2, 2) + obj.toString().substr(12, 6);
        //console.log('Command Send Serever: ', command);
        
        sendMessage(command)
        //socket.send(command);
    //}
}


function SendCommand(obj) {
  console.log('#SendCommand')
    let someElement = document.getElementById(obj.toString());

    if ((someElement.dataset.property == "unfix")) {

        someElement.style.background = color_color;
        someElement.style.color = color_color;

        SendServer(obj.toString() + '.01.00');
        //SendServer(obj.toString() + '.02.00');

        setTimeout(function () {
            ChangeStyleAfterTime(obj, '02');
        }, 100);

        return;
    }

    // если это кнопка управления разделом, то следует заменить id c C на B
    if (obj.substr(0, 1) == 'C') obj = 'B' + obj.substr(1, 10);

    if (someElement.dataset.state == "01") {
        SendServer(obj.toString() + '.02.00');
    }
    else {
        SendServer(obj.toString() + '.01.00');
    }

}

function ChangeStyleAfterTime(obj, state) {
  console.log('#ChangeStyleAfterTime')

    let someElement = document.getElementById(obj.toString());
    if (state == '01') {
        someElement.style.background = color_color;
        someElement.style.color = color_color;
    }
    else {
        someElement.style.background = color_white;
        someElement.style.color = color_white;
    }
}


function GoToMainMenu() {
  console.log('#GoToMainMenu')

    let statusLine = document.getElementById('lowerLine');
    statusLine.className = 'row';
    statusLine.hidden = false;
    let cont = document.getElementById("MainContainer")
    console.log(cont)
    document.getElementById("MainContainer").hidden = true;

    let _col = $('<div>', {
        class: 'col'
    });

    let _obj = $('<div>', {
        class: 'row'
    });

    /*_obj.append($('<div>', {
        class: 'col',
        id: 'ST.OS.00.00',
        text: 'Системы Безопасности',
        onclick: 'NavigationMobile(' + "'ST.OS.00.00'" + ')'
    }));*/

    //_col.append(_obj);


    for (let i in currentData.pages) {

        let _margin = currentData.pages[i].name_page.length - currentData.pages[i].name_page.trim().length;

        let _obj = $('<div>', {
            class: 'row'
        });

        /*_obj.append($('<div>', {
            class: 'col',
            id: currentData.pages[i].number_page,
            text: currentData.pages[i].name_page,
            onclick: 'NavigationMobile(' + "'" + currentData.pages[i].number_page + "'" + ')',
            style: 'margin-left:' + _margin * 2 + 'px;'
        }));*/

        //_col.append(_obj);
    }

    //$('#MainContainer').append(_col);

    if (document.getElementById("MainContainer").childElementCount > 0) {
        statusLine.hidden = false;
        document.getElementById("MainContainer").hidden = false;
    }
}

function ButtonClicked(obj) {
  console.log('#ButtonClicked')
    let strRab = obj.toString();

    // переход на страницу ОПС
    if (strRab.substr(9, 2) == '66') {
        ShowOPSControlPanel();
        return;
    }


    // отправить в сокет команду
    // Для обычных кнопок - 01 и 02.
    // Для кнопок с фиксацией 01 или 02.

    let btn = document.getElementById(strRab);
    if (btn) {
        if (btn.dataset.property == 'unfix') {
            SendServer(strRab + '.01.00');
            setTimeout(() => {
                SendServer(strRab + '.02.00');
            }, 5000)
            //SendServer(strRab + '.02.00');
        }

        if (btn.dataset.property == 'fix') {
            if (btn.dataset.state == '01')
                SendServer(strRab + '.02.00');
            if (btn.dataset.state == '02')
                SendServer(strRab + '.01.00');
        }
    }


    if (strRab.substr(6, 2) == "00") return;
    if (strRab.substr(0, 2) == strRab.substr(6, 2)) return;

    let someElement = document.getElementById(strRab.substr(6, 2) + '.00.00.00');
    someElement.style.visibility = 'visible';
    someElement = document.getElementById(strRab.substr(0, 2) + '.00.00.00');
    someElement.style.visibility = 'hidden';

    createCameras()
}

function ChangedValue(obj) {
  console.log('#ChangedValue')

    let someElement = document.getElementById(obj.toString());
    //console.log('CHANGE!', someElement )
    if (someElement) {

        let value = someElement.value;
        //console.log('value', value )
        if (value >= 100) value = 98;
        var val = value / 1.54;
        val = Math.floor(val)
        SendServer(obj.toString() + '.' + val.toString().padStart(2, 0) + '.00');
    }
}


function getFileConfig(data) {
  //console.log('#getFileConfig')
  //console.log(data)
    if(data){
        currentData = data;
        if ($(window).width() <= 350) {
            console.log("Вы используете мобильное устройство");
            GoToMainMenu();
        }
        else {
          console.log("Вы используете ПК");
          StartControlPanel();
          password = currentData.password;
          panelReady();
          //cameras
          createCameras()
        }
    }
}
function createCameras(){
  const pages = document.getElementById('MainContainer').children
  let pageId = ''
  //console.log(pages)
  pages.forEach(page => {
    if(page.style['visibility'] == 'visible'){
      pageId = page.id.substr(0, 2)
    }
  })
  let url = ""
  console.log(currentPage)
  currentData.pages.forEach((data, index) => {
    const videoContainer = document.getElementsByClassName(`video-container${index}`)[0]
    if(videoContainer && videoContainer.firstChild)
    while (videoContainer.firstChild) {
      videoContainer.removeChild(videoContainer.lastChild);
    }
  })
  currentData.pages.forEach((page, pageNumber) => {
    page.elements.forEach(element => {
      //console.log(pageId + ' ' +  element.id.substr(0, 2))
      if(element.type == "video" && pageId == element.id.substr(0, 2)){
        cameras.forEach(cam => {
         // console.log(cam.panelElementId + '  ' + element.id)
          if(cam.panelElementId == element.id){
            console.log('page.id.substr(0, 5)')
            const rtspResultOnvideo = JSON.parse(cam.rtcp)
            updateProgressBar(-3)
           // console.log(rtspResultOnvideo)
            //StreamCintroller
            const canvasFathers = document.getElementsByClassName(`video-container${pageNumber}`)[0]
            if(streamService){
                streamService.destroy()
            }
            streamService = new StreamService(rtspResultOnvideo, canvasFathers, pageNumber, false, updateProgressBar)
            console.log(streamService)
            
            //this.isShowProgressBar = true
            //StreamCintroller.dropStreams()
            //StreamCintroller.openStreams(rtspResultOnvideo, pageNumber)
            //openFullStream(rtspResultOnvideo, pageNumber)
          }
        })
      }
    })
  });
}
function openFullStream(streamUrls, pageNumber){
  try{
    const videoCont = document.getElementsByClassName(`video-container${pageNumber}`)[0]
    streamUrls.forEach(async (streamUrl, index) => {
      //setTimeout(async () => {
        if(streamUrl.length > 10){
          const containerJQ = $(`.video-container${pageNumber}`)
          const w = containerJQ.width()
          const h = containerJQ.height()
        
          await fetch('http://84.201.165.76:3009/start', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({ streamUrl }),
          })
          .then((response) => response.json())
          .then((data) => {
            if (jsmpegPlayer.length-1 > index && jsmpegPlayer[index]) {
              jsmpegPlayer[index].stop()
              //jsmpegPlayer[index].destroy()
            }
            const canvas = document.createElement('canvas')
            canvas.id = `jsmpeg-player${index}`
            canvas.style.width = `${w}px`
            canvas.style.height = `${h}px`
            canvas.style.position = 'absolute'
            if(index == 0){
              canvas.style.top = 0
              canvas.style.left = 0
            }
            else if(index == 1){
              canvas.style.top = 0
              canvas.style.left = `${w/2}px`
              canvas.style.visibility = 'hidden'
            }
            else if(index == 2){
              canvas.style.top = `${h/2}px`
              canvas.style.left = 0
              canvas.style.visibility = 'hidden'
            }
            else if(index == 3){
              canvas.style.top = `${h/2}px`
              canvas.style.left = `${w/2}px`
              canvas.style.visibility = 'hidden'
            }
            setTimeout(() => {
              $(`#jsmpeg-player${index}`).click((da) => {
                isBig = !isBig
                if(isBig){
                  console.log('isbig')
                  canvasses.forEach((cnvs, ind) => {
                    if(cnvs.cnv != canvas){
                      cnvs.cnv.style.visibility = 'hidden'
                    }else{
                      cnvs.cnv.style.visibility = 'visible'
                      cnvs.cnv.style.width = `${w}px`
                      cnvs.cnv.style.height = `${h}px`
                      cnvs.cnv.style.top = 0
                      cnvs.cnv.style.left = 0
                    }
                  })
                }
                else
                {
                  console.log('not isbig')
                  canvasses.forEach((cnvs, ind) => {
                    cnvs.cnv.fillStyle = "orange";
                    cnvs.cnv.style.visibility = 'visible'
                    cnvs.cnv.style.width = `${w/2}px`
                    cnvs.cnv.style.height = `${h/2}px`
                    if(ind == 0){
                      cnvs.cnv.style.top = 0
                      cnvs.cnv.style.left = 0
                    }
                    else if(ind == 1){
                      cnvs.cnv.style.top = 0
                      cnvs.cnv.style.left = `${w/2}px`
                    }
                    else if(ind == 2){
                      cnvs.cnv.style.top = `${h/2}px`
                      cnvs.cnv.style.left = 0
                    }
                    else if(ind == 3){
                      cnvs.cnv.style.top = `${h/2}px`
                      cnvs.cnv.style.left = `${w/2}px`
                    }
                  })
                }
              });
            }, 1500)
            videoCont.appendChild(canvas)
            console.log({...data, streamUrl})
            if(jsmpegPlayer.length-1 > index && jsmpegPlayer[index]){
              jsmpegPlayer[index].stop()
              jsmpegPlayer[index] = new JSMpeg.Player(data.url, {
                canvas,
                autoplay: true,
              })
            }else{
              jsmpegPlayer.push(new JSMpeg.Player(data.url, {
                canvas,
                autoplay: true,
              }))
            }
            console.log(`jsmpegPlayer: ${jsmpegPlayer.length}`)
            if(canvasses[index]){
              canvasses[index] = {
                cnv: canvas,
                isBig: false
              }
            }else{
              canvasses.push({
                cnv: canvas,
                isBig: false
              })
            }
          })
          .catch(e => {
            for(let i = 0; i < jsmpegPlayer.length; i++){
              jsmpegPlayer[i].stop()
            }
            jsmpegPlayer = []
            createCameras()
          })
        }
      //}, 1500*index)
    })
  }catch(e){
    console.error(e)
  }
}
function openStream(streamUrl){
  const videoContainer = document.getElementById('video-container')
  const containerJQ = $('#video-container')
  const w = `${containerJQ.width()}px`
  const h = `${containerJQ.height()}px`

  fetch('http://212.192.202.112:3009/start', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({ streamUrl }),
  })
  .then((response) => response.json())
  .then((data) => {
    if (jsmpegPlayer) {
      jsmpegPlayer.stop()
      jsmpegPlayer.destroy()
    }

    console.log(w, h)
    const canvas = document.createElement('canvas')
    canvas.id = 'jsmpeg-player'
    canvas.style.width = w
    canvas.style.height = h
    setTimeout(() => {
      $("#jsmpeg-player").click((da) => {
        console.log(da)
        console.log('clickcanvas')
      });
    }, 3000)
    videoContainer.appendChild(canvas)
    jsmpegPlayer = new JSMpeg.Player(data.url, {
      canvas,
      autoplay: true,
    })
  })
}


function StartControlPanel() {
    if(!startedPanel) {
        return
    }
  console.log('#StartControlPanel')
    if (currentData){
        
        let statusLine = document.getElementById('lowerLine');
        if (statusLine)
        {
            statusLine.className = 'row statuspanel';
            statusLine.hidden = true;
        }
        
        let _contrainerWidth;
        let _containerHeight;
        $("#MainContainer").empty();
         document.getElementById("MainContainer").hidden = true;
        
        if (configName.includes("M2"))
        {
        }
        else
        {
        }
        _contrainerWidth = parseInt($('#MainContainer').width());
         _containerHeight = parseInt($('#MainContainer').height()); 
         const bars = document.getElementsByClassName('MainContainerBar')
         console.log(bars)
         bars[0].style.width = `${_contrainerWidth-12}px`
         console.log(_contrainerWidth)
         let hei = _containerHeight+27
        if(isMtwo){
            hei = 580
        }
        console.log(hei)
        let top_obj = $('<div>', {
            class: 'bottom',
            style: `position:absolute; top: ${hei}px; width: ${_contrainerWidth}px;`
        })
        for (let i in currentData.pages) {
            console.log('#1')
            // страница
            let _obj = $('<div>', {
                class: 'div page',
                id: currentData.pages[i].number_page,
                style: 'position:absolute; width:' + _contrainerWidth + 'px; height:' + _containerHeight + 'px; ' +
                  'z-index: 1; visibility:hidden;'
            });


            for (let j in currentData.pages[i].elements) {

                let _elemWidth = Math.round(parseInt(currentData.pages[i].elements[j].width) * parseInt(_contrainerWidth) / 1008);
                let _elemHeight = Math.round(parseInt(currentData.pages[i].elements[j].height) * parseInt(_containerHeight) / 540);
                let _elemTop = Math.round(parseInt(currentData.pages[i].elements[j].top) * parseInt(_containerHeight) / 540);
                let _elemLeft = Math.round(parseInt(currentData.pages[i].elements[j].left) * parseInt(_contrainerWidth) / 1008);
                


                // тип кнопка, тип прогрессбар, тип индикатор входа, тип метео данные и АЦП
                switch (currentData.pages[i].elements[j].type) {
                    case 'video' :
                        _obj.append($('<div>', {
                            style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                            id: currentData.pages[i].elements[j].id.substr(0, 5),
                            class: 'video',
                            class: `video-container${i}`,
                            'data-type': 'video' /*currentData.pages[i].elements[j].name*/,
                            'data-property': currentData.pages[i].elements[j].property,
                            'data-name': currentData.pages[i].elements[j].name
                        }));


                        continue;
                    case 'button':

                        let _class = '';
                        if (currentData.pages[i].number_page.substr(0, 2) == currentData.pages[i].elements[j].id.substr(6, 2))
                            _class = ' pressed';

                        _obj.append($('<button>', {
                            class: 'button' + _class + `${miniPanel?' panel-text-caption':''}`,
                            style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                            id: currentData.pages[i].elements[j].id,
                            'data-type': currentData.pages[i].elements[j].type,
                            'data-property': currentData.pages[i].elements[j].property,
                            'data-action': currentData.pages[i].elements[j].action,
                            'data-state': '01',
                            text: currentData.pages[i].elements[j].name
                        }));

                        break;

                    case 'slider':
                        // https://www.w3schools.com/howto/howto_js_rangeslider.asp

                        let _dad = document.createElement('div');
                        _dad.setAttribute("class", 'slidecontainer');
                        _dad.setAttribute("style", 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;');

                        let _child = document.createElement('input');
                        _child.addEventListener('touchend', (ad) => {
                            console.log(_child.value)
                            ChangedValue(currentData.pages[i].elements[j].id)
                        },{ passive: false })

                        _child.setAttribute("id", currentData.pages[i].elements[j].id);
                        _child.setAttribute("class", 'slider');
                        _child.setAttribute("type", 'range');
                        _child.setAttribute("style", 'position:absolute; left:0px; top:' + _elemHeight + 'px; width:' + _elemHeight + 'px; height:' + _elemWidth + 'px; z-index:1; transform-origin: top left;');
                        _child.setAttribute("min", '0');
                        _child.setAttribute("max", '100');
                        _child.setAttribute("value", '0');
                        _child.setAttribute("data-type", 'slider');

                        _dad.append(_child);

                        _obj.append($(_dad));

                        continue;


                    case 'param':
                        _obj.append($('<div>', {
                            style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                            id: currentData.pages[i].elements[j].id.substr(0, 5),
                            class: 'param',
                            'data-type': 'param' /*currentData.pages[i].elements[j].name*/,
                            'data-property': currentData.pages[i].elements[j].property,
                            'data-name': currentData.pages[i].elements[j].name,
                            text: currentData.pages[i].elements[j].name
                        }));


                        continue;

                    case 'status':
                        /*if(currentData.pages[i].elements[j].property == 'equivalent_in'){
                          //equivalents_in.push(currentData.pages[i].elements[j].id)
                          console.log(equivalents_in)
                        }*/
                        if(currentData.pages[i].elements[j].property == 'equivalent_out'){
                          equivalents_out.push(currentData.pages[i].elements[j].id)
                          console.log(equivalents_out)
                        }
                        _obj.append($('<div>', {
                            class: 'status',
                            style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                            id: currentData.pages[i].elements[j].id,
                            'data-type': 'status' /*currentData.pages[i].elements[j].name*/,
                            'data-property': currentData.pages[i].elements[j].property,
                            'data-state': '01',
                            text: currentData.pages[i].elements[j].name
                        }));

                        continue;

                    case 'image':

                        _obj.append($('<div>', {
                            style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                            id: currentData.pages[i].elements[j].id,
                            class: 'label',
                            'data-type': currentData.pages[i].elements[j].name,
                            'data-property': currentData.pages[i].elements[j].property,
                            text: currentData.pages[i].elements[j].name
                        }));

                        continue;

                    default:
                        continue;
                }
            }

            $('#MainContainer').append(_obj);
        }
        if (serialNumber && serialNumber.length==15)
        {
            MTwoComponents();
        }
        
        if (document.getElementById("MainContainer").childElementCount > 0) {
            statusLine.hidden = false;
            document.getElementById("MainContainer").hidden = false;
        }

        let someElement = null;
        someElement = document.getElementById('pageNumberLink');
        if (someElement != null) {
            let numberPage = someElement.value + '.00.00.00';
            someElement = document.getElementById(numberPage);
            if (someElement != null)
                someElement.style.visibility = 'visible';
            else
                document.getElementById(currentData.pages[0].number_page).style["visibility"] = 'visible';
        } else
            document.getElementById(currentData.pages[0].number_page).style["visibility"] = 'visible';
        
        SetEventClicked();

        let temp = $('<div>', {
            id: 'StatusTemp',
            class: 'status-element'
        })
        let hum = $('<div>', {
            id: 'StatusHum',
            class: 'status-element'
        })
        let press = $('<div>', {
            id: 'StatusPres',
            class: 'status-element'
        })
        top_obj.append(temp)
        top_obj.append(hum)
        top_obj.append(press)
        $('#MainContainer').append(top_obj);
        
        if (serialNumber && serialNumber.length==15)
        {
        }else{
            const bottom = document.getElementsByClassName('bottom')[0]
            if(miniPanel){
               // bottom.classList.add('mtfix-tablet')
            }else{
               // bottom.classList.add('mtfix')
            }
        }
        
    }
}

function ShowOPSControlPanel() {
  console.log('#ShowOPSControlPanel')
    if (configName.includes("M2"))
    {
        $("#MainContainer").empty();

    let _containerHeight = parseInt($('#MainContainer').height());
    let _contrainerWidth = 1086;
    
    let _obj1 = ($('<div>', {
        class: 'col-3 razdel',
        style: 'background:black; color:white; height:' + _containerHeight + 'px;',
        append: `<div class="row text-center" id="rowHead"><div class="col headers font-weight-bold">${controlPanelLangaugeData.Sections}</div></div>` +
            `<div class="row h-35" id="rowOPS"><div class="col colops" id="Razdels"></div></div><div class="row text-center" id="rowHead"><div class="col headers font-weight-bold">${controlPanelLangaugeData.FloorPlan}</div></div>` +
            '<div class="row h-35" id="rowOPS"><div class="col colops" id="Floors"></div></div>' +
            `<div class="row align-items-end" id="rowHead"><div class="col colops"><button class="buttonOPS">${controlPanelLangaugeData.Automatica}</button></div></div>`,
    }));
    let _obj2 = ($('<div>', {
        class: 'col-9 razdel',
        style: 'height:' + _containerHeight + 'px;',
        append: '<div class= "row h-100" id="ContentOPS"></div>',
    }));

    $('#MainContainer').append([_obj1, _obj2]);
    $(".buttonOPS").click(function() {
        BackAutomatica()
    });

    _containerHeight = parseInt($('#ContentOPS').height());

    for (let i in currentData.razdel) {

        let razdel = ($('<button>', {
            class: 'buttonOPS',
            id: currentData.razdel[i].id,
            'data-type': 'razdel',
            'data-state': '02',
            text: currentData.razdel[i].name
        }));

        $('#Razdels').append(razdel);
    }

    for (let i in currentData.pagesOPS) {

        if (i == 0)
            _contrainerWidth = Math.round(_containerHeight * currentData.pagesOPS[i].width / currentData.pagesOPS[i].height);

        let plan = ($('<button>', {
            class: 'buttonFloor',
            text: currentData.pagesOPS[i].name_page
        }));

        $('#Floors').append(plan);

        // разместить этажный план с датчиками


        // страница
        let _obj = $('<div>', {
            class: 'bgimg', id: 'page' + currentData.pagesOPS[i].id_page, style: 'position:absolute; width:' + _contrainerWidth + 'px; height:' + _containerHeight + 'px; ' +
                'z-index: 1; visibility:hidden; background-image: url(' + imageOpsBGUrl+ currentData.pagesOPS[i].picture.replace(/\s/g, '') + ');'
        });
        console.log(imageOpsBGUrl+ currentData.pagesOPS[i].picture.replace(/\s/g, ''))

        let widthpage = currentData.pagesOPS[i].width;
        let heightpage = currentData.pagesOPS[i].height;

        for (let j in currentData.pagesOPS[i].detector) {

            let _elemWidth = Math.round(parseInt(currentData.pagesOPS[i].detector[j].width) * parseInt(_contrainerWidth) / widthpage);
            let _elemHeight = Math.round(parseInt(currentData.pagesOPS[i].detector[j].height) * parseInt(_containerHeight) / heightpage);
            let _elemTop = Math.round(parseInt(currentData.pagesOPS[i].detector[j].y) * parseInt(_containerHeight) / heightpage);
            let _elemLeft = Math.round(parseInt(currentData.pagesOPS[i].detector[j].x) * parseInt(_contrainerWidth) / widthpage);


            _obj.append($('<img>', {
                style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                src: imageOpsUrl + currentData.pagesOPS[i].detector[j].name + '_passive.png',
                //id: currentData.pagesOPS[i].detector[j].id,
                'id_alarm': currentData.pagesOPS[i].detector[j].id_alarm,
                'name': currentData.pagesOPS[i].detector[j].name,
                'state': 'passive'
            }));


        }

        if (i == 0)
            _obj.append($('<canvas>', {
                id: 'canvasOPS'
            }));

        $('#ContentOPS').append(_obj);
    }
    //console.log(currentData)
    //console.log(document.getElementById('page' + currentData.pagesOPS[0].id_page))

    document.getElementById('page' + currentData.pagesOPS[0].id_page).style["visibility"] = 'visible';
    SetClickElement();
    }
    else
    {
        $("#MainContainer").empty();

    let _containerHeight = parseInt($('#MainContainer').height());
    let _contrainerWidth = 1086;
    
    let _obj1 = ($('<div>', {
        class: 'col-3 razdel',
        style: 'background:black; color:white; height:' + _containerHeight + 'px;',
        append: `<div class="row text-center" id="rowHead"><div class="col headers font-weight-bold">${controlPanelLangaugeData.Sections}</div></div>` +
            `<div class="row h-35" id="rowOPS"><div class="col colops" id="Razdels"></div></div><div class="row text-center" id="rowHead"><div class="col headers font-weight-bold">${controlPanelLangaugeData.FloorPlan}</div></div>` +
            '<div class="row h-35" id="rowOPS"><div class="col colops" id="Floors"></div></div>' +
            `<div class="row align-items-end" id="rowHead"><div class="col colops"><button class="buttonOPS">${controlPanelLangaugeData.Automatica}</button></div></div>`,
    }));
    let _obj2 = ($('<div>', {
        class: 'col-9 razdel',
        style: 'height:' + _containerHeight + 'px;',
        append: '<div class= "row h-100" id="ContentOPS"></div>',
    }));

    $('#MainContainer').append([_obj1, _obj2]);
    $(".buttonOPS").click(function() {
        BackAutomatica()
    });

    _containerHeight = parseInt($('#ContentOPS').height());

    for (let i in currentData.razdel) {

        let razdel = ($('<button>', {
            class: 'buttonOPS',
            id: currentData.razdel[i].id,
            'data-type': 'razdel',
            'data-state': '02',
            text: currentData.razdel[i].name
        }));

        $('#Razdels').append(razdel);
    }

    for (let i in currentData.pagesOPS) {

        if (i == 0)
            _contrainerWidth = Math.round(_containerHeight * currentData.pagesOPS[i].width / currentData.pagesOPS[i].height);

        let plan = ($('<button>', {
            class: 'buttonFloor',
            text: currentData.pagesOPS[i].name_page
        }));

        $('#Floors').append(plan);

        // разместить этажный план с датчиками


        // страница
        let _obj = $('<div>', {
            class: 'bgimg', id: 'page' + currentData.pagesOPS[i].id_page, style: 'position:absolute; width:' + _contrainerWidth + 'px; height:' + _containerHeight + 'px; ' +
                'z-index: 1; visibility:hidden; background-image: url(' + imageOpsBGUrl+ currentData.pagesOPS[i].picture.replace(/\s/g, '') + ');'
        });
        console.log(imageOpsBGUrl+ currentData.pagesOPS[i].picture.replace(/\s/g, ''))

        let widthpage = currentData.pagesOPS[i].width;
        let heightpage = currentData.pagesOPS[i].height;

        for (let j in currentData.pagesOPS[i].detector) {

            let _elemWidth = Math.round(parseInt(currentData.pagesOPS[i].detector[j].width) * parseInt(_contrainerWidth) / widthpage);
            let _elemHeight = Math.round(parseInt(currentData.pagesOPS[i].detector[j].height) * parseInt(_containerHeight) / heightpage);
            let _elemTop = Math.round(parseInt(currentData.pagesOPS[i].detector[j].y) * parseInt(_containerHeight) / heightpage);
            let _elemLeft = Math.round(parseInt(currentData.pagesOPS[i].detector[j].x) * parseInt(_contrainerWidth) / widthpage);


            _obj.append($('<img>', {
                style: 'position:absolute; left:' + _elemLeft + 'px; top:' + _elemTop + 'px; width:' + _elemWidth + 'px; height:' + _elemHeight + 'px; z-index:1;',
                src: imageOpsUrl + currentData.pagesOPS[i].detector[j].name + '_passive.png',
                //id: currentData.pagesOPS[i].detector[j].id,
                'id_alarm': currentData.pagesOPS[i].detector[j].id_alarm,
                'name': currentData.pagesOPS[i].detector[j].name,
                'state': 'passive'
            }));


        }

        if (i == 0)
            _obj.append($('<canvas>', {
                id: 'canvasOPS'
            }));

        $('#ContentOPS').append(_obj);
    }
    //console.log(currentData)
    //console.log(document.getElementById('page' + currentData.pagesOPS[0].id_page))

    document.getElementById('page' + currentData.pagesOPS[0].id_page).style["visibility"] = 'visible';
    SetClickElement();
    }
}


function DrawFigures(namePage) {
  console.log('#DrawFigures')
    currentPage = namePage;

    let _canvas = document.getElementById("canvasOPS");
    if (_canvas) _canvas.remove();

    for (let i in currentData.pagesOPS) {
        if (currentData.pagesOPS[i].name_page == namePage) {

            let _obj = $('<canvas>', {
                id: 'canvasOPS'
            });

            let _id = '#page' + currentData.pagesOPS[i].id_page;
            $(_id).append(_obj);
            console.log(_id);

            break;
        }
    }

    UpdateCanvas(namePage);

}

function UpdateCanvas(namePage) {

  //console.log('#UpdateCanvas')
    let canvas = document.getElementById("canvasOPS");
    if (canvas && canvas.getContext) {
        let ctx = canvas.getContext('2d');

        ctx.canvas.width = parseInt($('#ContentOPS').width());
        ctx.canvas.height = parseInt($('#ContentOPS').height());
        // а если задаешь стиль css, то не рисует, странно

        let _containerHeight = parseInt($('#ContentOPS').height());
        let _contrainerWidth = 1086;
        for (let i in currentData.pagesOPS) {
          //console.log('#2')
            document.getElementById('page' + currentData.pagesOPS[i].id_page).style["visibility"] = 'hidden';
            if (currentData.pagesOPS[i].name_page == namePage) {
                document.getElementById('page' + currentData.pagesOPS[i].id_page).style["visibility"] = 'visible';
                _contrainerWidth = Math.round(_containerHeight * currentData.pagesOPS[i].width / currentData.pagesOPS[i].height);

                let widthpage = currentData.pagesOPS[i].width;
                let heightpage = currentData.pagesOPS[i].height;

                // нарисовать зоны
                ctx.fillStyle = "#696969";
                ctx.strokeStyle = "#696969";
                ctx.lineWidth = 3;
                ctx.globalAlpha = 0.65;

                // самое жопистое это определить цвет зон
                // количество зон на странице определено, по-умолчанию они серые
                // зона - id, name, []points


                // перечисление по зонам
                for (let zn in currentData.pagesOPS[i].zones) {
                    ctx.fillStyle = "#696969";

                    let pointsZona = new Array();

                    for (let pn in currentData.pagesOPS[i].zones[zn].points) {

                        let _pointTop = Math.round(parseInt(currentData.pagesOPS[i].zones[zn].points[pn].oy) * parseInt(_containerHeight) / heightpage);
                        let _pointLeft = Math.round(parseInt(currentData.pagesOPS[i].zones[zn].points[pn].ox) * parseInt(_contrainerWidth) / widthpage);

                        let arr = { x: _pointLeft, y: _pointTop };
                        pointsZona.push(arr);
                    }


                    // перечисление по датчикам текущей страницы
                    for (let d in currentData.pagesOPS[i].detector) {

                        // если датчик лежит внутри зоны, то посмотреть, в состав какого раздела входит шлейф, в состав которого входит текущий датчик

                        // центр расположения датчика
                        let iLeft = Math.round(parseInt(currentData.pagesOPS[i].detector[d].x) * parseInt(_contrainerWidth) / widthpage) + Math.round(parseInt(currentData.pagesOPS[i].detector[d].width) * parseInt(_contrainerWidth) / widthpage) / 2;
                        let iTop = Math.round(parseInt(currentData.pagesOPS[i].detector[d].y) * parseInt(_containerHeight) / heightpage) + Math.round(parseInt(currentData.pagesOPS[i].detector[d].height) * parseInt(_containerHeight) / heightpage) / 2;
                        if (PointInPolygon(pointsZona, iLeft, iTop) != false) {

                            // если раздел под охраной - цвет зеленый
                            // если состояние шлейфа в сработке - цвет красный - выход, у этого цвета приоритет
                            let temp = currentData.pagesOPS[i].detector[d]
                            let alarmloop = currentData.pagesOPS[i].detector[d].id_alarm;

                            for (let r in currentData.razdel) {

                                for (let st in currentData.razdel[r].alarms) {

                                    if (currentData.razdel[r].alarms[st].id == alarmloop) {

                                        // если шлейф входит в состав раздела, посмотреть состояние раздела

                                        let _razdel = document.getElementById(currentData.razdel[r].id);
                                        if (!_razdel) continue;

                                        if (_razdel.dataset.state == '01') // стоит под охраной
                                        {
                                            // цвет зеленый
                                            console.log('цвет зеленый');
                                            ctx.fillStyle = "#008000";
                                            // посмотреть состояние датчика: если активное состояние - то зона красная
                                            let detectors = document.querySelectorAll("[id_alarm='" + alarmloop + "']");
                                            if (detectors.length > 0) {
                                                if (detectors[0].state == 'activeC') {

                                                    // цвет красный - выход
                                                    console.log('цвет красный');
                                                    ctx.fillStyle = "#FF0000";
                                                    st = currentData.razdel[r].alarms.length;
                                                    r = currentData.razdel.length;
                                                    d = currentData.pagesOPS[i].detector.length;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    ctx.beginPath();
                    for (let pn in pointsZona) {
                        if (pn == 0) {
                            ctx.moveTo(pointsZona[pn].x, pointsZona[pn].y);
                        }
                        else {
                            ctx.lineTo(pointsZona[pn].x, pointsZona[pn].y);
                        }
                    }
                    ctx.closePath();
                    ctx.stroke();
                    ctx.fill();
                }
                /*
                for (let pn in currentData.pagesOPS[i].zones[zn].points) {

                    let _pointTop = Math.round(parseInt(currentData.pagesOPS[i].zones[zn].points[pn].oy) * parseInt(_containerHeight) / heightpage);
                    let _pointLeft = Math.round(parseInt(currentData.pagesOPS[i].zones[zn].points[pn].ox) * parseInt(_contrainerWidth) / widthpage);

                    if (pn == 0) {
                        ctx.moveTo(_pointLeft, _pointTop);
                    }
                    else {
                        ctx.lineTo(_pointLeft, _pointTop);
                    }

                    ctx.closePath();
                    ctx.stroke();
                    ctx.fill();
                }*/

                // отсортировать датчики по возрастанию .number для корректного рисования шлейфа
                currentData.pagesOPS[i].detector.sort(function (a, b) {
                    if (a.number > b.number) {
                        return 1;
                    }
                    if (a.number < b.number) {
                        return -1;
                    }
                    return 0;
                });


                // нарисовать шлейф по координатам - центрам датчиков на плане в составе одного шлейфа
                ctx.font = "14px sans-serif";

                ctx.globalAlpha = 1;
                for (let al in currentData.pagesOPS[i].alarmloop) {

                    ctx.strokeStyle = "#FFA500";
                    ctx.fillStyle = "#FFA500";
                    let state = 0; // passive
                    if (currentData.pagesOPS[i].alarmloop[al].state != currentData.pagesOPS[i].alarmloop[al].current) {
                        ctx.fillStyle = "#FF0000";
                        ctx.strokeStyle = "#FF0000";
                        state = 1; // active
                    }

                    // взять все датчики, которые связаны с этим шлейфом и присвоить им ресурс: активное или пассивное состояние
                    let detectors = document.querySelectorAll("[id_alarm='" + currentData.pagesOPS[i].alarmloop[al].id + "']");
                    for (let d in detectors) {
                        if (state == 0) {
                            detectors[d].src = imageOpsUrl +  detectors[d].name + '_passive.png';
                            detectors[d].state = 'passive';
                        }
                        else {
                            detectors[d].src = imageOpsUrl + detectors[d].name + '_active.png';
                            detectors[d].state = 'activeC';
                        }
                    }

                    let points = new Array();

                    for (let j in currentData.pagesOPS[i].detector) {

                        if (currentData.pagesOPS[i].alarmloop[al].id == currentData.pagesOPS[i].detector[j].id_alarm) {

                            let _elemWidth = Math.round(parseInt(currentData.pagesOPS[i].detector[j].width) * parseInt(_contrainerWidth) / widthpage);
                            let _elemHeight = Math.round(parseInt(currentData.pagesOPS[i].detector[j].height) * parseInt(_containerHeight) / heightpage);
                            let _elemTop = Math.round(parseInt(currentData.pagesOPS[i].detector[j].y) * parseInt(_containerHeight) / heightpage);
                            let _elemLeft = Math.round(parseInt(currentData.pagesOPS[i].detector[j].x) * parseInt(_contrainerWidth) / widthpage);

                            let arr = { x: Math.round(_elemLeft + _elemWidth / 2), y: Math.round(_elemTop + _elemHeight / 2) };
                            points.push(arr);
                        }
                    }

                    if (points.length > 0) {

                        //ctx.strokeStyle = "#FFA500";
                        ctx.lineWidth = 2;
                        ctx.beginPath();

                        for (let p in points) {

                            if (p == 0) {
                                ctx.moveTo(points[p].x, points[p].y);
                            }
                            else {
                                ctx.lineTo(points[p].x, points[p].y);
                            }
                        }

                        if (parseInt(points[points.length - 1].x) > parseInt(_contrainerWidth / 2)) {
                            ctx.lineTo(points[points.length - 1].x + 100, points[points.length - 1].y);
                            ctx.fillText(currentData.pagesOPS[i].alarmloop[al].name, points[points.length - 1].x + 15, points[points.length - 1].y - 5);
                        }
                        else {
                            ctx.lineTo(points[points.length - 1].x - 100, points[points.length - 1].y);
                            ctx.fillText(currentData.pagesOPS[i].alarmloop[al].name, points[points.length - 1].x - 100, points[points.length - 1].y - 5);
                        }
                        ctx.stroke();
                    }
                }
            }
        }
    }
}

function PointInPolygon(points, x, y) {
  //console.log('#PointInPolygon')
    let max_point = points.length - 1;
    let total_angle = GetAngle(points[max_point].x, points[max_point].y, x, y, points[0].x, points[0].y);
    for (let i = 0; i < max_point; i++) {
        total_angle += GetAngle(points[i].x, points[i].y, x, y, points[i + 1].x, points[i + 1].y);
    }

    return (Math.abs(total_angle) > 0.000001);
}

function GetAngle(Ax, Ay, Bx, By, Cx, Cy) {
  //console.log('#GetAngle')
    let dot_product = DotProduct(Ax, Ay, Bx, By, Cx, Cy);
    let cross_product = CrossProductLength(Ax, Ay, Bx, By, Cx, Cy);

    return Math.atan2(parseFloat(cross_product), parseFloat(dot_product));
}

function CrossProductLength(Ax, Ay, Bx, By, Cx, Cy) {
  //console.log('#CrossProductLength')
    let BAx = parseFloat(Ax) - parseFloat(Bx);
    let BAy = parseFloat(Ay) - parseFloat(By);
    let BCx = parseFloat(Cx) - parseFloat(Bx);
    let BCy = parseFloat(Cy) - parseFloat(By);

    let result = parseFloat(parseFloat(BAx * BCy) - parseFloat(BAy * BCx));

    return result;
}

function DotProduct(Ax, Ay, Bx, By, Cx, Cy) {
  //console.log('#DotProduct')
    let BAx = parseFloat(Ax) - parseFloat(Bx);
    let BAy = parseFloat(Ay) - parseFloat(By);
    let BCx = parseFloat(Cx) - parseFloat(Bx);
    let BCy = parseFloat(Cy) - parseFloat(By);

    let result = parseFloat(parseFloat(BAx * BCx) + parseFloat(BAy * BCy));

    return result;
}

function SetEventClicked() {
  //console.log('#SetEventClicked')

    let btnContainer = document.getElementById("MainContainer");
    let btns = btnContainer.getElementsByClassName("button");

    for (let i = 0; i < btns.length; i++) {
        if(btns[i].dataset.action=='1' && btns[i].dataset.property == 'unfix'){
            btns[i].addEventListener("mousedown", function () {
                btns[i].classList = 'button pressed'
                SendServer(this.id.toString() + '.01.00');
            })
            btns[i].addEventListener("mouseup", function () {
                setTimeout(() =>{
                    btns[i].classList = 'button'
                    SendServer(this.id.toString() + '.02.00');
                }, 100)
            })
            btns[i].addEventListener("touchstart", function () {
                btns[i].classList = 'button pressed'
                SendServer(this.id.toString() + '.01.00');
            })
            btns[i].addEventListener("touchend", function () {
                setTimeout(() =>{
                    btns[i].classList = 'button'
                    SendServer(this.id.toString() + '.02.00');
                }, 100)
            })
            
            /*btns[i].addEventListener("click", function () {
                ButtonClicked(this.id);
                if(btns[i].dataset.property == 'unfix'){
                    btns[i].classList = 'button pressed'
                    setTimeout(() => {
                        btns[i].classList = 'button'
                    }, 1000)
                }
            });*/
        }else{
            btns[i].addEventListener("click", function () {
                ButtonClicked(this.id);
                if(btns[i].dataset.property == 'unfix'){
                    btns[i].classList = 'button pressed'
                    setTimeout(() => {
                        btns[i].classList = 'button'
                    }, 1000)
                }
            });
            btns[i].addEventListener('touchend', () => {
                ButtonClicked(this.id);
                if(btns[i].dataset.property == 'unfix'){
                    btns[i].classList = 'button pressed'
                    setTimeout(() => {
                        btns[i].classList = 'button'
                    }, 1000)
                }
            },{ passive: false })
        }
        /*btns[i].addEventListener("click", function () {
            ButtonClicked(this.id);
            if(btns[i].dataset&&btns[i].dataset.property == 'unfix'){
                btns[i].classList = 'button pressed'
                setTimeout(() => {
                    btns[i].classList = 'button'
                }, 1000)
            }
        });*/


    }

    let sliders = btnContainer.getElementsByClassName("slider");
    for (let i = 0; i < sliders.length; i++) {
        sliders[i].addEventListener("click", function () {
            ChangedValue(this.id);
        });
    }
}

function SetClickElement() {
  console.log('#SetClickElement')
    let btnContainer = document.getElementById("Floors");
    let btns = btnContainer.getElementsByClassName("buttonFloor");
    if(deviceType==2)
    {
        sendMessage(':FF.FF.00.00.01.00;')
    }
    for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function () {

            let current = document.getElementsByClassName("activeC");

            if (current.length > 0) {
                current[0].className = current[0].className.replace(" activeC", "");
            }

            this.className += " activeC";
            console.log("Переключиться на этажный план " + this.textContent);
            DrawFigures(this.textContent);
        });
    }
    if (btns.length > 0) btns[0].click();

    let btnContainer1 = document.getElementById("Razdels");
    let btns1 = btnContainer1.getElementsByClassName("buttonOPS");

    for (let i = 0; i < btns1.length; i++) {
        btns1[i].addEventListener("click", function () {

            let _state = this.dataset.state;
            let _idButton = 'B' + this.id.substr(1, 10);

            if (_state == '01') {
                SendServer(_idButton + '.02.00');
                //console.log('was sent ' + _idButton + '.02.00');
            }
            else {
                SendServer(_idButton + '.01.00');
                //console.log('was sent ' + _idButton + ".01.00");
            }
            btns1[i].disabled = true
            setTimeout(() => {
                btns1[i].disabled = false
            }, 1000)
        });
    }
}

function BackAutomatica() {
  console.log('#BackAutomatica')
    console.log("Вернуться к управлению Автоматикой");
    //getFileConfig();
    StartControlPanel();
    
    createCameras();
}
