let datasets = []
import store from '../../store/index.ts'
const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem) {
    sum += tooltipItem.parsed.y;
  });
  return 'Sum: ' + sum;
};
export const zoomChartData = {
  langaugeData: null,
  type: "line",
  data: {
    datasets: datasets
  },
  options: {
    legend: {
      show: false
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.6,
    title: {
      display: true,
      text: 'График параметра '
    },
    scales: {
      xAxes: [{
        type: 'realtime',
        time: {
          unit: 'second',
          displayFormats: {
            year: 'YYYY',
            month: 'MM.YY',
            day: 'DD.MM.YY',
            hour: 'HH:mm',
            minute: 'HH:mm:ss',
            second: 'HH:mm:ss'
          }
        },
        setNewData: (data) => {
          datasets = []
          datasets.push({
            label: 'hehe',
            data: [],
            borderColor: '#76A5F5',
            borderWidth: 1,
            backgroundColor: 'rgba(118,165,245, 0.07)',
            key: 'hehe'
          })
          data.sensorDatas.forEach((chart, index) => {
            datasets[0].data.push({
              x: chart.date.substr(11, 8),
              y: parseFloat(chart.state)
            })
          })
          console.log(datasets)
        },
        realtime: {
          duration: 30000,
          refresh: 1000,
          delay: 1000,
          onRefresh: () => {
            const chartValue = store?.getters?.getChartOneLastValue
            try{
              if(!chartValue) {
                return
              }
              chartValue.forEach((chart, index) => {
                if(datasets.length == 0){
                  datasets.push({
                    label: chart.label,
                    data: [{
                      x: Date.now(),
                      y: parseFloat(chart.value.replace(',', '.')).toFixed(3)
                    }],
                    hidden: false,
                    borderColor: '#76A5F5',
                    borderWidth: 1,
                    backgroundColor: 'rgba(118,165,245, 0.07)',
                    key: chart.key,
                    objectName: chart.objectName,
                    objectSensor: chart.objectSensor,
                    descript: chart.descript
                  })
                }
                else if(datasets.filter(ds => ds.key == chart.key).length == 0){
                  datasets.push({
                    label: chart.label,
                    data: [{
                      x: Date.now(),
                      y: parseFloat(chart.value.replace(',', '.')).toFixed(3)
                    }],
                    hidden: false,
                    borderColor: '#76A5F5',
                    borderWidth: 1,
                    backgroundColor: 'rgba(118,165,245, 0.07)',
                    key: chart.key,
                    objectName: chart.objectName,
                    objectSensor: chart.objectSensor,
                    descript: chart.descript
                  })
                }
                else{
                  datasets.filter(ds => ds.key == chart.key)[0].data.push({
                    x: Date.now(),
                    y: parseFloat(chart.value.replace(',', '.')).toFixed(3)
                  })
                }
              })
            }catch(e){
              console.log(e)
            }
            //console.log(chartValue)
          },
          onRestart: () => {
            datasets.length = 0
          }
        },
        distribution: 'series',
        scaleLabel: {
          display: true,
          labelString: '',
          fontStyle: 'bold'
        },
        color: "white"
      }],
      yAxes: [{
        type: 'linear',
        display: true,
        scaleLabel: {
          display: true,
          labelString: '',
          fontStyle: 'bold'
        },
        ticks: {
          color: "white",
          suggestedMin: 0,
          suggestedMax: 35,
          beginAtZero: false
        }
      }]
    },
   /*tooltips: {
      mode: 'nearest',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: false
    },*/
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          return ''/*data['labels'][tooltipItem[0]['index']];*/
        },
        beforeLabel: function(tooltipItem, data) {
          console.log(data)
          const dt =  new Date(data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].x)
          return `${zoomChartData.langaugeData.Object}: ${data['datasets'][tooltipItem.datasetIndex]['objectName']}`
        },
        label: function(tooltipItem, data) {
          return `${zoomChartData.langaugeData.Sensor}: ${data['datasets'][tooltipItem.datasetIndex]['objectSensor']}`
        },
        afterLabel: function(tooltipItem, data) {
          const dt =  new Date(data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].x)
          const dat = `${dt.getFullYear()}-${dt.getMonth()+1 < 10 ? '0'+(dt.getMonth()+1): dt.getMonth()+1}-${dt.getMonth()+1 < 10 ? '0'+(dt.getDate()+1): dt.getDate()}`
          const time = `${dt.getHours() < 10 ? '0'+dt.getHours():dt.getHours()}:${dt.getMinutes() < 10 ? '0'+dt.getMinutes():dt.getMinutes()}:${dt.getSeconds() < 10 ? '0'+dt.getSeconds():dt.getSeconds()}`
          return `${zoomChartData.langaugeData.Date}: ${dat}
${zoomChartData.langaugeData.Time}: ${time}
${zoomChartData.langaugeData.State}: ${data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].y}${data['datasets'][tooltipItem.datasetIndex].descript}`
        },
      },
      backgroundColor: 'black',
      titleFontSize: 16,
      titleFontColor: 'white',
      bodyFontColor: 'white',
      bodyFontSize: 14,
      displayColors: false
    },
    pan: {
      enabled: true,
      mode: 'x',
      rangeMax: {
        x: 4000
      },
      rangeMin: {
        x: 0
      }
    },
    zoom: {
      enabled: true,
      mode: 'x',
      rangeMax: {
        x: 20000
      },
      rangeMin: {
        x: 1000
      }
    }
  }
};

export default zoomChartData;