<template>
  <div class="container-fix">
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}">
      {{$t('ConfigurationDownloading.ConfigurationDownloading')}}
    </div>
    <v-row :class="`${isMobileDisplay?'mt-6':'mt-16'}`">
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12" justify="center" align="center">
        <div v-if="getLoadDevices">
          <v-select
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
            outlined
            dark
            v-model="itemsDevice"
            :items="getLoadDevices"
            @change="getFCTypes"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :label="$t('ConfigurationDownloading.Objects')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          >
            <template v-slot:item="{ item }">
              <v-tooltip right nudge-right="16px">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{item.name}}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>{{item.connection ? $t('ConfigurationDownloading.Connected') : $t('ConfigurationDownloading.Disconnected')}}</span>
              </v-tooltip>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12" justify="center" align="center">
        <div v-if="activeId">
          <v-select
            :disabled="!connectedSelectedDevice"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
            outlined
            dark
            v-model="itemsFC"
            :items="getLoadFCTypes"
            @change="selectFCType"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :label="$t('ConfigurationDownloading.FileConfigTypes')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          >
            <template v-slot:item="{ item }">
              <v-tooltip right nudge-right="16px">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name.length > 20?`${item.name.slice(0, 17)}<br>${item.name.slice(17)}`:item.name">
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{item.creationDate.slice(0, 10)}}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="item.isBaseConfig" class="ma-1">
                      <v-btn
                        fab
                        dark
                        x-small
                        @click.stop="clickToolTip(item)"
                      >
                        <v-icon dark>
                          mdi-help
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <span>{{item.description}}</span>
              </v-tooltip>
            </template>
          </v-select>
        </div>
      </v-col> 
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12" justify="center" align="center">
        <div v-if="activeFCId">
          <v-btn
            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
            dark
            @click="dialogOpen = true, downloadingInProcess = true"
            :loading="downloadingInProcess"
            :disabled="downloadingInProcess || !connectedSelectedDevice"
          >
            {{$t('ConfigurationDownloading.Download')}}
          </v-btn>
        </div>
      </v-col>
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12" justify="center" align="center">
        <div v-if="activeFCId">
          <v-btn
            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
            dark
            :disabled="downloadingInProcess || !connectedSelectedDevice"
            @click="deleteDialogOpen = true"
          >
            {{$t('ConfigurationDownloading.Delete')}}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      :persistent="true"
      v-model="dialogOpen"
      max-width="320"
    >
      <v-card class="dv-dialog">
        <v-card-title class="text-h5 d-flex justify-center">
          {{$t('ConfigurationDownloading.AreYouSure')}}
        </v-card-title>

        <v-card-text class="dv-dialog text-center">
          <div>
            {{$t('ConfigurationDownloading.SubTextDownload')}}
          </div>
          <div>
            <v-checkbox
              :dark="true"
              color="white"
              class="fix-checkbox"
              v-model="isUploadConfig"
              :label="$t('ConfigurationDownloading.UploadConfig')"
            ></v-checkbox>
            <v-checkbox
              :dark="true"
              color="white"
              class="fix-checkbox"
              v-model="isUploadMusic"
              :label="$t('ConfigurationDownloading.UploadMusic')"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-center text-center">
          <v-row>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="dialogOpen = false, downloadingInProcess = false"
              >
                {{$t('ConfigurationDownloading.Disagree')}}
              </v-btn>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="clickAgree(activeId, activeFCId)"
                :loading="isAgreeLoading"
              >
                {{$t('ConfigurationDownloading.Agree')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :persistent="true"
      v-model="deleteDialogOpen"
      max-width="320"
    >
      <v-card class="dv-dialog">
        <v-card-title class="text-h5 d-flex justify-center">
          {{$t('ConfigurationDownloading.AreYouSure')}}
        </v-card-title>

        <v-card-text class="dv-dialog text-center">
          <div>
            {{$t('ConfigurationDownloading.SubTextDelete')}}
          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-center text-center">
          <v-row>
            <v-col class="col-6" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="deleteDialogOpen = false"
              >
                {{$t('ConfigurationDownloading.Disagree')}}
              </v-btn>
            </v-col>
            <v-col class="col-6" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="deleteConfiguration(activeId, activeFCId)"
              >
                {{$t('ConfigurationDownloading.Agree')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :persistent="true"
      v-model="infoDialogOpen"
      width="1000"
    >
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <v-card class="dv-dialog">
            <v-card-title class="text-h5 d-flex justify-center bg-color-dark">
              {{configDescription}}
            </v-card-title>
            <v-img
              width="900"
              :src="`${config}/${imageSource}`"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="1">
          <div class="d-flex justify-start">
            <v-btn
              fab
              dark
              x-small
              @click="infoDialogOpen = false"
            >
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"

export default Vue.extend({
  name: "ConfigurationDownloading",
  components: {
  },
  data: () => ({
    config: process.env.VUE_APP_ui_api_url,
    infoDialogOpen: false,
    deleteDialogOpen: false,
    isUploadConfig: false,
    isUploadMusic: false,
    isAgreeLoading: false,
    dialogOpen: false,
    downloadingInProcess: false,
    imageSource: "",
    configDescription: "",
    items: [],
    itemsFC: [],
    itemsDevice: [],
    activeId: false,
    activeFCId: false,
    connectedSelectedDevice: false
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'getLoadFCTypes', 'getSelectedDevice', 'isMobileDisplay'])
  },
  methods: {
    ...mapActions(['deleteConfig', 'getOwnerDevices', 'userDeviceDeleteCheck', 'userDeviceDelete', 'createFirmware', 'getConfigsAsync', 'bindDeviceWithConfig']),
    ...mapMutations(['selectDevice', 'clearConfigs', 'selectDeviceById']),
    clickToolTip(item){
      console.log(item)
      this.infoDialogOpen = true
      this.configDescription = item.description
      this.imageSource = item.imageSource
    },
    async getFCTypes(deviceId){
      console.log(deviceId)
      this.selectDeviceById(deviceId)
      this.itemsFC = []
      console.log(deviceId)
      console.log(this.getLoadDevices)
      const filteredConnectDevice = this.getLoadDevices.filter(device => device.id == deviceId && device.connection)
      console.log(filteredConnectDevice)
      if(filteredConnectDevice.length == 0){
        this.connectedSelectedDevice = false
        this.itemsFC = []
        return
      }
      this.connectedSelectedDevice = filteredConnectDevice[0].connection
      let result = await this.getConfigsAsync(filteredConnectDevice[0].serialNumber)
      console.log(result)
      this.activeId = deviceId
    },
    async selectFCType(fcId){
      this.activeFCId = fcId
      console.log(`FC Id Selected: ${this.activeFCId}`)
    },
    async clickAgree(activeId, activeFCId){
      this.isAgreeLoading = true
      try{
        const result = await this.bindDeviceWithConfig({
          deviceId: activeId,
          configId: activeFCId,
          needLoadToDevice: this.isUploadConfig,
          needLoadMusic: this.isUploadMusic
        })
        console.log(result)
        this.isAgreeLoading = false
        this.dialogOpen = false
        this.downloadingInProcess = false
      } catch(e){
        console.log(e)
        this.isAgreeLoading = false
        this.dialogOpen = false
        this.downloadingInProcess = false
      }
    },
    async deleteConfiguration(activeId, activeFCId){
      try{
        const result = await this.deleteConfig(activeFCId)
        console.log(result)
        this.deleteDialogOpen = false
        await this.getFCTypes(activeId)
      } catch(e){
        console.log(e)
        this.isAgreeLoading = false
        this.dialogOpen = false
        this.downloadingInProcess = false
      }
    }
  },
  async created(){
    await this.clearConfigs()
    await this.getOwnerDevices()
    const device = this.getSelectedDevice
    if(device !== null && device !== undefined && device.id){
      this.activeId = device.id
      this.itemsDevice = device.id
      await this.getFCTypes(device.id)
    }
  }
});
</script>
<style scoped>
.v-menu__content::-webkit-scrollbar {
  width: 5px;
}
.bg-color-dark{
  background-color: rgba(0,0,0,1) !important;
}
.v-dialog__content .v-dialog--active {
  background-color: rgba(0,0,0,0) !important;
}
.v-menu__content::-webkit-scrollbar-thumb{
  background-color: rgb(59, 59, 59);
  border-radius: 3px;
}
.dv-dialog{
  color: white !important;
}
.v-card {
  background-color: rgba(0,0,0,0) !important;
  border: 1px solid white !important;
}
.v-dialog--active{
  background-color: rgba(0,0,0,1) !important;
  overflow-y:hidden;
}
.dv-btn-micros{
  font-size: 12pt !important;
  width: 95pt !important;
  max-width: 100pt !important;
  height: 30pt !important;
  font-weight: 400 !important;
  color: white !important;
  border: 1px solid #fff;
  background-color: rgba(39, 39, 39, 0) !important;
  letter-spacing: normal !important;
}
.dv-btn-micros:hover{
  background-color: rgba(0, 0, 0, 1) !important;
}
.v-label:not([v-label--active]){
  margin-top: 5px !important;
}
.fix-checkbox{
  margin-top: 0 !important;
  height: 50px !important;
}
</style>