<template>
  <div ref="sssda">
    <div :style="`height:100px;`" @click="emitGlobalClickEvent">
      <div class="login-text"
        v-show="showAccount"
        :class="{'title':isMobileDisplay}"
        align="center">
        <span v-if="getSelectedDevice">{{getSelectedDevice.name}}</span> {{$t('Charts.Chart')}}
         {{$tc(`SensorNames.${filterState}`, getSelectedDevice.subDeviceType == 10 ? 2:1, {n: getHLStates(filterState)})}}
      </div>
      <div class="container-fix mt-2" v-show="showAccount">
        <v-row class="d-flex align-content-center justify-center" v-show="showAccount">
          <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <cube @controlButtonClick="controlButtonClick"/>
          </v-col>
          <v-col class="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 offset-xl-1 offset-lg-1">
            <div class="home">
              <Ch @openBigMap="chClick">
              </Ch>
            </div>
          </v-col>
        </v-row>
      </div>
      <YaMap :key="updateComponent"></YaMap>
      <Copyright></Copyright>
      <v-snackbar
        v-model="snackbar.active"
        :timeout="snackbar.timeout"
      >
        <div class="text-center">
          {{ snackbar.text }}
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import smoothHeight from 'vue-smooth-height'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
import Cube from "./Cube/Cube.vue"
import Ch from "./Chart/ch.vue"
import YaMap from "./Maps/YaMap.vue"
import Copyright from "./Copyright.vue"
export default Vue.extend({
  mixins:[smoothHeight],
  name: "Charts",
  components: {
    Copyright:Copyright,
    cube: Cube,
    Ch: Ch,
    YaMap: YaMap
  },
  data: () => ({
    showAccount: true,
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    },
    updateComponent: 0
  }),
  computed: {
    ...mapGetters(['getHLStates','filterState', 'getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay']),
  },
  methods: {
    ...mapActions(['getDevices', 'getCoordsDevices', 'devicesLoadOnlineStatus']),
    ...mapMutations(['selectDevice']),
    emitGlobalClickEvent(){
      //this.$root.$emit('closeBigMapGlobal')
    },
    clickAddDeviceMap(data){
      console.log(data)
    },
    chClick(){
      this.$root.$emit('openBigMapGlobal')
    }, 
    controlButtonClick(){
      if(this.getSelectedDevice && this.getSelectedDevice.connection){
        this.$router.push('control').catch(() => {})
      }
    },
  },
  async mounted(){
    console.error('showHiddenChart')
    this.$root.$on('showHiddenChart', () => {
      console.warn('showHiddenChart')
      this.showAccount = true
      if(this.$route.path == '/Charts'){
        window.scrollTo(0,0)
        console.log('window.scrollTo(0,0)')
      }
    })
    this.$root.$on('closeAccountGlobal', () => {
      this.showAccount = false
    })
    this.$root.$on('updateYaMapFilter', () => {
      this.updateComponent ++
    })
    /*this.$root.$on('closeBigMapGlobal', () => {
      console.warn('closeBigMapGlobal')
      this.showAccount = true
      if(this.$route.path == '/Charts'){
        window.scrollTo(0,0)
        console.log('window.scrollTo(0,0)')
      }
    })*/
    await this.getDevices()
  },
  beforeDestroy(){
    this.$root.$off('showHiddenChart')
    this.$root.$off('closeAccountGlobal')
    this.$root.$off('updateYaMapFilter')
  }
});
</script>
<styles scoped lang="sass">
.fix-maps
  margin-top: -20%
.login-text
  padding-top: 3vh
.v-card 
  background-color: rgba(0,0,0,0) !important
  border: 1px solid white !important
.fix-padding
  padding: 2px
</styles>