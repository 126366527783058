import axiosService from "../../services/axiosService";
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
const axios = axiosService.getInstance()
import axiosBase from 'axios'
import states from '../States/index'
export default {
  actions: {
    async StartMapConnection(context:any, data:any) {
      const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_ui_api_url}/map`, {
        accessTokenFactory: () => data.accessToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build()

      connection.start()
      .then(() => {})
      .catch((err:any) => {
        return console.error('err ' + err)
      })

      connection.on("OnConnected", (message:any) => {
        if(message){
          
          const dataS = JSON.parse(message).AllDeviceMapData
          console.error(dataS)
          //console.warn(dataS.filter((d:any)=> d.Id == 57))
          const filtered:any = []
          dataS.forEach((dt:any) => {
            //TODO id == 57 fix for HL!!!
            filtered.push({...dt,sensorTypes: dt.sensorTypes?.filter((s:any) => s.IsActive || dt.headType == 4 || dt.headType == 1)})
          })
          console.log(filtered)
          context.commit('setLastMapData', filtered)
          data.root.$emit('MapConnectionOnConnected', filtered)
        }
      })

      connection.on("MapDeviceUpdate", (message:any) => {
        if(message){
          const dataS = JSON.parse(message)
          console.error(dataS)
          const filtered:any = {...dataS,sensorTypes: dataS.sensorTypes.filter((s:any) => s.IsActive || dataS.headType == 4 || dataS.headType == 1)}

          console.log(filtered)
          context.commit('updateLastMapData', filtered)
          data.root.$emit('MapConnectionMapDeviceUpdate', filtered)
        }
      })

      connection.on("InstantDataUpdate", (message:any) => {
        if(message != undefined){
          data.root.$emit('InstantDataUpdateDeviceUpdate', JSON.parse(message))
          /*const filtered:any = {...dataS,sensorTypes: dataS.sensorTypes.filter((s:any) => s.IsActive || dataS.headType == 4 || dataS.headType == 1)}

          console.log(filtered)
          context.commit('updateLastMapData', filtered)
          data.root.$emit('MapConnectionMapDeviceUpdate', filtered)*/
        }
      })
      connection.onclose((e:any) => {})

      context.commit('setMapConnection', connection)
    },
    async StartStateConnection(context:any, data:any) {
      const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_ui_api_url}/state`, {
        accessTokenFactory: () => data.accessToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build()

      connection.start()
      .then(() => {})
      .catch((err:any) => {
        return console.error('err ' + err)
      })

      connection.on("ReceiveStatus", (message:any) => {
        if(message){
          data.root.$emit('StateConnectionReceiveStatus', message)
        }
      })
      
      connection.on("ReceiveSubscribeStatus", (message:any) => {
        if(message){
          data.root.$emit('StateConnectionReceiveSubscribeStatus', message)
        }
      })

      connection.on("ReceiveMessage", (message:any) => {
        if(message){
          data.root.$emit('StateConnectionReceiveMessage', message)
        }
      })

      connection.onclose((e:any) => {})

      context.commit('setStateConnection', connection)
    },
  },
  state: {
    mapConnection: null,
    stateConnection: null,
    lastMapData: null
  },
  mutations: {
    updateLastMapData(state:any, data:any){
      console.log(data)
      const clastMapDataIndex = state.lastMapData.findIndex((chart:any) => chart.Id == data.Id)
      console.log(clastMapDataIndex)
      if(clastMapDataIndex > 0) {
        state.lastMapData[clastMapDataIndex] = data
      }
      //state.lastMapData = JSON.parse(data).AllDeviceMapData
    },
    setLastMapData(state:any, data:any){
      state.lastMapData = data
    },
    setMapConnection(state:any, data:any){
      state.mapConnection = data
    },
    setStateConnection(state:any, data:any){
      state.stateConnection = data
    }
  },
  getters:{
    getLastMapData(state:any){
      return state.lastMapData
    },
		getMapConnection(state:any) {
      return state.mapConnection
		},
		getStateConnection(state:any) {
      return state.stateConnection
		}
  }
}