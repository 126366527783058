<template>
  <hello-world :devices="devices" :configs="configs"/>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";
import HelloWorld from "../components/HelloWorld.vue";

export default Vue.extend({
  name: "Home",
  data(){
    return {
      devices: null,
      configs: null
    }
  },
  components: {
    HelloWorld,
  },
  computed: {
    ...mapGetters(['devicesData'])
  },
  methods: {
    ...mapActions(['getDevicesAsync', 'getConfigsAsync']),
  },
  async created(){
    this.devices = await this.getDevicesAsync()
    this.configs = await this.getConfigsAsync()
    console.log(this.configs)
  }
});
</script>
