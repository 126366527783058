<template>
    <div>
      <v-row class="d-flex justify-center pl-2 pr-2 mt-3">
        <v-col class="col-xl-8 col-lg-8 col-md-10 col-sm-12" cols="12">
          <v-card dark>
            <v-card-text class="pa-6">
              <v-row class="no-gutters">
                <v-col class="col-12 text-center" cols="12">
                  <h2>OBJECT NAME</h2>
                </v-col>
                <v-col v-if="!isControlPage" cols="12">
                  <v-row>
                    <v-col class="col-6 text-center">
                      <h2 class="mt-8">OUTPUTS STATUS</h2>
                    </v-col>
                    <v-col class="col-6 text-center">
                      <h2 class="mt-8">INPUTS LEVELS</h2>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="mt-8">
                        <v-col class="col-6 d-flex justify-center">
                          <div
                            class="d-flex align-content-center flex-wrap justify-center dv-btn caption"
                            dark
                            disabled
                            :class="`${HLTunes.DOut1State==1?'white-button':'black-button'}`"
                          >
                            OUT 1
                          </div>
                        </v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`${HLTunes.Input1.name} ${HLTunes.Input1.state}${HLTunes.Input1.measure}`"
                            inverse-label
                            readonly
                            dark
                            step="0.1"
                            v-model="HLTunes.Input1.state"
                            :max="HLTunes.Input1.max"
                            :min="0"
                          ></v-slider>
                        </v-col>
                        <v-col class="col-6 d-flex justify-center">
                          <div
                            class="d-flex align-content-center flex-wrap justify-center dv-btn caption"
                            dark
                            disabled
                            :class="`${HLTunes.DOut2State==1?'white-button':'black-button'}`"
                          >
                            OUT 2
                          </div>
                        </v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`${HLTunes.Input2.name} ${HLTunes.Input2.state}${HLTunes.Input2.measure}`"
                            inverse-label
                            readonly
                            dark
                            step="0.1"
                            v-model="HLTunes.Input2.state"
                            :max="HLTunes.Input2.max"
                            :min="0"
                          ></v-slider>
                        </v-col>
                        <v-col class="col-6 d-flex justify-center">
                          <div
                            class="d-flex align-content-center flex-wrap justify-center dv-btn caption"
                            dark
                            disabled
                            :class="`${HLTunes.DOut3State==1?'white-button':'black-button'}`"
                          >
                            OUT 3
                          </div>
                        </v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`${HLTunes.Input3.name} ${HLTunes.Input3.state}${HLTunes.Input3.measure}`"
                            inverse-label
                            readonly
                            dark
                            step="0.1"
                            v-model="HLTunes.Input3.state"
                            :max="HLTunes.Input3.max"
                            :min="0"
                          ></v-slider>
                        </v-col>
                        <v-col class="col-6 d-flex justify-center">
                        </v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`${HLTunes.Temp.name} ${HLTunes.Temp.state}${HLTunes.Temp.measure}`"
                            inverse-label
                            readonly
                            dark
                            step="0.1"
                            :max="HLTunes.Temp.max"
                            :min="HLTunes.Temp.min"
                            v-model="HLTunes.Temp.state"
                          ></v-slider>
                        </v-col>
                        <v-col class="col-6 d-flex justify-center"></v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`X ${HLTunes.ADXL1.state}%`"
                            inverse-label
                            readonly
                            dark
                            v-model="HLTunes.ADXL1.state"
                            :max="HLTunes.ADXL1.max"
                            :min="HLTunes.ADXL1.min"
                          ></v-slider>
                        </v-col>
                        <v-col class="col-6 d-flex justify-center">
                          <v-btn class="dv-btn" @click="updateData">UPDATE</v-btn>
                        </v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`Y ${HLTunes.ADXL2.state}%`"
                            inverse-label
                            readonly
                            dark
                            v-model="HLTunes.ADXL2.state"
                            :max="HLTunes.ADXL2.max"
                            :min="HLTunes.ADXL2.min"
                          ></v-slider>
                        </v-col>
                        <v-col class="col-6 d-flex justify-center">
                          <v-btn class="dv-btn" @click="isControlPage = true">TUNES</v-btn>
                        </v-col>
                        <v-col class="col-6 d-flex align-content-center flex-wrap justify-center">
                          <v-slider
                            class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                            height="30"
                            :label="`Z ${HLTunes.ADXL3.state}%`"
                            inverse-label
                            readonly
                            dark
                            v-model="HLTunes.ADXL3.state"
                            :max="HLTunes.ADXL3.max"
                            :min="HLTunes.ADXL3.min"
                          ></v-slider>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else>
                  <v-row class="mt-6" no-gutters>
                    <v-col class="col-12 text-center" cols="12">
                      <h2>INSERT INPUTS LIMITS</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">{{HLTunes.Input1.name}}</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Input1.stateLimit"
                        :max="HLTunes.Input1.max"
                        :min="HLTunes.Input1.min"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.Input1.stateLimit}} {{HLTunes.Input1.measure}}</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">{{HLTunes.Input2.name}}</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Input2.stateLimit"
                        :max="HLTunes.Input2.max"
                        :min="HLTunes.Input2.min"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.Input2.stateLimit}} {{HLTunes.Input2.measure}}</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">{{HLTunes.Input3.name}}</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Input3.stateLimit"
                        :max="HLTunes.Input3.max"
                        :min="HLTunes.Input3.min"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.Input3.stateLimit}} {{HLTunes.Input3.measure}}</span>
                    </v-col>
                    
                    <v-col class="col-12 text-center" cols="12">
                      <h2>INSERT AXIS LIMITS</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">AXIS X</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.ADXL1.stateLimit"
                        :max="90"
                        :min="5"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.ADXL1.stateLimit}} %</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">AXIS Y</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.ADXL2.stateLimit"
                        :max="90"
                        :min="5"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.ADXL2.stateLimit}} %</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">AXIS Z</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.ADXL3.stateLimit"
                        :max="90"
                        :min="5"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.ADXL3.stateLimit}} %</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">{{HLTunes.Temp.name}}</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        step="0.1"
                        :max="HLTunes.Temp.max"
                        :min="HLTunes.Temp.min"
                        v-model="HLTunes.Temp.stateLimit"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.Temp.stateLimit}}{{HLTunes.Temp.measure}}</span>
                    </v-col>
                    
                    <v-col class="col-12 text-center" cols="12">
                      <h2>TIMING</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">SEND PERIOD</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Timers.SendPeriod.state"
                        :max="HLTunes.Timers.SendPeriod.max"
                        :min="HLTunes.Timers.SendPeriod.min"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.Timers.SendPeriod.state}} min</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">POLLING PERIOD</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Timers.PollingPeriod.state"
                        :max="HLTunes.Timers.PollingPeriod.max"
                        :min="HLTunes.Timers.PollingPeriod.min"
                      ></v-slider>
                      <span class="mt-3">{{HLTunes.Timers.PollingPeriod.state}} min</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">SIGNAL 1</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Timers.Alarm1.state"
                        :max="HLTunes.Timers.Alarm1.max"
                        :min="HLTunes.Timers.Alarm1.min"
                      ></v-slider>
                      <span class="mt-3">{{mathTime(HLTunes.Timers.Alarm1.state)}}</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <span class="mt-3">SIGNAL 2</span>
                      <v-slider
                        class="px-xl-12 px-lg-12 px-md-12 px-sm-8 px-xs-4"
                        height="30"
                        dark
                        v-model="HLTunes.Timers.Alarm2.state"
                        :max="HLTunes.Timers.Alarm2.max"
                        :min="HLTunes.Timers.Alarm2.min"
                      ></v-slider>
                      <span class="mt-3">{{mathTime(HLTunes.Timers.Alarm2.state)}}</span>
                    </v-col>
                    
                    <v-col class="col-6 d-flex justify-center px-6">
                      <v-btn class="dv-btn" @click="controlPage">CONTROL</v-btn>
                    </v-col>
                    <v-col class="col-6 d-flex justify-center px-6">
                      <v-btn class="dv-btn" @click="saveDataUpdatedPeriod">SAVE</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
  import dateFormat, { masks } from "dateformat"
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import Vue from "vue"
  const config = process.env.VUE_APP_ui_api_url
  export default Vue.extend({
    name: "Mercury236ControlPanel",
    data(){
      return{
        isControlPage: false,
        HLTunes: {
          Input1: {
            min: 200,
            max: 12000,
            state: 200,
            name: '',
            measure: '',
            stateLimit: 0,
            stateLimitServer: 0
          },
          Input2: {
            min: 200,
            max: 5000,
            state: 100,
            name: '',
            measure: '',
            stateLimit: 0,
            stateLimitServer: 0
          },
          Input3: {
            min: 200,
            max: 5000,
            state: 100,
            name: '',
            measure: '',
            stateLimit: 0,
            stateLimitServer: 0
          },
          DOut1State: 0,
          DOut2State: 0,
          DOut3State: 0,
          Temp: {
            min: -25.0,
            max: 125.0,
            state: 0,
            name: 'T',
            measure: '°C',
            stateLimit: 0,
            stateLimitServer: 0
          },
          ADXL1: {
            min: -90,
            max: 90,
            state: 0,
            stateLimit: 0,
            stateLimitServer: 0
          },
          ADXL2: {
            min: -90,
            max: 90,
            state: 0,
            stateLimit: 0,
            stateLimitServer: 0
          },
          ADXL3: {
            min: -90,
            max: 90,
            state: 0,
            stateLimit: 0,
            stateLimitServer: 0
          },
          Timers:{
            Alarm1:{
              state: 0,
              ServerData: 0,
              min: 0,
              max: 1439
            },
            Alarm2:{
              state: 0,
              ServerData: 0,
              min: 0,
              max: 1439
            },
            SendPeriod:{
              state: 0,
              ServerData: 0,
              min: 1,
              max: 360
            },
            PollingPeriod:{
              state: 0,
              ServerData: 0,
              min: 1,
              max: 360
            }
          }
        },
        connection: null
      }
    },
    watch: {
      '$i18n.locale': async function(newVal, oldVal) {
        //this.refreshData(this.metricsParse)
      }
    },
    computed: {
      ...mapGetters(['getSelectedDeviceSerialNumber', 'getAccessToken', 'getUser', 'getSelectedDevice', 'isMobile']),
      windowWidth(){
        return this.$vuetify.breakpoint.width
      },
      windowHeight(){
        return this.$vuetify.breakpoint.height
      }
    },
    methods:{
      ...mapActions(['getNewDataHeadLight', 'getHLNames', 'changeHLConfig', 'getLimitsHL']),
      async saveDataUpdatedPeriod(){
        try{
          await this.changeHLConfig({
            deviceId: this.getSelectedDevice.id,
            body: {
              Inp1Limit: this.HLTunes.Input1.stateLimit,
              Inp2Limit: this.HLTunes.Input2.stateLimit,
              Inp3Limit: this.HLTunes.Input3.stateLimit,
              Adxl1Limit: this.HLTunes.ADXL1.stateLimit,
              Adxl2Limit: this.HLTunes.ADXL2.stateLimit,
              Adxl3Limit: this.HLTunes.ADXL3.stateLimit,
              TempLimit: this.HLTunes.Temp.stateLimit,
              SendPeriod: this.HLTunes.Timers.SendPeriod.state,
              PollingPeriod: this.HLTunes.Timers.PollingPeriod.state,
              Alarm1: this.mathTime(this.HLTunes.Timers.Alarm1.state),
              Alarm2: this.mathTime(this.HLTunes.Timers.Alarm2.state)
            }
          })
          .then(result => {
            console.log(result)
            this.$root.$emit('globalError', this.$t('SnackBar.Success'))
          })
        }
        catch(e){
          this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          console.log(e)
        }
      },
      mathTime(data){
        const dt = new Date()
        dt.setTime(0)
        dt.setHours(0)
        dt.setTime(dt.getTime() + data*60000)
        return `${dt.getHours()<10 ? `0${dt.getHours()}`:dt.getHours()}.${dt.getMinutes()<10 ? `0${dt.getMinutes()}`:dt.getMinutes()}`
      },
      controlPage(){
        this.HLTunes.Timers.SendPeriod.state = this.HLTunes.Timers.SendPeriod.ServerData
        this.HLTunes.Timers.PollingPeriod.state = this.HLTunes.Timers.PollingPeriod.ServerData
        
        this.HLTunes.Timers.Alarm1.state = this.HLTunes.Timers.Alarm1.ServerData
        this.HLTunes.Timers.Alarm2.state = this.HLTunes.Timers.Alarm2.ServerData

        this.HLTunes.ADXL1.stateLimit = this.HLTunes.ADXL1.stateLimitServer
        this.HLTunes.ADXL2.stateLimit = this.HLTunes.ADXL2.stateLimitServer
        this.HLTunes.ADXL3.stateLimit = this.HLTunes.ADXL3.stateLimitServer

        this.HLTunes.Input1.stateLimit = this.HLTunes.Input1.stateLimitServer
        this.HLTunes.Input2.stateLimit = this.HLTunes.Input2.stateLimitServer
        this.HLTunes.Input3.stateLimit = this.HLTunes.Input3.stateLimitServer

        this.HLTunes.Temp.stateLimit = this.HLTunes.Temp.stateLimitServer
        this.isControlPage = false
      },
      receiveMessage(message){
        const parsedMessage = JSON.parse(message)

        this.HLTunes.Input1.state = parseInt(parsedMessage[1].replace(' ', '').split(':')[1])
        this.HLTunes.Input2.state = parseInt(parsedMessage[2].replace(' ', '').split(':')[1])
        this.HLTunes.Input3.state = parseInt(parsedMessage[3].replace(' ', '').split(':')[1])
        
        this.HLTunes.DOut1State = parseInt(parsedMessage[4].replace(' ', '').split(':')[1])
        this.HLTunes.DOut2State = parseInt(parsedMessage[5].replace(' ', '').split(':')[1])
        this.HLTunes.DOut3State = parseInt(parsedMessage[6].replace(' ', '').split(':')[1])

        this.HLTunes.Temp.state = parseFloat(parsedMessage[7].replace(' ', '').split(':')[1])
        
        this.HLTunes.ADXL1.state = parseInt(parsedMessage[8].replace(' ', '').split(':')[1])
        this.HLTunes.ADXL2.state = parseInt(parsedMessage[9].replace(' ', '').split(':')[1])
        this.HLTunes.ADXL3.state = parseInt(parsedMessage[10].replace(' ', '').split(':')[1])
        console.log(message)
      },
      async tryConnectToHub(){
        try {
          this.connection = new HubConnectionBuilder()
          .withUrl(`${config}/state`, {
            accessTokenFactory: () => this.getAccessToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build();
  
          this.connection.start()
          .then(() => {
            this.connection.invoke("StartGettingValuesDevice", {
              UserId: this.getUser.id,
              SerialNumber: this.getSelectedDeviceSerialNumber
            })
          })
          .catch((err) => {
            this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          })
          
          this.connection.on("ReceiveStatus", (message) => {})
          this.connection.on("ReceiveMessage", (message) => {
            this.receiveMessage(message)
          })
        }
        catch(e){
          this.$root.$emit('globalError', this.$t('SnackBar.ServerConnectionError'))
          console.log(e)
        }
      },
      async updateData(){
        await this.getNewDataHeadLight(this.getSelectedDevice.id)
        .then(result => {
          this.HLTunes.ADXL1.state = parseInt(result.headLightSensors.adxl1)
          this.HLTunes.ADXL2.state = parseInt(result.headLightSensors.adxl2)
          this.HLTunes.ADXL3.state = parseInt(result.headLightSensors.adxl3)
          this.HLTunes.Temp.state = parseFloat(result.headLightSensors.temperature)

          this.HLTunes.Input1.state = parseInt(result.headLightSensors.input1)
          this.HLTunes.Input2.state = parseInt(result.headLightSensors.input2)
          this.HLTunes.Input3.state = parseInt(result.headLightSensors.input3)
          
          this.HLTunes.DOut1State = parseInt(result.headLightSensors.out1)
          this.HLTunes.DOut2State = parseInt(result.headLightSensors.out2)
          this.HLTunes.DOut3State = parseInt(result.headLightSensors.out3)

          this.HLTunes.Timers.SendPeriod.ServerData = result.headLightTimers.sendPeriod
          this.HLTunes.Timers.SendPeriod.state = result.headLightTimers.sendPeriod
          this.HLTunes.Timers.PollingPeriod.ServerData = result.headLightTimers.pollingPeriod
          this.HLTunes.Timers.PollingPeriod.state = result.headLightTimers.pollingPeriod

          const hAlarm1 = parseInt(result.headLightTimers.alarm1.split('.')[0])
          const mAlarm1 = parseInt(result.headLightTimers.alarm1.split('.')[1])
          const dAlarm1 = new Date()
          dAlarm1.setTime(0)
          dAlarm1.setHours(hAlarm1+3)
          dAlarm1.setMinutes(mAlarm1)
          this.HLTunes.Timers.Alarm1.ServerData = dAlarm1.getTime()/60000

          const hAlarm2 = parseInt(result.headLightTimers.alarm2.split('.')[0])
          const mAlarm2 = parseInt(result.headLightTimers.alarm2.split('.')[1])
          const dAlarm2 = new Date()
          dAlarm2.setTime(0)
          dAlarm2.setHours(hAlarm2+3)
          dAlarm2.setMinutes(mAlarm2)
          this.HLTunes.Timers.Alarm2.ServerData = dAlarm2.getTime()/60000

          this.HLTunes.Timers.Alarm1.state = this.HLTunes.Timers.Alarm1.ServerData
          this.HLTunes.Timers.Alarm2.state = this.HLTunes.Timers.Alarm2.ServerData

          console.log(result)
          console.log(this.HLTunes)
        })
        .catch(err => {
          console.log(err)
        })

        await this.getHLNames(this.getSelectedDevice.id)
        .then(result => {
          console.log(result)
          this.HLTunes.Input1.name = result.inp1Name
          this.HLTunes.Input2.name = result.inp2Name
          this.HLTunes.Input3.name = result.inp3Name
          
          this.HLTunes.Input1.measure = result.inp1Measure
          this.HLTunes.Input2.measure = result.inp2Measure
          this.HLTunes.Input3.measure = result.inp3Measure
        })
        .catch(err => {
          console.log(err)
        })

        await this.getLimitsHL(this.getSelectedDevice.id)
        .then(result => {
          console.log(result)
          this.HLTunes.ADXL1.stateLimitServer = result.adxl1
          this.HLTunes.ADXL2.stateLimitServer = result.adxl2
          this.HLTunes.ADXL3.stateLimitServer = result.adxl3
          
          this.HLTunes.Input1.stateLimitServer = result.inp1
          this.HLTunes.Input2.stateLimitServer = result.inp2
          this.HLTunes.Input3.stateLimitServer = result.inp3

          this.HLTunes.Temp.stateLimitServer = parseFloat(result.temperature)

          this.HLTunes.ADXL1.stateLimit = this.HLTunes.ADXL1.stateLimitServer
          this.HLTunes.ADXL2.stateLimit = this.HLTunes.ADXL2.stateLimitServer
          this.HLTunes.ADXL3.stateLimit = this.HLTunes.ADXL3.stateLimitServer

          this.HLTunes.Input1.stateLimit = this.HLTunes.Input1.stateLimitServer
          this.HLTunes.Input2.stateLimit = this.HLTunes.Input2.stateLimitServer
          this.HLTunes.Input3.stateLimit = this.HLTunes.Input3.stateLimitServer

          this.HLTunes.Temp.stateLimit = this.HLTunes.Temp.stateLimitServer
        })
        .catch(err => {
          console.log(err)
        })
      }
    },
    async created(){
      console.log(this.getSelectedDevice.id)
      await this.updateData()
      await this.tryConnectToHub()
    },
    beforeDestroy(){
      if(this.connection) this.connection.stop()
    }
  })
  </script>
  <style scoped>
  .fix-color{
    color:#fff !important;
  }
  .black-button{
    color: #fff !important;
    background-color: rgba(39, 39, 39, 0) !important;
  }
  .white-button{
    background-color: rgba(240, 240, 240, 0.8) !important;
    color: #000 !important;
  
  }
  </style>