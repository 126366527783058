<template>
  <div class="container">
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}" >
      {{$t('OpenSensors.OpenSensors')}}
    </div>
    <v-row no-gutters :class="`${isMobileDisplay?'mt-6':'mt-16'}`">
      <v-col class="col-12 col-xl-2 col-md-3 col-sm-12">
        <div v-if="devicesData.data">
          <v-select
            :loading="devicesData.loading"
            :disabled="devicesData.disabled"
            class="mt-3"
            outlined
            dark
            v-model="devicesData.selected"
            :items="devicesData.data"
            @change="getSensors"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :class="`${isMobileDisplay?'dv-input-tablet':'dv-input'}`"
            :label="$t('OpenSensors.Objects')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-10" v-if="sensorsData.data.length > 0" >
      <v-col  class="col-12 col-xl-2 col-md-3 col-sm-12">
        <div>
          <v-select
            :loading="sensorsData.loading"
            :disabled="sensorsData.disabled"
            outlined
            dark
            v-model="sensorsData.selected"
            :items="sensorsData.data"
            @change="switchAvailableSensor"
            :hint="`${items.PanelElementName}, ${items.PanelElementId}`"
            item-text="PanelElementName"
            item-value="PanelElementId"
            multiple
            item-color="#000"
            :class="`${isMobileDisplay?'dv-input-tablet':'dv-input'}`"
            :label="$t('OpenSensors.OpenSensors')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index == 0" class="mr-2">{{ item.PanelElementName }}</span>
              <span
                v-if="index == 1"
                class="grey--text text-caption"
              >
                (+{{ sensorsData.selected.length - 1 }} others)
              </span>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col class="col-12 col-md-2 col-sm-12 ml-md-5 ml-sm-0 mt-10 mt-sm-10 mt-md-0">
        <v-btn v-if="sensorsData.data.length > 0" dark class="dv-btn" @click="saveAvailableSensor"
          :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}">
          {{$t('OpenSensors.Save')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue"
export default Vue.extend({
  name: "OpenSensors",
  data: () => ({
    activeId:null,
    items:[],
    devicesData:{
      loading: true,
      disabled: true,
      data: [],
      selected: null
    },
    sensorsData:{
      loading: true,
      disabled: true,
      data: [],
      selected: []
    },
    snackbar:{
      text: "",
      active: false,
      timeout: 6000
    }
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'isMobileDisplay'])
  },
  methods: {
    ...mapActions(['getDevices', 'getUsersByDevice', 'createUserByDevice', 'getDeviceConfig', 'getUserConfig', 'updateOpenSenors', 'getSenorsBySerialNumber']),
    async getUserDevices(){
      try{
        let result = await this.getDevices()
        this.devicesData.data = result.devices.filter(dev => dev.deviceOwnerId == this.getUser.id)
        this.devicesData.loading = false
        this.devicesData.disabled = false

        console.log(result)
      }
      catch(e){
        console.log(e)
      }
    },
    async getSensors(deviceId){
      try{
        console.log(deviceId)
        const res = await this.getSenorsBySerialNumber(deviceId)
        this.sensorsData.data = JSON.parse(res.result.description)
        this.sensorsData.loading = false
        this.sensorsData.disabled = false
        this.sensorsData.selected = []
        this.sensorsData.data.forEach(sensor => {
          if(sensor.Available == 2){
            this.sensorsData.selected.push(sensor.PanelElementId)
          }
        })
        //console.log(JSON.parse(res.result.description))
      }catch(e){
        this.sensorsData.data = []
        console.log(e)
      }
    },
    async switchAvailableSensor(){
      console.log(this.sensorsData.selected)
    },
    async saveAvailableSensor(){
      try{
        this.sensorsData.data.forEach(sensor => {
          let isCheck = false
          this.sensorsData.selected.forEach(panelElementId => {
            if(panelElementId == sensor.PanelElementId){
              sensor.Available = 2
              isCheck = true
            }
          })
          if(!isCheck){
            sensor.Available = 1
            isCheck = true
          }
        })
        console.log(JSON.stringify(this.sensorsData.data))
        const data = {
          Sensors: JSON.stringify(this.sensorsData.data),
          DeviceId: this.devicesData.selected
        }
        const res = await this.updateOpenSenors(data)
        console.log(res)
        this.snackbar.text = this.$t('SnackBar.Success')
        this.snackbar.active = true
        this.sensorsData.data = []
        this.sensorsData.selected = []
        this.devicesData.selected = null
      }catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
      }
    }
  },
  async created(){
    await this.getUserDevices()
  }
});
</script>
<style>
.v-list-item__content{
  color: rgba(255, 255, 255, 0.87) !important;
}
</style>