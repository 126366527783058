import StreamAPI from './AxiosStreamInstance'
import JSMpeg from "../../../Libs/jsmpeg.min.js"

class StreamService {
  streamList = []
  loadinginterval = null
  fatherWidth = null
  fatherHeight = null
  isMobileVersion = false
  canvasFather = null
  loadingHook = () => {}

  constructor(rtspUrls, canvasFather, pageId, isMobileVersion = false, loadingHook = null){
    if(loadingHook != null){
      this.loadingHook = loadingHook
      console.log(this.loadingHook)
    }
    this.isMobileVersion = isMobileVersion
    console.log(rtspUrls)
    this.canvasFather = canvasFather
    this.fatherWidth = canvasFather.offsetWidth
    this.fatherHeight = canvasFather.offsetHeight
    console.log(this.fatherWidth)
    console.log(this.fatherHeight)
    this.createCheckLoadStreamsInterval()

    const validRtspUrls = rtspUrls.filter(url => url.length > 0)
    this.destroy()
    this.showOverlay()
    this.openStreams(validRtspUrls, canvasFather, pageId)
  }

  createCheckLoadStreamsInterval(){
    if(this.loadinginterval){
      clearInterval(this.loadinginterval)
    }
    this.loadinginterval = setInterval(() => {
      this.checkLoadStreams()
    }, 1000)
  }

  async dropStreamUrls(){
    return await StreamAPI.post('/stream/drop')
  }

  async dropAllStreams(){
    const streamUrls = this.streamList.map(stream => stream.rtspUrl)
    if(streamUrls.length == 0) return;
    /*await this.dropStreamUrls()
    .then(req => {
      console.log(req)
    })
    .catch(e => {
      console.log(e)
    })*/
  }
  
  destroy(){
    this.dropAllStreams()
    this.streamList.forEach(stream => {
      stream.jsMpegInstance.stop()
      stream.jsMpegInstance.destroy()
      if(stream.canvasElement) stream.canvasElement.remove()
      //if(stream.divElement) stream.divElement.remove()
    })
    this.streamList = []
  }

  async restartStreamUrl(stream){
    return await StreamAPI.post('/stream/restart', {rtspUrl: stream.rtspUrl})
  }

  async restartStream(stream, index){
    console.error(stream)
    await this.restartStreamUrl(stream)
      .then(async res => {
        this.streamList[index].wsPort = res.data.wsPort
        this.streamList[index].wsUrl = res.data.wsUrl
        this.streamList[index].jsMpegInstance.stop()
        this.streamList[index].jsMpegInstance.destroy()
        const idCanvas = this.streamList[index].canvasElement.id
        const styleCanvas = this.streamList[index].canvasElement.style
        console.log(idCanvas)
        console.log(styleCanvas)

        const canvas = document.createElement('canvas')
        canvas.id = idCanvas
        canvas.style = styleCanvas
        canvas.style.left = '0'
        canvas.style.float = 'left'
        canvas.style.top = '0'
        canvas.style.visibility = 'hidden'
        canvas.style.position = 'relative'
        canvas.onclick = (event) => {
          this.setCanvasSize(stream.id)
        }
        if(index == 0){
          stream.fatherElement.insertBefore(canvas, this.streamList[index+1].canvasElement)
        } else if(index == 1){
          stream.fatherElement.insertBefore(canvas, this.streamList[index+1].canvasElement)
        } else if(index == 2){
          stream.fatherElement.insertBefore(canvas, this.streamList[index+1].canvasElement)
        } else if(index == 3){
          stream.fatherElement.appendChild(canvas)
        }

        this.streamList[index].canvasElement = canvas

        this.setCanvasSize(index, true)
        this.streamList[index].jsMpegInstance = this.createJSMPegInstance(res.data.wsUrl, this.streamList[index].canvasElement)
        console.warn(res)
      })
      .catch(e => console.error(e))
    
  }

  async checkLoadStreams(){
    const indexFocus = this.streamList.findIndex(stream => stream.isFocus)
    await this.streamList.forEach(async (stream, index) => {
      if(!stream.isSuccessLoading)
      if(stream?.jsMpegInstance?.video?.frameRate != undefined){
        stream.isSuccessLoading = true
        stream.isLoading = false
        
        //stream.divElement.style.visibility = 'hidden'
        //stream.divElement.style.position = 'absolute'
        this.hideOverlay()
        this.loadingHook()
        if(this.streamList.filter(st => st.isSuccessLoading).length == this.streamList.length){
          this.loadingHook(-1)
        }
        if(indexFocus == -1){
          stream.canvasElement.style.position = 'relative'
          stream.canvasElement.style.visibility = 'visible'
        }else if(indexFocus == index){
          stream.canvasElement.style.position = 'relative'
          stream.canvasElement.style.visibility = 'visible'
        }else{
          stream.canvasElement.style.position = 'relative'
          stream.canvasElement.style.visibility = 'hidden'
        }

        stream.streamFps = stream.jsMpegInstance.video.frameRate
      }else{
        stream.errors++
        if(stream.errors == 15){
          await this.restartStream(stream, index)
        }
        if(stream.errors == 40){
          await this.restartStream(stream, index)
        }
        if(stream.errors >= 60){
          stream.jsMpegInstance.stop()
          //stream.jsMpegInstance.destroy()
          this.loadingHook(-2)
          /*stream.divElement.classList = 'loading-error'
          stream.divElement.style.position = 'relative'
          stream.divElement.style.left = 0
          stream.divElement.style.top = 0*/

          //stream.divElement.style.visibility = 'visible'
        }
      }
    })
  }
  showOverlay(){
    this.canvasFather.id = 'overlay-boy'
  }
  hideOverlay(){
    this.canvasFather.id = ''
  }
  async openStreams(validRtspUrls, canvasFather, pageId){
    await this.getStreamUrl(validRtspUrls)
    .then(result => {
      result.data.streams.forEach(async (str, canvasId) => {
        const canvas = this.createCanvas(canvasId)
        //const div = this.createDiv(canvasId)
        const isFocus = false
        canvasFather.appendChild(canvas)
        //canvasFather.appendChild(div)
        const stream = {
          id: canvasId,
          wsUrl: str.wsUrl,
          wsPort: str.wsPort,
          rtspUrl: str.rtspUrl,
          canvasElement: canvas,
          divElement: null,
          pageId: pageId,
          errors: 0,
          recoveryAttempts: null,
          isFocus: isFocus,
          isLoading: true,
          isSuccessLoading: false,
          isDead: false,
          streamFps: null,
          jsMpegInstance: this.createJSMPegInstance(str.wsUrl, canvas),
          fatherElement: canvasFather
        }
        this.streamList.push(stream)
      })
      console.log(result.data.streams)
    })
    .catch(e => {
      console.log(e)
    })
  }

  createJSMPegInstance(streamWSUrl, canvas){
    if(streamWSUrl == undefined || streamWSUrl == null){
      console.error(`StreamUrlError ${streamWSUrl}`)
      return 
    }
    if(canvas == undefined || canvas == null){
      console.error(`CanvasError ${canvas}`)
      return 
    }
    return new JSMpeg.Player(streamWSUrl, {
      canvas,
      autoplay: true,
      audio: false
    })
  }

  async getStreamUrl(rtspUrls){
    return await StreamAPI.post('/stream/create', {rtspUrls: rtspUrls})
  }

  createCanvas(canvasId){
    const canvas = document.createElement('canvas')
    canvas.id = `jsmpeg-player${canvasId}`
    canvas.onclick = (event) => {
      this.setCanvasSize(canvasId)
    }
    this.setStartStyleCanvas(canvas.style, canvasId)
    return canvas
  }

  /*createDiv(divId){
    const div = document.createElement('div')
    div.id = `jsmpeg-player-div${divId}`
    div.classList = 'loading-image'
    div.onclick = (event) => {
      this.setCanvasSize(divId)
    }
    this.setStartStyleDiv(div.style, divId)
    return div
  }

  setStartStyleDiv(elementStyle, id){
    if(id == 0){
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = `${this.fatherWidth}px`
      elementStyle.height = `${this.fatherHeight}px`
      elementStyle.visibility = 'visible'
      elementStyle.position = 'relative'
    }else{
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = 0
      elementStyle.height = 0
      elementStyle.visibility = 'hidden'
      elementStyle.position = 'absolute'
    }
    elementStyle.float = 'left'
  }*/
  setStartStyleCanvas(elementStyle, id){
    if(id == -1){
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = `${this.fatherWidth-2}px`
      elementStyle.height = `${this.fatherHeight-2}px`
      elementStyle.visibility = 'hidden'
      elementStyle.position = 'relative'
    }else if(id == 0){
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = `${this.fatherWidth/2-2}px`
      elementStyle.height = `${this.fatherHeight/2-2}px`
      elementStyle.visibility = 'hidden'
      elementStyle.position = 'relative'
    }else if(id == 1){
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = `${this.fatherWidth/2-2}px`
      elementStyle.height = `${this.fatherHeight/2-2}px`
      elementStyle.visibility = 'hidden'
      elementStyle.position = 'relative'
    }else if(id == 2){
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = `${this.fatherWidth/2-2}px`
      elementStyle.height = `${this.fatherHeight/2-2}px`
      elementStyle.visibility = 'hidden'
      elementStyle.position = 'relative'
    }else if(id == 3){
      elementStyle.top = 0
      elementStyle.left = 0
      elementStyle.width = `${this.fatherWidth/2-2}px`
      elementStyle.height = `${this.fatherHeight/2-2}px`
      elementStyle.visibility = 'hidden'
      elementStyle.position = 'relative'
    }
    elementStyle.float = 'left'
  }

  setCanvasSize(canvasId, isNotClick = false){
    this.streamList.sort((a, b) => a.id-b.id)
    if(!isNotClick){
      this.streamList[canvasId].isFocus = !this.streamList[canvasId].isFocus
      this.streamList.filter((str, index) => index != canvasId).forEach(str => str.isFocus = false)
    }else{
      const indexFocus = this.streamList.findIndex(data => data.isFocus)
      if(indexFocus == -1){
        this.streamList[canvasId].canvasElement.style.visibility = 'hidden'
      }else if(indexFocus == canvasId){
        this.streamList[canvasId].canvasElement.style.visibility = 'visible'
      }
    }


    //console.warn(canvasId)
    //console.warn(this.streamList)

    let indexData = -1
    this.streamList.forEach((stream, index) => {
      if(stream.isFocus){
        indexData = index
      }
      if(index+1 == this.streamList.length){
        //console.error(indexData)
        this.reMountElements(this.streamList, indexData)
      }
    })
  }
  reMountElements(streams, indexFocus){
    if(indexFocus == -1){
      streams.forEach((stream, index) => {
        if(index === 0){
          stream.canvasElement.style.top = 0
          stream.canvasElement.style.left = 0
          //stream.divElement.style.top = 0
          //stream.divElement.style.left = 0
        }else if(index === 1){
          stream.canvasElement.style.top = 0
          //stream.canvasElement.style.left = `${this.fatherWidth/2-2}px`
          //stream.divElement.style.top = 0
          //stream.divElement.style.left = `${this.fatherWidth/2-2}px`
        }else if(index === 2){
         // stream.canvasElement.style.top = `${this.fatherHeight/2-2}px`
          stream.canvasElement.style.left = 0
          //stream.divElement.style.top = `${this.fatherHeight/2-2}px`
          //stream.divElement.style.left = 0
        }else if(index === 3){
          //stream.canvasElement.style.top = `${this.fatherHeight/2-2}px`
          //stream.canvasElement.style.left = `${this.fatherWidth/2-2}px`
          //stream.divElement.style.top = `${this.fatherHeight/2-2}px`
          //stream.divElement.style.left = `${this.fatherWidth/2-2}px`
        }

        stream.canvasElement.style.width = `${this.fatherWidth/2-2}px`
        stream.canvasElement.style.height = `${this.fatherHeight/2-2}px`
        //stream.divElement.style.width = `${this.fatherWidth/2-2}px`
        //stream.divElement.style.height = `${this.fatherHeight/2-2}px`

        stream.canvasElement.style.visibility = 'visible'
        
        stream.canvasElement.style.position = 'relative'

        /*if(stream.errors >= 20){
          stream.divElement.style.position = 'relative'
          stream.divElement.style.top = 0
          stream.divElement.style.left = 0
        }
        else stream.divElement.style.position = 'absolute'*/

        if(stream.isSuccessLoading){
          //stream.divElement.style.visibility = 'hidden'
          //stream.divElement.style.position = 'absolute'
        }else{
          //stream.divElement.style.visibility = 'visible'
          //stream.divElement.style.position = 'relative'

          stream.canvasElement.style.visibility = 'hidden'
          stream.canvasElement.style.position = 'relative'
        }
        //stream.divElement.style.position = 'relative'
      })
    }else{
      streams[indexFocus].canvasElement.style.top = 0
      streams[indexFocus].canvasElement.style.left = 0
      //streams[indexFocus].divElement.style.top = 0
      //streams[indexFocus].divElement.style.left = 0

      streams[indexFocus].canvasElement.style.width = `${this.fatherWidth-2}px`
      streams[indexFocus].canvasElement.style.height = `${this.fatherHeight-2}px`
      //streams[indexFocus].divElement.style.width = `${this.fatherWidth-2}px`
      //streams[indexFocus].divElement.style.height = `${this.fatherHeight-2}px`

      //streams[indexFocus].canvasElement.style.visibility = 'visible'
      if(streams[indexFocus].isSuccessLoading){
       // streams[indexFocus].divElement.style.visibility = 'hidden'
       // streams[indexFocus].divElement.style.position = 'absolute'
      }else{
        //streams[indexFocus].divElement.style.visibility = 'visible'
        //streams[indexFocus].divElement.style.position = 'relative'

        streams[indexFocus].canvasElement.style.visibility = 'hidden'
        streams[indexFocus].canvasElement.style.position = 'absolute'
      }
      
      //streams[indexFocus].canvasElement.style.position = 'relative'
      //streams[indexFocus].divElement.style.position = 'absolute'

      streams.filter(stream => stream.id != indexFocus).forEach(stream => {
        stream.canvasElement.style.top = 0
        stream.canvasElement.style.left = 0
        stream.canvasElement.style.width = 0
        stream.canvasElement.style.height = 0
        stream.canvasElement.style.visibility = 'hidden'
        stream.canvasElement.style.position = 'absolute'
        
        /*stream.divElement.style.top = 0
        stream.divElement.style.left = 0
        stream.divElement.style.width = 0
        stream.divElement.style.height = 0
        stream.divElement.style.visibility = 'hidden'
        stream.divElement.style.position = 'absolute'*/
      })
    }
  }
}
export default {
  StreamService
}