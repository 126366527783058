<template>
  <v-app>
    <v-app-bar app class="grad fix-size" @click="emitGlobalClickEvent">
      <v-row no-gutters>
        <v-col class="col-12 d-flex justify-space-between" cols="12">
          <div class="d-flex justify-start align-center">
            <div>
              <a class="d-flex align-center fix-link pr-3"
                :href="LANDING_URL"
              >
                <div
                  v-show="!isMobile"
                  ref="header"
                  class="brand-name cursor-pointer pr-3">
                  DIVISION
                </div>
                <v-img
                  alt="Vuetify Logo"
                  class="shrink cursor-pointer"
                  contain
                  src="./assets/division_logo.png"
                  transition="scale-transition"
                  min-width="42"
                  min-height="42"
                />
              </a>
            </div>
            <div v-if="getServerDateTime">
              <div class="say-hi" :class="`${isMobile? 'caption':''}`"><span v-show="windowWidth > 360">SRV.Time: </span>{{getFormatDate(getServerDateTime)}}</div>
            </div>
          </div>
          <!--<div v-if="$vuetify.breakpoint.width > 800">
            <div class="say-device text-center" :class="`${isMobile? 'title':''}`">{{`${checkCurrentRouteName('/ControlPanel')&&getSelectedDevice&&!isMobile ? getSelectedDevice.name : ''}`}}</div>
          </div> -->
          <div class="d-flex justify-end align-center">
            <div class="text-right pr-3" v-if="!checkCurrentRouteName('/Login') && getUser && getUser.firstName">
              <v-btn
                class="mr-2 fix-p" 
                v-if="LANGAUGES.length <= 2"
                dark
                icon
                @click="swithLangauge(LANGAUGES.filter(lang => lang != $i18n.locale)[0])"
              >
                <flag :iso="$i18n.locale"/>
              </v-btn>
              <v-menu v-else offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="fix-p"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <flag :iso="$i18n.locale"/>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="langauge in LANGAUGES.filter(lang => lang != $i18n.locale)" :key="langauge" class="fix-pad">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn
                        dark
                        icon
                        @click="swithLangauge(langauge)"
                      >
                        <flag :iso="langauge"/>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <span class="say-hi" :class="`${isMobile? 'caption':''}`" @click="goToAccount()? $router.push('Account').catch(() => {}) : ''">{{$t('NavBar.Hi')}}, {{getUser.firstName}}!</span>
            </div>
            <div class="text-right">
              <v-btn 
                v-if="!checkCurrentRouteName('/Login') && (!checkCurrentRouteName('/objectcreation') && getUser)"
                class="dv-btn-mini dv-head-btn"
                dark
                @click="logOut">
                {{$t('NavBar.LogOut')}}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="fixsa">
      <router-view />
    </v-main>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import dateFormat, { masks } from "dateformat";
import Vue from "vue"
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'
import axiosService from "./services/axiosService"
import storageService from "./services/storageService"
const config = require('../config')
export default Vue.extend({
  name: "App",
  data: () => ({
    LANDING_URL: process.env.VUE_APP_ui_landing_url,
    connection: null,
    LANGAUGES: config.LANGAUGES,
    checkCurrentRouteName(location) {
     // console.log(location + " " + this.$route.path)
      return this.$route.path == location
    },
    goToAccount(){
      if(this.$route.path != '/Account'  && this.$route.path != '/Login'){
        return true
      }
      return false
    },
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    }
  }),
  computed: {
    ...mapGetters(['getServerDateTime','getStateConnection','getAccessToken', 'getUser',
    'getSelectedDevice', 'isMobileDisplay', 'getMapConnection', 'isMobile']),
    windowWidth(){
      return this.$vuetify.breakpoint.width
    },
  },
  methods: {
    ...mapActions(['loginUserByToken','StartMapConnection','StartStateConnection', 'getDevices']),
    ...mapMutations(['saveAccessToken', 'resetState', 'setPointLastData', 'selectDeviceStatus',
    'setDeviceStateInfo','selectDeviceById', 'setDeviceStatesInfo', 'setDeviceConnectionState']),
    swithLangauge(langauge){
      this.$i18n.locale = langauge
      storageService.setLangauge(langauge)
    },
    getFormatDate(date){
      return dateFormat(date, "HH:MM:ss")
    },
    emitGlobalClickEvent(){
      console.log('closeBigMapGlobal')
      this.$root.$emit('closeBigMapGlobal')
    },
    logOut(){
      this.resetState()
      storageService.removeAll()
      this.$router.push('Login').catch(() => {})
    },
    connectStateHub(){
      console.log(this.getStateConnection)
      if(this.getStateConnection == null){
        this.StartStateConnection({
          accessToken: this.getAccessToken,
          root: this.$root
        })
      }
    },
    connectToMapHub(){
      console.log(this.getMapConnection)
      if(this.getMapConnection == null){
        this.StartMapConnection({
          accessToken: this.getAccessToken,
          root: this.$root
        })
        this.$root.$on('MapConnectionOnConnected', (message) => {
          this.buildMarkers(message)
        })
        this.$root.$on('MapConnectionMapDeviceUpdate', (message) => {
          console.log(message)
          this.setDeviceConnectionState(message)
          this.updateMarker(message)
        })
      }
    },
    buildMarkers(dataAll){
      this.setDeviceStatesInfo(dataAll)
      console.log(dataAll)
      dataAll.forEach((data, index) => {
        this.selectDeviceStatus({
          id: data.Id,
          status: data.headConnectedStatusCode == 1? false : true
        })
      })
    },
    updateMarker(data){
      this.setDeviceStateInfo(data)
      if(data.headType == 4){
        this.setPointLastData(data)
      }
      this.selectDeviceStatus({
        id: data.Id,
        status: data.headConnectedStatusCode == 1? false : true
      })
    }
  },
  async created() {
    this.$root.$on("ConnectToAllHubs", () => {
      this.connectToMapHub()
      this.connectStateHub()
    })
    this.$root.$on('globalError', (data) => {
      this.snackbar.active = true
      this.snackbar.text = data
    })
    if(storageService.getLangauge()){
      this.$i18n.locale = storageService.getLangauge()
    }
    if(axiosService.tryRestoreAccessToken()){
      try{
        const result = await this.loginUserByToken()
        this.saveAccessToken(storageService.getToken())
        if(result.result.code == 0){
          await this.getDevices()
          if(storageService.getTryLocation().includes('ControlPanel') && storageService.getSelectedDeviceId()){
            this.$router.push(`/ControlPanel?o=${storageService.getSelectedDeviceId()}`).catch(() => {})
          }else{
            this.$router.push(storageService.getTryLocation()).catch(() => {})
          }
        }else{
          storageService.removeAll()
          this.resetState()
          this.$router.push('Login').catch(() => {})
        }
      }catch(e){
        console.log(e)
        storageService.removeAll()
        this.resetState()
        this.$router.push('Login').catch(() => {})
      }
    }
  },
  beforeDestroy(){
    this.$root.$off("ConnectToAllHubs")
    if(this.connection != null) this.connection.stop()
  }
});
</script>

<style lang="sass">
@import "./styles/index.sass"
.k-pr
  height: 4px
.k-progress
  margin-right: 0 !important
  margin-bottom: 3px !important
.k-progress > .k-progress-outer
  margin-right: 0 !important
  padding-right: 0 !important
.loading-image
  background-image: url(https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif)
  background-color: black
  background-position: center
  background-size: 5%
  z-index: 9999
.loading-error
  background-color: black
  background-size: 5%  
  background-position: center
  z-index: 9999
.loading-error:after
  content: "Video stream is not available, please try again"
  color: white
  position: absolute
  width: 80%
  top: 40%
  left: 15%
.fix-pad
  padding: 0
.mobile-video .loading-error:after
  top: 20%
  font-size: 0.8em !important
.fix-p
  margin-top: -4px
.scroll-off
  overflow: hidden !important
.fixsa
  overflow-x: hidden !important
html
  overflow-x: hidden !important
.say-hi
  color: white
  font-size: 18px !important
  font-weight: 400 !important
  cursor: pointer

.say-device
  color: white
  font-size: 22pt !important
  font-weight: 400 !important

.cursor-pointer
  cursor: pointer
.v-toolbar__content
  padding: 4px 8px !important
.grad
  background-color: transparent
  background-image: linear-gradient(180deg, #666565 0%, #000 32%)
.brand-name
  font-size: 28px
  font-weight: 400
  line-height: 1.5
  font-family: "Montserrat", Sans-serif
  color: #ffffff
  border-top: 1px solid #ffffff
  border-bottom: 1px solid #ffffff

.theme--light.v-application
  background: rgba(0,0,0,0) !important
  color: #ffffff

.dv-head-btn
  font-size: 0.8em !important
  max-height: 43px !important

.fix-link
  text-decoration: none
.white 
  background-color: #fff
.yellow
  background-color: yellow
</style>
