import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()

export default {
  actions: {
    async refreshTokens(context:any, refreshTokenData:any) {
      return await axios.post(`/api/User/Refresh/${refreshTokenData}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('saveAccessToken', response.data.accessToken)
          context.commit('saveRefreshToken', response.data.refreshToken)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        throw err
      })
    },
  },
  state: {
    accessToken: null,
    refreshToken: null
  },
  mutations: {
    saveAccessToken(state:any, accessToken:any){
      state.accessToken = accessToken
    },
    saveRefreshToken(state:any, refreshToken:any){
      state.refreshToken = refreshToken
    }
  },
  getters:{
		getAccessToken(state:any) {
      return state.accessToken
		},
		getRefreshToken(state:any) {
      return state.refreshToken
		},
  }
}
