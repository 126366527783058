<template>
    <div class="container-fix">
      <div class="login-text" align="center" :class="{'title': isMobileDisplay}">
        Reconnect Device
      </div>
      <v-row no-gutters  class="d-flex align-start" :class="`${isMobileDisplay?'mt-6':'mt-16'}`">
        <v-col class="offset-lg-1 col-12 col-lg-6 col-xl-6 col-md-6 col-sm-12">
          <v-row no-gutters >
            <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <v-btn
                :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                dark
                @click="getUserDevices()"
                :loading="userDevicesLoading"
              >
                {{$t('ObjectDeletion.Objects')}}
              </v-btn>
            </v-col>
          </v-row>
          <div
            v-if="isDrop"
          >
            <v-row no-gutters v-for="device in getLoadDevices.filter(dev => dev.deviceOwnerId == getUser.id)" :key="device.id">
              <v-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <v-btn
                  dark
                  :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                  class="mt-3 text-center" 
                  @click="loadDeviceData(device)"
                >
                  <span v-if="device.name == null">
                    {{device.serialNumber}}
                  </span>
                  <span v-else>
                    {{device.name}}
                  </span>
                </v-btn>
              </v-col>
              <v-col class="mt-3 col-12" v-if="activeId == device.id">
                <v-card dark> 
                  <v-card-text>
                    <v-row no-gutters class="text-body-1">
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.MasterUserName')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.ownerName}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.ObjectTitle')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.name}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.PlaceAddress')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.locationName}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.Latitude">{{$t('Account.ObjectInfo.GNSS')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.Latitude">
                        {{$t('Account.ObjectInfo.Lat')}}: {{device.pointLastData.Latitude}} {{$t('Account.ObjectInfo.Lon')}}: {{device.pointLastData.Longitude}} {{$t('Account.ObjectInfo.Alt')}}: {{device.pointLastData.Altitude}}
                      </v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.PhoneNumber">{{$t('ObjectDeletion.ObjectInfo.DevicePhoneNumber')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.pointLastData && device.pointLastData.PhoneNumber">{{device.pointLastData.PhoneNumber}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.DeviceType')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.typeName}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.controller">{{$t('ObjectDeletion.ObjectInfo.TypeOfController')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.controller">{{device.controller.name}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.DateOfCommissioning')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{getFormatDate(new Date(device.dateManufacture))}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.DateOfLastConnection')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{getFormatDate(new Date(device.dateConnection))}}</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.utc">{{$t('Account.ObjectInfo.DateOfUtc')}}:</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center" v-if="device.utc">{{getUtcDate(device.utc)}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.serialNumber.length == 8 ? $t('ObjectDeletion.ObjectInfo.SerialNumber') : 'POINT IMEI:'}}</v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{device.serialNumber}}</v-col>
                      <v-col cols="4" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">{{$t('ObjectDeletion.ObjectInfo.Connection')}}: </v-col>
                      <v-col cols="8" class="fix-padding" :class="{'caption': isMobileDisplay}"  align-self="center">
                        {{device.connection ? $t('ObjectDeletion.ObjectInfo.Connected') : `${$t('ObjectDeletion.ObjectInfo.Disconnected')} (${getFormatDate(new Date(device.dateDisconnection))})`}}
                        <span v-if="device.connection  && device.pointLastData && device.pointLastData.SignalPower"> Signal Power: {{device.pointLastData.SignalPower}}</span>
                        <span v-if="device.headStatus && device.headStatus.code">
                          ({{device.headStatus.description}})
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            
            <v-row no-gutters >
              <v-col class="mt-16 mb-8 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <v-btn
                  :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                  dark
                  :disabled="!activeId"
                  @click="showDialog()"
                >
                  {{$t('ObjectDeletion.Delete')}}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
  
  
      <v-dialog
        v-model="dialog"
        max-width="320"
      >
        <v-card class="dv-dialog">
          <v-card-title class="text-h5 d-flex justify-center">
            {{$t('ObjectDeletion.AreYouSure')}}
          </v-card-title>
  
          <v-card-text class="dv-dialog d-flex justify-center">
            <div>
              {{$t('ObjectDeletion.SubText')}}
              <br/>
              {{getLoadDevices.length == 1? $t('ObjectDeletion.SubTextTwo'):''}}
            </div>
            <v-text-field
              v-if="step == 1"
              v-model="smsConfirm"
              ref="smsFocus"
              outlined
              :label="$t('ObjectDeletion.EnterSms')"
              required
              class="mt-6 fix-center" 
            ></v-text-field>
          </v-card-text>
  
          <v-card-actions class="d-flex justify-center text-center">
            <v-row>
              <v-col class="col-6 d-flex justify-center" cols="6">
                <v-btn
                  class="dv-btn-micros"
                  @click="dialog = false"
                >
                  {{$t('ObjectDeletion.Disagree')}}
                </v-btn>
              </v-col>
              <v-col class="col-6 d-flex justify-center" cols="6">
                <v-btn
                  class="dv-btn-micros"
                  @click="clickAgree()"
                  :loading="isAgreeLoading"
                >
                  {{$t('ObjectDeletion.Agree')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.active"
        :timeout="snackbar.timeout"
      >
        <div class="text-center">
          {{ snackbar.text }}
        </div>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  import dateFormat, { masks } from "dateformat";
  import {mapGetters, mapActions, mapMutations} from 'vuex'
  import Vue from "vue"
  import YaMap from "./Maps/YaMap.vue"
  import storageService from "../services/storageService"
  
  export default Vue.extend({
    name: "Account",
    components: {
      YaMap:YaMap
    },
    data: () => ({
      isAgreeLoading: false,
      smsConfirm: null,
      step: 0,
      dialog: false,
      userDevicesLoading: false,
      text: 'My timeout is set to 2000.',
      timeout: 2000,
      isDrop: false,
      isShowForm: false,
      activeId: 0,
      snackbar: {
        active: false,
        text: null,
        timeout: 6000
      }
    }),
    computed: {
      ...mapGetters(['isMobileDisplay', 'getUser', 'getLoadDevices', 'getSelectedDevice'])
    },
    methods: {
      ...mapActions(['getDevices', 'userDeviceDeleteCheck', 'userDeviceDelete']),
      ...mapMutations(['clearConfigs', 'resetState', 'selectDevice']),
      getFormatDate(date){
        return dateFormat(date, "dd.mm.yyyy HH:MM:ss")
      },
      getUtcDate(gmt){
        return dateFormat(new Date().setHours(new Date().getHours() + gmt), "UTC:dd.mm.yyyy HH:MM:ss")
      },
      async clickAgree(){
        if(this.step == 0){
          this.isAgreeLoading = true
          try{
            await this.userDeviceDelete({deviceId: this.activeId})
            this.snackbar.text = this.$t('SnackBar.SmsNotifySend')
            this.snackbar.active = true
            this.isAgreeLoading = false
            this.step = 1
            this.$nextTick(() => {
              this.$refs.smsFocus.focus()
            })
          }catch(e){
            console.log(e)
            if(!e.description){
              this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
            }else{
              this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
            }
            this.snackbar.active = true
            this.isAgreeLoading = false
            this.step = 0
          }
        } else if(this.step == 1){
          try{
            await this.userDeviceDeleteCheck({deviceId: this.activeId, code: this.smsConfirm})
            this.snackbar.text = this.$t('SnackBar.DeviceHasBeenSuccessDeleted')
            this.snackbar.active = true
            this.isAgreeLoading = false
            this.dialog = false
            this.step = 0
            this.activeId = 0
            this.isDrop = false
            this.smsConfirm = null
  
            let devices = await this.getDevices()
          }catch(e){
            console.log(e)
            if(e.code == 3001){
              this.resetState()
              storageService.removeAll()
              this.$router.push('Login').catch(() => {})
            }
            if(!e.description){
              this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
            }else{
              this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
            }
            this.snackbar.active = true
            this.isAgreeLoading = false
          }
        }
      },
      showDialog(){
        this.dialog = true
      },
      async loadDeviceData(device){
        if(this.activeId != device.id){
          this.activeId = device.id
          this.selectDevice(device.serialNumber)
          try{
            //await this.getFullInfoDevice(this.activeId)
          }catch(e){
            console.log(e)
            if(!e.description){
              this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
            }else{
              this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
            }
            this.snackbar.active = true
          }
        }else{
          this.activeId = 0
        }
      },
      async getUserDevices(){
        this.userDevicesLoading = true
        this.isDrop = !this.isDrop
        this.activeId = 0
        //if(!this.getLoadDevices){
          try{
            let result = await this.getDevices()
            console.log(result)
          }
          catch(e){
            console.log(e)
            if(!e.description){
              this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
            }else{
              this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
            }
            this.snackbar.active = true
          }
        //}
        this.userDevicesLoading = false
      }
    },
    async created(){
      this.clearConfigs()
      const device = this.getSelectedDevice
      if(device !== null && device !== undefined && device.id){
        await this.getUserDevices()
        await this.loadDeviceData(device)
      }
    }
  });
  </script>
  <style>
  .dv-dialog{
    color: white !important;
  }
  .v-card {
    background-color: rgba(0,0,0,0) !important;
    border: 1px solid white !important;
  }
  .v-dialog--active{
    background-color: rgba(0,0,0,1) !important;
  }
  </style>
  