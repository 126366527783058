const SensorType = require('../../Enums/SensorType')
import states from '../States/index'
import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()
import storageService from "../../services/storageService";

export default {
  actions: {
  },
  state: {
    isSimpleMode: false,
    simpleFilterMode: 0
  },
  mutations: {
    switchSimpleMode(state:any, isSimple:Boolean){
      state.isSimpleMode = isSimple
    },
    setSimpleFilter(state:any, filterCode:any){
      console.log(`filterCode: ${filterCode}`)
      state.simpleFilterMode = filterCode
      console.log(SensorType[filterCode])

      storageService.setFilter(SensorType[filterCode])
      states.state.filterState = SensorType[filterCode]
      states.state.filterStateCode = filterCode
    }
  },
  getters:{
    getMode(state:any){
      return state.isSimpleMode
    },
    getSimpleFilter(state:any){
      return state.simpleFilterMode
    },
  }
}
