import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()
const axiosBase = axiosService.getBaseInstance()
export default {
  actions: {
    async getLimitsHL(context:any, deviceId: any) {
      return await axios.get(`/api/Head/GetLimitsHL/${deviceId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async changeHLConfig(context:any, data: any) {
      return await axios.post(`/api/Head/ChangeHLConfig/${data.deviceId}`, data.body)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getHLNames(context:any, deviceId: any) {
      return await axios.get(`/api/Head/GetHLNames/${deviceId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getNewDataHeadLight(context:any, deviceId: any) {
      return await axios.get(`/api/Head/GetHLData/${deviceId}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async loadYaSuggest(context:any, data:any) {
      return await axiosBase.get(`https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${data.apiKey}&suggest_apikey=${data.suggest_apikey}&load=SuggestView`)
      .then((response:any) => {
        return response
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async deviceTypeCreate(context:any, data:any) {
      return await axios.post(`/api/Head/CreateDeviceType`, data)
      .then((response:any) => {
        //context.dispatch('getControllers')
        if(response.data.result.code == 0){
          context.dispatch('getDeviceTypes')
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getDeviceTypes(context:any) {
      return await axios.get(`/api/Head/GetDeviceTypes`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('loadControllers', response.data.controllers)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    }
  },
  state: {
    deviceTypes: []
  },
  mutations: {
    loadControllers(state:any, deviceTypes:any){
      state.deviceTypes = deviceTypes
    }
  },
  getters:{
		deviceTypes(state:any) {
      return state.deviceTypes
		}
  }
}