<template>
  <div class="container">
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}">
      {{$t('AddUsersToAccount.AddUsersToAccount')}}
    </div>
    <v-row align="center" :class="`${isMobileDisplay?'mt-6':'mt-16'}`">
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12" justify="center" align="center">
        <v-btn dark class="mt-5" :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`">
          {{getUser.firstName}}
        </v-btn>
        <div v-if="getLoadDevices" class="mt-3">
          <v-select
            :loading="userDevicesLoading"
            :disabled="userDevicesLoading"
            outlined
            dark
            v-model="items"
            :items="getLoadDevices"
            @change="getUsersDevice"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
            :label="$t('AddUsersToAccount.Objects')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="activeId != null && activeId > 0" :class="`${isMobileDisplay?'mt-6':'mt-12'}`">
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12" justify="center" align="center">
        <v-select
          v-if="fullDeviceInfo"
          :loading="userDevicesLoading"
          :disabled="userDevicesLoading"
          outlined
          dark
          v-model="itemsUsers"
          :items="fullDeviceInfo.users"
          @change="getUserInfo"
          :hint="`${items.firstName}, ${items.id}`"
          item-text="firstName"
          item-value="id"
          item-color="#000"
          :label="$t('AddUsersToAccount.Users')"
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
        ></v-select>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12 d-flex align-content-center justify-center">
        <v-btn v-if="fullDeviceInfo" dark :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`" @click="addUserToDevice" :disabled="isDeleteUserToDeviceFormOpen">
          {{$t('AddUsersToAccount.Add')}}
        </v-btn>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12 d-flex align-content-center justify-center">
        <v-btn dark
          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
          @click="isDeleteUserToDeviceFormOpen = true"
          v-if="selectedUserId"
          :loading="dialog"
          :disabled="isDeleteUserToDeviceFormOpen">
          {{$t('AddUsersToAccount.PrepareForDeletion')}}
        </v-btn>
      </v-col>
    </v-row>

    <!--Add user to object Start-->
    <v-row v-if="isAddUserToDeviceFormOpen && FKconfig" :class="`${isMobileDisplay?'mt-6':'mt-12'}`">
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12" justify="center" align="center">
        <v-text-field
          v-model="userName"
          outlined
          :label="$t('AddUsersToAccount.EnterUserName')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
        ></v-text-field>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12 d-flex justify-center">
        <phoneInput :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`" class="w-100" @phone="updatePhone"/>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12" justify="center" align="center">
        <v-select
          :loading="FKconfig == null"
          :disabled="FKconfig == null"
          outlined
          dark
          v-model="itemsFCTypes"
          :items="FKconfig.pages"
          @change="check"
          :hint="`${items.name_page}, ${items.id}`"
          item-text="name_page"
          item-value="id"
          multiple
          item-color="#000"
          :label="$t('AddUsersToAccount.FileConfigType')"
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
        >
          <template v-slot:selection="{ item, index }">
            <v-row no-gutters v-if="index == 0" class="d-flex align-center">
              <v-col cols="10" v-if="itemsFCTypes.length > 1">
                <span>{{ item.name_page }}</span>
              </v-col>
              <v-col cols="12" v-else>
                <span>{{ item.name_page }}</span>
              </v-col>
              <v-col cols="2">
                <span v-if="itemsFCTypes.length > 1">+{{ itemsFCTypes.length - 1 }}</span>
              </v-col>
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12">
        <div>
          <v-btn
            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
            v-if="activeId != null && activeId > 0"
            dark
            @click="createUser"
            :disabled="userName == null || phone == null || itemsFCTypes == null || userName.length < 2 || itemsFCTypes.length == 0"
            :loading="createUserLoading"
          >
            {{$t('AddUsersToAccount.Create')}}
          </v-btn>
        </div>
        <div>
          <v-btn
            :class="`${isMobileDisplay?'mt-6 dv-btn-tablet caption':'dv-btn mt-12'}`"
            dark
            @click="cancelCreateUser"
            :loading="createUserLoading"
          >
            {{$t('AddUsersToAccount.Cancel')}}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!--Add user to object End-->
    
    <!--Show delete user info Start-->
    <v-row v-if="isDeleteUserToDeviceFormOpen" :class="`${isMobileDisplay?'mt-6':'mt-12'}`">
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12" justify="center" align="center">
        <v-text-field
          v-model="userName"
          outlined
          disabled
          :label="$t('AddUsersToAccount.UserName')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
        ></v-text-field>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12 d-flex justify-center">
        <v-text-field
          v-model="phone"
          outlined
          disabled
          :label="$t('AddUsersToAccount.PhoneNumber')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
        ></v-text-field>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12" justify="center" align="center">
        <v-select
          :loading="FKconfig == null"
          :disabled="FKconfig == null"
          outlined
          dark
          v-model="itemsFCTypes"
          :items="FKconfig.pages"
          @change="check"
          :hint="`${items.name_page}, ${items.id}`"
          item-text="name_page"
          item-value="id"
          multiple
          item-color="#000"
          :label="$t('AddUsersToAccount.FileConfigType')"
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          class="no-input-v-select"
          :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
        >
          <template v-slot:selection="{ item, index }">
            <v-row no-gutters v-if="index == 0" class="d-flex align-center">
              <v-col cols="10" v-if="itemsFCTypes.length > 1">
                <span>{{ item.name_page }}</span>
              </v-col>
              <v-col cols="12" v-else>
                <span>{{ item.name_page }}</span>
              </v-col>
              <v-col cols="2">
                <span v-if="itemsFCTypes.length > 1">+{{ itemsFCTypes.length - 1 }}</span>
              </v-col>
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-12">
        <div>
          <v-btn dark
            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
            @click="deleteUser"
            v-if="selectedUserId"
            :loading="dialog"
            :disabled="dialog">
            {{$t('AddUsersToAccount.Delete')}}
          </v-btn>
        </div>
        <div>
          <v-btn
            :class="`${isMobileDisplay?'mt-6 dv-btn-tablet caption':'dv-btn mt-12'}`"
            dark
            @click="cancelDeleteUser"
            :loading="deleteUserLoading"
          >
            {{$t('AddUsersToAccount.Cancel')}}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!--Show delete user info End-->

    <!--User delete dialog Start-->
    <v-dialog
      v-model="dialog"
      max-width="320"
    >
      <v-card class="dv-dialog">
        <v-card-title class="text-h5 d-flex justify-center">
          {{$t('AddUsersToAccount.AreYouSure')}}
        </v-card-title>

        <v-card-text  class="dv-dialog d-flex justify-center">
          <div>
            {{$t('AddUsersToAccount.SubText')}}
          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-center text-center">
          <v-row>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="dialog = false"
              >
                {{$t('ObjectDeletion.Disagree')}}
              </v-btn>
            </v-col>
            <v-col class="col-6 d-flex justify-center" cols="6">
              <v-btn
                class="dv-btn-micros"
                @click="clickAgree()"
                :loading="isAgreeLoading"
              >
                {{$t('ObjectDeletion.Agree')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--User delete dialog End-->

    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import PhoneInput from '../components/PhoneInput.vue'
import Vue from "vue"
export default Vue.extend({
  name: "Account",
  components: {
    phoneInput:PhoneInput
  },
  data: () => ({
    userEmail: null,
    isAgreeLoading: false,
    dialog: false,
    selectedUserId: null,
    createUserLoading: false,
    itemsUsers: [],
    itemsFCTypes:[],
    items:[],
    selectedDevice: null,
    fcTypeLoading: false,
    userDevicesLoading: false,
    usersLoading: false,
    isDrop: false,
    activeId: 0,
    snackbar:{
      text: "",
      active: false,
      timeout: 6000
    },
    userName: null,
    phone: '',
    isAddUserToDeviceFormOpen: false,
    isDeleteUserToDeviceFormOpen: false,
    FKconfig: null,
    fullDeviceInfo: null,
    deleteUserLoading: false
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay'])
  },
  async created(){
    console.log(this.items)
    await this.getUserDevices()

    const device = this.getSelectedDevice
    if(device !== null && device !== undefined && device.id){
      await this.getUsersDevice(device.id)
      this.items = this.activeId
    }
  },
  methods: {
    ...mapActions(['getDevices', 'getUsersByDevice', 'createUserByDevice', 'getDeviceConfig', 'getUserConfig', 'deleteUserConfig']),
    ...mapMutations(['selectDeviceById']),
    async clickAgree(){
      this.isAgreeLoading = false
      this.dialog = false
      let result = await this.deleteUserConfig({userId: this.selectedUserId, serialNumber: this.fullDeviceInfo.serialNumber })
      await this.getUsersDevice(this.activeId)
      this.selectedUserId = null
      this.cancelDeleteUser()
      console.log(result)
      this.FKconfig = {
        pages: []
      }
    },
    updatePhone(phone){
      console.log(phone)
      if(phone.isValidByLibPhoneNumberJs){
        this.phone = phone.number
      }else{
        this.phone = null
      }
    },
    check(data){
      console.log(this.itemsFCTypes)
    },
    cancelDeleteUser(){
      this.itemsUsers = []
      this.itemsFCTypes = []
      this.isDeleteUserToDeviceFormOpen = false
      this.FKconfig = {
        pages: []
      }
      this.userName = null
      this.phone = null
      this.selectedUserId = 0
    },
    cancelCreateUser(){
      this.itemsUsers = []
      this.itemsFCTypes = []
      this.isAddUserToDeviceFormOpen = false
      this.FKconfig.pages = {
        pages: []
      }
      this.userName = null
      this.phone = null
    },
    updateFKId(id){
      console.log(id)
    },
    async deleteUser(userId){
      this.dialog = true
    },
    async getUserInfo(userId){
      try{
        this.itemsFCTypes = []
        this.isAddUserToDeviceFormOpen = false
        this.FKconfig = {
          pages: []
        }
        this.userName = null
        this.phone = null
        this.selectedUserId = userId
        console.log('getUserInfo ' + userId)
        
        /*const res = await this.getUserConfig({
          userId: userId,
          serialNumber: this.fullDeviceInfo.serialNumber
        })
        console.log(res)*/
        await this.getUserConfig({
          userId: userId,
          serialNumber: this.fullDeviceInfo.serialNumber
        })
        .then(result => {
          this.FKconfig = JSON.parse(result.config)

          this.itemsFCTypes = []
          if(this.FKconfig.pagesOPS.length > 0){
            this.FKconfig.pages.unshift({
              name_page: 'Разрешить OPS',
              number_page: "01"
            })
          }
          if(this.FKconfig.razdel.length > 0){
            this.FKconfig.pages.unshift({
              name_page: 'Разрешить оповещения',
              number_page: "02"
            })
          }
          if(result == null){
            this.snackbar.text = "Device Config Not Found"
            this.snackbar.active = true
          }
          this.FKconfig.pages.forEach(page => {
            this.itemsFCTypes.push(page.name_page)
          })
          this.FKconfig.pages = this.FKconfig.pages.map(data => {
            return {...data, disbled: true}
          })
          console.log(this.FKconfig.pages)
          //this.isDeleteUserToDeviceFormOpen = true
          this.selectedUserId = userId
          console.log(this.fullDeviceInfo.users.filter(user => user.id == userId))
          const findUser = this.fullDeviceInfo.users.filter(user => user.id == userId)[0]
          if(findUser){
            this.userName = findUser.firstName
            this.phone = findUser.phone
          }
        })
        
        //this.FKconfig = JSON.parse()
      }catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
      }
    },
    async createUser(){
      try{
        this.createUserLoading = true
        let isRazdel = false
        let isPagesOPS = false
        let activePagesId = []
        const pages = this.FKconfig.pages.filter(page => {
          return this.itemsFCTypes.filter(element => {
            if(page.name_page == element && page.name_page == 'Разрешить оповещения'){
              isRazdel = true
            }
            else if(page.name_page == element && page.name_page == 'Разрешить OPS'){
              isPagesOPS = true
            }
            else
            {
              if(page.name_page == element){
                activePagesId.push(page.number_page.substring(0, 2))
              }
              return page.name_page == element
            }
          }).length > 0
        })

        /* Disable unactive links to others pages */
        let newPages = []
        pages.forEach(page => {
          newPages.push({
            elements:[],
            name_page: page.name_page,
            number_page: page.number_page
          })
          page.elements.forEach(element => {
            let splElements = element.id.split('.')
            if(splElements[2] != '00'){
              let isFindElement = false
              activePagesId.forEach(elementId => {
                if(splElements[2] == elementId){
                  isFindElement = true
                }
              })
              if(isFindElement){
                newPages[newPages.length-1].elements.push(element)
              }
            }else{
              newPages[newPages.length-1].elements.push(element)
            }
            /*if(activePagesId.filter(elementId => elementId == element.id.substring(6, 2)).length == 0){
              console.log(elementId)
              console.log(element.id.split('.'))
              element.action = 100
            }*/
          })
        })
        let userFKConfig = {
          password: this.FKconfig.password,
          serial_number: this.FKconfig.serial_number,
          pages: newPages,
          razdel: isRazdel? this.FKconfig.razdel : [],
          pagesOPS: isPagesOPS? this.FKconfig.pagesOPS : []
        }
        let data = {
          UserName: this.userName,
          UserPhone: this.phone,
          DeviceId: this.fullDeviceInfo.id,
          Email: this.userEmail,
          UserFKConfig: JSON.stringify(userFKConfig),
          OwnerId: 0
        }
        /*console.log(data)
        if(this.userName == null || this.userName.length < 2){
          this.snackbar.text = "Invalid UserName"
          this.snackbar.active = true
          this.createUserLoading = false
          return
        }
        if(this.phone == null || this.phone.length < 2){
          this.snackbar.text = "Invalid phone"
          this.snackbar.active = true
          this.createUserLoading = false
          return
        }*/
        console.log(data)
        let result = await this.createUserByDevice(data)
        console.log(result)
        this.snackbar.text = this.$t('SnackBar.SuccessUserCreated')
        this.snackbar.active = true
        this.createUserLoading = false
        await this.getUsersDevice(this.activeId)
        this.isAddUserToDeviceFormOpen = false
      }
      catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
        this.createUserLoading = false
      }
    },
    selectedObject(deviceId){
      console.log(deviceId)
    },
    getFCTypes(){
      console.log('FC Click')
    },
    async getUserDevices(){
      this.userDevicesLoading = true
      this.isDrop = !this.isDrop
      this.activeId = 0
      try{
        let result = await this.getDevices()
        console.log(result)
        //this.snackbar.text = "Success"
        //this.snackbar.active = true
      }
      catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
      }
      this.isAddUserToDeviceFormOpen = false
      this.userDevicesLoading = false
    },
    async addUserToDevice(){
      this.itemsUsers = []
      this.selectedUserId = null
      this.isAddUserToDeviceFormOpen = true
      console.log(this.fullDeviceInfo.serialNumber)
      try{
        let result = await this.getDeviceConfig(this.fullDeviceInfo.serialNumber)
        this.FKconfig = result.config
        console.log(this.FKconfig)

        if(this.FKconfig.pagesOPS && this.FKconfig.pagesOPS.length > 0){
          this.FKconfig.pages.unshift({
            name_page: 'Разрешить OPS',
            number_page: "01"
          })
        }
        if(this.FKconfig.razdel && this.FKconfig.razdel.length > 0){
          this.FKconfig.pages.unshift({
            name_page: 'Разрешить оповещения',
            number_page: "02"
          })
        }

        console.log(this.FKconfig.pages)
        if(result == null){
          this.snackbar.text = this.$t('SnackBar.DeviceConfigNotFound')
          this.snackbar.active = true
        }
      }catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
      }
    },
    async getUsersDevice(deviceId){

      this.selectDeviceById(deviceId)
      this.activeId = deviceId
      this.usersLoading = true
      this.FKconfig  = {
        pages: []
      }
      this.itemsUsers = []
      this.itemsFCTypes = []
      console.log('getUsersDevice ' + deviceId)
      try{
        let result = await this.getUsersByDevice(deviceId)
        this.fullDeviceInfo = result.devices[0]
        console.log(this.fullDeviceInfo)
        //this.snackbar.text = "Success"
        //this.snackbar.active = true
      }
      catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
        this.selectedUserId = null
        this.fullDeviceInfo = null
      }
      this.usersLoading = false
    }
  }
});
</script>
<style>
.no-input-v-select > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > input {
  width: 0;
  height: 0;
  padding: 0;
}
.w-100{
  width: 100%;
}
.theme--light.v-list {
  background: #000 !important;
  color: rgba(255, 255, 255, 0.87) !important;
  border: 1px solid #fff;
  border-radius: 3px;
  max-width: 100%;
}
.v-list-item__content{
  color: rgba(255, 255, 255, 0.87) !important;
}
.v-select-list{
  /*background: rgb(0,0,0) !important;*/
}
.mdi-checkbox-marked::before{
  color: #fff !important;
}
</style>