import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ControlPanel from "../views/ControlPanel/ControlPanel.vue";
import ObjectCreation from "../views/ObjectCreation.vue";
import ObjectDeletion from "../views/ObjectDeletion.vue";
import Cube from "../views/Cube/Cube.vue";
import ControllerCreate from "../views/ControllerCreate.vue";
import AddUsersToAccount from "../views/AddUsersToAccount.vue";
import Account from "../views/Account.vue";
import FirmwareDownloading from "../views/FirmwareDownloading.vue";
import ConfigurationDownloading from "../views/ConfigurationDownloading.vue";
import Charts from "../views/Charts.vue";
import TablesOne from "../views/TablesOne.vue";
import TablesTwo from "../views/TablesTwo.vue";
import TablesThree from "../views/TablesThree.vue";
import SingleChart from "../views/SingleChart/index.vue";
import OpenSensors from "../views/OpenSensors/index.vue";
import EventLog from "../views/EventLog/EventLog.vue";
import VideoStreams from "../views/VideoStreams/index.vue";
import store from "../store"
import storageService from "../services/storageService"
import OpenCharts from "../views/OpenCharts/index.vue";
import ReconnectDevice from "../views/ReconnectDevice.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresLogin: true }
  },
  {
    path: "/OpenCharts",
    name: "OpenCharts",
    component: OpenCharts,
    props: (route) => ({ query: route.query.q }),
    meta: { requiresLogin: false }
  },
  {
    path: "/SingleChart",
    name: "SingleChart",
    component: SingleChart,
    meta: { requiresLogin: true }
  },
  {
    path: "/VideoStreams",
    name: "VideoStreams",
    component: VideoStreams,
    meta: { requiresLogin: true }
  },
  {
    path: "/ReconnectDevice",
    name: "ReconnectDevice",
    component: ReconnectDevice,
    meta: { requiresLogin: true }
  },
  {
    path: "/OpenSensors",
    name: "OpenSensors",
    component: OpenSensors,
    meta: { requiresLogin: true }
  },
  {
    path: "/TablesOne",
    name: "TablesOne",
    component: TablesOne,
    meta: { requiresLogin: true }
  },
  {
    path: "/TablesTwo",
    name: "TablesTwo",
    component: TablesTwo,
    meta: { requiresLogin: true }
  },
  {
    path: "/TablesThree",
    name: "TablesThree",
    component: TablesThree,
    meta: { requiresLogin: true }
  },
  {
    path: "/AddUsersToAccount",
    name: "AddUsersToAccount",
    component: AddUsersToAccount,
    meta: { requiresLogin: true }
  },
  {
    path: "/EventLog",
    name: "EventLog",
    component: EventLog,
    meta: { requiresLogin: true }
  },
  {
    path: "/ConfigurationDownloading",
    name: "ConfigurationDownloading",
    component: ConfigurationDownloading,
    meta: { requiresLogin: true }
  },
  {
    path: "/Account",
    name: "Account",
    component: Account,
    meta: { requiresLogin: true }
  },
  {
    path: "/ControlPanel",
    name: "ControlPanel",
    component: ControlPanel,
    props: (route) => ({ query: route.query.o }),
    meta: { requiresLogin: true }
  },
  {
    path: "/ObjectDeletion",
    name: "ObjectDeletion",
    component: ObjectDeletion,
    meta: { requiresLogin: true }
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/FirmwareDownloading",
    name: "FirmwareDownloading",
    component: FirmwareDownloading,
    meta: { requiresLogin: true }
  },
  {
    path: "/cube",
    name: "Cube",
    component: Cube,
  },
  {
    path: "/objectcreation",
    name: "ObjectCreation",
    component: ObjectCreation
  },
  {
    path: "/controllercreate",
    name: "ControllerCreate",
    component: ControllerCreate
  },
  {
    path: "/Charts",
    name: "Charts",
    component: Charts,
    meta: { requiresLogin: true }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if(to.name == 'ControlPanel'){
    storageService.setSelectedDeviceId(to.query.o)
  }
  if(to.name == from.name && to.name != 'OpenCharts'){
    return
  }
  if (to.matched.some(record => record.meta.requiresLogin) && store.state.Tokens.accessToken == null) {
    storageService.setTryLocation(window.location.pathname.substring(1))
    next("/Login")
  } else {
    if(to.name == 'ControlPanel' || to.name == 'Charts'){
      console.log(store.state.Devices.selectedDeviceSerialnumber)
      next()
    }
    if(to.name != 'Login' && to.name != null){
      
      //storageService.setReloads('0')
      storageService.setLocation(to.name)
    }
    next()
  }
})
export default router;
