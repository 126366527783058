enum SubDeviceType
{
    Omega = 7,
    Proteus = 8,
    Mercury206 = 4,
    HeadPro = 6,
    Mercury236 = 3,
    Galaxy = 1,
    Point = 2
}
module.exports = SubDeviceType