<template>
  <div class="container-fix">
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}" >
      {{$t('ObjectCreation.ObjectCreation')}}
    </div>
    <v-row class="d-flex align-start" :class="`${isMobileDisplay?'mt-6':'mt-16'}`">
      <v-col class="text-center col-12 col-xl-5 col-md-5 col-sm-12">
        <v-btn
          dark
          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
          :loading="loadingDeviceTypes"
          @click="clickDeviceTypes"
        >
          {{$t('ObjectCreation.DeviceTypes')}}
        </v-btn>
        <div
          v-if="isDrop"
        >
        <div class="mt-3 text-center" v-for="controller in deviceTypes.filter(d => d.isHead == true)" :key="controller.id">
          <v-btn
            dark @click="showForm(controller.id, controller)"
            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'} ${controller.id==selectedDeviceId?'dv-btn-border':''}`"
            :disabled="controller.inDevelopment">
            {{controller.name}}
          </v-btn>
        </div>
        </div>
      </v-col>
      <v-col v-show="isShowForm" class="col-12 col-xl-7 col-md-7 col-sm-12">
        <v-text-field
          :disabled="getAccessToken != null"
          v-model="phone"
          class="mb-3"
          outlined
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
          :label="$t('ObjectCreation.EnterPhoneNumber')"
        ></v-text-field>
        <v-text-field
          v-model="userName"
          outlined
          :label="$t('ObjectCreation.EnterUserName')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
        ></v-text-field>
        <v-text-field
          v-model="userEmail"
          outlined
          :label="$t('ObjectCreation.EnterEmail')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
          class="mb-3 mt-3" 
        ></v-text-field>
        <v-text-field
          v-if="dataController"
          v-model="serialNumber"
          outlined
          :label="(dataController.type != 129 && dataController.type != 137) ? $t('ObjectCreation.EnterSerialNumber') : $t('ObjectCreation.EnterImei')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input mb-3'}`"
          class="mt-3" 
        ></v-text-field>
        <v-text-field
          v-if="dataController && dataController.type == 134"
          v-model="omegaAddress"
          outlined
          :label="$t('ObjectCreation.EnterIpAddress')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
          class="mb-3 mt-3" 
        ></v-text-field>
        <v-text-field
          v-if="dataController && dataController.type == 134"
          v-model="omegaPort"
          outlined
          :label="$t('ObjectCreation.EnterPort')"
          required
          :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
        ></v-text-field>
        <div v-if="dataController && dataController.type == 129">
          <v-select
            class="mt-3"
            outlined
            dark
            v-model="itemsMeters"
            :items="deviceTypes.filter(d => d.isHead != true)"
            @change="selectSubController"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
            :label="$t('ObjectCreation.EnterMeter')"
          >
          </v-select>
          <v-text-field
            v-if="this.subDeviceId && this.subDeviceId == 4"
            v-model="mercury206Address"
            outlined
            :label="$t('ObjectCreation.EnterMercury206Address')"
            required
            :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
            class="mb-3 mt-3" 
          ></v-text-field>
          <div name="module-A_elements" v-if="this.subDeviceId && this.subDeviceId == 9" class="descriptors">
            <div>
              <v-text-field
                v-model="temperatureName"
                outlined
                :label="$t('ObjectCreation.EnterModuleATemperatureName')"
                required
                :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                class="mt-3 mb-3 mr-3">
              </v-text-field>
              <v-text-field
                v-model="moduleAFirstAdcName"
                outlined
                :label="$t('ObjectCreation.EnterModuleAFirstAdcName')"
                required
                :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                class="mt-3 mb-3 mr-3">
              </v-text-field>
              <v-text-field
                v-model="moduleASecondAdcName"
                outlined
                :label="$t('ObjectCreation.EnterModuleASecondAdcName')"
                required
                :class="
                `${isMobileDisplay
                ?'dv-input-tablet caption mt-3 mr-3 mb-3'
                :'dv-input mt-3 mr-3'}`">
              </v-text-field>
            </div>
          <div class="text-center col-2 col-xl-2 col-md-2 col-sm-2 mr-3">
            <v-btn
                    dark
                    :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                    :loading="loadingDescriptorTypes"
                    :disabled="true">
                    TMPR
            </v-btn>
            <v-select
                    v-if = "!this.isNewFirstAdcSensor"
                    class="mt-3 mb-3"
                    outlined
                    dark
                    v-model="itemsDescriptorsAdc1"
                    :items="this.descriptors"
                    @change="selectDescriptorAdc1"
                    :hint="`${items.name}, ${items.id}`"
                    item-text="name"
                    item-value="id"
                    item-color="#000"
                    :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                    :label="$t('ObjectCreation.SelectDescriptor')">
            </v-select>
            <v-text-field
                    v-if = "this.isNewFirstAdcSensor"
                    v-model="newDescriptor1Sensor"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleANewDescriptor')"
                    required
                    :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                    class="mt-3 mb-3">
            </v-text-field>
            <v-select
                    v-if = "!this.isNewSecondAdcSensor"
                    outlined
                    dark
                    v-model="itemsDescriptorsAdc2"
                    :items="this.descriptors"
                    @change="selectDescriptorAdc2"
                    :hint="`${items.name}, ${items.id}`"
                    item-text="name"
                    item-value="id"
                    item-color="#000"
                    :class="
                    `${isMobileDisplay
                    ?'dv-input-tablet caption mt-3 mb-6'
                    :'dv-input mt-3'}`"
                    :label="$t('ObjectCreation.SelectDescriptor')">
              </v-select>
              <v-text-field
                    v-if = "this.isNewSecondAdcSensor"
                    v-model="newDescriptor2Sensor"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleANewDescriptor')"
                    required
                    :class="`${isMobileDisplay
                    ?'dv-input-tablet caption mt-3 mb-3'
                    :'dv-input mt-3'}`">
              </v-text-field>
          </div>
          <div class="text-center col-2 col-xl-2 col-md-2 col-sm-2 mr-3">
            <v-btn
                    dark
                    :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                    :loading="loadingDescriptorTypes"
                    :disabled="true">
                    °C
            </v-btn>
            <v-btn
                    v-if = "!this.isNewFirstAdcSensor"
                    dark
                    class="mt-3"
                    :class="!isNewSecondAdcSensor
                    ?`${isMobileDisplay?'dv-btn-tablet caption mb-3':'dv-btn'}`
                    :`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                    :loading="loadingDescriptorTypes"
                    :disabled="true">
                    {{descriptor1Multiplier}}
            </v-btn>
            <v-text-field
                    v-if = "this.isNewFirstAdcSensor"
                    v-model="newDescriptor1Multiplier"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleANewMultiplier')"
                    required
                    :class="!isNewSecondAdcSensor
                    ?`${isMobileDisplay?'dv-input-tablet caption mt-3 mb-6':'dv-input mt-3'}`
                    :`${isMobileDisplay?'dv-input-tablet caption mt-3 mb-6':'dv-input mt-3 mb-3'}`
                    ">
            </v-text-field>
            <v-btn
                    v-if = "!this.isNewSecondAdcSensor"
                    dark
                    :class="
                    `${isMobileDisplay
                    ?'dv-btn-tablet caption mb-3'
                    :'dv-btn mt-3'}`"
                    :loading="loadingDescriptorTypes"
                    :disabled="true">
                    {{descriptor2Multiplier}}
            </v-btn>
            <v-text-field
                    v-if = "this.isNewSecondAdcSensor"
                    v-model="newDescriptor2Multiplier"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleANewMultiplier')"
                    required
                    :class="`${isMobileDisplay
                    ?'dv-input-tablet caption mt-3 mb-3'
                    :'dv-input mt-3'}`">
              </v-text-field>
          </div>
          <div class="text-center col-2 col-xl-2 col-md-2 col-sm-2 mr-3 end-column">
            <v-btn
                    v-if = "!this.isNewFirstAdcSensor"
                    dark
                    class="mt-3"
                    :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                    :loading="loadingDescriptorTypes"
                    :disabled="true">
                    {{descriptor1Unut}}
            </v-btn>
            <v-text-field
                    v-if = "this.isNewFirstAdcSensor"
                    v-model="newDescriptor1Measure"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleANewMeasure')"
                    required
                    :class="!isNewSecondAdcSensor
                    ?`${isMobileDisplay?'dv-input-tablet caption mt-3 mb-3':'dv-input mt-3'}`
                    :`${isMobileDisplay?'dv-input-tablet caption mt-3 mb-6':'dv-input mt-3 mb-3'}`">
            </v-text-field>
            <v-btn
                    v-if = "!this.isNewSecondAdcSensor"
                    dark
                    :class="
                    `${isMobileDisplay
                    ?'dv-btn-tablet caption mt-3 mb-3'
                    :'dv-btn mt-3'}`"
                    :loading="loadingDescriptorTypes"
                    :disabled="true">
                    {{descriptor2Unut}}
            </v-btn>
            <v-text-field
                    v-if = "this.isNewSecondAdcSensor"
                    v-model="newDescriptor2Measure"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleANewMeasure')"
                    required
                    :class="
                    `${isMobileDisplay
                    ?'dv-input-tablet caption mt-3 mb-3'
                    :'dv-input mt-3'}`"
                  >
              </v-text-field>
          </div>
          <div v-if="isNewFirstAdcSensor && !isNewSecondAdcSensor" class="text-center col-1 col-xl-1 col-md-1 col-sm-1 display-new-adc1">
              <v-btn
                      dark
                      class="mt-3"
                      :class="`${isMobileDisplay? 'dv-btn-tablet caption mb-6':'dv-btn mb-3'}`"
                      @click="cancelAdc1DescriptorCreation()"
                      :loading="loadingDescriptorTypes"
                      :disabled="false">
                      X
              </v-btn>
          </div>
          <div v-if="(isNewFirstAdcSensor && isNewSecondAdcSensor)||isNewSecondAdcSensor" class="text-center col-1 col-xl-1 col-md-1 col-sm-1 end-column">
              <v-btn
                      v-if = "isNewFirstAdcSensor && isNewSecondAdcSensor"
                      dark
                      class="mt-3"
                      :class="`${isMobileDisplay? 'dv-btn-tablet caption ':'dv-btn'}`"
                      @click="cancelAdc1DescriptorCreation()"
                      :loading="loadingDescriptorTypes"
                      :disabled="false">
                      X
              </v-btn>
              <v-btn
                      dark
                      :class="
                      `${isMobileDisplay
                      ?'dv-btn-tablet caption mt-3'
                      :'dv-btn mt-3'}`"
                      @click="cancelAdc2DescriptorCreation()"
                      :loading="loadingDescriptorTypes"
                      :disabled="false">
                      X
              </v-btn>
          </div>
          </div>
        </div>
        <div name="head-light_elements" v-if = "dataController && dataController.type == 137">
            <v-select
                      outlined
                      dark
                      v-model="workmodesItems"
                      :items="this.workModes"
                      @change="selectWorkMode"
                      :hint="`${items.name}, ${items.id}`"
                      item-text="name"
                      item-value="id"
                      item-color="#000"
                      :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                      :label="$t('ObjectCreation.SelectWorkMode')">
            </v-select>
          <v-row class="col-12">
              <v-col name="temperatureFields" class="col-11">
                <v-row no-gutter>
                  <v-col class="col-4">
                    <v-text-field
                    v-model="temperatureName"
                    outlined
                    :label="$t('ObjectCreation.EnterModuleATemperatureName')"
                    required
                    :class="`${isMobileDisplay?'dv-input-tablet caption mr-3':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-btn
                      dark
                      :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                      :loading="loadingDescriptorTypes"
                      :disabled="true">
                      TMPR
                    </v-btn>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-btn
                      dark
                      :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                      :loading="loadingDescriptorTypes"
                      :disabled="true">
                      °C
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col name="descriptor1Fields" class="col-11">
                <v-row no-gutter>
                  <v-col class="col-4">
                    <v-text-field
                      v-model="hlDescriptor1Name"
                      outlined
                      :label="$t('ObjectCreation.EnterDescriptor1Name')"
                      required
                      :class="`${isMobileDisplay?'dv-input-tablet caption mr-3':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-select
                            v-if = "!this.isNewFirstAdcSensor"
                            outlined
                            dark
                            v-model="itemsDescriptorsAdc1"
                            :items="this.descriptors"
                            @change="selectDescriptorAdc1"
                            :hint="`${items.name}, ${items.id}`"
                            item-text="name"
                            item-value="id"
                            item-color="#000"
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                            :label="$t('ObjectCreation.SelectDescriptor')">
                    </v-select>
                    <v-text-field
                            v-if = "this.isNewFirstAdcSensor"
                            v-model="newDescriptor1Sensor"
                            outlined
                            :label="$t('ObjectCreation.EnterModuleANewDescriptor')"
                            required
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-btn
                            v-if = "!this.isNewFirstAdcSensor"
                            dark
                            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                            :loading="loadingDescriptorTypes"
                            :disabled="true">
                            {{descriptor1Multiplier}}
                    </v-btn>
                    <v-text-field
                            v-if = "this.isNewFirstAdcSensor"
                            v-model="newDescriptor1Multiplier"
                            outlined
                            :label="$t('ObjectCreation.EnterModuleANewMultiplier')"
                            required
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3 ">
                    <v-btn
                            v-if = "!this.isNewFirstAdcSensor"
                            dark
                            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                            :loading="loadingDescriptorTypes"
                            :disabled="true">
                            {{descriptor1Unut}}
                    </v-btn>
                    <v-text-field
                            v-if = "this.isNewFirstAdcSensor"
                            v-model="newDescriptor1Measure"
                            outlined
                            :label="$t('ObjectCreation.EnterModuleANewMeasure')"
                            required
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col v-if ="isNewFirstAdcSensor" class="col-1 ">
                    <v-btn
                            dark
                            :class="`${isMobileDisplay? 'dv-btn-tablet caption':'dv-btn'}`"
                            @click="cancelAdc1DescriptorCreation()"
                            :loading="loadingDescriptorTypes"
                            :disabled="false">
                            X
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col name="descriptor2Fields" class="col-11">
                <v-row no-gutter>
                  <v-col class="col-4">
                    <v-text-field
                      v-model="hlDescriptor2Name"
                      outlined
                      :label="$t('ObjectCreation.EnterDescriptor2Name')"
                      required
                      :class="`${isMobileDisplay?'dv-input-tablet caption mr-3':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-select
                            v-if = "!this.isNewSecondAdcSensor"
                            outlined
                            dark
                            v-model="itemsDescriptorsAdc2"
                            :items="this.descriptors"
                            @change="selectDescriptorAdc2"
                            :hint="`${items.name}, ${items.id}`"
                            item-text="name"
                            item-value="id"
                            item-color="#000"
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                            :label="$t('ObjectCreation.SelectDescriptor')">
                    </v-select>
                    <v-text-field
                            v-if = "this.isNewSecondAdcSensor"
                            v-model="newDescriptor2Sensor"
                            outlined
                            :label="$t('ObjectCreation.EnterModuleANewDescriptor')"
                            required
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-btn
                            v-if = "!this.isNewSecondAdcSensor"
                            dark
                            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                            :loading="loadingDescriptorTypes"
                            :disabled="true">
                            {{descriptor2Multiplier}}
                    </v-btn>
                    <v-text-field
                            v-if = "this.isNewSecondAdcSensor"
                            v-model="newDescriptor2Multiplier"
                            outlined
                            :label="$t('ObjectCreation.EnterModuleANewMultiplier')"
                            required
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-2 mr-3">
                    <v-btn
                            v-if = "!this.isNewSecondAdcSensor"
                            dark
                            :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                            :loading="loadingDescriptorTypes"
                            :disabled="true">
                            {{descriptor3Unut}}
                    </v-btn>
                    <v-text-field
                            v-if = "this.isNewSecondAdcSensor"
                            v-model="newDescriptor2Measure"
                            outlined
                            :label="$t('ObjectCreation.EnterModuleANewMeasure')"
                            required
                            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                    </v-text-field>
                  </v-col>
                  <v-col v-if ="isNewSecondAdcSensor" class="col-1">
                    <v-btn
                            dark
                            :class="`${isMobileDisplay? 'dv-btn-tablet caption':'dv-btn'}`"
                            @click="cancelAdc2DescriptorCreation()"
                            :loading="loadingDescriptorTypes"
                            :disabled="false">
                            X
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col name="descriptor3Fields" class="col-11">
              <v-row no-gutter>
                <v-col class="col-4">
                  <v-text-field
                    v-model="hlDescriptor3Name"
                    outlined
                    :label="$t('ObjectCreation.EnterDescriptor3Name')"
                    required
                    :class="`${isMobileDisplay?'dv-input-tablet caption mr-3':'dv-input'}`">
                  </v-text-field>
                </v-col>
                <v-col class="col-2 mr-3">
                  <v-select
                          v-if = "!this.isNewThirdAdcSensor"
                          outlined
                          dark
                          v-model="itemsDescriptorsAdc3"
                          :items="this.descriptors"
                          @change="selectDescriptorAdc3"
                          :hint="`${items.name}, ${items.id}`"
                          item-text="name"
                          item-value="id"
                          item-color="#000"
                          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                          :label="$t('ObjectCreation.SelectDescriptor')">
                  </v-select>
                  <v-text-field
                          v-if = "this.isNewThirdAdcSensor"
                          v-model="newDescriptor3Sensor"
                          outlined
                          :label="$t('ObjectCreation.EnterModuleANewDescriptor')"
                          required
                          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                  </v-text-field>
                </v-col>
                <v-col class="col-2 mr-3">
                  <v-btn
                          v-if = "!this.isNewThirdAdcSensor"
                          dark
                          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                          :loading="loadingDescriptorTypes"
                          :disabled="true">
                          {{descriptor3Multiplier}}
                  </v-btn>
                  <v-text-field
                          v-if = "this.isNewThirdAdcSensor"
                          v-model="newDescriptor3Multiplier"
                          outlined
                          :label="$t('ObjectCreation.EnterModuleANewMultiplier')"
                          required
                          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                  </v-text-field>
                </v-col>
                <v-col class="col-2 mr-3">
                  <v-btn
                          v-if = "!this.isNewThirdAdcSensor"
                          dark
                          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
                          :loading="loadingDescriptorTypes"
                          :disabled="true">
                          {{descriptor3Unut}}
                  </v-btn>
                  <v-text-field
                          v-if = "this.isNewThirdAdcSensor"
                          v-model="newDescriptor3Measure"
                          outlined
                          :label="$t('ObjectCreation.EnterModuleANewMeasure')"
                          required
                          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`">
                  </v-text-field>
                </v-col>
                <v-col v-if ="isNewThirdAdcSensor" class="col-1">
                  <v-btn
                          dark
                          :class="`${isMobileDisplay? 'dv-btn-tablet caption':'dv-btn '}`"
                          @click="cancelAdc3DescriptorCreation()"
                          :loading="loadingDescriptorTypes"
                          :disabled="false">
                          X
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
            <v-select
                      class = "mb-3"
                      outlined
                      dark
                      v-model="itemsOutputsInp1"
                      :items="this.outputs"
                      @change="selectInputToBindToOutput1"
                      :hint="`${items.name}, ${items.id}`"
                      item-text="name"
                      item-value="id"
                      item-color="#000"
                      :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                      :label="$t('ObjectCreation.BindOut1ToInp')">
            </v-select>
            <v-select
                      class = "mb-3"
                      outlined
                      dark
                      v-model="itemsOutputsInp2"
                      :items="this.outputs"
                      @change="selectInputToBindToOutput2"
                      :hint="`${items.name}, ${items.id}`"
                      item-text="name"
                      item-value="id"
                      item-color="#000"
                      :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                      :label="$t('ObjectCreation.BindOut2ToInp')">
            </v-select>
            <v-select
                      class = "mb-3"
                      outlined
                      dark
                      v-model="itemsOutputsInp3"
                      :items="this.outputs"
                      @change="selectInputToBindToOutput3"
                      :hint="`${items.name}, ${items.id}`"
                      item-text="name"
                      item-value="id"
                      item-color="#000"
                      :class="`${isMobileDisplay?'dv-input-tablet caption mb-6':'dv-input'}`"
                      :label="$t('ObjectCreation.BindOut3ToInp')">
            </v-select>
        </div>

        <v-text-field
          v-model="title"
          outlined
          :label="$t('ObjectCreation.EnterObjectTitle')"
          required
          :class="(subDeviceId && subDeviceId == 9)|| (dataController && dataController.type == 137)
          ?`${isMobileDisplay?'dv-input-tablet caption mb-3 ':'dv-input'}`
          :`${isMobileDisplay?'dv-input-tablet caption mb-3 mt-3':'dv-input mt-3'}`"
        ></v-text-field>
        <v-autocomplete
          color="#fff"
          :noFilter="true"
          item-color="#fff"
          :label="$t('ObjectCreation.EnterAddress')"
          :items="suggestItems"
          v-model="suggest"
          outlined
          @update:search-input="suggestCheck"
          @change="selectSuggestItem"
          class="mt-3 auto-complite-white-color"
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
        ></v-autocomplete>
        <!--<div class="cont">
          <input 
            type="text" 
            :placeholder="$t('ObjectCreation.EnterAddress')"
            dark
            class="mt-3 dv-btn pl-3"
            :class="`${isMobileDisplay?'dv-input-tablet-address caption':'dv-input'}`"
            id="suggest">
        </div>-->
        <v-text-field
          v-if="getAccessToken == null && step ==2"
          v-model="smsConfirm"
          outlined
          :label="$t('ObjectCreation.EnterSms')"
          required
          class="mt-3" 
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
        ></v-text-field>
        <v-btn
          dark
          class="mt-3 mb-8"
          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
          @click="create()"
          :loading="loadingCreateDevice"
          :disabled="loadingCreateDevice || isNotValidForm">
          {{$t('ObjectCreation.Enter')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import ymapsBase from 'ymaps'
import PhoneInput from '../components/PhoneInput.vue'
import { loadYmap } from 'vue-yandex-maps'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
import storageService from "../services/storageService"
export default Vue.extend({
  name: "ObjectCreation",
  components: {
    phoneInput:PhoneInput
  },
  data: () => ({
    suggestItems: [],
    suggest: '',

    descriptor1Multiplier: null,
    descriptor1Id: null,
    descriptor1Unut: null,

    descriptor2Multiplier: null,
    descriptor2Id: null,
    descriptor2Unut: null,

    descriptor3Multiplier: null,
    descriptor3Id: null,
    descriptor3Unut: null,
    
    temperatureName: null,
    moduleAFirstAdcName: null,
    moduleASecondAdcName: null,

    hlDescriptor1Name: null,
    hlDescriptor2Name: null,
    hlDescriptor3Name: null,

    newDescriptor1Sensor: null,
    newDescriptor1Multiplier: null,
    newDescriptor1Measure: null,

    newDescriptor2Sensor: null,
    newDescriptor2Multiplier: null,
    newDescriptor2Measure: null,

    newDescriptor3Sensor: null,
    newDescriptor3Multiplier: null,
    newDescriptor3Measure: null,

    bindedOutput1: null,
    bindedOutput2: null,
    bindedOutput3: null,

    mercury206Address: null,
    omegaAddress: null,
    omegaPort: null,
    workModes:[],
    workmodesItems:[],
    descriptors:[],
    outputs:[],
    itemsMeters: [],
    itemsDescriptorsAdc1: [],
    itemsDescriptorsAdc2: [],
    itemsDescriptorsAdc3: [],
    itemsOutputsInp1:[],
    itemsOutputsInp2:[],
    itemsOutputsInp3:[],
    address: null,
    serialNumber: null,
    userName: null,
    userEmail: null,
    phone: null,
    title: null,
    point: null,
    latitude: null,
    longitude: null,
    smsConfirm: null,
    selectedDeviceId: null,
    isShowForm: false,
    items:[],
    subDeviceId: 0,
    isDrop: false,
    dataController: null,
    step: 1,
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    },
    isNewFirstAdcSensor: false,
    isNewSecondAdcSensor: false,
    isNewThirdAdcSensor: false,
    loadingDescriptorTypes: false,
    loadingDeviceTypes: false,
    loadingCreateDevice: false,
    isBatteryMode: false,
    settings: {
      apiKey: 'ed39e323-e93c-4e72-bee9-e0863456d707',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1',
      suggest_apikey: '33e03d88-bc42-4618-8c92-b42a04d3d9de'
    },
    coords:[],
    saveDataAddr: null,
    ymapsInstance: null
  }),
  watch: {
    isShowForm(val) {
      /*if(val){
        console.log(document.getElementsByClassName('x-center'))
        const $btn = document.getElementById('suggest');
        // добавим к $btn обработчик события click
        console.log($btn)
        $btn.onchange = function(data) {
          console.log(data)
          console.log(data.target.innerText)
        }
      }*/
    }
  },
  computed: {
    ...mapGetters(['deviceTypes', 'getUser', 'getAccessToken', 'isMobileDisplay',]),
    isNotValidForm(){
      if(this.serialNumber != null && this.title != null && this.userEmail != null  && this.userName != null && this.phone !=null && this.serialNumber.length > 3 && this.title.length > 2 && this.userEmail.length > 3 && this.userName.length >2 && this.phone.length > 5) return false
      return true
    }
  },
  methods: {
    ...mapActions(['getDeviceTypes', 'createDevice', 'userDeviceRegitration', 'userDeviceRegitrationCode','getAdcSensorTypes', 'loadYaSuggest']),
    ...mapMutations(['updateUser']),
    selectSuggestItem(data){
      console.log(data)
      this.address = data
    },
    async suggestCheck(value){
      console.log(value)  
      if(this.ymapsInstance == null || value.length <= 2) return;    
      this.ymapsInstance.suggest(value)
      .then(items => {
        this.suggestItems = []
        items.map(item => {
          this.suggestItems.push(item.value)
        })
        console.log(items)
      })
      .catch(e => {
        console.log(e) 
      })  
      /*ymaps.ready(() => {
        ymaps.geocode(value)
        .then(result => {
          console.log(result.geoObjects.get(0).properties.get('metaDataProperty'));
        })
        .catch(e => {
          console.log(e) 
        })     
        this.ymapsData = ymaps
      })*/
    },
    updatePhone(phone){
      if(phone.isValidByLibPhoneNumberJs){
        this.phone = phone.number
      }
    },
    check(data){
      this.saveDataAddr = data.target.value
      console.log(data.target.value)
    },
    async loadAdcSensorType(data)
    {
      this.loadingDescriptorTypes = true
      try{
        await this.getAdcSensorTypes()
        .then(adcDescriptors =>{
          this.descriptors = adcDescriptors
        })
        this.loadingDescriptorTypes = false
      }catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
        this.loadingDescriptorTypes = false
      }
    },
    selectWorkMode(data){
      console.log('select workmode:'+data)
      if(data && data.includes('12'))
      {
        this.isBatteryMode = false
      }
      if(data && data.includes('3,6'))
      {
        this.isBatteryMode = true
      }
    },
    selectInputToBindToOutput1(data)
    {
      if(data)
      {
        this.bindedOutput1 = data
      }
    },
    selectInputToBindToOutput2(data)
    {
      if(data)
      {
        this.bindedOutput2 = data
      }
    },
    selectInputToBindToOutput3(data)
    {
      if(data)
      {
        this.bindedOutput3 = data
      }
    },
    selectDescriptorAdc1(data){
      if(data==this.$t('ObjectCreation.CreateSensorModuleA'))
      {
        this.isNewFirstAdcSensor=true
        return
      }
      let descriptor = this.descriptors.find(d=>d.id==data)
      this.descriptor1Id = descriptor.id
      this.descriptor1Multiplier = descriptor.multiplier
      this.descriptor1Unut = descriptor.measure
    },
    selectDescriptorAdc2(data){
      if(data==this.$t('ObjectCreation.CreateSensorModuleA'))
      {
        this.isNewSecondAdcSensor=true
        return
      } 
      let descriptor = this.descriptors.find(d=>d.id==data)
      this.descriptor2Id = descriptor.id
      this.descriptor2Multiplier = descriptor.multiplier
      this.descriptor2Unut = descriptor.measure
    },
    selectDescriptorAdc3(data){
      if(data==this.$t('ObjectCreation.CreateSensorModuleA'))
      {
        this.isNewThirdAdcSensor=true
        return
      } 
      let descriptor = this.descriptors.find(d=>d.id==data)
      this.descriptor3Id = descriptor.id
      this.descriptor3Multiplier = descriptor.multiplier
      this.descriptor3Unut = descriptor.measure
    },
    cancelAdc1DescriptorCreation()
    {
      this.descriptor1Multiplier = null,
      this.descriptor1Unut = null,
      this.newDescriptor1Sensor = null
      this.newDescriptor1Multiplier = null
      this.newDescriptor1Measure = null
      this.itemsDescriptorsAdc1 = []
      this.isNewFirstAdcSensor = false
    },
    cancelAdc2DescriptorCreation()
    {
      this.descriptor2Multiplier = null,
      this.descriptor2Unut = null,
      this.newDescriptor2Sensor = null
      this.newDescriptor2Multiplier = null
      this.newDescriptor2Measure = null
      this.itemsDescriptorsAdc2 = []
      this.isNewSecondAdcSensor = false
    },
    cancelAdc3DescriptorCreation()
    {
      this.descriptor3Multiplier = null,
      this.descriptor3Unut = null,
      this.newDescriptor3Sensor = null
      this.newDescriptor3Multiplier = null
      this.newDescriptor3Measure = null
      this.itemsDescriptorsAdc3 = []
      this.isNewThirdAdcSensor = false
    },
    selectSubController(data){
      this.subDeviceId = data
      if(data == 5 && this.dataController.type == 129){
        this.subDeviceId = 2
      }
      if(data==9)
      {
        this.findDescriptorTypes()
      }
    },
    async showForm(selectedDeviceId, dataController){
      this.dataController = dataController
      if(this.dataController.type==137)
      {
        this.findDescriptorTypes()
        this.workModes.push(this.$t('ObjectCreation.WorkmodeBattery'), this.$t('ObjectCreation.WorkmodeNetwork'))
        this.outputs.push('Input1','Input2','Input3')
      }
      this.selectedDeviceId = selectedDeviceId
      this.isShowForm = true

      /*ymapsBase
      .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=ed39e323-e93c-4e72-bee9-e0863456d707&suggest_apikey=33e03d88-bc42-4618-8c92-b42a04d3d9de&load=SuggestView')
      .then(ymaps => {
        const suggestView = new ymapsInstance.suggest('suggest', {results: 1, offset: [20, 30]});
        console.log(suggestView)
      })*/
      /*await this.loadYaSuggest({apiKey: this.settings.apiKey, suggest_apikey: this.settings.suggest_apikey})
      .then(result => {
        const ymaps = result.data
        var suggestView = new ymaps.SuggestView('suggest', {results: 1, offset: [20, 30]});
        console.log(suggestView)
      })
      .catch(e => {
        console.log(e)
      })*/
      /*await loadYmap({ ...this.settings, debug: true })
      ymaps.ready(() => {
        console.log(ymaps)
        ymaps.suggest('мурлыч')
        .then(items => {
          console.log(items)
        })
        .catch(e => {
          console.log(e)
        })*/
        /*const suggestView = new ymaps.SuggestView('suggest', {results: 5, offset: [20, 30]})
        suggestView.events.add("select", (e) => {
          this.address = e.get('item').value
          console.log(this.address)
        })*/
      //})
    },
    async create(){
      console.log(this.hlDescriptor1Name)
      console.log(this.hlDescriptor2Name)
      console.log(this.hlDescriptor3Name)
      this.saveDataAddr = this.address
      this.loadingCreateDevice = true
      try{
        if(this.getAccessToken != null){
          await this.geoCodeing(this.saveDataAddr)
          let data = {
            TypeId: this.selectedDeviceId,
            Phone: this.phone,
            SerialNumber: this.serialNumber,
            Name: this.title,
            LocationName: this.saveDataAddr,
            Email: this.userEmail,
            FirstName: this.userName,
            Latitude: this.coords[0],
            Longitude: this.coords[1],
            SubDeviceId :this.subDeviceId,
            Mercury206Address: this.mercury206Address,
            OmegaAddress: JSON.stringify({
              Address: this.omegaAddress,
              Port: this.omegaPort
            }),
            ModuleASensorsData: JSON.stringify({
              TmprName: this.temperatureName,
              Adc1Data:{
                DescriptorId: this.descriptor1Id,
                Name: this.moduleAFirstAdcName,
                IsNew: this.isNewFirstAdcSensor
              },
              Adc2Data:{
                DescriptorId: this.descriptor2Id,
                Name: this.moduleASecondAdcName,
                IsNew: this.isNewSecondAdcSensor
              }
            }),
            NewModuleADescriptors: JSON.stringify({
              Adc1Data:{
                Name: this.newDescriptor1Sensor,
                Multiplier: this.newDescriptor1Multiplier,
                Measure: this.newDescriptor1Measure
              },
              Adc2Data:{
                Name: this.newDescriptor2Sensor,
                Multiplier: this.newDescriptor2Multiplier,
                Measure: this.newDescriptor2Measure
              }
            }),
            HeadLightRegistrationData: JSON.stringify({
              Mode: this.isBatteryMode,
              Temperature: this.temperatureName,
              Input1:{
                DescriptorId: this.descriptor1Id,
                Name: this.hlDescriptor1Name,
                IsNew: this.isNewFirstAdcSensor
              },
              Input2:{
                DescriptorId: this.descriptor2Id,
                Name: this.hlDescriptor2Name,
                IsNew: this.isNewSecondAdcSensor
            },
            Input3:{
                DescriptorId: this.descriptor3Id,
                Name: this.hlDescriptor3Name,
                IsNew: this.isNewThirdAdcSensor
            },
            Output1: this.bindedOutput1,
            Output2: this.bindedOutput2,
            Output3: this.bindedOutput3}),
            NewHeadLightDescriptors: JSON.stringify({
              Input1:{
                Name: this.newDescriptor1Sensor,
                Multiplier: this.newDescriptor1Multiplier,
                Measure: this.newDescriptor1Measure
              },
              Input2:{
                Name: this.newDescriptor2Sensor,
                Multiplier: this.newDescriptor2Multiplier,
                Measure: this.newDescriptor2Measure
              },
              Input3:{
                Name: this.newDescriptor3Sensor,
                Multiplier: this.newDescriptor3Multiplier,
                Measure: this.newDescriptor3Measure
              }
            })
          }

          await this.createDevice(data)
          this.updateUser({firstName:this.userName, email: this.userEmail})
          this.snackbar.text = this.$t('SnackBar.Success')
          this.snackbar.active = true
          this.isShowForm = false
          this.isDrop = false
        }else{
          let data = {
            TypeId: this.selectedDeviceId,
            SerialNumber: this.serialNumber,
            Phone: this.phone,
            DeviceName: this.title,
            FirstName: this.userName,
            Email: this.userEmail,
            LocationName: this.saveDataAddr,
            Mercury206Address: this.mercury206Address,
            OmegaAddress: JSON.stringify({
              Address: this.omegaAddress,
              Port: this.omegaPort
            }),
            ModuleANames: JSON.stringify({
              TmprName: this.temperatureName,
              Adc1Data:{
                DescriptorId: this.descriptor1Id,
                Name: this.moduleAFirstAdcName,
                IsNew: this.isNewFirstAdcSensor
              },
              Adc2Data:{
                DescriptorId: this.descriptor2Id,
                Name: this.moduleASecondAdcName,
                IsNew: this.isNewSecondAdcSensor
              }
          }),
            NewModuleADescriptors: JSON.stringify({
                Adc1Data:{
                  Name: this.newDescriptor1Sensor,
                  Multiplier: this.newDescriptor1Multiplier,
                  Measure: this.newDescriptor1Measure,
                },
                Adc2Data:{
                  Name: this.newDescriptor2Sensor,
                  Multiplier: this.newDescriptor2Multiplier,
                  Measure: this.newDescriptor2Measure,
                }
              }),
              HeadLightRegistrationData: JSON.stringify({
              Mode: this.isBatteryMode,
              Temperature: this.temperatureName,
              Input1:{
                DescriptorId: this.descriptor1Id,
                Name: this.hlDescriptor1Name,
                IsNew: this.isNewFirstAdcSensor
              },
              Input2:{
                DescriptorId: this.descriptor2Id,
                Name: this.hlDescriptor2Name,
                IsNew: this.isNewSecondAdcSensor
              },
              Input3:{
                DescriptorId: this.descriptor3Id,
                Name: this.hlDescriptor3Name,
                IsNew: this.isNewThirdAdcSensor
              },
            Output1: this.bindedOutput1,
            Output2: this.bindedOutput2,
            Output3: this.bindedOutput3}),
            NewHeadLightDescriptors: JSON.stringify({
              Input1:{
                Name: this.newDescriptor1Sensor,
                Multiplier: this.newDescriptor1Multiplier,
                Measure: this.newDescriptor1Measure
              },
              Input2:{
                Name: this.newDescriptor2Sensor,
                Multiplier: this.newDescriptor2Multiplier,
                Measure: this.newDescriptor2Measure
              },
              Input3:{
                Name: this.newDescriptor3Sensor,
                Multiplier: this.newDescriptor3Multiplier,
                Measure: this.newDescriptor3Measure
              }
            }) 
        }
          if(this.step == 1){
            console.log('step 1')
            console.log(data)
            await this.geoCodeing(this.saveDataAddr)
            
            data.Latitude = this.coords[0]
            data.Longitude = this.coords[1]
            let result = await this.userDeviceRegitration(data)
            console.log(result)
            this.step = 2
            this.snackbar.text = this.$t('SnackBar.SmsNotifySend')
            this.snackbar.active = true
            this.loadingCreateDevice = false
          } else if(this.step == 2){
            console.log('step 2')
            console.log(data)
            data.Code = this.smsConfirm

            await this.geoCodeing(this.saveDataAddr)
            
            data.Latitude = this.coords[0]
            data.Longitude = this.coords[1],
            data.OmegaAddress = JSON.stringify({
              Address: this.omegaAddress,
              Port: this.omegaPort
            })
            console.log(data)
            let result = await this.userDeviceRegitrationCode(data)
            storageService.removeAll()
            this.$router.push('Login').catch(() => {})
            console.log(result)
            this.step = 2
           this.snackbar.text = this.$t('SnackBar.Success')
            this.snackbar.active = true
            this.loadingCreateDevice = false
          }
        }
      }catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
        this.loadingCreateDevice = false
      }
    },
    async loadDeviceTypes(){
      this.loadingDeviceTypes = true
      try{
        await this.getDeviceTypes()
        this.loadingDeviceTypes = false
      }catch(e){
        console.log(e)
        if(!e.description){
          this.snackbar.text = this.$t('SnackBar.ServerConnectionError')
        }else{
          this.snackbar.text = this.$t(`SnackBar.ServerErrors.k${e.code}`)
        }
        this.snackbar.active = true
        this.loadingDeviceTypes = false
      }
    },
    async clickDeviceTypes(){ 
      if(this.dataController!=null)
      {
        this.dataController=null
      }
      if(this.deviceTypes == null || this.deviceTypes.length == 0) {
        await this.loadDeviceTypes()
      }
      else{
       
        this.isDrop=!this.isDrop
        this.isShowForm=false
      }
    },
     async findDescriptorTypes(){
      if(this.descriptorTypes == null || this.descriptorTypes.length == 0) {
        await this.loadAdcSensorType()
        this.descriptors.push(this.$t('ObjectCreation.CreateSensorModuleA'))
      }
    },
    async geoCodeing(address){
      await ymaps.geocode(address)
      .then(res => {
        let firstGeoObject = res.geoObjects.get(0)
        this.coords = firstGeoObject.geometry.getCoordinates()
        console.log(this.coords);
      })
      .catch(e => {
        console.log(e)
      })
    }
  },
  async mounted(){
    await this.loadDeviceTypes()
    .then(() => {
      if (this.getUser && this.getUser.phone){
        this.phone = this.getUser.phone
      }
      if (this.getUser && this.getUser.firstName){
        this.userName = this.getUser.firstName
      }
      if(this.getUser && this.getUser.email){
        this.userEmail = this.getUser.email
      }
    })
    ymapsBase
      .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=ed39e323-e93c-4e72-bee9-e0863456d707&suggest_apikey=33e03d88-bc42-4618-8c92-b42a04d3d9de')
      .then(ymaps => {
        this.ymapsInstance = ymaps
        console.log(this.ymapsInstance)
      })
  },
  created(){
  }
});
</script>
<style>
.auto-complite-white-color .v-input__control .v-input__slot .v-select__slot input{
  color: white;
}
.descriptors{
    display: flex;
}
.display-new-adc1{
  align-self: center !important;
}
.end-column{
  align-self: end;
}
.fix-center{
  margin: 0 auto !important;
}
.v-input__slot{
  align-self: center !important;
  justify-content: center !important;
}
.text-center > .cont >ymaps > ymaps > ymaps{
  margin-left: -33px;
  margin-right: 7px;
  background-color: black !important;
  border: 1px solid white !important;
  border-radius: 3px;
  color: white;
}
.text-center > .cont > ymaps > ymaps > ymaps > ymaps > ymaps:hover{
  color: black;
  background-color: #383838 !important;
}
.dv-input{
  color: white;
  border-radius: 3px;
}
.dv-input::placeholder{
  color: white;
}
.dv-btn-border{
  border: 2.5px solid white;
  background-color: #383838 !important;
}
</style>
