module.exports = {
    /*LANDING_URL: "http://84.201.175.195/",
    VIDEO_URL: "http://212.192.202.112:3009",
    API_HTTP_URL: "http://212.192.202.112:5010",
    YA_MAP_API_KEY: "5166066c-78ca-4794-bf22-6784fdaad572",
    YA_MAP_LAT: 47.231331,
    YA_MAP_LON: 39.748304,
    LANGAUGE: 'us',*/
    LANGAUGES: [
        'us',
        'ru',
        'il'
    ],
    SOCIAL: [
        /*{
            //ICON: "mdi-facebook",
            //LINK: "https://facebook.com/"
        },*/
        {
            ICON: "mdi-twitter",
            LINK: "https://twitter.com/"
        },
        {
            ICON: "mdi-linkedin",
            LINK: "https://linkedin.com/"
        },
        {
            ICON: "mdi-pinterest",
            LINK: "https://www.pinterest.com/"
        }/*,
        {
            //ICON: "mdi-instagram",
            //LINK: "https://www.instagram.com/"
        }*/
    ]
}