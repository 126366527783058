import axios from 'axios'
import { Service } from 'axios-middleware'
import store from "../store"
const local:any = localStorage
const axiosBaseInstance = axios
const axiosInstance = axios.create ({
  baseURL: `${process.env.VUE_APP_ui_api_url}`,
  headers: {
    Accept: 'application/json',
    Authorization: null
  }
})
const service = new Service(axiosInstance);

service.register({
  onRequest(data:any) {
    if(data.url.includes('/api/Account/UserDeviceRegistration') || data.url.includes('/api/Account/CheckUserDeviceExist') || data.url.includes('/api/Account/Refresh/') || data.url.includes('/api/Account/Login') || data.url.includes('/api/Head/GetDeviceTypes')|| data.url.includes(`/api/Head/GetAdcSensorTypes`)) return data;
    const refreshTokenData = local.getItem('refresh_token')

    const tokenData = local.getItem('access_token')
    if(tokenData == null) return null
    let base64Url = tokenData.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    //console.log(JSON.parse(jsonPayload))
    if(Date.now() >= JSON.parse(jsonPayload).exp * 1000){
      axiosInstance.post(`/api/Account/Refresh/`, {RefreshToken:refreshTokenData})
      .then((response:any) => {
        //console.log(response)
        if(response.data.result.code == 0){
          //console.log(response.data)
          local.setItem('access_token', response.data.accessToken)
          local.setItem('refresh_token', response.data.refreshToken)
          /*store.commit('saveAccessToken', response.data.accessToken)
          store.commit('saveRefreshToken', response.data.refreshToken)*/
        }else{
          //console.log(response.data)
          //throw response.data.result
        }
      })
      .catch((err:any) => {
        //console.log(err)
        //throw err
      })
    }
    //console.log(tokenData)

    //console.log('onRequest');
    return data;
  },
  onSync(promise:any) {
    ////console.log('onSync');
    return promise;
  },
  onResponse(response:any) {
    if(response.config.url.includes('/api/Account/Refresh/') && response.data.result.code != 0){
      local.remove('access_token')
      local.remove('refresh_token')
    }
    if(response.status == 401){
      const refreshTokenData = local.getItem('refresh_token')
      const tokenData = local.getItem('access_token')
      if(tokenData == null) return null
      let base64Url = tokenData.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      //console.log(JSON.parse(jsonPayload))
      if(Date.now() >= JSON.parse(jsonPayload).exp * 1000){
        axiosInstance.post(`/api/Account/Refresh/`, {RefreshToken:refreshTokenData})
        .then((response:any) => {
          //console.log(response)
          if(response.data.result.code == 0){
            //console.log(response.data)
            local.setItem('access_token', response.data.accessToken)
            local.setItem('refresh_token', response.data.refreshToken)
            /*store.commit('saveAccessToken', response.data.accessToken)
            store.commit('saveRefreshToken', response.data.refreshToken)*/
          }else{
            //console.log(response.data)
            //throw response.data.result
          }
        })
        .catch((err:any) => {
          //console.log(err)
          //throw err
        })
      }
    }
    return response;
  }
});

export default {
  updateAccessToken(accessToken: string){
    axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`
    localStorage.setItem('access_token', `${accessToken}`);
  },
  getInstance(){
    return axiosInstance
  },
  getBaseInstance(){
    return axiosBaseInstance
  },
  tryRestoreAccessToken(){
    if(localStorage.getItem('access_token')){
      const accessToken = localStorage.getItem('access_token')
      axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`
      return true
    }
    return false
  }
}