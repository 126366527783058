<template>
  <div class="container-fix">
    <div class="login-text" align="center" :class="{'title': isMobileDisplay}">
      {{$t('VideoStreams.VideoStreams')}}
    </div>
    <v-row align="center" :class="`${isMobileDisplay?'mt-3':'mt-5'}`">
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center">
        <v-btn dark :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`">
          {{getUser.firstName}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center">
        <div v-if="devicesData.data">
          <v-select
            :loading="devicesData.loading || loadingSave"
            :disabled="devicesData.disabled || loadingSave"
            outlined
            dark
            v-model="devicesData.selected"
            :items="devicesData.data"
            @change="getCamerasByDevice"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
            :label="$t('VideoStreams.Objects')"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center">
        <div v-if="devicesData.selected != null">
          <v-select
            :loading="camerasData.loading || loadingSave"
            :disabled="camerasData.disabled || loadingSave"
            outlined
            dark
            v-model="camerasData.selected"
            :items="camerasData.data"
            @change="getSensors"
            :hint="`${items.name}, ${items.panelElementId}`"
            item-text="name"
            item-value="panelElementId"
            item-color="#000"
            :label="$t('VideoStreams.VideoStreams')"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          ></v-select>
        </div>
      </v-col>
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center" cols="6">
        <v-btn dark
          :class="`${isMobileDisplay?'dv-btn-tablet caption mt-3':'dv-btn'}`"
          @click="addNewCamera"
          v-if="devicesData.selected != null"
          :disabled="isAddNewCamera || isEditOldCamera"
        >
          {{$t('VideoStreams.Add')}}
        </v-btn>
      </v-col>
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center" cols="6">
        <v-btn dark :class="`${isMobileDisplay?'dv-btn-tablet caption mt-3':'dv-btn'}`" @click="canselCreateCamera" v-if="isAddNewCamera || isEditOldCamera"
          :loading="loadingSave">
          {{$t('VideoStreams.Cancel')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="col-xl-3 col-lg-3 col-md-3 col-sm-3" cols="12" justify="center" align="center">
        <div v-if="isAddNewCamera">
          <v-select
            :loading="videoWindowData.loading"
            :disabled="videoWindowData.disabled"
            outlined
            dark
            v-model="videoWindowData.selected"
            :items="videoWindowData.data"
            @change="selectVideoWindow"
            :hint="`${items.name}, ${items.id}`"
            item-text="name"
            item-value="id"
            item-color="#000"
            :label="$t('VideoStreams.VideoWindowInConfig')"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
            :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
          ></v-select>
        </div>
      </v-col>
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center" cols="12">
        <v-text-field
          v-if="videoWindowData.selected || isEditOldCamera"
          v-model="rtspData.data1"
          :disabled="loadingSave"
          outlined
          :label="$t('VideoStreams.Enter1RtspLink')"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          required
        ></v-text-field>
      </v-col>
      <v-col class="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" justify="center" align="center" cols="12" v-if="windowWidth>600">
        <v-btn dark
          :class="`${isMobileDisplay?'dv-btn-tablet caption mt-3':'dv-btn'}`"
          @click="createCameras"
          v-if="isAddNewCamera && videoWindowData.selected || isEditOldCamera"
          :disabled="rtspData.data1 == null || rtspData.data1 == ''"
          :loading="loadingSave"
        >
          {{$t('VideoStreams.Save')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col 
        justify="center" align="center" cols="12"
        :class="{'offset-5 offset-xl-3 offset-md-3 offset-sm-3':windowWidth>600}"
        class="col-xl-3 col-md-3 col-sm-3">
        <v-text-field
          v-if="videoWindowData.selected || isEditOldCamera"
          :disabled="rtspData.data1 == null || rtspData.data1.length == 0 || loadingSave"
          v-model="rtspData.data2"
          outlined
          :label="$t('VideoStreams.Enter2RtspLink')"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col 
        justify="center" align="center" cols="12"
        :class="{'offset-5 offset-xl-3 offset-md-3 offset-sm-3':windowWidth>600}"
        class="col-xl-3 col-md-3 col-sm-3">
        <v-text-field
          v-if="videoWindowData.selected || isEditOldCamera"
          :disabled="rtspData.data2 == null || rtspData.data2.length == 0 || loadingSave"
          v-model="rtspData.data3"
          outlined
          :label="$t('VideoStreams.Enter3RtspLink')"
            :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col  
        justify="center" align="center" cols="12"
        :class="{'offset-5 offset-xl-3 offset-md-3 offset-sm-3':windowWidth>600}"
        class="col-xl-3 col-md-3 col-sm-3">
        <v-text-field
          v-if="videoWindowData.selected || isEditOldCamera"
          :disabled="rtspData.data3 == null || rtspData.data3.length == 0 || loadingSave"
          v-model="rtspData.data4"
          outlined
          :label="$t('VideoStreams.Enter4RtspLink')"
          :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" v-if="windowWidth<600">
      <v-col  
        class="col-xl-2 col-md-4 col-sm-4 d-flex justify-center">
        <v-btn dark
          :class="`${isMobileDisplay?'dv-btn-tablet caption':'dv-btn'}`"
          @click="createCameras"
          v-if="isAddNewCamera && videoWindowData.selected || isEditOldCamera"
          :disabled="rtspData.data1 == null || rtspData.data1 == ''"
          :loading="loadingSave"
        >
          {{$t('VideoStreams.Save')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
export default Vue.extend({
  name: "OpenSensors",
  data: () => ({
    loadingSave:false,
    selectedPanelId:null,
    isEditOldCamera: false,
    configId: null,
    serialNumberActive: null,
    activeId: null,
    isAddNewCamera: false,
    items:[],
    devicesData:{
      loading: true,
      disabled: true,
      data: [],
      selected: null
    },
    camerasData:{
      loading: true,
      disabled: true,
      data: [],
      selected: null
    },
    videoWindowData:{
      loading: true,
      disabled: true,
      data: [],
      selected: null
    },
    rtspData:{
      loading: true,
      disabled: true,
      data1: null,
      data2:null,
      data3:null,
      data4:null,
      data: [],
      selected: []
    },
    snackbar:{
      text: "",
      active: false,
      timeout: 6000
    }
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'getSelectedDevice', 'isMobileDisplay']),
    windowWidth(){
      return this.$vuetify.breakpoint.width
    }
  },
  methods: {
    ...mapMutations(['selectDeviceById']),
    ...mapActions(['getAllCamerasByDeviceId', 'getDevices', 'getSenorsBySerialNumber', 'getDeviceConfig', 'createCamera', 'updateCamera']),
    async createCameras(){
      try{
        this.loadingSave = true
        if(this.isEditOldCamera){
          const cnf = await this.getDeviceConfig(this.serialNumberActive)
          this.configId = cnf.id
          console.log('EYEYEYEY')
          const findPanelelementName = this.videoWindowData.data.filter(da => da.id == this.videoWindowData.selected)
          const data = {
            deviceId: this.activeId,
            rtsp: JSON.stringify([this.rtspData.data1, this.rtspData.data2 == null ? '' : this.rtspData.data2, this.rtspData.data3 == null ? '' : this.rtspData.data3, this.rtspData.data4 == null ? '' : this.rtspData.data4]),
            configId: this.configId,
            panelElementId: this.selectedPanelId
          }
          console.log(data)
          const result = await this.updateCamera(data)
          console.log(result)
        }else{
          const findPanelelementName = this.videoWindowData.data.filter(da => da.id == this.videoWindowData.selected)
          const data = {
            deviceId: this.activeId,
            rtcp: JSON.stringify([this.rtspData.data1, this.rtspData.data2 == null ? '' : this.rtspData.data2, this.rtspData.data3 == null ? '' : this.rtspData.data3, this.rtspData.data4 == null ? '' : this.rtspData.data4]),
            name: findPanelelementName[0].name,
            configId: this.configId,
            panelElementId: this.videoWindowData.selected
          }
          console.log(data)
          const result = await this.createCamera(data)
          console.log(result)
        }
        await this.getCamerasByDevice(this.activeId)
        this.rtspData.data1 = null
        this.rtspData.data2 = null
        this.rtspData.data3 = null
        this.rtspData.data4 = null
        this.isAddNewCamera = false
        this.isEditOldCamera = false
        this.videoWindowData.selected = null
        
      }catch(e){
        console.log(e)
      }
      this.loadingSave = false
    },
    async getSensors(data){
      this.isAddNewCamera = false
      this.selectedPanelId = data
      const dat = this.camerasData.data.filter(dt => dt.panelElementId == data)
      this.isEditOldCamera = true
      console.log(this.camerasData.data)
      console.log(data)
      console.log(dat)
      if(dat.length >= 1){
        const jsonInfoParsed = JSON.parse(dat[0].rtcp)
        console.log(jsonInfoParsed)
        this.rtspData.data1 = jsonInfoParsed[0]
        this.rtspData.data2 = jsonInfoParsed[1]
        this.rtspData.data3 = jsonInfoParsed[2]
        this.rtspData.data4 = jsonInfoParsed[3]
      }
    },
    canselCreateCamera(){
      this.rtspData.data1 = null
      this.rtspData.data2 = null
      this.rtspData.data3 = null
      this.rtspData.data4 = null
      this.isEditOldCamera = false
      this.isAddNewCamera = false
      this.videoWindowData.selected = null
      this.camerasData.selected = null
    },
    async getUserDevices(){
      try{
        let result = await this.getDevices()
        this.devicesData.data = result.devices.filter(device => device.deviceOwnerId == this.getUser.id)
        this.devicesData.loading = false
        this.devicesData.disabled = false
        console.log(result)
      }
      catch(e){
        console.log(e)
      }
    },
    async getCamerasByDevice(deviceId){
      try{
        this.selectDeviceById(deviceId)
        //this.camerasData.data = []
        this.activeId = deviceId
        const findDevice = this.devicesData.data.filter(d => d.id == deviceId)
        if(findDevice.length > 0){
          this.serialNumberActive = findDevice[0].serialNumber
        }
        const result = await this.getAllCamerasByDeviceId(deviceId)
        console.log(result)
        this.camerasData.data = result.cameras
        this.camerasData.loading = false
        this.camerasData.disabled = false
        this.camerasData.selected = null
        console.log(this.camerasData.data)
      }
      catch(e){
        console.log(e)
      }
    },
    async selectVideoWindow(panelElementId){
      console.log(panelElementId)
      this.videoWindowData.selected = panelElementId
      console.log(this.videoWindowData)
    },
    async addNewCamera(){
      this.isAddNewCamera = true
      const result = await this.getDeviceConfig(this.serialNumberActive)
      this.videoWindowData.data = []
      if(result.config){
        result.config.pages.forEach(page => {
          page.elements.forEach(element => {
            if(element.type == "video"){
              console.log(element)
              if(this.camerasData.data.filter(d => d.panelElementId == element.id).length == 0){
                this.videoWindowData.data.push(element)
              }
            }
          })
        })
        this.videoWindowData.disabled = false
        this.videoWindowData.loading = false
      }
      this.configId = result.id
      console.log(result)
    }
  },
  async created(){
    console.log('123')
    try{
      await this.getUserDevices()
      const device = this.getSelectedDevice
      if(device !== null && device !== undefined && device.id){
        this.activeId = device.id
        this.devicesData.selected = device.id
        await this.getCamerasByDevice(device.id)
      }
      //await this.getUserDevices()
      /*const result = await this.getAllCamerasByDeviceId(1)
        this.camerasData.data = result.cameras
        this.camerasData.loading = false
        this.camerasData.disabled = false
      console.log(result)*/
    }
    catch(e)
    {
      console.log(e)
    }
  }
});
</script>
<style>
.v-list-item__content{
  color: rgba(255, 255, 255, 0.87) !important;
}
</style>