const session:any = sessionStorage
const local:any = localStorage
import axiosService from "./axiosService";
const axios = axiosService.getInstance()
export default {
  removeAll(){
    local.removeItem('access_token')
    local.removeItem('refresh_token')
    local.removeItem('location')
    local.removeItem('try_location')
    local.removeItem('serial_number')
    local.removeItem('reloads')
    local.removeItem('filter')
    local.removeItem('langauge')
    local.removeItem('object_id')
  },
  setLangauge(langauge: string | null | undefined){
    return local.setItem('langauge', langauge)
  },
  getLangauge(){
    return local.getItem('langauge')
  },
  getRefreshToken(){
    return local.getItem('refresh_token')
  },
  getToken(){
    return local.getItem('access_token')
  },
  setToken(accessToken: string, refreshtoken: string){
    local.setItem('access_token', accessToken)
    local.setItem('refresh_token', refreshtoken)
  },
  getTryLocation(){
    const session = local.getItem('try_location')
    if(session == null) return `Account`
    return session
  },
  setTryLocation(location: string | null | undefined){
    if(location == null) return
    local.setItem('try_location', location)
  },
  getLocation(){
    const session = local.getItem('location')
    if(session == null) return `Account`
    return session
  },
  setLocation(location: string | null | undefined){
    if(location == null) return
    local.setItem('location', location)
  },
  getSelectedDeviceId(){
    const selectedDeviceData = local.getItem('object_id')
    return selectedDeviceData
  },
  setSelectedDeviceId(location: any){
    local.setItem('object_id', location)
  },
  getSelectedDevice(){
    const selectedDeviceData = local.getItem('serial_number')
    return selectedDeviceData
  },
  setSelectedDevice(location: string | null | undefined){
    local.setItem('serial_number', location)
  },
  getReloads(){
    const reloadsData = local.getItem('reloads')
    return reloadsData
  },
  setReloads(reloads: string | null | undefined){
    local.setItem('reloads', reloads)
  },
  getFilter(){
    const filterData = local.getItem('filter')
    return filterData
  },
  setFilter(filter: string | null | undefined){
    local.setItem('filter', filter)
  }
}
