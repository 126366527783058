



































































































































































































import { 
  mdiExpansionCardVariant,
  mdiInformation,
  mdiTicketConfirmation,
  mdiCogs,
  mdiCctv,
  mdiAccountSupervisor
 } from "@mdi/js";
import Vue from "vue";

export default Vue.extend({
  name: "HelloWorld",
  props: {
    devices: Array,
    configs: Array
  },
  data: () => ({
    icons: {
      mdiExpansionCardVariant,
      mdiInformation,
      mdiTicketConfirmation,
      mdiCogs,
      mdiCctv,
      mdiAccountSupervisor
    },
    tab: null,
    items: [
      { 
        tab: "TEST",
        info: {
          name: "TEST",
          serialNumber: "023E6B5E",
          family: "Division Head-2",
          address: "адрес не указан",
          buildDate: "15 сентября 2020 г.",
          connectDate: "7 августа 2021 г.",
          status: "подключен"
        },
        firmwares: [
          {
            id: 1,
            name: "Версия 1 от 29 июня 2020 г.",
            description: "Прошивка v0.72 (fix)"
          },
          {
            id: 2,
            name: "Версия 1 от 25 сентября 2020 г.	",
            description: "Прошивка v0.72 (ВЮ)	"
          },
        ],
        configurations: [
          {
            id: 1,
            name: "TEST 22.07.21 10-45-53",
            description: "от 20 июля 2021 г."
          },
          {
            id: 2,
            name: "TEST 02.08.21 10-24-48",
            description: "от 2 августа 2021 г."
          },
          {
            id: 3,
            name: "TEST 03.08.21 12-14-40",
            description: "от 3 августа 2021 г."
          },
          {
            id: 4,
            name: "Stand второй проект 06.08.21 14-11-21",
            description: "от 6 августа 2021 г."
          },
          {
            id: 5,
            name: "Stand рабочий проект 06.08.21 15-24-18",
            description: "от 6 августа 2021 г."
          },
          {
            id: 6,
            name: "Stand второй проект 06.08.21 16-15-47",
            description: "от 6 августа 2021 г."
          }
        ],
        videoMonitoring: [],
        accessUsers: [
          {
            id: 1,
            name: "(А. Сюняков)",
            phone: "79282964000"
          },
          {
            id: 2,
            name: "(А. Степанов)",
            phone: "79286132259"
          },
          {
            id: 3,
            name: "(А. Степанов)",
            phone: "79054574722"
          }
        ]
      },
      { 
        tab: "STAND",
        info: {
          name: "STAND",
          serialNumber: "023E6B5E",
          family: "Division Head-2",
          address: "адрес не указан",
          buildDate: "15 сентября 2020 г.",
          connectDate: "7 августа 2021 г.",
          status: "не подключен"
        },
        firmwares: [
          {
            id: 1,
            name: "Версия 1 от 29 июня 2020 г.",
            description: "Прошивка v0.72 (fix)"
          },
          {
            id: 2,
            name: "Версия 1 от 25 сентября 2020 г.	",
            description: "Прошивка v0.72 (ВЮ)	"
          },
        ],
        configurations: [
          {
            id: 1,
            name: "TEST 22.07.21 10-45-53",
            description: "от 20 июля 2021 г."
          },
          {
            id: 2,
            name: "TEST 02.08.21 10-24-48",
            description: "от 2 августа 2021 г."
          },
          {
            id: 3,
            name: "TEST 03.08.21 12-14-40",
            description: "от 3 августа 2021 г."
          },
          {
            id: 4,
            name: "Stand второй проект 06.08.21 14-11-21",
            description: "от 6 августа 2021 г."
          },
          {
            id: 5,
            name: "Stand рабочий проект 06.08.21 15-24-18",
            description: "от 6 августа 2021 г."
          },
          {
            id: 6,
            name: "Stand второй проект 06.08.21 16-15-47",
            description: "от 6 августа 2021 г."
          }
        ],
        videoMonitoring: [],
        accessUsers: [
          {
            id: 1,
            name: "(А. Сюняков)",
            phone: "79282964000"
          },
          {
            id: 2,
            name: "(А. Степанов)",
            phone: "79286132259"
          },
          {
            id: 3,
            name: "(А. Степанов)",
            phone: "79054574722"
          }
        ]
      }
    ],
  }),
});
