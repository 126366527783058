<template>
  <div>
    <v-row :class="`${isMobileDisplay && getUser==null?'mt-2':''}`">
      <v-col class="col-12" v-show="isDynamicChart">
        <canvas id="chart1"></canvas>
      </v-col>
      <v-col class="fix-pad col-12" v-if="!isDynamicChart">
        <plotlyTest
          :plotlyData="plotlyData"
          :isMini="isMobileDisplay"
          class="mb-1"
        ></plotlyTest>
      </v-col>
      <v-col class="col-12">
        <v-row class="d-flex justify-space-around justify-center">
          <v-col class="col-xl-3 col-lg-3 col-md-3 chart-pad fix-chart-control" cols="12">
            <v-select
              :loading="selectController.loading"
              :disabled="true"
              outlined
              dark
              v-model="selectController.selected"
              :items="selectController.items"
              :hint="`${selectController.items.data}, ${selectController.items.id}`"
              @change="selectControllerNameChange(selectController.selected)"
              item-text="data"
              item-value="id"
              item-color="#000"
              item-disabled="connection"
              :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              :label="$t('Charts.Object')"
              :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
          <v-col class="col-xl-3 col-lg-3 col-md-3 chart-pad fix-chart-control" cols="12">
            <v-select
              :loading="selectSensorType.loading"
              :disabled="true"
              outlined
              dark
              v-model="selectSensorType.selected"
              :items="selectSensorType.items"
              @change="selectSensorTypeChange(selectSensorType.selected)"
              :hint="`${selectSensorType.items.data}, ${selectSensorType.items.data}`"
              item-text="data"
              item-value="data"
              item-color="#000"
              :label="$t('Charts.SensorType')"
              :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
              :menu-props="{ maxHeight: '300', bottom: true, offsetY: true }"
            ></v-select>
          </v-col>
          <v-col class="col-xl-3 col-lg-3 col-md-3 chart-pad fix-chart-control" cols="12">
            <v-dialog
              v-if="windowWidth < 600"
              ref="menu"
              v-model="menu"
              :disabled="selectSensorType.disabled"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dark
                  v-model="datesRange"
                  :label="$t('Charts.SelectDate')"
                  class="menu-picker"
                  :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                no-title
                scrollable
                :locale="$t('Calendar.locale')"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{$t('Charts.Cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datesRange), selectDateRange(datesRange)"
                >
                  {{$t('Charts.Ok')}}
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-menu
              v-else
              ref="menu"
              v-model="menu"
              :disabled="selectSensorType.disabled"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="dv-input"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dark
                  v-model="datesRange"
                  :label="$t('Charts.SelectDate')"
                  class="menu-picker"
                  :class="`${isMobileDisplay?'dv-input-tablet caption':'dv-input'}`"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                no-title
                scrollable
                :locale="$t('Calendar.locale')"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{$t('Charts.Cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datesRange), selectDateRange(datesRange)"
                >
                  {{$t('Charts.Ok')}}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="col-xl-1 col-lg-1 col-md-1 chart-pad fix-chart-control" cols="12">
            <v-btn
              :disabled="selectSensorType.disabled"
              v-if="selectedTypeChart == 0"
              @click.stop="stopChartOne"
              class="dv-chart-btn-mini"
              :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}"
              dark
            >
              {{chartOneStopped?$t('Charts.Start'):$t('Charts.Stop')}}
            </v-btn>
            <v-btn
              :disabled="selectSensorType.disabled"
              v-else
              @click.stop="clearChart"
              class="dv-chart-btn-mini"
              :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}"
              dark
            >
              {{$t('Charts.Clear')}}
            </v-btn>
          </v-col>
          <v-col class="col-xl-1 col-lg-1 col-md-1 chart-pad fix-chart-control" cols="12">
            <v-btn
              :disabled="selectSensorType.disabled"
              @click.stop="$emit('openBigMap')"
              class="dv-chart-btn-mini "
              :class="{'caption dv-chart-btn-mini-tablet': isMobileDisplay}"
              dark
            >
              {{$t('Charts.Add')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SensorType from "../../Enums/SensorType";

const SensorTypeEnum = require('../../Enums/SensorType')
import storageService from '../../services/storageService'
import Chart from 'chart.js'
import {  mapActions, mapGetters, mapMutations } from 'vuex'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import ChartZoom from 'chartjs-plugin-zoom'
import ChartSreaming from 'chartjs-plugin-streaming'
import zoomChartData from './ChartsSettings/dynamicChart.js'
import plotlyTest from './plotlyTest.vue'
import chartData from './ChartsSettings/staticChart.js'
const config = process.env.VUE_APP_ui_api_url

export default {
  components: {
    plotlyTest: plotlyTest
  },
  data: () => ({
    plotlyData:[],
    isDynamicChart: true,
    datas: [],
    selectedTypeChart: 0,
    Chart: null,
    datesRange: null,
    selectSensorType:{
      disabled: false,
      loading: false,
      selected: 'Temperature',
      items: [],
      allItems: []
    },
    selectController:{
      disabled: false,
      loading: false,
      selected: null,
      items: [],
      allItems: []
    },
    dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    zoomChartData: zoomChartData,
    chartData: chartData,
    charts: null,
    connection: null,
    tempSensors: [],
    isPause: false,
    serialNumber: null,
    elementsId: [],
    chartOneStopped: false
  }),
  computed: {
    ...mapGetters(['isMobileDisplay', 'getRawState', 'getMode', 'filterStateCode', 'filterState','getUser', 'getAccessToken', 'getLoadDevices', 'getChartOneLastValue', 'getSelectedDeviceSerialNumber', 'getSelectedDevice']),
    windowWidth(){
      return this.$vuetify.breakpoint.width
    }
  },
  methods: {
    ...mapActions(['loadStateTypes', 'loadStateSensorsByTypes', 'getDevices', 'GetOpenSensorsData']),
    ...mapMutations(['setFilterState', 'updateChartOne', 'selectDeviceById', 'selectDevice', 'clearSelectedDevice']),
    show(){
      console.log('show')
      console.log(this.$sensors)
    },
    async selectDateRange(dataRange){
      console.log('dataRange')
      this.isDynamicChart = false
      /*setTimeout(() => {
        this.isDynamicChart = false
      }, 10)*/
      this.plotlyData = []
      this.$root.$emit('clearSingleUpdate')
      this.selectedTypeChart = 1
      //return
      console.log(dataRange)
      this.connection.stop()
      this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
      if(this.Chart) this.Chart.destroy();
      //this.zoomChartData.options.scales.xAxes[0] = this.zoomChartData.options.scales.xAxes[0]
      this.updateChartOne(this.tempSensors)
      try {
        this.stopGetData = true
        this.chartData.data.labels = []
        this.chartData.data.datasets = []
        //const ctx1 = document.getElementById('chart1').getContext('2d')
        if(this.Chart != null) {
          this.Chart.destroy()
          //this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
          //this.chartData.options.scales.xAxes[0].realtime.onRestart()
          //this.Chart = null
          console.log(this.Chart)
        }
        if(this.Chart) this.Chart.destroy();
        this.chartData.options.zoom.enabled = true
       // this.Chart = new Chart(ctx1, this.chartData)
        //this.Chart.resetZoom()
        this.chartData.datesRange = this.datesRange
        this.chartData.rawChart = this.Chart
        //this.Chart.defaults.global.defaultFontColor = '#FFF'
        console.log(this.selectController)
        const buffData = []
        console.log(this.$sensors)
        if(this.$sensors.length == 0 && this.getSelectedDevice.deviceType == 4){

          const info = {
            dateStart: this.datesRange + 'T00:00:00.411Z',
            dateEnd: this.datesRange + 'T00:00:00.411Z',
            deviceId: this.getSelectedDevice.id,
            panelControlId: "",
            type: 1001
          }
          console.log(info)
          await this.GetOpenSensorsData(info)
          .then(dt => {
            console.log(dt)
            /*buffData.push({
              rawData: dt.sensorDatas,
              name: item.Name + item.Descriptor + item.PanelControlName
            })
            console.log({
              rawData: dt.sensorDatas,
              name: item.Name + item.Descriptor + item.PanelControlName
            })
            this.$root.$emit('chartSingleUpdate', {
              rawData: dt.sensorDatas,
              name: item.Name + item.Descriptor + item.PanelControlName,
              descriptor: desk,
              hover: {
                name: item.Name,
                controlName: item.PanelControlName
              }
            })*/
            /*
            console.log(this.$sensors.length + ' ' +ind)
            if(this.$sensors.length == ind+1 && buffData.length == index+1){
              this.plotlyData = buffData
              console.log(this.plotlyData)
            }*/
          })
          .catch(e => {
          })
        }
        await this.$sensors.forEach(async (item, ind) => {
          let type = 1
          let desk = ''
          if(item.Descriptor == '.T.'){
            type = 1
            desk = '°C'
          }
          else if(item.Descriptor == '.V.'){
            type = 2
            desk = '%'
          }
          else if(item.Descriptor == '.P.'){
            type = 3
            desk = 'mm Hg'
          }
          console.log(this.datesRange)
          const info = {
            dateStart: this.datesRange + 'T00:00:00.411Z',
            dateEnd: this.datesRange + 'T00:00:00.411Z',
            deviceId: item.DeviceId,
            panelControlId: item.PanelControlId,
            type: type
          }
          console.log(info)
          await this.GetOpenSensorsData(info)
          .then(dt => {
            buffData.push({
              rawData: dt.sensorDatas,
              name: item.Name + item.Descriptor + item.PanelControlName
            })
            console.log({
              rawData: dt.sensorDatas,
              name: item.Name + item.Descriptor + item.PanelControlName
            })
            this.$root.$emit('chartSingleUpdate', {
              rawData: dt.sensorDatas,
              name: item.Name + item.Descriptor + item.PanelControlName,
              descriptor: desk,
              hover: {
                name: item.Name,
                controlName: item.PanelControlName
              }
            })/*
            console.log(this.$sensors.length + ' ' +ind)
            if(this.$sensors.length == ind+1 && buffData.length == index+1){
              this.plotlyData = buffData
              console.log(this.plotlyData)
            }*/
          })
          .catch(e => {
          })
        })
        //this.isDynamicChart = false
      } catch(e){
        console.log(e)
      }
      this.selectController.disabled = false
    },
    stopChartOne(){
      console.log('stopChartOne')
      if(this.chartOneStopped){
        this.chartOneStopped = !this.chartOneStopped
        this.zoomChartData.options.plugins.streaming.pause = false;
      }else{
        this.chartOneStopped = !this.chartOneStopped
        this.zoomChartData.options.plugins.streaming.pause = true;
      }
    },
    addTemp(){
      console.log('addTemp')
      if(this.selectSensorType.items.filter(item => {
        return item.data == 'Temperature'
      }).length == 0){
        console.log('addTemp')
        this.selectSensorType.items.push({
          id: this.selectSensorType.items.length+1,
          data: 'Temperature'
        })
      }
    },
    addHum(){
      console.log('addTemp')
      if(this.selectSensorType.items.filter(item => {
        return item.data == 'Humidity'
      }).length == 0){
        console.log('addHum')
        this.selectSensorType.items.push({
          id: this.selectSensorType.items.length+1,
          data: 'Humidity'
        })
      }
    },
    addPress(){
      console.log('addPress')
      if(this.selectSensorType.items.filter(item => {
        return item.data == 'Pressure'
      }).length == 0){
        console.log('addPress')
        this.selectSensorType.items.push({
          id: this.selectSensorType.items.length+1,
          data: 'Pressure'
        })
      }
    },
    async selectControllerNameChange(data){
      this.selectDeviceById(data)
      console.log('this.getSelectedDevice.id = ' + this.getSelectedDevice.id)
      console.log('selectControllerNameChange')
      console.log(this.selectedTypeChart)
      if(this.selectedTypeChart == 0){
        await this.clearChart()
        console.log(data)
        console.log('unSubscribeOnSensors')
        console.log('EEEEEEEEEEEEEEEEEEE')
        console.log(this.selectSensorType)
        this.tempSensors = []
        this.$sensors = []
        this.updateChartOne([])
        this.connection.invoke("UnSubscribeGettingValuesDeviceById")
        .then(async res => {
          this.getLoadDevices.selected = data
          this.serialNumber = this.getSelectedDeviceSerialNumber
          this.selectSensorType.selected = this.filterState
          await this.selectSensorTypeChange(this.filterState)
          this.Chart.update()
          this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
        })
        .catch(e => {
          this.selectController.disabled = false
          //this.selectSensorType.selected = null
          //this.selectSensorType.items = []
          console.log(e)
        })
        //if(this.Chart)this.Chart.update()
        //this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
        console.log('AAAAAAAAAAAAAAAAAAAB')
        await this.updateSensorTypeByDeviceId(this.getSelectedDevice.id)
        await this.selectSensorTypeChange(this.filterState)
      }else{
        this.$sensors = []
        this.selectDeviceById(data)
        this.getLoadDevices.selected = data
        this.serialNumber = this.getSelectedDeviceSerialNumber
        this.selectSensorType.selected = this.filterState
        console.log('AAAAAAAAAAAAAAAAAAAB')
        await this.updateSensorTypeByDeviceId(this.getSelectedDevice.id)
        this.$root.$emit('clearSingleUpdate')
      }
      this.selectController.disabled = false
    },
    async selectSensorTypeChange(id){
      try{
        if(this.$route.path == '/OpenCharts'){
          console.log(id)
          this.$router.replace({ path: 'OpenCharts', query: { q: SensorTypeEnum[id] } })
          //location.reload()
          //this.$router.push(`OpenCharts?q=${}`).catch(() => {})
          if(this.selectedTypeChart == 0) {
            this.clearChart()
            this.setFilterState(id)
            this.$root.$emit('updateYaMapFilter')
            let data = await this.loadStateSensorsByTypes({
              id: this.getSelectedDevice.id,
              type: typeSensor
            })
            console.log(data)
          }else{

          }
          return
        }
        console.log('selectSensorTypeChange')
        this.selectSensorType.disabled = true
        this.selectController.disabled = true
        //this.selectController.selected = this.getSelectedDevice.id
        console.log(this.selectedTypeChart)
        if(this.selectedTypeChart == 0){
          this.clearChart()
          this.setFilterState(id)
          this.unSubscribeOnSensors()
          this.$root.$emit('updateYaMapFilter')
          console.log(id)
          let typeSensor = 0
          if(id == 'Temperature') typeSensor = 1
          if(id == 'Humidity') typeSensor = 2
          if(id == 'Pressure') typeSensor = 3
          let data = await this.loadStateSensorsByTypes({
            id: this.getSelectedDevice.id,
            type: typeSensor
          })
          console.log(data)
          const device = this.getLoadDevices.filter(d => d.id == this.getSelectedDevice.id)[0]
          Object.keys(data.states).forEach((element, index) => {
            if((data.states[element].property == 'T' && typeSensor == 1)
            || (data.states[element].property == 'p' && typeSensor == 2)
            || (data.states[element].property == 'P' && typeSensor == 3)){
              /*this.selectController.items.forEach(async (item, ind) => {
                item.raw = 
              })*/
              let Descriptor = '.T.'
              
              if(typeSensor == 1){
                Descriptor = '.T.'
              }else if(typeSensor == 2){
                Descriptor = '.V.'
              }
              else if(typeSensor == 3){
                Descriptor = '.P.'
              }
              this.$sensors.push({
                UserId: this.getUser.id,
                DeviceId: this.getSelectedDevice.id,
                PanelControlId: data.states[element].elementId,
                Descriptor: Descriptor,
                Name: device.name,
                PanelControlName: element
              })
              this.$root.$emit('updateSensors', this.$sensors)
              console.log(this.$sensors)
              this.subscribeOnSensor(data.states[element].elementId, this.getSelectedDevice.id,false)
            }
          })
          this.Chart.update()
          this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
          this.selectSensorType.disabled = false
          this.selectController.disabled = false
        }else{
          this.isDynamicChart = false
          /*setTimeout(() => {
            this.isDynamicChart = false
          }, 10)*/
          //this.selectedTypeChart = 0
          console.log('YEP')
          this.plotlyData = []
          this.$root.$emit('clearSingleUpdate')
          this.setFilterState(id)
          console.log('setFilterState')

          this.tempSensors = []
          this.$sensors = []
          this.connection.invoke("UnSubscribeGettingValuesDeviceById")
          .then(res => console.log('unSubscribeOnSensors'))
          .catch(e => console.log(e))


          console.log('unSubscribeOnSensors')
          this.$root.$emit('updateYaMapFilter')
          console.log(id)
          let typeSensor = 1
          if(id == 'Temperature') typeSensor = 1
          if(id == 'Humidity') typeSensor = 2
          if(id == 'Pressure') typeSensor = 3
          let data = await this.loadStateSensorsByTypes({
            id: this.getSelectedDevice.id,
            type: typeSensor
          })
          //this.isDynamicChart = false
          const device = this.getLoadDevices.filter(d => d.id == this.getSelectedDevice.id)[0]
          console.log('122222')
          this.selectedTypeChart = 1
              await this.selectDateRange(this.datesRange)
          Object.keys(data.states).forEach(async (element, index) => {
            if((data.states[element].property == 'T' && typeSensor == 1)
            || (data.states[element].property == 'p' && typeSensor == 2)
            || (data.states[element].property == 'P' && typeSensor == 3)){
              /*this.selectController.items.forEach(async (item, ind) => {
                item.raw = 
              })*/
              console.log(data.states[element])
              let Descriptor = '.T.'
              if(typeSensor == 2){
                Descriptor = '.V.'
              }
              else if(typeSensor == 3){
                Descriptor = '.P.'
              }
              this.$sensors.push({
                UserId: this.getUser.id,
                DeviceId: this.getSelectedDevice.id,
                PanelControlId: data.states[element].elementId,
                Descriptor: Descriptor,
                Name: device.name,
                PanelControlName: element
              })
              //this.subscribeOnSensor(data.states[element].elementId, this.getSelectedDevice.id,false)
            }
          })
          await this.selectDateRange(this.datesRange)
          this.$root.$emit('updateSensors', this.$sensors)
          console.log(this.$sensors)
          this.selectSensorType.disabled = false
          this.selectController.disabled = false
        }
        this.selectController.disabled = false
      }catch(e){
        console.log(e)
        this.selectController.disabled = false
      }
    },
    changeSensor(sensId){
      console.log('changeSensor')
      console.log(sensId)
    },
    resetZoom(){
      console.log('resetZoom')
      this.charts.resetZoom()
    },
    swAnim(){
      console.log('swAnim')
      this.isPause = !this.isPause
      this.charts.options.plugins.streaming.pause = this.isPause
    },
    async fixSelectedDevice(){
      console.log('fixSelectedDevice')
      if((this.getAccessToken || storageService.getToken()) && (this.getSelectedDeviceSerialNumber || storageService.getSelectedDevice())){
        this.serialNumber = this.getSelectedDeviceSerialNumber? this.getSelectedDeviceSerialNumber : storageService.getSelectedDevice()
        console.log(this.serialNumber)
        storageService.setSelectedDevice(this.serialNumber)
        storageService.setSelectedDeviceId(this.getSelectedDevice.id)
      }
      console.log('fixSelectedDevice1')
      if(this.getSelectedDevice == null){
        console.log('fixSelectedDevice1.0')
        await this.getDevices()
        console.log('fixSelectedDevice1.1')
        this.selectDevice(this.serialNumber)
        console.log('fixSelectedDevice1.2')
      }
      console.log('fixSelectedDevice2')
      if(this.getSelectedDevice != null){
        this.selectController.selected = this.getSelectedDevice.id
      }
      console.log('endfixSelectedDevice')
    },
    mountChart(){
      console.log('mountChart')
      this.isDynamicChart = true
      const ctx1 = document.getElementById('chart1').getContext('2d')
      if(this.Chart != null) {
        this.Chart.destroy()
        //this.Chart = null
        console.log(this.Chart)
      }
      if(this.Chart) this.Chart.destroy();
      this.Chart = new Chart(ctx1, this.zoomChartData)
      this.Chart.canvas.parentNode.style.height = this.isMobileDisplay? '372px' : '460px';
      this.Chart.resetZoom()
      //this.Chart.defaults.global.defaultFontColor = '#FFF'
      this.Chart.update()
    },
    connectToHub(){
      console.log('connectToHub')
      this.connection = 
        new HubConnectionBuilder()
          .withUrl(`${config}/open_state`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .configureLogging(LogLevel.Error)
          .build()
      this.connection.start()
      .then(() => {})
      .catch((err) => {})

      this.connection.on("ReceiveStatus", (message) => {})
      this.connection.on("ReceiveSubscribeStatus", (message) => {
        //console.log(this.filterStateCode)
        const element = JSON.parse(message)
        if(element){
          if(element.State.length > 12){
            const dataSens = element.State.slice(1, 6)
            const temp = element.State.slice(9)

            let tempOut = temp[0] == '0' ? '' : '-'
            tempOut += temp[1] + temp[2] + '.' + temp[3]
            
            if(element.State.includes('.T.') && dataSens != '01.01'){
              //console.log(element)
              if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
                //console.log('l=0')
                if(this.filterStateCode == 1)
                this.tempSensors.push({
                  sensorType: 1,
                  key: element.Id,
                  value: tempOut,
                  objectName: element.Name,
                  objectSensor: element.Sensor,
                  descript: '°C'
                })
              }else{
                //console.log(this.tempSensors.length)
                this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = tempOut
              }
              this.updateChartOne(this.tempSensors)
            }
            else if(element.State.includes('.P.') && dataSens != '01.01'){
              let pressOut = temp[0] + temp[1] + temp[2]
              if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
                console.log('l=0')
                if(this.filterStateCode == 3)
                this.tempSensors.push({
                  sensorType: 3,
                  key: element.Id,
                  value: pressOut,
                  objectName: element.Name,
                  objectSensor: element.Sensor,
                  descript: 'mm Hg'
                })
              }else{
                this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = pressOut
              }
              this.updateChartOne(this.tempSensors)
            }
            else if(element.State.includes('.V.') && dataSens != '01.01'){
              let humOut = temp[1] + temp[2] + '.' + temp[3]
              if(this.tempSensors.filter(sensor => sensor.key == element.Id).length == 0){
                console.log(this.filterStateCode)
                if(this.filterStateCode == 2)
                this.tempSensors.push({
                  sensorType: 2,
                  key: element.Id,
                  value: humOut,
                  objectName: element.Name,
                  objectSensor: element.Sensor,
                  descript: '%'
                })
              }else{
                this.tempSensors.filter(sensor => sensor.key == element.Id)[0].value = humOut
              }
              this.updateChartOne(this.tempSensors)
            }
          }
          this.updateChartOne(this.tempSensors)
          this.selectSensorType.disabled = false
          this.selectController.disabled = false
        }
      })
      this.connection.on("ReceiveMessage", (message) => {})
      this.connection.onclose(e => {})
    },
    updateControllers(){
      console.log('updateControllers')
      this.getLoadDevices.forEach(device => {
        this.selectController.items.push({
          id: device.id,
          data: device.name,
          connection: false //!device.connection
        })
      })
      if(this.getSelectedDevice != null){
        this.getLoadDevices.selected = this.getSelectedDevice.id
      }
    },
    async updateSensorTypeByDeviceId(id){
      console.log('updateSensorTypeByDeviceId')
      try{
        console.log('typesData ' + id)
        let typesData = await this.loadStateTypes(id)
        console.log(typesData)
        typesData.states.forEach(state => {
          if(state == 66){
            this.addTemp()
            this.addHum()
            this.addPress()
          }
          else if(state == 67){
            this.addTemp()
            this.addHum()
          }
          else if(state == 68){
            this.addTemp()
          }
        })
        this.selectSensorType.selected = this.filterState
        await this.selectSensorTypeChange(this.filterState)
        console.log('DAAAAAAAAA')
        console.log(this.selectSensorType)
      }catch(e){
        console.log('updateSensorTypeByDeviceId ' + e)
        this.selectSensorType.items = []
        this.selectSensorType.disabled = true
      }
      this.selectController.disabled = false
      //this.updateControllers()
    },
    unSubscribeOnSensors(){
      console.log('unSubscribeOnSensors')
      this.tempSensors = []
      this.$sensors = []
      this.updateChartOne([])
      if(this.connection.state == "Connected")
      this.connection.invoke("UnSubscribeGettingValuesDeviceById")
      .then(res => console.log('unSubscribeOnSensors'))
      .catch(e => console.log(e))
      if(this.Chart)this.Chart.update()
      this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
    },
    dataSubscription(data){

    },
    subscribeOnSensor(panelControlId, deviceId, isUnsubscribe){
      console.log('subscribeOnSensor')
      if(panelControlId == null || deviceId == null) return
      console.log(panelControlId + ' ' + deviceId)
      if(isUnsubscribe){
        this.unSubscribeOnSensors()
      }
      let dataSubscription = {
        UserId: this.getUser.id,
        DeviceId: deviceId,
        PanelControlId: panelControlId
      }
      this.selectController.items.push({id: dataSubscription.DeviceId, data: dataSubscription.Name, raw: dataSubscription})
      console.log(this.connection)
      if(this.connection == null || this.connection.state == "Disconnected"){
        this.connectToHub()
      }
      console.log(dataSubscription)
      this.connection.invoke("SubscribeGettingValuesDeviceById", dataSubscription)
      .then(res => console.log('subscribeOnSensor ' + panelControlId + ' ' + deviceId))
      .catch(async e => {
        console.log(e)
      })
    },
    async clearChart(){
      console.log('clearChart')
      this.plotlyData = []
      this.$root.$emit('clearSingleUpdate')
      
      if(this.chartOneStopped){
        this.chartOneStopped = !this.chartOneStopped
        this.zoomChartData.options.plugins.streaming.pause = false;
      }
      if(this.selectedTypeChart == 0) return
      this.selectSensorType.selected = 'Temperature'
      //this.selectController.items = []
      this.selectedTypeChart = 0
      if(this.Chart){
        this.Chart.destroy();
        //this.Chart.resetZoom()
      }
      await this.connectToHub()
      await this.fixSelectedDevice()
      this.mountChart()
      await this.updateControllers()
      if(this.getSelectedDevice != null){
        await this.updateSensorTypeByDeviceId(this.getSelectedDevice.id)
      }
    },
    addChartRealTimeData(){
      console.log('addChartRealTimeData')
      //this.tempSensors = []
      //this.elementsId = []
      Object.keys(data.states).forEach(state => {
        if((id == 'Temperature' && data.states[state].property == 'T') || (id == 'Humidity' && data.states[state].property == 'p') || (id == 'Pressure' && data.states[state].property == 'P')){
          this.tempSensors.push({
            key: `${id} Name: ${state}`,
            value: null,
            address: data.states[state].address,
            elementId: data.states[state].elementId[0] + data.states[state].elementId[1] + '.' + data.states[state].elementId[2] + data.states[state].elementId[3]
          })
          this.elementsId.push(this.tempSensors[this.tempSensors.length-1])
        }
      })
      this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
      this.Chart.update()
      this.updateChartOne(this.tempSensors)
      console.log(this.tempSensors)
    }
  },
  async created(){
    try{
      this.clearSelectedDevice()
    }
    catch(e){
      console.warn(e)
    }
    try {
      this.selectSensorType.selected = this.filterState
      console.log('created')
      this.datas= []
      for(let i = 0; i < 25; i++){
        if(i!=24){
          const dat = new Date()
          dat.setMinutes(0)
          dat.setSeconds(0)
          dat.setHours(i)
          this.datas.push(dat)
        }else{
          const dat = new Date()
          dat.setMinutes(59)
          dat.setSeconds(0)
          dat.setHours(23)
          this.datas.push(dat)
        }
      }
      Chart.defaults.global.defaultFontColor = 'white'
      await this.connectToHub()
      await this.fixSelectedDevice()
      this.mountChart()
      await this.updateControllers()
      if(this.getSelectedDevice != null){
        await this.updateSensorTypeByDeviceId(this.getSelectedDevice.id)
      }
      this.$root.$on('updateChartFull', async (data) => {
        await this.selectSensorTypeChange(data)
        console.warn('updateChartFull')
        this.selectSensorType.selected = data
      })
      this.$root.$on('subscribeSensor', async (data) => {
        console.log(data)
        if(this.getRawState.length > 0){
          const findDataRaw = this.getRawState.filter(d => d.Id == data.DeviceId)
          if(findDataRaw.length > 0){
            this.selectSensorType.items = []
            console.log(findDataRaw[0])
            findDataRaw[0].openSensorTypes.forEach(s => {
              this.selectSensorType.items.push({
                id: this.selectSensorType.items.length+1,
                data: SensorTypeEnum[s.Type]
              })
            })
          }
        }
        /*this.selectSensorType.items = []
        this.selectSensorType.items.push({data: 'Temperature'})*/
        if(this.selectedTypeChart == 0){
          if(this.$sensors.indexOf({
            UserId: this.getUser == null ? 0 : this.getUser.id,
            DeviceId:  data.DeviceId,
            PanelControlId: data.PanelControlId
          }) == -1){
            this.$sensors.push({
              UserId: data.UserId,
              DeviceId:  data.DeviceId,
              PanelControlId: data.PanelControlId,
              Descriptor: data.Descriptor,
              Name: data.Name,
              PanelControlName: data.PanelControlName,
              hide:false
            })
            this.$root.$emit('updateSensors', this.$sensors)
          }
          if(this.selectController.items.indexOf({id: data.DeviceId, data: data.Name, raw: data}) == -1)
            this.selectController.items.push({id: data.DeviceId, data: data.Name, raw: data})
          this.selectController.selected = data.DeviceId

          this.connection.invoke("SubscribeGettingValuesDeviceById", data)
            .then(res => console.log(res))
            .catch(e => console.log(e))
          this.Chart.update()
        }else{
          if(this.$sensors.indexOf({
            UserId: this.getUser == null ? 0 : this.getUser.id,
            DeviceId:  data.DeviceId,
            PanelControlId: data.PanelControlId
          }) == -1){
            this.$sensors.push({
              UserId: data.UserId,
              DeviceId:  data.DeviceId,
              PanelControlId: data.PanelControlId,
              Descriptor: data.Descriptor,
              Name: data.Name,
              PanelControlName: data.PanelControlName
            })
            this.$root.$emit('updateSensors', this.$sensors)
          }
          if(this.selectController.items.indexOf({id: data.DeviceId, data: data.Name, raw: data}) == -1)
            this.selectController.items.push({id: data.DeviceId, data: data.Name, raw: data})
          this.selectController.selected = data.DeviceId

          this.connection.invoke("SubscribeGettingValuesDeviceById", data)
            .then(res => console.log(res))
            .catch(e => console.log(e))

          await this.selectDateRange(this.datesRange)
        }
        document.getElementById("ya-map-open").scrollIntoView();
      })

      //await this.mounted()
      this.selectSensorType.disabled = false
      this.selectController.disabled = false
    }catch (e){
      console.log(e)
    }
    console.error(this.filterState)
    this.selectSensorType.selected = this.filterState
    this.selectController.selected = null
  },
  mounted(){
      this.$emit('openBigMapStart')
  },
  beforeDestroy(){
    console.log('beforeDestroy')
    if(this.chartOneStopped){
      this.chartOneStopped = !this.chartOneStopped
      this.zoomChartData.options.plugins.streaming.pause = false;
    }
    //this.zoomChartData.options.scales.xAxes[0].realtime.onRestart()
    //this.chartData.options.scales.xAxes[0].realtime.onRestart()
    //this.charts.destroy();
    //this.charts[1].destroy();
    //this.updateChartOne(null)
    this.Chart.destroy();
    this.Chart = null
    this.connection.stop()
    this.connection = null
  }
};
</script>
<style>
.fix-pad{
  padding: 3px;
}
.fix-h{
  min-height: 474px;
}
.dv-btn-micro{
  font-size: 12pt !important;
  width: 160px !important;
  max-width: 250px !important;
  height: 30pt !important;
  font-weight: 400 !important;
  color: white !important;
  border: 1px solid #fff;
  background-color: rgba(39, 39, 39, 0) !important;
  margin-top: -40px;
}
.dv-btn-micro:hover{
  background-color: rgba(0, 0, 0, 1) !important;
}
.mt-fix{
  margin-top: -40px;
}
.v-picker__actions{
  background-color: rgba(0, 0, 0, 1) !important;
}
.chart-pad{
  padding:12px 2.8%;
}
.fix-chart-control{
  margin: 0;
  padding:12px 0;
}
.mini-controls {
  padding: 0 3%;
  width: 94% !important;
}
.fix-ml{
  margin-left: 1px;
}
</style>