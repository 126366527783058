import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()
import storageService from "../../services/storageService";
const SensorTypeEnum = require('../../Enums/SensorType')
export default {
  actions: {
    async loadStateTypes(context:any, id:any) {
      console.log(id)
      return await axios.get(`/api/State/StateTypes/${id}`)
      .then((response:any) => {
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async loadStateSensorsByTypes(context:any, data:any) {
      console.log(data)
      return await axios.get(`/api/State/StateSensorsByTypes/${data.id}/${data.type}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    }
  },
  state: {
    dataState: null,
    filterState: null,
    filterStateCode: null,
    stateRaw: null,
    HLState: null
  },
  mutations: {
    setHLStates(state: any, data:any){
      state.HLStates = data
    },
    setStatesRaw(state: any, data:any){
      state.stateRaw = data
    },
    setFilterSimpleCode(state: any, filter: any){
      storageService.setFilter(filter.text)
      state.filterState = filter.text
      state.filterStateCode = filter.code
    },
    setFilterState(state: any, filter: any){
      storageService.setFilter(filter)
      state.filterState = filter
      state.filterStateCode = SensorTypeEnum[filter]
      /*
      if(filter == SensorTypeEnum[1]){
        state.filterStateCode = 1
      }
      else if(filter == SensorTypeEnum[2]){
        state.filterStateCode = 2
      }
      else if(filter == SensorTypeEnum[3]){
        state.filterStateCode = 3
      }*/
    }
    /*saveUser(state:any, user:any){
      state.user = user
      console.log(user)
    }*/
  },
  getters:{
    getHLDescriptor: (state:any) => (data:any) => {
      if(state.HLStates && state.HLStates[data]){
        return state.HLStates[data].measure
      }else{
        return ''
      }
    },
    getHLStates: (state:any) => (data:any) => {
      if(state.HLStates && state.HLStates[data]){
        return state.HLStates[data].name
      }else{
        return ''
      }
    },
    getRawState(state:any){
      return state.stateRaw
    },
		filterState(state:any) {
      if(state.filterState == null){
        const filter = storageService.getFilter()
        state.filterState = filter
      }
      return state.filterState
		},
    filterStateCode(state:any){
      if(state.filterState == null || state.filterStateCode == null){
        const filter = storageService.getFilter()
        if(filter == SensorTypeEnum[1]){
          state.filterStateCode = 1
        }
        else if(filter == SensorTypeEnum[2]){
          state.filterStateCode = 2
        }
        else if(filter == SensorTypeEnum[3]){
          state.filterStateCode = 3
        }
      }
      return state.filterStateCode
    }
  }
}