import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()

export default {
  actions: {
    async deleteUserConfig(context:any, data:any) {
      //if(data.userPhone.startsWith('+')) data.userPhone = data.userPhone.slice(1)
      return await axios.post(`/api/Head/DeleteUserConfig/${data.userId}/${data.serialNumber}`)
      .then((response:any) => {
        console.log(response)
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async createUserByDevice(context:any, data:any) {
      //if(data.userPhone.startsWith('+')) data.userPhone = data.userPhone.slice(1)
      return await axios.post(`/api/Account/createUserByDevice`, data)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    },
    async getUsersByDevice(context:any, data:any) {
      return await axios.get(`/api/Account/UsersByDevice/${data}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        throw err
      })
    },
    async loginUserByPhone(context:any, data:any) {
      if(data.phone.startsWith('+')) data.phone = data.phone.slice(1)
      return await axios.get(`/api/Account/Login/${data.phone}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        throw err
      })
    },
    async loginUserByPhoneAndCode(context:any, data:any) {
      if(data.phone.startsWith('+')) data.phone = data.phone.slice(1)
      return await axios.post(`/api/Account/LoginCheck/${data.phone}/${data.code}`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('saveUser', response.data.user)
          axiosService.updateAccessToken(response.data.accessToken)
          context.commit('saveAccessToken', response.data.accessToken)
          context.commit('saveRefreshToken', response.data.refreshToken)
          localStorage.setItem('refresh_token', response.data.refreshToken)
          if(response.data.dateTime){
            context.commit('setDateTime', new Date(response.data.dateTime.split('.')[0]))
            context.commit('startInteval', null)
          }
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        throw err
      })
    },
    async loginUserByToken(context:any) {
      return await axios.get(`/api/Account/Auth`)
      .then((response:any) => {
        if(response.data.result.code == 0){
          context.commit('saveUser', response.data.user)
          if(response.data.dateTime){
            context.commit('setDateTime', new Date(response.data.dateTime.split('.')[0]))
            context.commit('startInteval', null)
          }
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        throw err
      })
    },
    async getUserConfig(context:any, data:any) {
      return await axios.get(`/api/Account/GetUserConfig/${data.userId}/${data.serialNumber}`)
      .then((response:any) => {
        //console.log(response)
        if(response.data.result.code == 0){
          //context.commit('saveUser', response.data.user)
          return {config: JSON.parse(response.data.config)}
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        throw err
      })
    }
  },
  state: {
    user: null,
    serverDateTime: null,
    intervalUpdateDateTime: null
  },
  mutations: {
    saveUser(state:any, user:any){
      state.user = user
    },
    updateUser(state:any, user:any){
      state.user.firstName = user.firstName
      state.user.email = user.email
    },
    startInteval(state:any, dateTime:null){
      if(state.intervalUpdateDateTime == null){
        state.intervalUpdateDateTime = setInterval(() => {
          const dt = new Date(state.serverDateTime)
          dt.setSeconds(state.serverDateTime.getSeconds() + 1);
          state.serverDateTime = dt
        }, 1000)
      }
    },
    setDateTime(state:any, dateTime:Date){
      state.serverDateTime = dateTime
    }
  },
  getters:{
		getUser(state:any) {
      return state.user
		},
		getServerDateTime(state:any) {
      return state.serverDateTime
		}
  }
}