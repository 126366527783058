import axiosService from "../../services/axiosService";
const axios = axiosService.getInstance()

export default {
  actions: {
    async createFirmware(context:any, data:object) {
      return await axios.post(`/api/Account/DevicesLocation`, data)
      .then((response:any) => {
        console.log(response)
        if(response.data.result.code == 0){
          //context.commit('loadFirmwares', response.data.devices)
          return response.data
        }else{
          throw response.data.result
        }
      })
      .catch((err:any) => {
        console.log(err)
        throw err
      })
    }
  },
  state: {
    firmwares:[]
  },
  mutations: {
    loadFirmwares(state:any, firmwares:any){
      state.firmwares = firmwares
    }
  },
  getters:{
		/*getLoadDevices(state:any) {
      return state.devices
		},
    getSelectedDeviceSerialNumber(state:any) {
      return state.selectedDeviceSerialnumber
		}*/
  }
}
