<template>
  <div>
    <div :style="`height:100px;`" @click="emitGlobalClickEvent">
      <div class="login-text" align="center">
        CHART {{filterState}}
      </div>
      <div class="container mt-2" v-show="showAccount">
        <v-row class="d-flex align-start">
          <v-col class="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12">
            <cube @controlButtonClick="controlButtonClick"/>
          </v-col>
          <v-col class="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 offset-xl-1 offset-lg-1 offset-md-1">
            <div class="home">
              <div>
                <Ch @openBigMap="chClick">
                </Ch>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-snackbar
        v-model="snackbar.active"
        :timeout="snackbar.timeout"
      >
        <div class="text-center">
          {{ snackbar.text }}
        </div>
      </v-snackbar>
    </div>
    <YaMap></YaMap>
  </div>
</template>

<script>
import smoothHeight from 'vue-smooth-height'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Vue from "vue"
import Cube from "../Cube/Cube.vue"
import Ch from "./ch.vue"
import YaMap from "../Maps/YaMap.vue"
export default Vue.extend({
  mixins:[smoothHeight],
  name: "SingleChart",
  components: {
    cube: Cube,
    Ch: Ch,
    YaMap: YaMap
  },
  data: () => ({
    showAccount: true,
    snackbar: {
      active: false,
      text: null,
      timeout: 6000
    },
  }),
  computed: {
    ...mapGetters(['getUser', 'getLoadDevices', 'getSelectedDevice', 'filterState']),
  },
  methods: {
    ...mapActions(['getDevices', 'getCoordsDevices', 'devicesLoadOnlineStatus']),
    ...mapMutations(['selectDevice']),
    emitGlobalClickEvent(){
      this.$root.$emit('closeBigMapGlobal')
    },
    clickAddDeviceMap(data){
      console.log(data)
    },
    chClick(){
      this.$root.$emit('openBigMapGlobal')
    }, 
    controlButtonClick(){
      if(this.getSelectedDevice && this.getSelectedDevice.connection){
        this.$router.push('control')
        .catch(() => {})
      }
    },
  },
  async created(){
    this.$root.$on('closeAccountGlobal', () => {
      this.showAccount = false
    })
    this.$root.$on('closeBigMapGlobal', () => {
      this.showAccount = true
    })
    await this.getDevices()
  },
  mounted(){
  }
});
</script>
<style lang="sass">
.login-text
  padding-top: 3vh
.v-card 
  background-color: rgba(0,0,0,0) !important
  border: 1px solid white !important
.fix-padding
  padding: 2px
.map-p
  margin-bottom: 5px
.map-button
  border: 1px solid black
  padding: 5px
  background: black
  color: white
  border-radius: 5px
</style>