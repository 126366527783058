import Vue from "vue";
import Vuex from "vuex";
import Devices from "./Devices";
import Configurations from "./Configurations";
import Controllers from "./Controllers";
import User from "./User";
import Firmwares from "./Firmwares";
import Tokens from "./Tokens";
import Helpers from "./Helpers";
import Charts from "./Charts";
import States from "./States";
import Cameras from "./Cameras";
import SimpleMode from "./SimpleMode";
import Connections from "./Connections";

Vue.use(Vuex);

export default new Vuex.Store({
  modules:{
    Devices,
    Configurations,
    Controllers,
    User,
    Tokens,
    Helpers,
    Firmwares,
    Charts,
    States,
    Cameras,
    SimpleMode,
    Connections
  },
  state: {},
  mutations: {
    resetState(state:any){
      User.state.user = null

      Tokens.state.accessToken = null
      Tokens.state.refreshToken = null

      Firmwares.state.firmwares = []

      Devices.state.devices = []
      Devices.state.coords = null
      Devices.state.selectedDeviceSerialnumber = null
      Devices.state.selectedDevice = null
      Devices.state.descriptors = []

      Controllers.state.deviceTypes = []

      Configurations.state.configs = []
      Configurations.state.rawConfigs = []

      States.state.dataState = null
      Connections.state.mapConnection = null
    },
  },
  actions: {},
});
