enum SensorType
{
  Temperature = 1,
  Humidity = 2,
  Pressure = 3,
  Noise = 4,
  CarbonMonoxide = 5,
  Carbonic = 6,
  Rain = 7,
  AirQuality = 8,
  SensorReserve = 9,
  WaterTemperature = 10,
  WaterLevel = 11,
  WaterPH = 12,
  DissolvedOxygen = 13,
  WaterORP = 14,
  WaterQualit = 15,
  FluidPressure = 16,
  HeatConsumptionMeter = 17,
  WaterMeter = 18,
  SoilTemperature = 19,
  SoilMoisture = 20,
  SoilPH = 21,
  Vibration = 22,
  GyroscopeX = 23,
  GyroscopeY = 24,
  GyroscopeZ = 25,
  LightSensor = 26,
  ElectricityConsumptionMeter = 27,
  DV_REG_ACP_Input = 28,
  DV_REG_Digital_Input = 29,
  DVC_GalaxyACP0_Input = 30,
  DVC_GalaxyACP1_Input = 31,
  DVC_Galaxy_Digital0_Input = 32,
  DVC_Galaxy_Digital1_Input = 33,
  DVC_Galaxy_Digital2_Input = 34,
  DVC_Galaxy_Digital3_Input = 35,
  ACP = 36,
  DVC_Point = 1000,
  EnergyToday_ActiveForward = 1001,
  EnergyToday_ReactiveForward = 1002,
  EnergyToday_ReactiveReturn = 1003,
  VoltagePhaseA = 1026,
  VoltagePhaseB = 1027,
  VoltagePhaseC = 1028,
  CurrentPhaseA = 1004,
  CurrentPhaseB = 1005,
  CurrentPhaseC = 1006,
  CosPhiPhaseA = 1007,
  CosPhiPhaseB = 1008,
  CosPhiPhaseC = 1009,
  CosPhiSum = 1010,
  ActivePowerPhaseA = 1011,
  ActivePowerPhaseB = 1012,
  ActivePowerPhaseC = 1013,
  ActiveInstantPower_PhaseSum = 1014,
  ActiveInstantPower_PhaseA = 1015,
  ActiveInstantPower_PhaseB = 1016,
  ActiveInstantPower_PhaseC = 1017,
  ReactiveInstantPower_PhaseSum = 1018,
  ReactiveInstantPower_PhaseA = 1019,
  ReactiveInstantPower_PhaseB = 1020,
  ReactiveInstantPower_PhaseC = 1021,
  FullInstantPower_PhaseSum = 1022,
  FullInstantPower_PhaseA = 1023,
  FullInstantPower_PhaseB = 1024,
  FullInstantPower_PhaseC = 1025,
  CollectedEnergySumTarif_PhaseA = 1029,
  CollectedEnergySumTarif_PhaseB = 1030,
  CollectedEnergySumTarif_PhaseC = 1031,
  CollectedEnergyTarif1_PhaseA = 1032,
  CollectedEnergyTarif1_PhaseB = 1033,
  CollectedEnergyTarif1_PhaseC = 1034,
  CollectedEnergyTarif2_PhaseA = 1035,
  CollectedEnergyTarif2_PhaseB = 1036,
  CollectedEnergyTarif2_PhaseC = 1037,
  CollectedEnergyTarif3_PhaseA = 1038,
  CollectedEnergyTarif3_PhaseB = 1039,
  CollectedEnergyTarif3_PhaseC = 1040,
  CollectedEnergyTarif4_PhaseA = 1041,
  CollectedEnergyTarif4_PhaseB = 1042,
  CollectedEnergyTarif4_PhaseC = 1043,
  EnergyTodayTarif1_ActiveForward = 1044,
  EnergyTodayTarif1_ReactiveForward = 1045,
  EnergyTodayTarif1_ReactiveReturn = 1046,
  EnergyTodayTarif2_ActiveForward = 1047,
  EnergyTodayTarif2_ReactiveForward = 1048,
  EnergyTodayTarif2_ReactiveReturn = 1049,
  EnergyTodayTarif3_ActiveForward = 1050,
  EnergyTodayTarif3_ReactiveForward = 1051,
  EnergyTodayTarif3_ReactiveReturn = 1052,
  EnergyTodayTarif4_ActiveForward = 1053,
  EnergyTodayTarif4_ReactiveForward = 1054,
  EnergyTodayTarif4_ReactiveReturn = 1055,
  CollectedActiveEnergy_TarifSum = 1100,
  CollectedReactiveEnergy_TarifSum = 1101,
  Voltage = 1115,
  Current = 1116,
  Power = 1102,
  CosPhi = 1103,
  FullPower = 1104,
  ReactivePower = 1105,
  CollectedActiveEnergy_Tarif1 = 1106,
  CollectedActiveEnergy_Tarif2 = 1107,
  CollectedActiveEnergy_Tarif3 = 1108,
  CollectedActiveEnergy_Tarif4 = 1109,
  CollectedReactiveEnergy_Tarif1 = 1110,
  CollectedReactiveEnergy_Tarif2 = 1111,
  CollectedReactiveEnergy_Tarif3 = 1112,
  CollectedReactiveEnergy_Tarif4 = 1113,
  InstantPowerInResistor = 1114,
  WaterConsumption = 1200,
  Adc1 = 1300,
  Adc2 = 1301,
  TmprA = 1302,

  Input1 = 2000,
  Input2 = 2001,
  Input3 = 2002,
  Dout1 = 2003,
  Dout2 = 2004,
  Dout3 = 2005,
  Adxl1 = 2006,
  Adxl2 = 2007,
  Adxl3 = 2008,
  HLTemperature = 2009,


  UnknownType = 5000
}
module.exports = SensorType