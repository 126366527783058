<template>
  <div class="map">
    <yandex-map
      ref="map"
      :settings="settings"
      :coords="coordsCenter"
      :zoom="yaMapZoom"
      :controls="[]"
      class="ya-map"
      value="map"
      :class="{'ya-map-open': bigMap}"
      @click="resizeToBigMap">
      <ymap-marker
        v-for="(marker, index) in coordsComputed" :key="index"
        :markerId="index"
        :coords="marker.coords"
        :icon="marker.icon"
        @click="marker.online && getDeviceById(marker.id) ? markerClick(marker) : ''"
        @mouseenter="(marker.online || marker.isOwner) && getDeviceById(marker.id) ? mouseEnter(marker) : ''"
        :balloon-template="marker.balloonContent"
      ></ymap-marker>
    </yandex-map>
  </div>
</template>
<script>
import SensorTypeEnum from '../../Enums/SensorType'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import { mount } from '@vue/test-utils'
const markers = {
  markerOwnerOnlineClose: require('../../assets/icons/markerOwnerOnlineClose.png'), //мой вкл закрыт
  markerOwnerOfflineClose: require('../../assets/icons/markerOwnerOfflineClose.png'), //мой выкл закрыт

  markerOwnerOnlineOpen: require('../../assets/icons/markerOwnerOnlineOpen.png'), //мой вкл открыт
  markerOwnerOfflineOpen: require('../../assets/icons/markerOwnerOfflineOpen.png'), //мой выкл открыт

  markerOnlineClose: require('../../assets/icons/markerOnlineClose.png'), //не мой вкл закрыт
  markerOfflineClose: require('../../assets/icons/markerOfflineClose.png'), //не мой выкл закрыт

  markerOnlineOpen: require('../../assets/icons/markerOnlineOpen.png'), //не мой вкл открыт
  markerOfflineOpen: require('../../assets/icons/markerOfflineOpen.png'), //не мой выкл открыт

  
  markerOwnerOnlineCloseSelected: require('../../assets/icons/markerOwnerOnlineCloseSelected.png'), //мой вкл закрыт
  markerOwnerOfflineCloseSelected: require('../../assets/icons/markerOwnerOfflineCloseSelected.png'), //мой выкл закрыт

  markerOwnerOnlineOpenSelected: require('../../assets/icons/markerOwnerOnlineOpenSelected.png'), //мой вкл открыт
  markerOwnerOfflineOpenSelected: require('../../assets/icons/markerOwnerOfflineOpenSelected.png'), //мой выкл открыт

  markerOnlineCloseSelected: require('../../assets/icons/markerOnlineCloseSelected.png'), //не мой вкл закрыт
  markerOfflineCloseSelected: require('../../assets/icons/markerOfflineCloseSelected.png'), //не мой выкл закрыт

  markerOnlineOpenSelected: require('../../assets/icons/markerOnlineOpenSelected.png'), //не мой вкл открыт
  markerOfflineOpenSelected: require('../../assets/icons/markerOfflineOpenSelected.png'), //не мой выкл открыт
}

export default {
  htmlFather: null,
  scrollElement: null,
  components: { yandexMap, ymapMarker },
  props:['sortData'],
  data: () => ({
    yaMapZoom: 16,
    coordsCenter:[process.env.VUE_APP_ui_ya_map_lat, process.env.VUE_APP_ui_ya_map_lon],
    sensorTypes: [],
    coords:[],
    settings: {
      apiKey: process.env.VUE_APP_ui_ya_map_api_key,
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    },
    hideAccount: true,
    devicesCoords: null,
    connection: null,
    connectionSensors: null,
    bigMap:false,
    fetchSensorData:{
      DeviceId: null,
      PanelElementsId: []
    },
    baloonCloseButton: null,
    tempSensors: [],
    selectedSensors: [],
    buttonsClass: [],
    buttonsElements:[],
    spanElements:[],
    sensors: [],
    activeId: null,
    lastMarker: null
  }),
  computed: {
    ...mapGetters(['getLastMapData','getAccessToken','getUser', 'getLoadDevices',
    'getSelectedDevice', 'filterState', 'filterStateCode', 'getStateConnection',
    'getDeviceById', 'getSubscribeCharts']),
    coordsComputed: function() {
      let i = 0
      if (this.getSelectedDevice!=undefined&&this.getSelectedDevice!=null)
      {
        if(this.coords.length)
        {
          if (this.coords.some(element=>{
            return element.id==this.getSelectedDevice.id
          }))
          {
            if(this.activeId!=this.getSelectedDevice.id)
            {
              this.activeId=this.getSelectedDevice.id,
              this.coords.forEach((coord, index)=>{
                if (coord.id==this.activeId)
                {
                  console.error(coord)
                  this.coordsCenter=coord.coords
                  coord.icon = {
                    zIndex:"OVERLAY_ACTIVE",
                    layout: 'default#imageWithContent',
                    imageHref: this.getMarker(coord.id, coord.isOwner, coord.online, coord.otherInfo, true),
                    iconImageSize: [140, 140],
                    iconImageOffset: [-32, -45],
                    imageSize: [70, 70],
                    imageOffset: [-32, -45]
                  }
                }else{
                  coord.icon = {
                    zIndex:"OVERLAY_ACTIVE",
                    layout: 'default#imageWithContent',
                    imageHref: this.getMarker(coord.id, coord.isOwner, coord.online, coord.otherInfo, false),
                    iconImageSize: [140, 140],
                    iconImageOffset: [-32, -45],
                    imageSize: [70, 70],
                    imageOffset: [-32, -45]
                  }
                }
              })
            }else{
              this.coords.forEach((coord, index)=>{
                if (coord.id==this.activeId)
                {
                 this.coordsCenter=coord.coords
                }
              })
            }
          }
        }
      }
      console.log(this.coords)
      return this.coords
    }
  },
  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      this.$refs.map?.myMap?.balloon?.close()
      console.log(this.getLastMapData)
      this.buildMarkers(this.getLastMapData)
    }
  },
  methods:{
    ...mapActions(['getNewDataHeadLight', 'getHLNames', 'getLastMetrics', 'getDevices', 'getCoordsDevices']),
    ...mapMutations(['updateChartOne','subscribeChart','selectDevice', 'selectDeviceStatus', 'buildMarkersVuex', 'updateMarkerVuex', 'setPointLastData']),
    mouseEnter(data){
      console.warn(data)
      this.lastMarker = data
      this.markerClick(data)
      console.log(this.$refs.map.myMap.balloon)
      if(data.balloonContent == null) data.balloonContent = '<p><h1 class="baloon-test fix-height">Undefined</h1></p>'
      console.log(this.$refs.map.myMap.balloon)
      if(!data.isOwner && !data.online) data.balloonContent = `<p><h1 class="baloon-test fix-height">Id: ${data.Id}, Name:${data.objectName}</h1></p>`
      this.$refs.map.myMap.balloon.open(data.coords, data.balloonContent, {offset: [10,-50],});
    },
    focusByDeviceId(DeviceId){
      const findDevice = this.coords.filter(device => device.id == DeviceId)
      if(findDevice.length > 0){
        //console.log(findDevice[0])
        //this.coordsCenter = findDevice[0].coords
        //this.resizeToBigMap()
        //this.coordsCenter = findDevice[0].coords
      }
    },
    async markerClick(data){
      /*this.lastMarker = data
      if(this.connectionSensors == null){
        await this.ConnectedToStateHub()
      }
      if(this.connectionSensors.connectionState != 'Connected'){
        setTimeout(async () => {
          await this.markerClick(data)
        }, 500)
        return
      }*/
      //console.log(data)
      if(this.fetchSensorData.DeviceId != null){
        this.getStateConnection.invoke("EndGettingValuesDeviceById", {
          UserId: this.getUser.id,
          DeviceId: this.fetchSensorData.DeviceId
        })
      }
      this.fetchSensorData.DeviceId = data.id
      const device = this.getDeviceById(data.id)
      console.log(device)
      if(device.deviceType == 4){
        await this.getLastMetrics(device.serialNumber)
        .then(metrics => {
          console.warn(metrics)
          const metricsParse = JSON.parse(metrics.lastData)
          setTimeout(() => {
            this.fetchSensorData.PanelElementsId.forEach(element => {
              metricsParse.forEach(metric => {
                if(element.id == `${metric.ElementId}${metric.Type}` && element.elem != null){
                  let oldText = element.elem.innerText.split(":")[0]
                  element.elem.innerText = `${oldText}: ${parseFloat(metric.State.replace(',', '.')).toFixed(3)}${this.$t(`Sensors.${SensorTypeEnum[metric.Type]}`)}`
                }
              })
            })
          }, 1500)
          //this.refreshData(this.metricsParse)
        })
      }else if(device.subDeviceType == 10){
        const HLNames = {}
        await this.getHLNames(device.id)
        .then(result => {
          console.log(result)
          HLNames.Input1Name = result.inp1Name
          HLNames.Input2Name = result.inp2Name
          HLNames.Input3Name = result.inp3Name
          
          HLNames.Input1Measure = result.inp1Measure
          HLNames.Input2Measure = result.inp2Measure
          HLNames.Input3Measure = result.inp3Measure
        })
        .catch(err => {
          console.log(err)
        })

        await this.getNewDataHeadLight(device.id)
        .then(result => {
          console.log(result)
          const HLData = {
            2000: parseInt(result.headLightSensors.input1),
            2001: parseInt(result.headLightSensors.input2),
            2002: parseInt(result.headLightSensors.input3),
            2003: parseInt(result.headLightSensors.out1),
            2004: parseInt(result.headLightSensors.out2),
            2005: parseInt(result.headLightSensors.out3),
            2006: parseInt(result.headLightSensors.adxl1),
            2007: parseInt(result.headLightSensors.adxl2),
            2008: parseInt(result.headLightSensors.adxl2),
            2009: parseFloat(result.headLightSensors.temperature),
          }
          setTimeout(() => {
            this.fetchSensorData.PanelElementsId.forEach(element => {
              const tp = element.id.substring(4)
              if (element.elem != null){
                let oldText = element.elem.innerText.split(":")[0]
                if (tp == 2000) {
                  element.elem.innerText = `${HLNames.Input1Name}: ${HLData[tp]}${HLNames.Input1Measure}`
                } else if(tp == 2001) {
                  element.elem.innerText = `${HLNames.Input2Name}: ${HLData[tp]}${HLNames.Input2Measure}`
                } else if(tp == 2002) {
                  element.elem.innerText = `${HLNames.Input3Name}: ${HLData[tp]}${HLNames.Input3Measure}`
                } else if(tp == 2003) {
                  element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                } else if(tp == 2004) {
                  element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                } else if(tp == 2005) {
                  element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                } else if(tp == 2006) {
                  element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                } else if(tp == 2007) {
                  element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                } else if(tp == 2008) {
                  element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                } else {
                  element.elem.innerText = `${oldText}: ${HLData[tp].toFixed(2)}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
                }
              }
            })
          }, 1500)
        })
        .catch(err => {
          console.log(err)
        })
        this.getStateConnection.invoke("StartGettingValuesDeviceById", {
          UserId: this.getUser.id,
          DeviceId: this.fetchSensorData.DeviceId
        })
      }else{
        this.getStateConnection.invoke("StartGettingValuesDeviceById", {
          UserId: this.getUser.id,
          DeviceId: this.fetchSensorData.DeviceId
        })
      }
      setTimeout(() => {
        this.fetchSensorData.PanelElementsId = []
        this.buttonsElements =[]
        this.spanElements =[]
        this.buttonsClass.forEach(cls => {
          const el = document.getElementsByClassName(cls.raw)[0]
          const elspan = document.getElementById(cls.raw)
          if(elspan){
            this.spanElements.push(elspan)
          }
          if(el){
            this.buttonsElements.push(el)
            let dt = ''
            if(cls.raw[4] == 1){
              dt = '.T.'
            }
            else if(cls.raw[4] == 2){
              dt = '.V.'
            }
            else if(cls.raw[4] == 3){
              dt = '.P.'
            }
            console.log(cls.raw.slice(0, 4))
            this.getSubscribeCharts.forEach(sens => {
              //console.log(sens)
              if(sens.PanelElementId == cls.raw.slice(0, 4)){
                el.disabled="true"
                el.classList.add('disabled')
                elspan.classList.add('font-weight-bold')
                //console.log('HEHE')
              }
            })
            if(this.$route.path != '/Account'){
              elspan.onclick = () => {
                const dataSub = data.object.sensorTypes.filter(sens => sens.PanelElementId == cls.raw.slice(0, 4))
                console.log(data)
                this.clearChart()
                this.$root.$emit('unSubscribeOnSensorsCh')
                this.$root.$emit('clearSingleUpdate')
                if(dataSub.length > 0){
                  const dat = {
                    ...dataSub[0],
                    isShowOnChart: true,
                    deviceId: data.object.Id
                  }
                  console.log(dat)
                  this.subscribeChart(dat)
                  this.$root.$emit('addSensorToSubscribe', dat)
                }
                let dataSubscription = {
                  UserId: this.getUser.id,
                  DeviceId: data.object.Id,
                  PanelControlId: cls.raw.slice(0, 4)
                }
                console.log(dataSubscription)
                if(this.getStateConnection && this.getStateConnection.state == "Connected"){


                  console.log(dataSubscription)
                  this.getStateConnection.invoke("SubscribeGettingValuesDeviceById", dataSubscription)
                  .then(res => console.log('subscribeOnSensor ' + cls.raw.slice(0, 4) + ' ' + data.id))
                  .catch(e => console.log(e))
                }
                el.disabled="true"
                el.classList.add('disabled')
                elspan.classList.add('font-weight-bold')
                this.$root.$emit('closeBigMapGlobal')
              }
              el.onclick = () => {
                /*let dataSubscription = {
                  UserId: this.getUser.id,
                  DeviceId: data.id,
                  PanelControlId: cls.raw.slice(0, 4),
                  Descriptor: dt,
                  PanelControlName: cls.PanelElementName,
                  Name: data.objectName
                }*/
                //console.log(dataSubscription)
                const dataSub = data.object.sensorTypes.filter(sens => sens.PanelElementId == cls.raw.slice(0, 4))
                console.log(data)
                if(dataSub.length > 0){
                  const dat = {
                    ...dataSub[0],
                    isShowOnChart: true,
                    deviceId: data.object.Id
                  }
                  console.log(dat)
                  this.subscribeChart(dat)
                  this.$root.$emit('addSensorToSubscribe', dat)
                }
                let dataSubscription = {
                  UserId: this.getUser.id,
                  DeviceId: data.object.Id,
                  PanelControlId: cls.raw.slice(0, 4)
                }
                console.log(dataSubscription)
                if(this.getStateConnection && this.getStateConnection.state == "Connected"){
                  console.log(dataSubscription)
                  this.getStateConnection.invoke("SubscribeGettingValuesDeviceById", dataSubscription)
                  .then(res => console.log('subscribeOnSensor ' + cls.raw.slice(0, 4) + ' ' + data.id))
                  .catch(e => console.log(e))
                }
                el.disabled="true"
                el.classList.add('disabled')
                elspan.classList.add('font-weight-bold')
                this.$root.$emit('closeBigMapGlobal')
              }
            }
          }
        })
        if(data.isOwner){
          data.object.sensorTypes.forEach(sensor => {
            let elem = document.getElementById(`${sensor.PanelElementId}${sensor.Type}`)
            this.fetchSensorData.PanelElementsId.push({elem: elem, id:`${sensor.PanelElementId}${sensor.Type}`})
          })
        }else{
          data.object.openSensorTypes.forEach(sensor => {
            let elem = document.getElementById(`${sensor.PanelElementId}${sensor.Type}`)
            this.fetchSensorData.PanelElementsId.push({elem: elem, id:`${sensor.PanelElementId}${sensor.Type}`})
          })
        }
      }, 500)
    },
    resizeToBigMap(){
      this.$refs.map?.myMap?.balloon?.close()
      console.log('resizeToBigMap ' + this.bigMap)
      if(!this.bigMap){
        this.bigMap = !this.bigMap
        this.htmlFather.className+=" scroll-off"
        console.log(this.scrollElement)
        this.$refs["map"]?.$el?.scrollIntoView({ behavior: 'smooth' });

        this.$root.$emit('closeAccountGlobal')
        /*this.$nextTick(() => {
          this.$vuetify.goTo("map", {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic'
          })
        });*/
      }

    },
    async resizeToMiniMap(){
      console.log(`resizeToMiniMap ${this.bigMap}`)
      if(this.bigMap){
        this.htmlFather.className = this.htmlFather.className.replace('scroll-off','');
        this.bigMap = !this.bigMap
        this.htmlFather.scrollIntoView({ behavior: 'smooth' });
        console.log(this.htmlFather)
      }
      //this.hideAccount = true
    },
    getMarker(selectedDevice, isOwner, isOnline, isOpen, isSelected){
      if(this.getSelectedDevice !=null){
        isSelected = selectedDevice == this.getSelectedDevice.id
      }
      console.log(`selectedDevice = ${selectedDevice} isOwner = ${isOwner} isOnline = ${isOnline} isOpen = ${isOpen} isSelected = ${isSelected}`)
      if(isOwner){
        if(isOnline){
          if(isOpen){
            return isSelected ? markers.markerOwnerOnlineOpenSelected : markers.markerOwnerOnlineOpen
          }else{
            return isSelected ? markers.markerOwnerOnlineCloseSelected : markers.markerOwnerOnlineClose
          }
        }else{
          if(isOpen){
            return isSelected ? markers.markerOwnerOfflineOpenSelected : markers.markerOwnerOfflineOpen
          }else{
            return isSelected ? markers.markerOwnerOfflineCloseSelected : markers.markerOwnerOfflineClose
          }
        }
      }else{
        if(isOnline){
          if(isOpen){
            return isSelected ? markers.markerOnlineOpenSelected : markers.markerOnlineOpen
          }else{
            return isSelected ? markers.markerOnlineCloseSelected : markers.markerOnlineClose
          }
        }else{
          if(isOpen){
            return isSelected ? markers.markerOfflineOpenSelected : markers.markerOfflineOpen
          }else{
            return isSelected ? markers.markerOfflineCloseSelected :  markers.markerOfflineClose
          }
        }
      }
    },
    async ConnectedToMapHub(){
      
    },
    async ConnectedToStateHub(){
    },
    buildSensor(data, isOwner){
      let acc =""
      data.forEach(sensor => {
        const btn = `<button class="black-btn ${sensor.PanelElementId}${sensor.Type}">+</button></div>`
        //console.log(this.filterStateCode)
        if(this.$route.path == '/Account' || sensor.Type == this.filterStateCode)
        acc += `<div class="sensor"><span id="${sensor.PanelElementId}${sensor.Type}"
        class="${isOwner ? (sensor.Available != 2 ? '' : 'bg-gray') : ''}">
        ${sensor.PanelElementName}: </span>
        ${this.$route.path == '/Account' ? '' : btn}</div>`
        if(this.buttonsClass.filter(b => b.raw == `${sensor.PanelElementId}${sensor.Type}`).length == 0){
          this.buttonsClass.push({raw: `${sensor.PanelElementId}${sensor.Type}`, PanelElementName: sensor.PanelElementName})
        }
      })
      //console.log(this.buttonsClass)
      return acc
    },
    clearChart(){
      this.buttonsElements.forEach(el => {
        el.removeAttribute("disabled");
        el.classList.remove('disabled')
      })
      this.spanElements.forEach(elspan => {
        elspan.classList.remove('font-weight-bold')
      })
    },
    async buildMarkers(data){
      console.log(this.getSelectedDevice)
      if(this.getSelectedDevice == null){
        this.activeId = 0
      }else{
      this.activeId = this.getSelectedDevice.id
      }
      console.log(data)
      if(data == null) return
      this.coords = []
      data.forEach((object, index) => {
        const isConnected = this.getDeviceById(object.Id)?.headStatus.description == 'Connected'
        let deviceFind = null
        const isOwner = this.getDeviceById(object.Id)?.deviceOwnerId == this.getUser.id
        if (object.sensorTypes!=null)
        {
          if(this.$route.path == '/Account' 
          || object.sensorTypes.filter(s => s.Type == this.filterStateCode).length > 0 
          || object.openSensorTypes.filter(s => s.Available == 2&& s.Type == this.filterStateCode).length > 0 
          || (isOwner && this.$route.path == '/Account')){
            console.log(`isConnected ${isConnected} ${this.getDeviceById(object.Id)?.name} ${object.headConnectedStatusCode}`)
            this.coords.push({
              coords:[object.Latitude, object.Longitude],
              id: object.Id,
              online: isConnected,
              object: object,
              otherInfo: object.openSensorTypes != null && object.openSensorTypes.length > 0,
              objectName: isOwner? this.getDeviceById(object.Id)?.name : `Object${object.Id}`,
              isOwner: isOwner,
              icon:{
                zIndex:"OVERLAY_ACTIVE",
                layout: 'default#imageWithContent',
                imageHref: this.getMarker(object.Id, isOwner, isConnected, object.openSensorTypes != null && object.openSensorTypes.length > 0, data.Id==this.activeId),
                imageSize: [70, 70],
                imageOffset: [-32, -45]
              },
              balloonContent: isConnected ? isOwner? `
                <p><h1 class="baloon-test fix-height">${this.getDeviceById(object.Id)?.name}${object.sensorTypes.length == 0 ? '' : ` ${this.$t('Map.Tooltip.All')} ${this.filterState==null || this.$route.path == '/Account'?'':this.filterState + ' '}${this.$t('Map.Tooltip.Sensors')}:     `}</h1></p>
                ${this.buildSensor(object.sensorTypes, isOwner)}
              `: object.openSensorTypes.length == 0 ? null :`
                <p><h1 class="baloon-test fix-height">${object.openSensorTypes.length == 0 ? '' : `${this.$t('Map.Tooltip.Object')}${object.Id} ${this.$t('Map.Tooltip.Open')} ${this.filterState==null || this.$route.path == '/Account'?'':this.filterState + ' '}${this.$t('Map.Tooltip.Sensors')}:     `}</h1></p>
                ${this.buildSensor(object.openSensorTypes, isOwner)}
              ` : null,
            })
          }
        }
        
      })
      console.log(this.coords)
    },
    async updateMarker(data){
      this.activeId = this.getSelectedDevice.id
      console.log("updating...")
      console.log(data)
      console.log(this.activeId)
      this.setPointLastData(data)
      const indexCoord = this.coords.findIndex(coord => coord.id == data.Id)
      const isConnected = this.getDeviceById(data.Id)?.headStatus.description == 'Connected'
      const isOwner = this.getDeviceById(data.Id)?.deviceOwnerId == this.getUser.id
      console.log(indexCoord)
      if(indexCoord >= 0){
        this.selectDeviceStatus({
          id: data.Id,
          status: isConnected
        })
        this.coords[indexCoord].coords=[data.Latitude, data.Longitude]
        this.coords[indexCoord].icon={
          layout: 'default#imageWithContent',
          imageHref: this.getMarker(data.Id, isOwner, isConnected, data.openSensorTypes != null && data.openSensorTypes.length > 0, data.Id==this.activeId),
          imageSize: [70, 70],
          imageOffset: [-32, -45]
        }
        this.coords[indexCoord].id= data.Id
        this.coords[indexCoord].object = data
        this.coords[indexCoord].isOwner = isOwner
        this.coords[indexCoord].online= isConnected
      }
    },
    async create(){
      this.scrollElement = document.getElementsByClassName("map")[0]
      this.htmlFather = document.getElementsByClassName("fixsa")[0]
      this.$root.$on('updateSensors', async (data) => {
        this.sensors = data
      })
      this.sensorTypes = [
        '',
        this.$t('SensorNames.Temperature'),
        this.$t('SensorNames.Humidity'),
        this.$t('SensorNames.Pressure')
      ]
      await this.getDevices()
      if(this.getLoadDevices.length > 0){
        //this.coordsCenter = [this.getLoadDevices[0].latitude, this.getLoadDevices[0].longitude]
      }
      //console.log('mapcreated')
      this.$root.$on('updateChartFull', () => {
        //console.log('updateChartFull')
        //this.connection.stop()
        //this.ConnectedToMapHub()
      })
      this.$root.$on('clearChart', this.clearChart)
      this.$root.$on('closeBigMapGlobal', this.resizeToMiniMap)
      this.$root.$on('openBigMapGlobal', this.resizeToBigMap)
      this.$root.$on('OpenBigMapGlobalAndFocusByDeviceId', this.focusByDeviceId)
      
      await this.ConnectedToMapHub()
    },
    destroy(){
      if(this.connection != null) this.connection.stop()
      if(this.connectionSensors != null) this.connectionSensors.stop()
    },
    async receiveMessage(message){
      try{
        const parsedMessage = JSON.parse(message)
        
        const HLNames = {}
        await this.getHLNames(device.id)
        .then(result => {
          console.log(result)
          HLNames.Input1Name = result.inp1Name
          HLNames.Input2Name = result.inp2Name
          HLNames.Input3Name = result.inp3Name
          
          HLNames.Input1Measure = result.inp1Measure
          HLNames.Input2Measure = result.inp2Measure
          HLNames.Input3Measure = result.inp3Measure
        })
        .catch(err => {
          console.log(err)
        })
        const HLData = {
          2000: parseInt(parsedMessage[1].replace(' ', '').split(':')[1]),
          2001: parseInt(parsedMessage[2].replace(' ', '').split(':')[1]),
          2002: parseInt(parsedMessage[3].replace(' ', '').split(':')[1]),

          2003: parseInt(parsedMessage[4].replace(' ', '').split(':')[1]),
          2004: parseInt(parsedMessage[5].replace(' ', '').split(':')[1]),
          2005: parseInt(parsedMessage[6].replace(' ', '').split(':')[1]),

          2006: parseInt(parsedMessage[8].replace(' ', '').split(':')[1]),
          2007: parseInt(parsedMessage[9].replace(' ', '').split(':')[1]),
          2008: parseInt(parsedMessage[10].replace(' ', '').split(':')[1]),
          2009: parseFloat(parsedMessage[7].replace(' ', '').split(':')[1])
        }
        setTimeout(() => {
          this.fetchSensorData.PanelElementsId.forEach(element => {
            if(element.elem != null){
              const tp = element.id.substring(4)
              console.log(tp)
              let oldText = element.elem.innerText.split(":")[0]
              if (tp == 2000) {
                element.elem.innerText = `${HLNames.Input1Name}: ${HLData[tp]}${HLNames.Input1Measure}`
              } else if(tp == 2001) {
                element.elem.innerText = `${HLNames.Input2Name}: ${HLData[tp]}${HLNames.Input2Measure}`
              } else if(tp == 2002) {
                element.elem.innerText = `${HLNames.Input3Name}: ${HLData[tp]}${HLNames.Input3Measure}`
              } else if(tp == 2003) {
                element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              } else if(tp == 2004) {
                element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              } else if(tp == 2005) {
                element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              } else if(tp == 2006) {
                element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              } else if(tp == 2007) {
                element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              } else if(tp == 2008) {
                element.elem.innerText = `${oldText}: ${HLData[tp]}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              } else {
                element.elem.innerText = `${oldText}: ${HLData[tp].toFixed(2)}${this.$t(`Sensors.${SensorTypeEnum[tp]}`)}`
              }
            }
          })
        }, 1500)

      }catch(e){
        let msg = message.split(';')
        msg.forEach(element => {
          if(element.length > 12){
            let elementId = element[1] + element[2] + element[4] + element[5]
            let typeSensor = null
            if(element.includes('.T.')){
              typeSensor = 1
            } else if(element.includes('.P.')){
              typeSensor = 3
            } else if(element.includes('.V.')){
              typeSensor = 2
            }
            const findEl = this.fetchSensorData.PanelElementsId.filter(sensor => sensor.id == `${elementId}${typeSensor}`)
            if(typeSensor==null && (findEl.length == 0 || !findEl[0].elem)){
              const findEl2 = this.fetchSensorData.PanelElementsId.filter(sensor => sensor.id.includes(`${elementId}`))
              if(findEl2.length == 0 || !findEl2[0].elem){
                return
              }else{
                const enumType = SensorTypeEnum[findEl2[0].id.slice(4, 6)]//this.$t(`Sensors.${SensorTypeEnum[metric.Type]}`
                if(enumType == SensorTypeEnum[28]|| enumType == SensorTypeEnum[30] || enumType == SensorTypeEnum[31] || enumType == SensorTypeEnum[36]){ //ACP
                  let oldText1 = findEl2[0].elem.innerText.split(":")[0]
                  let parsedState1 = `${(parseFloat(element.slice(13, 15))/10.0)}`
                  findEl2[0].elem.innerText = oldText1 + ": " + parsedState1 + this.$t(`Sensors.${SensorTypeEnum[findEl2[0].id.slice(4, 6)]}`)
                }else{
                  let oldText1 = findEl2[0].elem.innerText.split(":")[0]
                  let parsedState1 = `${parseInt(element.slice(13, 15))}`
                  findEl2[0].elem.innerText = oldText1 + ": " + parsedState1
                }
                //console.log(findEl2[0].id.slice(4, 6))
                //console.log(`COOL:${message}`)
                return
              }
            }
            if(findEl[0] && findEl[0].elem){
              let oldText = findEl[0].elem.innerText.split(":")[0]
              let parsedState = ""
              const dataSens = element.slice(1, 6)
              const temp = element.slice(9)
              let tempOut = temp[0] == '0' ? '' : '-'
              tempOut += temp[1] + temp[2] + '.' + temp[3]
              if(element.includes('.T.') && dataSens != '01.01'){
                parsedState = tempOut + "°C"
              }
              else if(element.includes('.P.') && dataSens != '01.01'){
                parsedState = temp[0] + temp[1] + temp[2] + this.$t('Sensors.Pressure')
                
              }
              else if(element.includes('.V.') && dataSens != '01.01'){
                parsedState = temp[1] + temp[2] + '.' + temp[3] + "%"
              }
              findEl[0].elem.innerText = oldText + ": " + parsedState
            }
            return
          }
        })
      }
    },
    subscribeOnMapHub(){
      this.$root.$on('MapConnectionOnConnected', (message) => {
        this.buildMarkers(message)
      })
      this.$root.$on('MapConnectionMapDeviceUpdate', (message) => {
        console.error('MapConnectionMapDeviceUpdate')
        console.error(message)
        this.updateMarker(message)
      })
      if(this.coords.length == 0){
        this.buildMarkers(this.getLastMapData)
      }
    },
    subscribeStateHub(){
      this.$root.$on('StateConnectionReceiveMessage', (message) => {
        this.receiveMessage(message)
      })
    }
  },
  async mounted(){
    console.log(`YaMap created start`)
    this.scrollElement = document.getElementsByClassName("map")[0]
    this.htmlFather = document.getElementsByClassName("fixsa")[0]
    this.$root.$on('closeBigMapGlobal', () => {
      console.warn('resizeToMiniMap')
      this.$root.$emit('showHiddenChart')
      this.resizeToMiniMap()
    })
    this.$root.$on('openBigMapGlobal', this.resizeToBigMap)
    this.$root.$on('OpenBigMapGlobalAndFocusByDeviceId', this.focusByDeviceId)
    this.$root.$on('updateYaMapFilter', () => {
      this.$refs.map?.myMap?.balloon?.close()
      this.buildMarkers(this.getLastMapData)
    })

    this.subscribeOnMapHub()
    this.subscribeStateHub()
    console.log(`YaMap created end`)
    //await this.create()
    //await this.ConnectedToStateHub()
  },
  beforeDestroy(){
    this.$root.$off('updateYaMapFilter')
    this.$root.$off('closeBigMapGlobal')
    this.$root.$off('openBigMapGlobal')
    this.$root.$off('OpenBigMapGlobalAndFocusByDeviceId')
    //this.destroy()
  }
}
</script>

<style lang="sass">
.sensor > span
  cursor: pointer
ymaps[class$="-i-custom-scroll"]
  border-radius: 5px !important
  background-color: #e6e6e6 !important
  opacity: 0.85
ymaps[class$="-i-custom-scroll"] > ymaps
  border-radius: 5px !important
  background-color: #e6e6e6 !important
ymaps[class$="balloon__content"]
  background-color: #e6e6e6 !important
ymaps[class$="-balloon__tail"]::after
  background-color: #e6e6e6 !important
.disabled
  background-color: #bababa !important
.scroll-off
  overflow: hidden !important

.fix-height
  width: 270px !important

.black-btn
  background-color: black
  color: white
  border-radius: 3px
  float: right
  margin-top: -3px
  padding: 3px 10px 3px 10px !important

.ya-map
  opacity: 0.4
  height: 250px

.ya-map-open
  height: 100%
  position: absolute
  overflow-y: hidden
  top: 0
  left: 0
  opacity: 1

.black-btn:hover
  opacity: 0.8

.sensor
  margin-bottom: 10px

.map
  margin-top: 100px
  width: 100%

</style>